/* eslint-disable no-console */
import React from 'react';
import { Application, AppClient } from '@vizsla/types';
import { useClient, useUserCurrentApp } from '..';
export const useClientRedirect = () => {
    const { client, clientsUrl } = useClient();
    const { loading: isAppLoading, currentApp } = useUserCurrentApp();
    React.useEffect(() => {
        let redirectUrl;
        if (!isAppLoading && currentApp) {
            const isAdminCurrentApp = currentApp === Application.OrganizationAdminClient ||
                currentApp === Application.VizslaAdminClient;
            const isCheckinCurrentApp = currentApp === Application.CheckInClient;
            if (client === AppClient.AdminClient && isAdminCurrentApp) {
                redirectUrl = clientsUrl.adminClientUrl;
            }
            if (client === AppClient.CheckInClient && isCheckinCurrentApp) {
                redirectUrl = clientsUrl.checkinClientUrl;
            }
        }
        if (redirectUrl) {
            window.location.replace(redirectUrl);
        }
    }, [client, clientsUrl, currentApp, isAppLoading]);
};
