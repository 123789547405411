var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { TEAM_FUNDRAISING_BY_EXPERIENCE_QUERY, } from '@vizsla/graphql';
/**
 * Find teams by experience ID.
 */
export function useListTeamFundraisingsByExperience() {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [execute, { error }] = useLazyQuery(TEAM_FUNDRAISING_BY_EXPERIENCE_QUERY);
    /**
     * Fetch all the teams by experience and setup into {@link data} variable.
     * @param id Experience ID
     * @returns A list of {@link TeamFundraising}
     */
    function fetch(id) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            if (!id)
                return;
            try {
                setLoading(true);
                const response = yield execute({
                    variables: {
                        experienceID: id,
                    },
                });
                const result = (_b = (_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.teams) === null || _b === void 0 ? void 0 : _b.items;
                if (Array.isArray(result)) {
                    setLoading(false);
                    setData(result);
                    return;
                }
                throw new TypeError("The 'teams' must be a list of items");
            }
            catch (err) {
                setLoading(false);
                throw err;
            }
        });
    }
    return {
        data,
        error,
        loading,
        fetch,
    };
}
