var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback } from 'react';
import { InboxesListDocument, useDeleteInboxByUserMutation } from '@vizsla/graphql';
import { useCurrentUserQuery } from '.';
import { InboxEvents, useEventBus } from '../inbox.events';
export function useRemoveInboxMutation({ inbox, onRemoveCompleted }) {
    const { publish } = useEventBus();
    const { userId, loading: currentUserIsLoading } = useCurrentUserQuery();
    const [removeInbox, { loading, error }] = useDeleteInboxByUserMutation();
    const mutation = useCallback(() => __awaiter(this, void 0, void 0, function* () {
        if (!inbox || !userId)
            return;
        yield removeInbox({
            variables: { userId, inboxId: inbox.id },
            refetchQueries: [InboxesListDocument],
            awaitRefetchQueries: true,
        });
        onRemoveCompleted();
        publish(InboxEvents.InboxDeleted, { inbox });
    }), [inbox, userId, removeInbox, onRemoveCompleted, publish]);
    return { mutation, loading: loading || currentUserIsLoading, error };
}
