import { useVizslaOrganizationQuery } from '@vizsla/graphql';
export const useOrganizationById = (organizationId) => {
    const { data, loading: organizationIsLoading, refetch, } = useVizslaOrganizationQuery({
        fetchPolicy: 'no-cache',
        variables: {
            id: organizationId || '',
        },
        skip: !organizationId,
    });
    const organization = data === null || data === void 0 ? void 0 : data.vizslaOrganization;
    return { organization, organizationIsLoading, refetch };
};
