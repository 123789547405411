import { useMemo } from 'react';
import { useUserQuery } from '@vizsla/graphql';
import { getUserFullName } from '@vizsla/utils';
export function useCurrentUser() {
    const { data, loading, error } = useUserQuery({
        fetchPolicy: 'cache-first',
        nextFetchPolicy: 'cache-first',
    });
    const user = useMemo(() => { var _a; return (_a = data === null || data === void 0 ? void 0 : data.user) !== null && _a !== void 0 ? _a : null; }, [data]);
    const firstName = useMemo(() => { var _a; return (_a = user === null || user === void 0 ? void 0 : user.firstName) !== null && _a !== void 0 ? _a : null; }, [user]);
    const lastName = useMemo(() => { var _a; return (_a = user === null || user === void 0 ? void 0 : user.lastName) !== null && _a !== void 0 ? _a : null; }, [user]);
    const fullName = useMemo(() => getUserFullName(user), [user]);
    return {
        user,
        firstName,
        lastName,
        fullName,
        loading,
        error,
    };
}
