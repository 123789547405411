import React from 'react';
import { InboxEvents, useEventBus } from '@vizsla/providers';
import { useNotification } from '../shared';
import { useInbox } from './useInbox';
export function useInboxNotifications() {
    const { selectInbox } = useInbox();
    const notifications = useNotification();
    const { subscribe } = useEventBus();
    const onInboxDeletedCallback = React.useCallback(() => notifications.success('Inbox has been deleted'), [notifications]);
    const onNewMessageCallback = React.useCallback(({ inbox, message }) => {
        notifications.success(`New message from ${message.createdBy.fullName}`, {
            onClick: () => selectInbox(inbox),
        });
    }, [notifications, selectInbox]);
    const onMessageSentCallback = React.useCallback(() => notifications.success('Your message has been sent'), [notifications]);
    const onInboxMessageDeletedCallback = React.useCallback(() => notifications.success('Message has been deleted'), [notifications]);
    React.useEffect(() => {
        const subscriptions = [
            subscribe(InboxEvents.MessageReceived, onNewMessageCallback),
            subscribe(InboxEvents.MessageSent, onMessageSentCallback),
            subscribe(InboxEvents.MessageDeleted, onInboxMessageDeletedCallback),
            subscribe(InboxEvents.InboxDeleted, onInboxDeletedCallback),
        ];
        return () => subscriptions.forEach(unsubscribe => unsubscribe());
    }, [
        subscribe,
        onNewMessageCallback,
        onMessageSentCallback,
        onInboxMessageDeletedCallback,
        onInboxDeletedCallback,
    ]);
}
