import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const FileFragmentFragmentDoc = gql `
  fragment FileFragment on File {
    id
    fileId
    filename
    downloadUrl
  }
`;
export const CheckinAttendeeInfoFragmentDoc = gql `
  fragment CheckinAttendeeInfo on Attendee {
    id
    user {
      id
      firstName
      lastName
      gender
      birthDate
      avatar {
        ...FileFragment
      }
    }
    teamMembership {
      id
      role
      team {
        id
        name
      }
    }
    bibAssignment {
      id
      bibNumber
    }
  }
  ${FileFragmentFragmentDoc}
`;
export const CheckinCampaignFragmentFragmentDoc = gql `
  fragment CheckinCampaignFragment on Campaign {
    id
    name
    timeZone
    startDate
    endDate
    campaignStatus
    logo {
      ...FileFragment
    }
  }
  ${FileFragmentFragmentDoc}
`;
export const CheckinExperienceInfoFragmentDoc = gql `
  fragment CheckinExperienceInfo on Experience {
    id
    name
    experienceType
    attendees {
      count
    }
    dateRangeType
    timeZone
    startDate
    endDate
    isActive
    hideFromPublic
  }
`;
export const OrderFragmentCheckinFragmentDoc = gql `
  fragment OrderFragmentCheckin on Order {
    id
    status
    reasonStatus
    totalAmount
    user {
      id
      firstName
      lastName
    }
  }
`;
export const AsyncTaskFragmentFragmentDoc = gql `
  fragment AsyncTaskFragment on AsyncTask {
    id
    name
    args
    status
  }
`;
export const Attendee_User_FragmentFragmentDoc = gql `
  fragment ATTENDEE_USER_FRAGMENT on User {
    id
    firstName
    lastName
    email
    avatar {
      downloadUrl
    }
  }
`;
export const Client_Attendee_FragmentFragmentDoc = gql `
  fragment CLIENT_ATTENDEE_FRAGMENT on Attendee {
    id
    user {
      ...ATTENDEE_USER_FRAGMENT
    }
    experience {
      id
      name
    }
    registrationOption {
      id
      name
    }
    teamMembership {
      role
      team {
        id
        name
      }
    }
    fundraising {
      id
      goal
      createdAt
      allocatedDonations(groupBy: { query: { amount: { as: "raised", fn: { aggregate: SUM } } } }) {
        groups {
          raised: Float
        }
      }
    }
  }
  ${Attendee_User_FragmentFragmentDoc}
`;
export const CampaignsPageCampaignFragmentFragmentDoc = gql `
  fragment CampaignsPageCampaignFragment on Campaign {
    id
    name
    code
    contactEmail
    contactPhone {
      code
      number
    }
    logo {
      id
      downloadUrl
    }
    image {
      id
      downloadUrl
    }
    city
    state
    campaignType
    fundraisingGoal
    fundraiserGoal
    attendanceFundraisersCountGoal
    teamGoal
    teams {
      count
      items {
        id
        createdAt
      }
    }
    donorGoal
    donors {
      count
    }
    attendees: experiences(
      groupBy: { query: { attendees: { id: { fn: { aggregate: COUNT }, as: "count" } } } }
    ) {
      groups {
        count: Int
      }
    }
    fundraisers: experiences(
      groupBy: {
        query: { attendees: { fundraisingPageLink: { fn: { aggregate: COUNT }, as: "count" } } }
      }
    ) {
      groups {
        count: Int
      }
    }
    totalRaised
    startDate
    endDate
    campaignStatus
    webLink
    program {
      id
      name
    }
    createdAt
    updatedAt
    createdBy {
      id
      firstName
      lastName
    }
    experiences {
      count
      items {
        id
        name
        startDate
        endDate
        experienceLogoImage {
          id
          downloadUrl
        }
        image {
          id
          downloadUrl
        }
        experienceHeaderImage {
          id
          downloadUrl
        }
      }
    }
    dnsRecord {
      id
      slug
    }
    campaignTemplates {
      id
      templates {
        items {
          id
          templateType {
            type
            title
          }
        }
      }
    }
    campaignStatus
    teams {
      count
    }
  }
`;
export const User_Recent_FragmentFragmentDoc = gql `
  fragment USER_RECENT_FRAGMENT on User {
    firstName
    lastName
    email
    avatar {
      downloadUrl
    }
  }
`;
export const CampaignDiscountsSettingsFragmentFragmentDoc = gql `
  fragment CampaignDiscountsSettingsFragment on DiscountCodeSetting {
    id
    name
    category
    amount
    amountType
    datePickerType
    startDate
    startTime
    endDate
    endTime
    allocationType
    experienceType {
      id
      name
    }
    limitToOneUsePerAttendee
    totalUses
    usesCount
    isEnabled
  }
`;
export const CampaignFaqExperienceFragmentFragmentDoc = gql `
  fragment CampaignFAQExperienceFragment on Experience {
    id
    name
  }
`;
export const CampaignFaqFragmentFragmentDoc = gql `
  fragment CampaignFaqFragment on CampaignFAQ {
    id
    question
    answer
    allocation {
      items {
        ...CampaignFAQExperienceFragment
      }
    }
    userGroup
  }
  ${CampaignFaqExperienceFragmentFragmentDoc}
`;
export const CampaignQuestionExperienceFragmentFragmentDoc = gql `
  fragment CampaignQuestionExperienceFragment on Experience {
    id
    name
  }
`;
export const DefaultCampaignQuestionFragmentFragmentDoc = gql `
  fragment DefaultCampaignQuestionFragment on DefaultCampaignQuestion {
    id
    isEnabled
    isRequired
    shouldDisplay
    order
    experience {
      items {
        ...CampaignQuestionExperienceFragment
      }
    }
    question {
      id
      title
      name
      category
      type
      format
      options
      userGroup
      isMandatory
    }
  }
  ${CampaignQuestionExperienceFragmentFragmentDoc}
`;
export const CustomCampaignQuestionFragmentFragmentDoc = gql `
  fragment CustomCampaignQuestionFragment on CustomCampaignQuestion {
    id
    title
    isEnabled
    isRequired
    userGroup
    type
    format
    options
    order
    experience {
      items {
        ...CampaignQuestionExperienceFragment
      }
    }
  }
  ${CampaignQuestionExperienceFragmentFragmentDoc}
`;
export const CampaignStaffFragmentFragmentDoc = gql `
  fragment CampaignStaffFragment on CampaignStaff {
    id
    user {
      id
      firstName
      lastName
      email
      avatar {
        id
        downloadUrl
      }
    }
    campaign {
      id
    }
    emailForwarding
  }
`;
export const DonorFragmentFragmentDoc = gql `
  fragment DonorFragment on Donor {
    id
    createdAt
    user {
      id
      firstName
      lastName
      email
      avatar {
        id
        downloadUrl
      }
    }
    campaign {
      id
    }
    donations {
      count
      items {
        id
        amount
      }
    }
  }
`;
export const EarlyBirdRegistrationOptionFragmentFragmentDoc = gql `
  fragment EarlyBirdRegistrationOptionFragment on EarlyBirdRegistrationOption {
    id
    startDate
    endDate
    registrationOption {
      id
      name
      registrationStartDate
      registrationEndDate
    }
  }
`;
export const EarlyBirdRegistrationInvitationFragmentFragmentDoc = gql `
  fragment EarlyBirdRegistrationInvitationFragment on EarlyBirdRegistrationInvitation {
    id
    invitationType
    sentDate
    attendee {
      id
      user {
        id
        avatar {
          id
          downloadUrl
        }
        firstName
        lastName
      }
    }
    team {
      id
      avatar {
        id
        downloadUrl
      }
      name
    }
  }
`;
export const ExperienceFragmentFragmentDoc = gql `
  fragment ExperienceFragment on Experience {
    id
    name
    experienceType
    experienceCategory
    startDate
    earlyBirdUrlCode
    endDate
    waiverAllowMultipleAttendeeConsent
    waiverTextBody
    campaign {
      name
    }
    attendees {
      items {
        id
      }
    }
    registrationOptions {
      items {
        id
        attendees {
          items {
            id
          }
        }
      }
    }
  }
`;
export const ExperienceVenueFragmentFragmentDoc = gql `
  fragment ExperienceVenueFragment on ExperienceVenue {
    id
    address
    name
    notes
  }
`;
export const ExperienceGeneralFragmentFragmentDoc = gql `
  fragment ExperienceGeneralFragment on Experience {
    hideFromPublic
    timeZone
    privacy
    whiteListTicketingExperience {
      items {
        id
        name
      }
    }
    whiteListRegistrationExperience {
      id
      name
    }
    dateRangeType
    venues {
      items {
        ...ExperienceVenueFragment
      }
    }
  }
  ${ExperienceVenueFragmentFragmentDoc}
`;
export const BibAssignmentFragmentFragmentDoc = gql `
  fragment BibAssignmentFragment on ExperienceBibAssignment {
    id
    type
    bibNumber
    bibPool {
      id
    }
    attendee {
      id
      user {
        id
        firstName
        lastName
      }
    }
    experience {
      id
    }
  }
`;
export const BibPoolFragmentFragmentDoc = gql `
  fragment BibPoolFragment on ExperienceBibPool {
    id
    name
    bibRanges
    registrationOption {
      id
      name
    }
    allocationType
    teamAllocation {
      items {
        id
        name
        avatar {
          id
          downloadUrl
        }
      }
    }
    bibAssignments {
      items {
        ...BibAssignmentFragment
      }
      count
    }
  }
  ${BibAssignmentFragmentFragmentDoc}
`;
export const ExperienceBibAssignmentSettingsFragmentFragmentDoc = gql `
  fragment ExperienceBibAssignmentSettingsFragment on Experience {
    allowIndividualBibAssignment
    allowExcludedBibNumbers
    bibAutoAssignEnabled
    bibAutoAssignStartDate
    bibAutoAssignEndDate
    bibPools {
      items {
        ...BibPoolFragment
      }
    }
    bibAssignments {
      items {
        ...BibAssignmentFragment
      }
    }
  }
  ${BibPoolFragmentFragmentDoc}
  ${BibAssignmentFragmentFragmentDoc}
`;
export const BaseUserFragmentFragmentDoc = gql `
  fragment BaseUserFragment on User {
    id
    firstName
    lastName
    avatar {
      id
      downloadUrl
    }
    attendees {
      items {
        id
        teamMembership {
          team {
            id
            name
          }
        }
        totalRaised
        experience {
          campaign {
            id
          }
        }
      }
    }
  }
`;
export const RegistrationPricingTierFragmentFragmentDoc = gql `
  fragment RegistrationPricingTierFragment on RegistrationPricingTier {
    id
    price
    startDate
    endDate
    taxDeductibleCurrencyAmount
    taxDeductibleType
    taxDeductiblePercentsAmount
    pricingSettings {
      id
      registrationOption {
        id
      }
    }
  }
`;
export const TicketingPricingTierFragmentFragmentDoc = gql `
  fragment TicketingPricingTierFragment on TicketingPricingTier {
    id
    price
    startDate
    endDate
    taxDeductibleCurrencyAmount
    taxDeductibleType
    taxDeductiblePercentsAmount
    pricingSettings {
      id
      ticketingOption {
        id
      }
    }
  }
`;
export const ProgramFragmentFragmentDoc = gql `
  fragment ProgramFragment on Program {
    id
    name
  }
`;
export const RegistrationOptionFragmentFragmentDoc = gql `
  fragment RegistrationOptionFragment on RegistrationOption {
    id
    name
    distance
    unitOfMeasure
    startWaveOrder
    description
    fundraisingMinimum
    ageRequirement
    ageRequirementAsOfDate
    attendeesPerRegistration
    maximumRegistrationPerOrder
    groupRegistrationMinimum
    groupRegistrationMaximum
    maximumAttendees
    fundraisingMinimum
    checkpointAmount
    checkpointDate
    checkpointNotification
    registrationStartDate
    registrationEndDate
    attendees {
      count
    }
    pricingSettings {
      id
      allowCostToBeFundraisingCredit
      pricingEnabled
      pricingType
      pricingTiers {
        items {
          id
          price
          taxDeductibleCurrencyAmount
          taxDeductiblePercentsAmount
          taxDeductibleType
          startDate
          endDate
        }
        count
      }
    }
  }
`;
export const StoreAssetFragmentFragmentDoc = gql `
  fragment StoreAssetFragment on StoreAsset {
    id
    name
    description
    image {
      ...FileFragment
    }
    storeAvailability
    swagbagAvailability
    price
    unitCost
    continueSalesWhenOutOfStock
    notifyWhenStockMinimumIsAchieved
    stockMinimumBoundary
    note
    variantsPossible
  }
  ${FileFragmentFragmentDoc}
`;
export const UserFragmentFragmentDoc = gql `
  fragment UserFragment on User {
    id
    email
    firstName
    lastName
    fullName
    status
    avatar {
      downloadUrl
    }
  }
`;
export const StoreHistoryEventFragmentFragmentDoc = gql `
  fragment StoreHistoryEventFragment on StoreHistoryEvent {
    id
    quantity
    type
    dateReceived
    variantsValues
    orderCost
    createdAt
    createdBy {
      ...UserFragment
    }
    note
  }
  ${UserFragmentFragmentDoc}
`;
export const StoreAssetSimpleFragmentFragmentDoc = gql `
  fragment StoreAssetSimpleFragment on StoreAsset {
    id
    name
    description
    continueSalesWhenOutOfStock
    price
    image {
      ...FileFragment
    }
    vizslaOrganization {
      id
    }
  }
  ${FileFragmentFragmentDoc}
`;
export const ExperienceStoreAssetFragmentFragmentDoc = gql `
  fragment ExperienceStoreAssetFragment on ExperienceStoreAsset {
    id
    price
    experience {
      id
    }
    storeAsset {
      ...StoreAssetSimpleFragment
    }
  }
  ${StoreAssetSimpleFragmentFragmentDoc}
`;
export const ExperienceStoreSettingsFragmentFragmentDoc = gql `
  fragment ExperienceStoreSettingsFragment on Experience {
    id
    isStoreEnabled
    storeAssets {
      count
      items {
        id
      }
    }
  }
`;
export const TeamRtFragmentFragmentDoc = gql `
  fragment TeamRTFragment on TeamFundraising {
    id
    name
    createdAt
    avatar {
      id
      downloadUrl
    }
    teamCaptain: members(filter: { role: { equals: "Team Captain" } }) {
      items {
        id
        role
        attendee {
          id
          user {
            id
            firstName
            lastName
          }
        }
      }
    }
    members {
      count
      items {
        id
      }
    }
    fundraiserGoal
    donations {
      items {
        id
        amount
      }
    }
  }
`;
export const TicketingOptionFragmentFragmentDoc = gql `
  fragment TicketingOptionFragment on TicketingOption {
    id
    ticketName
    tableNumber
    description
    ageRequirement
    ageRequirementAsOfDate
    maximumAttendees
    attendeesPerTicket
    maximumPerOrder
    minimumGroupTickets
    maximumGroupTickets
    ticketingAvailabilityStartDateTime
    ticketingAvailabilityEndDateTime
    pricingSettings {
      id
      allowCostToBeFundraisingCredit
      pricingEnabled
      pricingType
      pricingTiers {
        items {
          id
          price
          taxDeductibleCurrencyAmount
          taxDeductiblePercentsAmount
          taxDeductibleType
          startDate
          endDate
        }
        count
      }
    }
  }
`;
export const InboxUserFragmentFragmentDoc = gql `
  fragment InboxUserFragment on User {
    id
    firstName
    lastName
    fullName
    email
    avatar {
      downloadUrl
    }
  }
`;
export const InboxFragmentFragmentDoc = gql `
  fragment InboxFragment on Inbox {
    id
    type
    createdAt
    recipients {
      items {
        ...InboxUserFragment
      }
    }
  }
  ${InboxUserFragmentFragmentDoc}
`;
export const InboxMessageFragmentFragmentDoc = gql `
  fragment InboxMessageFragment on InboxMessage {
    id
    createdAt
    createdBy {
      ...InboxUserFragment
    }
    body
    readBy {
      items {
        id
      }
    }
  }
  ${InboxUserFragmentFragmentDoc}
`;
export const InboxFragmentWithLastMessageFragmentDoc = gql `
  fragment InboxFragmentWithLastMessage on Inbox {
    ...InboxFragment
    inboxMessages(first: 1, sort: [{ createdAt: DESC }]) {
      items {
        ...InboxMessageFragment
      }
    }
  }
  ${InboxFragmentFragmentDoc}
  ${InboxMessageFragmentFragmentDoc}
`;
export const FileUrlFragmentFragmentDoc = gql `
  fragment FileURLFragment on File {
    id
    fileId
    filename
    downloadUrl
    createdAt
    createdBy {
      id
      firstName
      lastName
    }
  }
`;
export const Team_Fundraising_FragmentFragmentDoc = gql `
  fragment TEAM_FUNDRAISING_FRAGMENT on TeamFundraising {
    id
    name
    teamMissionTitle
    avatar {
      ...FileURLFragment
    }
    teamPhoto {
      ...FileURLFragment
    }
    members {
      items {
        role
        attendee {
          user {
            ...UserFragment
          }
        }
      }
    }
  }
  ${FileUrlFragmentFragmentDoc}
  ${UserFragmentFragmentDoc}
`;
export const Team_Membership_FragmentFragmentDoc = gql `
  fragment TEAM_MEMBERSHIP_FRAGMENT on CampaignTeamMember {
    id
    role
    team {
      ...TEAM_FUNDRAISING_FRAGMENT
    }
  }
  ${Team_Fundraising_FragmentFragmentDoc}
`;
export const Attendee_FragmentFragmentDoc = gql `
  fragment ATTENDEE_FRAGMENT on Attendee {
    id
    user {
      ...UserFragment
      avatar {
        ...FileURLFragment
      }
    }
    registrationOption {
      id
      name
      description
      fundraisingMinimum
    }
    teamMembership {
      ...TEAM_MEMBERSHIP_FRAGMENT
    }
    experience {
      id
      name
      startDate
      endDate
      venues {
        items {
          id
          name
          address
          notes
        }
      }
      experienceLogoImage {
        ...FileURLFragment
      }
      experienceHeaderImage {
        ...FileURLFragment
      }
      campaign {
        id
        name
        contactEmail
        city
        state
        contactPhone {
          code
          number
        }
        logo {
          ...FileURLFragment
        }
        dnsRecord {
          slug
        }
        campaignTemplates {
          templates(filter: { templateType: { type: { equals: "" } } }) {
            items {
              structure
            }
          }
        }
      }
    }
  }
  ${UserFragmentFragmentDoc}
  ${FileUrlFragmentFragmentDoc}
  ${Team_Membership_FragmentFragmentDoc}
`;
export const ClientCampaignFragmentFragmentDoc = gql `
  fragment ClientCampaignFragment on Campaign {
    id
    name
    code
    contactEmail
    contactPhone {
      code
      number
    }
    logo {
      id
      downloadUrl
    }
    image {
      id
      downloadUrl
    }
    city
    state
    campaignType
    fundraisingGoal
    fundraiserGoal
    attendanceFundraisersCountGoal
    teamGoal
    teams {
      count
      items {
        id
        createdAt
      }
    }
    donorGoal
    donors {
      count
    }
    totalRaised
    startDate
    endDate
    campaignStatus
    webLink
    program {
      id
      name
    }
    createdAt
    updatedAt
    createdBy {
      id
      firstName
      lastName
    }
    experiences {
      items {
        id
      }
    }
    dnsRecord {
      id
      slug
    }
    campaignTemplates {
      id
      templates {
        items {
          id
          templateType {
            type
            title
          }
        }
      }
    }
  }
`;
export const PublicClientCampaignFragmentFragmentDoc = gql `
  fragment PublicClientCampaignFragment on Campaign {
    id
    name
    code
    contactEmail
    contactPhone {
      code
      number
    }
    logo {
      id
      downloadUrl
    }
    image {
      id
      downloadUrl
    }
    city
    state
    campaignType
    fundraisingGoal
    fundraiserGoal
    attendanceFundraisersCountGoal
    teamGoal
    donorGoal
    totalRaised
    startDate
    endDate
    campaignStatus
    createdAt
    updatedAt
    createdBy {
      id
      firstName
      lastName
    }
  }
`;
export const DnsRecordFragmentFragmentDoc = gql `
  fragment DnsRecordFragment on DnsRecord {
    id
    slug
    campaign {
      ...PublicClientCampaignFragment
    }
  }
  ${PublicClientCampaignFragmentFragmentDoc}
`;
export const DonationOptionFragmentFragmentDoc = gql `
  fragment DonationOptionFragment on DonationOption {
    id
    price
    description
    image {
      id
      downloadUrl
    }
  }
`;
export const ExperienceHeaderImageFragmentFragmentDoc = gql `
  fragment ExperienceHeaderImageFragment on Experience {
    experienceHeaderImage {
      id
      filename
      downloadUrl
      fileId
      public
    }
  }
`;
export const ExperienceHeaderLogoFragmentFragmentDoc = gql `
  fragment ExperienceHeaderLogoFragment on Experience {
    experienceLogoImage {
      id
      filename
      downloadUrl
      fileId
      public
    }
  }
`;
export const ExperienceGalleryImagesFragmentFragmentDoc = gql `
  fragment ExperienceGalleryImagesFragment on Experience {
    experienceGalleryImages {
      items {
        id
        filename
        downloadUrl
        fileId
        public
      }
    }
  }
`;
export const Individual_Fundraising_Content_FragmentFragmentDoc = gql `
  fragment INDIVIDUAL_FUNDRAISING_CONTENT_FRAGMENT on IndividualFundraising {
    id
    title
    description
    goal
    webLink
  }
`;
export const Individual_Fundraising_Header_FragmentFragmentDoc = gql `
  fragment INDIVIDUAL_FUNDRAISING_HEADER_FRAGMENT on IndividualFundraising {
    logo {
      ...FileURLFragment
    }
    hero {
      ...FileURLFragment
    }
  }
  ${FileUrlFragmentFragmentDoc}
`;
export const Individual_Fundraising_Gallery_FragmentFragmentDoc = gql `
  fragment INDIVIDUAL_FUNDRAISING_GALLERY_FRAGMENT on IndividualFundraising {
    gallery {
      items {
        ...FileURLFragment
      }
    }
  }
  ${FileUrlFragmentFragmentDoc}
`;
export const Individual_Fundraising_Management_FragmentFragmentDoc = gql `
  fragment INDIVIDUAL_FUNDRAISING_MANAGEMENT_FRAGMENT on IndividualFundraising {
    id
    title
    description
    goal
    raised
    webLink
    logo {
      ...FileURLFragment
    }
    hero {
      ...FileURLFragment
    }
    gallery {
      items {
        ...FileURLFragment
      }
    }
    attendee {
      id
      user {
        ...UserFragment
      }
    }
  }
  ${FileUrlFragmentFragmentDoc}
  ${UserFragmentFragmentDoc}
`;
export const Organization_Logo_FragmentFragmentDoc = gql `
  fragment ORGANIZATION_LOGO_FRAGMENT on VizslaOrganization {
    logo {
      ...FileURLFragment
    }
    negativeLogo {
      ...FileURLFragment
    }
  }
  ${FileUrlFragmentFragmentDoc}
`;
export const TeamFundraisingFileFragmentDoc = gql `
  fragment TeamFundraisingFile on File {
    id
    fileId
    filename
    downloadUrl
    createdAt
    createdBy {
      id
      firstName
      lastName
    }
  }
`;
export const TeamFundraisingCampaignFragmentDoc = gql `
  fragment TeamFundraisingCampaign on Campaign {
    id
    name
    description
    contactEmail
    city
    state
    contactPhone {
      code
      number
    }
    logo {
      fileId
      downloadUrl
    }
    image {
      fileId
      downloadUrl
    }
    dnsRecord {
      slug
    }
    campaignTemplates {
      templates(filter: { templateType: { type: { equals: "" } } }) {
        items {
          structure
        }
      }
    }
  }
`;
export const TeamFundraisingManagementFragmentFragmentDoc = gql `
  fragment TeamFundraisingManagementFragment on TeamFundraising {
    id
    createdAt
    name
    teamMissionTitle
    teamMissionDescription
    totalRaised
    fundraiserGoal
    webLink
    avatar {
      ...TeamFundraisingFile
    }
    teamPhoto {
      ...TeamFundraisingFile
    }
    donations {
      items {
        amount
      }
    }
    gallery {
      items {
        ...TeamFundraisingFile
      }
    }
    campaign {
      ...TeamFundraisingCampaign
    }
    captains: members(filter: { role: { equals: "Team Captain" } }) {
      items {
        id
        role
        attendee {
          id
          user {
            ...UserFragment
            avatar {
              ...TeamFundraisingFile
            }
            phone {
              code
              number
            }
            address {
              street1
              street2
              city
              state
              country
              zip
            }
          }
        }
      }
    }
    members(sort: { attendee: { fundraising: { raised: DESC } } }) {
      count
      items {
        id
        role
        attendee {
          id
          createdAt
          user {
            ...UserFragment
            avatar {
              downloadUrl
            }
            phone {
              code
              number
            }
            address {
              city
              state
            }
          }
          fundraising {
            id
            goal
            allocatedDonations(
              groupBy: {
                query: {
                  donor: { id: { as: "donors", fn: { aggregate: COUNT, distinct: true } } }
                  amount: { as: "raised", fn: { aggregate: SUM } }
                }
              }
            ) {
              groups {
                donors: Int
                raised: Float
              }
            }
            hero {
              fileId
              downloadUrl
            }
            logo {
              fileId
              downloadUrl
            }
          }
        }
      }
    }
  }
  ${TeamFundraisingFileFragmentDoc}
  ${TeamFundraisingCampaignFragmentDoc}
  ${UserFragmentFragmentDoc}
`;
export const TeamFundraisingGalleryFragmentFragmentDoc = gql `
  fragment TeamFundraisingGalleryFragment on TeamFundraising {
    gallery {
      items {
        ...TeamFundraisingFile
      }
    }
  }
  ${TeamFundraisingFileFragmentDoc}
`;
export const TeamFundraisingContentFragmentFragmentDoc = gql `
  fragment TeamFundraisingContentFragment on TeamFundraising {
    name
    teamMissionTitle
    teamMissionDescription
    totalRaised
    fundraiserGoal
    webLink
  }
`;
export const TeamFundraisingHeaderFragmentFragmentDoc = gql `
  fragment TeamFundraisingHeaderFragment on TeamFundraising {
    avatar {
      ...TeamFundraisingFile
    }
    teamPhoto {
      ...TeamFundraisingFile
    }
  }
  ${TeamFundraisingFileFragmentDoc}
`;
export const PublicClientDnsRecordFragmentFragmentDoc = gql `
  fragment PublicClientDnsRecordFragment on DnsRecord {
    id
    campaign {
      id
      vizslaOrganization {
        id
        name
      }
    }
  }
`;
export const ExperienceLandingFragmentFragmentDoc = gql `
  fragment ExperienceLandingFragment on Experience {
    id
    name
    experienceType
    experienceCategory
    startDate
    endDate
    waiverAllowMultipleAttendeeConsent
    waiverTextBody
    registrationOptions {
      items {
        id
        name
        pricingSettings {
          id
          pricingType
          pricingTiers {
            items {
              id
              price
              startDate
              endDate
            }
          }
          pricingEnabled
        }
      }
    }
    campaign {
      id
      name
      fundraisingGoal
      totalRaised
      vizslaOrganization {
        id
      }
      contactEmail
      contactPhone {
        number
      }
    }
    donations {
      items {
        amount
        order {
          number
        }
      }
    }
    venues {
      items {
        id
        name
        notes
        address
      }
    }
    fundraisingSettings {
      donationSettings {
        feesMessage
        feesEnabled
      }
    }
    isStoreEnabled
  }
`;
export const ExperienceRegistrationOptionFragmentFragmentDoc = gql `
  fragment ExperienceRegistrationOptionFragment on RegistrationOption {
    id
    name
    distance
    unitOfMeasure
    startWaveOrder
    description
    fundraisingMinimum
    ageRequirement
    ageRequirementAsOfDate
    attendeesPerRegistration
    maximumRegistrationPerOrder
    groupRegistrationMinimum
    groupRegistrationMaximum
    maximumAttendees
    fundraisingMinimum
    checkpointAmount
    checkpointDate
    checkpointNotification
    registrationStartDate
    registrationEndDate
    attendees {
      count
    }
    pricingSettings {
      id
      allowCostToBeFundraisingCredit
      pricingEnabled
      pricingType
      pricingTiers {
        items {
          id
          price
          taxDeductibleCurrencyAmount
          taxDeductiblePercentsAmount
          taxDeductibleType
          startDate
          endDate
        }
        count
      }
    }
  }
`;
export const ExperienceDefaultCampaignQuestionFragmentFragmentDoc = gql `
  fragment ExperienceDefaultCampaignQuestionFragment on DefaultCampaignQuestion {
    id
    isEnabled
    isRequired
    shouldDisplay
    order
    question {
      id
      title
      name
      category
      type
      format
      options
      userGroup
      isMandatory
    }
  }
`;
export const ExperienceCustomCampaignQuestionFragmentFragmentDoc = gql `
  fragment ExperienceCustomCampaignQuestionFragment on CustomCampaignQuestion {
    id
    title
    isEnabled
    isRequired
    userGroup
    type
    format
    options
    order
  }
`;
export const OrderFragmentFragmentDoc = gql `
  fragment OrderFragment on Order {
    id
    status
    reasonStatus
    totalAmount
    user {
      id
      firstName
      lastName
    }
  }
`;
export const PublicClientExperienceStoreAssetFragmentFragmentDoc = gql `
  fragment PublicClientExperienceStoreAssetFragment on ExperienceStoreAsset {
    id
    price
    experience {
      id
    }
    storeAsset {
      id
      name
      description
      image {
        id
        fileId
        filename
        downloadUrl
      }
      inventoryItem {
        items {
          id
        }
      }
    }
  }
`;
export const CheckinAttendeesDocument = gql `
  query CheckinAttendees($filter: AttendeeFilter, $skip: Int, $first: Int) {
    attendeesList(filter: $filter, sort: { createdAt: DESC }, skip: $skip, first: $first) {
      count
      items {
        ...CheckinAttendeeInfo
      }
    }
  }
  ${CheckinAttendeeInfoFragmentDoc}
`;
/**
 * __useCheckinAttendeesQuery__
 *
 * To run a query within a React component, call `useCheckinAttendeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckinAttendeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckinAttendeesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useCheckinAttendeesQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(CheckinAttendeesDocument, options);
}
export function useCheckinAttendeesLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(CheckinAttendeesDocument, options);
}
export const UserAttendeesDocument = gql `
  query UserAttendees($id: ID) {
    attendee(id: $id) {
      user {
        id
        email
        avatar {
          id
          downloadUrl
          previewUrl
        }
        firstName
        lastName
        phone {
          number
        }
        gender
        birthDate
        address {
          city
          state
        }
        status
        createdAt
      }
      registrationOption {
        name
      }
      donations {
        items {
          amount
        }
      }
      fundraisingPageLink
      fundraiserGoal
      totalRaised
      bibAssignment {
        bibNumber
      }
      experience {
        id
        name
        waiverEnabled
        waiverTextBody
        store
        storeAssets {
          count
          items {
            price
          }
        }
      }
    }
  }
`;
/**
 * __useUserAttendeesQuery__
 *
 * To run a query within a React component, call `useUserAttendeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserAttendeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserAttendeesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserAttendeesQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(UserAttendeesDocument, options);
}
export function useUserAttendeesLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(UserAttendeesDocument, options);
}
export const AttendeesRegistrationOptionsDocument = gql `
  query AttendeesRegistrationOptions($id: ID) {
    attendee(id: $id) {
      id
      user {
        id
        email
        firstName
        lastName
        phone {
          number
        }
      }
      registrationOption {
        id
        name
        pricingSettings {
          pricingType
          pricingTiers {
            items {
              price
              taxDeductibleCurrencyAmount
              taxDeductiblePercentsAmount
              taxDeductibleType
            }
          }
        }
      }
      experience {
        id
        name
        campaign {
          id
          name
        }
        registrationOptions {
          items {
            id
            name
            fundraisingMinimum
            pricingSettings {
              pricingType
              pricingTiers {
                items {
                  price
                  taxDeductibleCurrencyAmount
                  taxDeductiblePercentsAmount
                  taxDeductibleType
                  startDate
                  endDate
                }
              }
            }
          }
        }
      }
    }
  }
`;
/**
 * __useAttendeesRegistrationOptionsQuery__
 *
 * To run a query within a React component, call `useAttendeesRegistrationOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttendeesRegistrationOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttendeesRegistrationOptionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAttendeesRegistrationOptionsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(AttendeesRegistrationOptionsDocument, options);
}
export function useAttendeesRegistrationOptionsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(AttendeesRegistrationOptionsDocument, options);
}
export const UpdateRegistrationOptionDocument = gql `
  mutation updateRegistrationOption($attenddeId: ID, $optionId: ID) {
    attendeeUpdate(
      filter: { id: $attenddeId }
      data: { registrationOption: { reconnect: { id: $optionId } } }
    ) {
      id
      registrationOption {
        id
        name
      }
    }
  }
`;
/**
 * __useUpdateRegistrationOptionMutation__
 *
 * To run a mutation, you first call `useUpdateRegistrationOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRegistrationOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRegistrationOptionMutation, { data, loading, error }] = useUpdateRegistrationOptionMutation({
 *   variables: {
 *      attenddeId: // value for 'attenddeId'
 *      optionId: // value for 'optionId'
 *   },
 * });
 */
export function useUpdateRegistrationOptionMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(UpdateRegistrationOptionDocument, options);
}
export const BibAssignmentByAttendeeDocument = gql `
  mutation BibAssignmentByAttendee($experienceId: ID!, $attendeeId: ID!) {
    bibAssignByAttendee(experienceId: $experienceId, attendeeId: $attendeeId) {
      bibNumber
    }
  }
`;
/**
 * __useBibAssignmentByAttendeeMutation__
 *
 * To run a mutation, you first call `useBibAssignmentByAttendeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBibAssignmentByAttendeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bibAssignmentByAttendeeMutation, { data, loading, error }] = useBibAssignmentByAttendeeMutation({
 *   variables: {
 *      experienceId: // value for 'experienceId'
 *      attendeeId: // value for 'attendeeId'
 *   },
 * });
 */
export function useBibAssignmentByAttendeeMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(BibAssignmentByAttendeeDocument, options);
}
export const AttendeeCheckedDocument = gql `
  mutation AttendeeChecked($attendeeId: ID!, $checked: Boolean!) {
    attendeeUpdate(filter: { id: $attendeeId }, data: { checkIn: $checked }) {
      id
      checkIn
      user {
        id
        firstName
      }
    }
  }
`;
/**
 * __useAttendeeCheckedMutation__
 *
 * To run a mutation, you first call `useAttendeeCheckedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttendeeCheckedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attendeeCheckedMutation, { data, loading, error }] = useAttendeeCheckedMutation({
 *   variables: {
 *      attendeeId: // value for 'attendeeId'
 *      checked: // value for 'checked'
 *   },
 * });
 */
export function useAttendeeCheckedMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(AttendeeCheckedDocument, options);
}
export const AttendeeIndividualFundraisingDocument = gql `
  query AttendeeIndividualFundraising($filter: AttendeeFilter!) {
    attendeesList(filter: $filter) {
      items {
        id
        fundraising {
          id
          title
        }
        user {
          id
          firstName
          lastName
          email
        }
      }
    }
  }
`;
/**
 * __useAttendeeIndividualFundraisingQuery__
 *
 * To run a query within a React component, call `useAttendeeIndividualFundraisingQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttendeeIndividualFundraisingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttendeeIndividualFundraisingQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAttendeeIndividualFundraisingQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(AttendeeIndividualFundraisingDocument, options);
}
export function useAttendeeIndividualFundraisingLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(AttendeeIndividualFundraisingDocument, options);
}
export const AttendeSendEmailDocument = gql `
  mutation attendeSendEmail($notificationData: JSON, $notificationMethods: [String!]) {
    notifications {
      notificationSend(
        notificationData: $notificationData
        notificationMethods: $notificationMethods
      ) {
        success
      }
    }
  }
`;
/**
 * __useAttendeSendEmailMutation__
 *
 * To run a mutation, you first call `useAttendeSendEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttendeSendEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attendeSendEmailMutation, { data, loading, error }] = useAttendeSendEmailMutation({
 *   variables: {
 *      notificationData: // value for 'notificationData'
 *      notificationMethods: // value for 'notificationMethods'
 *   },
 * });
 */
export function useAttendeSendEmailMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(AttendeSendEmailDocument, options);
}
export const GetSwagBagAssetsByOptionDocument = gql `
  query getSwagBagAssetsByOption($filter: AssetByRegistrationOptionViewFilter!) {
    assetByRegistrationOptionViewsList(filter: $filter) {
      items {
        available
        continueSalesWhenOutOfStock
        id
        inventoryId
        name
        picture
        registrationOptionAId
        storeAvailability
        itemToSellWhenOutOfStock
        variantsValues
      }
    }
  }
`;
/**
 * __useGetSwagBagAssetsByOptionQuery__
 *
 * To run a query within a React component, call `useGetSwagBagAssetsByOptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSwagBagAssetsByOptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSwagBagAssetsByOptionQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetSwagBagAssetsByOptionQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetSwagBagAssetsByOptionDocument, options);
}
export function useGetSwagBagAssetsByOptionLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetSwagBagAssetsByOptionDocument, options);
}
export const UpdateAttendeeWaiverSignatureDocument = gql `
  mutation updateAttendeeWaiverSignature($data: AttendeeUpdateInput!) {
    attendeeUpdate(data: $data) {
      id
      waiverSignedAt
      waiverSignature
    }
  }
`;
/**
 * __useUpdateAttendeeWaiverSignatureMutation__
 *
 * To run a mutation, you first call `useUpdateAttendeeWaiverSignatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAttendeeWaiverSignatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAttendeeWaiverSignatureMutation, { data, loading, error }] = useUpdateAttendeeWaiverSignatureMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateAttendeeWaiverSignatureMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(UpdateAttendeeWaiverSignatureDocument, options);
}
export const CheckinCampaignsDocument = gql `
  query CheckinCampaigns($filter: CampaignFilter, $skip: Int, $first: Int) {
    campaignsList(filter: $filter, sort: { createdAt: DESC }, skip: $skip, first: $first) {
      items {
        ...CheckinCampaignFragment
      }
    }
  }
  ${CheckinCampaignFragmentFragmentDoc}
`;
/**
 * __useCheckinCampaignsQuery__
 *
 * To run a query within a React component, call `useCheckinCampaignsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckinCampaignsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckinCampaignsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useCheckinCampaignsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(CheckinCampaignsDocument, options);
}
export function useCheckinCampaignsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(CheckinCampaignsDocument, options);
}
export const CheckinExperiencesDocument = gql `
  query CheckinExperiences($filter: ExperienceFilter, $skip: Int, $first: Int) {
    experiencesList(filter: $filter, sort: { createdAt: DESC }, skip: $skip, first: $first) {
      count
      items {
        ...CheckinExperienceInfo
      }
    }
  }
  ${CheckinExperienceInfoFragmentDoc}
`;
/**
 * __useCheckinExperiencesQuery__
 *
 * To run a query within a React component, call `useCheckinExperiencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckinExperiencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckinExperiencesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useCheckinExperiencesQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(CheckinExperiencesDocument, options);
}
export function useCheckinExperiencesLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(CheckinExperiencesDocument, options);
}
export const NewOrderShoppingCartCheckinDocument = gql `
  mutation NewOrderShoppingCartCheckin($data: OrderCreateInput!) {
    orderCreate(data: $data) {
      ...OrderFragmentCheckin
    }
  }
  ${OrderFragmentCheckinFragmentDoc}
`;
/**
 * __useNewOrderShoppingCartCheckinMutation__
 *
 * To run a mutation, you first call `useNewOrderShoppingCartCheckinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewOrderShoppingCartCheckinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newOrderShoppingCartCheckinMutation, { data, loading, error }] = useNewOrderShoppingCartCheckinMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useNewOrderShoppingCartCheckinMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(NewOrderShoppingCartCheckinDocument, options);
}
export const NewOrderItemCheckinDocument = gql `
  mutation NewOrderItemCheckin($data: OrderItemCreateInput!) {
    orderItemCreate(data: $data) {
      id
      description
      category
      transactionType
      amount
      order {
        id
      }
    }
  }
`;
/**
 * __useNewOrderItemCheckinMutation__
 *
 * To run a mutation, you first call `useNewOrderItemCheckinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewOrderItemCheckinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newOrderItemCheckinMutation, { data, loading, error }] = useNewOrderItemCheckinMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useNewOrderItemCheckinMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(NewOrderItemCheckinDocument, options);
}
export const NewStatusOrderCheckinDocument = gql `
  mutation NewStatusOrderCheckin($data: OrderHistoryCreateInput!) {
    orderHistoryCreate(data: $data) {
      id
      status
      reason
      order {
        id
      }
    }
  }
`;
/**
 * __useNewStatusOrderCheckinMutation__
 *
 * To run a mutation, you first call `useNewStatusOrderCheckinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewStatusOrderCheckinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newStatusOrderCheckinMutation, { data, loading, error }] = useNewStatusOrderCheckinMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useNewStatusOrderCheckinMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(NewStatusOrderCheckinDocument, options);
}
export const NewRegistratioOptionFullfimentCheckinDocument = gql `
  mutation NewRegistratioOptionFullfimentCheckin($data: RegistrationOptionFulfillmentCreateInput!) {
    registrationOptionFulfillmentCreate(data: $data) {
      id
      status
      amount
      attendee {
        id
      }
      order {
        id
      }
    }
  }
`;
/**
 * __useNewRegistratioOptionFullfimentCheckinMutation__
 *
 * To run a mutation, you first call `useNewRegistratioOptionFullfimentCheckinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewRegistratioOptionFullfimentCheckinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newRegistratioOptionFullfimentCheckinMutation, { data, loading, error }] = useNewRegistratioOptionFullfimentCheckinMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useNewRegistratioOptionFullfimentCheckinMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(NewRegistratioOptionFullfimentCheckinDocument, options);
}
export const NewAssetFullfimentCheckinDocument = gql `
  mutation NewAssetFullfimentCheckin($data: AssetFullfillmentCreateInput!) {
    assetFullfillmentCreate(data: $data) {
      id
      status
      amount
      attendee {
        id
      }
      order {
        id
      }
    }
  }
`;
/**
 * __useNewAssetFullfimentCheckinMutation__
 *
 * To run a mutation, you first call `useNewAssetFullfimentCheckinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewAssetFullfimentCheckinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newAssetFullfimentCheckinMutation, { data, loading, error }] = useNewAssetFullfimentCheckinMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useNewAssetFullfimentCheckinMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(NewAssetFullfimentCheckinDocument, options);
}
export const NewSwagbagFullfimentCheckinDocument = gql `
  mutation NewSwagbagFullfimentCheckin($data: AssetSwagbagFulfillmentCreateInput!) {
    assetSwagbagFulfillmentCreate(data: $data) {
      id
      status
      amount
      attendee {
        id
      }
      order {
        id
      }
    }
  }
`;
/**
 * __useNewSwagbagFullfimentCheckinMutation__
 *
 * To run a mutation, you first call `useNewSwagbagFullfimentCheckinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewSwagbagFullfimentCheckinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newSwagbagFullfimentCheckinMutation, { data, loading, error }] = useNewSwagbagFullfimentCheckinMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useNewSwagbagFullfimentCheckinMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(NewSwagbagFullfimentCheckinDocument, options);
}
export const NewPaymentOrderCheckinDocument = gql `
  mutation NewPaymentOrderCheckin($data: PaymentCreateInput!) {
    paymentCreate(data: $data) {
      id
      status
      gateway
      method
      category
      reasonStatus
      totalAmount
      order {
        id
      }
    }
  }
`;
/**
 * __useNewPaymentOrderCheckinMutation__
 *
 * To run a mutation, you first call `useNewPaymentOrderCheckinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewPaymentOrderCheckinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newPaymentOrderCheckinMutation, { data, loading, error }] = useNewPaymentOrderCheckinMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useNewPaymentOrderCheckinMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(NewPaymentOrderCheckinDocument, options);
}
export const NewDonationOrderCheckinDocument = gql `
  mutation NewDonationOrderCheckin($data: ExperienceDonationCreateInput!) {
    experienceDonationCreate(data: $data) {
      id
      amount
    }
  }
`;
/**
 * __useNewDonationOrderCheckinMutation__
 *
 * To run a mutation, you first call `useNewDonationOrderCheckinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewDonationOrderCheckinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newDonationOrderCheckinMutation, { data, loading, error }] = useNewDonationOrderCheckinMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useNewDonationOrderCheckinMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(NewDonationOrderCheckinDocument, options);
}
export const NewDonationOrderIndividualCheckinDocument = gql `
  mutation NewDonationOrderIndividualCheckin($data: IndividualFundraisingDonationCreateInput!) {
    individualFundraisingDonationCreate(data: $data) {
      id
      amount
    }
  }
`;
/**
 * __useNewDonationOrderIndividualCheckinMutation__
 *
 * To run a mutation, you first call `useNewDonationOrderIndividualCheckinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewDonationOrderIndividualCheckinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newDonationOrderIndividualCheckinMutation, { data, loading, error }] = useNewDonationOrderIndividualCheckinMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useNewDonationOrderIndividualCheckinMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(NewDonationOrderIndividualCheckinDocument, options);
}
export const NewOrderIntentDocument = gql `
  mutation NewOrderIntent($data: OrderIntentCreateInput!) {
    orderIntentCreate(data: $data) {
      id
      data
      parentOrderId
    }
  }
`;
/**
 * __useNewOrderIntentMutation__
 *
 * To run a mutation, you first call `useNewOrderIntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewOrderIntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newOrderIntentMutation, { data, loading, error }] = useNewOrderIntentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useNewOrderIntentMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(NewOrderIntentDocument, options);
}
export const OrderIntentToOrderDocument = gql `
  mutation OrderIntentToOrder($data: OrderIntentToOrderInput!) {
    orderIntentToOrder(data: $data) {
      success
    }
  }
`;
/**
 * __useOrderIntentToOrderMutation__
 *
 * To run a mutation, you first call `useOrderIntentToOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderIntentToOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderIntentToOrderMutation, { data, loading, error }] = useOrderIntentToOrderMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useOrderIntentToOrderMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(OrderIntentToOrderDocument, options);
}
export const User_Info_UpdateDocument = gql `
  mutation User_Info_update($userid: ID, $data: UserUpdateInput!) {
    userUpdate(filter: { id: $userid }, data: $data) {
      firstName
      lastName
      gender
      birthDate
      phone {
        code
        number
      }
      address {
        city
        state
        country
        zip
      }
    }
  }
`;
/**
 * __useUser_Info_UpdateMutation__
 *
 * To run a mutation, you first call `useUser_Info_UpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUser_Info_UpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userInfoUpdateMutation, { data, loading, error }] = useUser_Info_UpdateMutation({
 *   variables: {
 *      userid: // value for 'userid'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUser_Info_UpdateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(User_Info_UpdateDocument, options);
}
export const AttendeeregistrationordersDocument = gql `
  query attendeeregistrationorders($attendeeId: ID) {
    attendee(id: $attendeeId) {
      bibAssignment {
        bibNumber
      }
      registrationOptionFulfillment {
        items {
          amount
          attendee {
            user {
              firstName
              lastName
            }
          }
          order {
            number
            createdAt
            payment {
              status
              category
            }
          }
          registrationOption {
            id
            name
            experience {
              id
              name
              startDate
              venues {
                items {
                  address
                }
              }
              campaign {
                name
              }
            }
          }
        }
      }
    }
  }
`;
/**
 * __useAttendeeregistrationordersQuery__
 *
 * To run a query within a React component, call `useAttendeeregistrationordersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttendeeregistrationordersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttendeeregistrationordersQuery({
 *   variables: {
 *      attendeeId: // value for 'attendeeId'
 *   },
 * });
 */
export function useAttendeeregistrationordersQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(AttendeeregistrationordersDocument, options);
}
export function useAttendeeregistrationordersLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(AttendeeregistrationordersDocument, options);
}
export const ExperiencesByOrganizationIdDocument = gql `
  query ExperiencesByOrganizationID($userID: ID!, $filters: [ExperienceFilter!]) {
    experiences: experiencesList(filter: { AND: $filters }) {
      items {
        id
        name
        startDate
        endDate
        experienceLogoImage {
          fileId
          downloadUrl
        }
        venues {
          items {
            id
            address
          }
        }
        attendees(filter: { user: { id: { equals: $userID } } }) {
          items {
            id
          }
        }
      }
    }
  }
`;
/**
 * __useExperiencesByOrganizationIdQuery__
 *
 * To run a query within a React component, call `useExperiencesByOrganizationIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useExperiencesByOrganizationIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExperiencesByOrganizationIdQuery({
 *   variables: {
 *      userID: // value for 'userID'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useExperiencesByOrganizationIdQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ExperiencesByOrganizationIdDocument, options);
}
export function useExperiencesByOrganizationIdLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ExperiencesByOrganizationIdDocument, options);
}
export const OrdersbydonorDocument = gql `
  query ordersbydonor($orderId: ID) {
    order(id: $orderId) {
      donations {
        items {
          amount
          donor {
            createdAt
            firstName
            lastName
            email
            gender
            companyName
          }
          donationAllocations {
            items {
              teamFundraising {
                name
              }
              individualFundraising {
                attendee {
                  user {
                    firstName
                    lastName
                  }
                }
              }
            }
          }
        }
      }
      registrationOptionFulfillment {
        items {
          attendee {
            id
            user {
              id
              firstName
              lastName
              gender
              companyName
              email
            }
          }
        }
      }
    }
  }
`;
/**
 * __useOrdersbydonorQuery__
 *
 * To run a query within a React component, call `useOrdersbydonorQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersbydonorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersbydonorQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useOrdersbydonorQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(OrdersbydonorDocument, options);
}
export function useOrdersbydonorLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(OrdersbydonorDocument, options);
}
export const GetAssetsByOrderDocument = gql `
  query getAssetsByOrder($id: ID) {
    order(id: $id) {
      assetsFulfillment {
        items {
          storeInventoryItem {
            storeAsset {
              name
              image {
                downloadUrl
              }
            }
            itemToSellWhenOutOfStock
            variantsValues
          }
        }
      }
    }
  }
`;
/**
 * __useGetAssetsByOrderQuery__
 *
 * To run a query within a React component, call `useGetAssetsByOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetsByOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetsByOrderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAssetsByOrderQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetAssetsByOrderDocument, options);
}
export function useGetAssetsByOrderLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetAssetsByOrderDocument, options);
}
export const ImpactedOrganizationsDocument = gql `
  query ImpactedOrganizations($filters: [VizslaOrganizationFilter!]!) {
    organizations: vizslaOrganizationsList(filter: { OR: $filters }) {
      count
      items {
        id
        name
        logo {
          downloadUrl
        }
      }
    }
  }
`;
/**
 * __useImpactedOrganizationsQuery__
 *
 * To run a query within a React component, call `useImpactedOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useImpactedOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImpactedOrganizationsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useImpactedOrganizationsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ImpactedOrganizationsDocument, options);
}
export function useImpactedOrganizationsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ImpactedOrganizationsDocument, options);
}
export const GetTransactionsDocument = gql `
  query GetTransactions($filter: TransactionVizslaViewExpFilter, $skip: Int, $first: Int) {
    transactionVizslaViewExpsList(
      filter: $filter
      sort: { OrderDate: DESC }
      skip: $skip
      first: $first
    ) {
      count
      items {
        id
        OrderDate
        OrderStatus
        Ordersubtype
        OrderItemTransType
        UsersFirsName
        UsersLastName
        CampaignName
        ExperienceName
        ExperienceId
        OrderTotalAmount
        OrderItemsAmount
        OrderPaymentMethod
        OrderitemCommentDetail
        Userid
        OrderItemsId
      }
    }
  }
`;
/**
 * __useGetTransactionsQuery__
 *
 * To run a query within a React component, call `useGetTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransactionsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useGetTransactionsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetTransactionsDocument, options);
}
export function useGetTransactionsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetTransactionsDocument, options);
}
export const CurrentUserConsumerDocument = gql `
  query CurrentUserConsumer {
    user {
      id
      email
      firstName
      lastName
      createdAt
      gender
      notifications
      phone {
        code
        number
      }
      birthDate
      address {
        city
        country
        state
        street1
        street2
        zip
      }
      avatar {
        id
        downloadUrl
      }
      companyName
      about
    }
  }
`;
/**
 * __useCurrentUserConsumerQuery__
 *
 * To run a query within a React component, call `useCurrentUserConsumerQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserConsumerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserConsumerQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserConsumerQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(CurrentUserConsumerDocument, options);
}
export function useCurrentUserConsumerLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(CurrentUserConsumerDocument, options);
}
export const UserSignupDocument = gql `
  mutation UserSignup($user: UserCreateInput!) {
    userSignUpWithToken(user: $user) {
      id
      email
      firstName
      lastName
      avatar {
        id
        downloadUrl
      }
    }
  }
`;
/**
 * __useUserSignupMutation__
 *
 * To run a mutation, you first call `useUserSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userSignupMutation, { data, loading, error }] = useUserSignupMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useUserSignupMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(UserSignupDocument, options);
}
export const User_Consumer_Info_UpdateDocument = gql `
  mutation User_consumer_Info_update($userid: ID, $data: UserUpdateInput!) {
    userUpdate(filter: { id: $userid }, data: $data) {
      firstName
      lastName
      gender
      birthDate
      language
      notifications
      phone {
        code
        number
      }
      address {
        city
        state
        country
        street1
        zip
      }
    }
  }
`;
/**
 * __useUser_Consumer_Info_UpdateMutation__
 *
 * To run a mutation, you first call `useUser_Consumer_Info_UpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUser_Consumer_Info_UpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userConsumerInfoUpdateMutation, { data, loading, error }] = useUser_Consumer_Info_UpdateMutation({
 *   variables: {
 *      userid: // value for 'userid'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUser_Consumer_Info_UpdateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(User_Consumer_Info_UpdateDocument, options);
}
export const VizslaOrganizationUpdateDocument = gql `
  mutation VizslaOrganizationUpdate($data: VizslaOrganizationUpdateInput!) {
    vizslaOrganizationUpdate(data: $data) {
      id
    }
  }
`;
/**
 * __useVizslaOrganizationUpdateMutation__
 *
 * To run a mutation, you first call `useVizslaOrganizationUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVizslaOrganizationUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [vizslaOrganizationUpdateMutation, { data, loading, error }] = useVizslaOrganizationUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useVizslaOrganizationUpdateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(VizslaOrganizationUpdateDocument, options);
}
export const OrganizationsListDocument = gql `
  query OrganizationsList($filter: VizslaOrganizationFilter, $skip: Int, $first: Int) {
    vizslaOrganizationsList(filter: $filter, skip: $skip, first: $first) {
      count
      items {
        id
        name
        createdAt
        createdBy {
          id
          lastName
          firstName
        }
        vizslaOrganizationStaff {
          count
          items {
            id
            roles
            user {
              firstName
              lastName
            }
          }
        }
        isActive
        campaigns {
          count
          items {
            id
            campaignStaff {
              count
            }
            donors {
              count
              items {
                id
                user {
                  id
                }
              }
            }
            experiences {
              count
              items {
                id
                attendees {
                  count
                  items {
                    id
                    user {
                      id
                    }
                  }
                }
                fundraisers: attendees(filter: { fundraisingPageLink: { is_not_empty: true } }) {
                  count
                  items {
                    id
                    user {
                      id
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
/**
 * __useOrganizationsListQuery__
 *
 * To run a query within a React component, call `useOrganizationsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useOrganizationsListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(OrganizationsListDocument, options);
}
export function useOrganizationsListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(OrganizationsListDocument, options);
}
export const VizslaTotalMetricsDocument = gql `
  query VizslaTotalMetrics {
    vizslaOrganizationsList {
      count
    }
    campaignsList {
      count
    }
    usersList {
      count
    }
    experiencesList {
      count
    }
  }
`;
/**
 * __useVizslaTotalMetricsQuery__
 *
 * To run a query within a React component, call `useVizslaTotalMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVizslaTotalMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVizslaTotalMetricsQuery({
 *   variables: {
 *   },
 * });
 */
export function useVizslaTotalMetricsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(VizslaTotalMetricsDocument, options);
}
export function useVizslaTotalMetricsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(VizslaTotalMetricsDocument, options);
}
export const AsyncTaskSubscriptionDocument = gql `
  subscription AsyncTaskSubscription($filter: AsyncTaskSubscriptionFilter!) {
    AsyncTasks(filter: $filter) {
      mutation
      node {
        ...AsyncTaskFragment
      }
    }
  }
  ${AsyncTaskFragmentFragmentDoc}
`;
/**
 * __useAsyncTaskSubscriptionSubscription__
 *
 * To run a query within a React component, call `useAsyncTaskSubscriptionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAsyncTaskSubscriptionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAsyncTaskSubscriptionSubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAsyncTaskSubscriptionSubscription(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSubscription(AsyncTaskSubscriptionDocument, options);
}
export const AsyncTaskDispatchDocument = gql `
  mutation AsyncTaskDispatch($name: String!, $args: JSON) {
    asyncTaskDispatch(name: $name, args: $args) {
      taskId
    }
  }
`;
/**
 * __useAsyncTaskDispatchMutation__
 *
 * To run a mutation, you first call `useAsyncTaskDispatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAsyncTaskDispatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [asyncTaskDispatchMutation, { data, loading, error }] = useAsyncTaskDispatchMutation({
 *   variables: {
 *      name: // value for 'name'
 *      args: // value for 'args'
 *   },
 * });
 */
export function useAsyncTaskDispatchMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(AsyncTaskDispatchDocument, options);
}
export const AttendeesListDocument = gql `
  query AttendeesList($filter: AttendeeFilter, $skip: Int, $first: Int) {
    attendees: attendeesList(filter: $filter, skip: $skip, first: $first) {
      count
      items {
        ...CLIENT_ATTENDEE_FRAGMENT
      }
    }
  }
  ${Client_Attendee_FragmentFragmentDoc}
`;
/**
 * __useAttendeesListQuery__
 *
 * To run a query within a React component, call `useAttendeesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttendeesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttendeesListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useAttendeesListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(AttendeesListDocument, options);
}
export function useAttendeesListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(AttendeesListDocument, options);
}
export const IndividualFundraiserDocument = gql `
  query IndividualFundraiser($filter: IndividualFundraisingFilter, $skip: Int, $first: Int) {
    fundraisers: individualFundraisingsList(filter: $filter, skip: $skip, first: $first) {
      count
      items {
        id
        goal
        createdAt
        allocatedDonations(
          groupBy: { query: { amount: { as: "raised", fn: { aggregate: SUM } } } }
        ) {
          groups {
            raised: Float
          }
        }
        attendee {
          id
          experience {
            name
          }
          registrationOption {
            name
          }
          teamMembership {
            team {
              name
            }
          }
          user {
            firstName
            lastName
            email
          }
        }
      }
    }
  }
`;
/**
 * __useIndividualFundraiserQuery__
 *
 * To run a query within a React component, call `useIndividualFundraiserQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndividualFundraiserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndividualFundraiserQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useIndividualFundraiserQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(IndividualFundraiserDocument, options);
}
export function useIndividualFundraiserLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(IndividualFundraiserDocument, options);
}
export const AttendeesNamesListDocument = gql `
  query AttendeesNamesList($filter: AttendeeFilter) {
    attendeesList(filter: $filter, first: 10) {
      items {
        id
        user {
          id
          lastName
          firstName
          email
        }
        experience {
          id
        }
      }
    }
  }
`;
/**
 * __useAttendeesNamesListQuery__
 *
 * To run a query within a React component, call `useAttendeesNamesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttendeesNamesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttendeesNamesListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAttendeesNamesListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(AttendeesNamesListDocument, options);
}
export function useAttendeesNamesListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(AttendeesNamesListDocument, options);
}
export const AttendeeRegistrationDataDocument = gql `
  query AttendeeRegistrationData($id: ID!) {
    experience(id: $id) {
      id
      name
      waiverEnabled
      waiverTextBody
      waiverAllowMultipleAttendeeConsent
      campaign {
        id
        defaultCampaignQuestions(
          filter: { shouldDisplay: { equals: true }, isEnabled: { equals: true } }
        ) {
          items {
            ...DefaultCampaignQuestionFragment
          }
        }
        customCampaignQuestions(filter: { isEnabled: { equals: true } }) {
          items {
            ...CustomCampaignQuestionFragment
          }
        }
      }
      registrationOptions {
        items {
          id
          name
          ageRequirement
          ageRequirementAsOfDate
          description
          maximumAttendees
          attendeesPerRegistration
          maximumRegistrationPerOrder
          groupRegistrationMinimum
          groupRegistrationMaximum
          attendees {
            count
          }
          pricingSettings {
            pricingEnabled
            pricingType
            pricingTiers {
              items {
                id
                price
                startDate
                endDate
              }
            }
          }
        }
      }
    }
  }
  ${DefaultCampaignQuestionFragmentFragmentDoc}
  ${CustomCampaignQuestionFragmentFragmentDoc}
`;
/**
 * __useAttendeeRegistrationDataQuery__
 *
 * To run a query within a React component, call `useAttendeeRegistrationDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttendeeRegistrationDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttendeeRegistrationDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAttendeeRegistrationDataQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(AttendeeRegistrationDataDocument, options);
}
export function useAttendeeRegistrationDataLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(AttendeeRegistrationDataDocument, options);
}
export const AuthProfileDocument = gql `
  query AuthProfile {
    authProfile {
      id
    }
  }
`;
/**
 * __useAuthProfileQuery__
 *
 * To run a query within a React component, call `useAuthProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useAuthProfileQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(AuthProfileDocument, options);
}
export function useAuthProfileLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(AuthProfileDocument, options);
}
export const CampaignCreateDocument = gql `
  mutation CampaignCreate($data: CampaignCreateInput!) {
    campaignCreate(data: $data) {
      ...CampaignsPageCampaignFragment
    }
  }
  ${CampaignsPageCampaignFragmentFragmentDoc}
`;
/**
 * __useCampaignCreateMutation__
 *
 * To run a mutation, you first call `useCampaignCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCampaignCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [campaignCreateMutation, { data, loading, error }] = useCampaignCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCampaignCreateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CampaignCreateDocument, options);
}
export const CampaignUpdateDocument = gql `
  mutation CampaignUpdate($data: CampaignUpdateInput!) {
    campaign: campaignUpdate(data: $data) {
      ...CampaignsPageCampaignFragment
    }
  }
  ${CampaignsPageCampaignFragmentFragmentDoc}
`;
/**
 * __useCampaignUpdateMutation__
 *
 * To run a mutation, you first call `useCampaignUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCampaignUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [campaignUpdateMutation, { data, loading, error }] = useCampaignUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCampaignUpdateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CampaignUpdateDocument, options);
}
export const CampaignDeleteDocument = gql `
  mutation CampaignDelete($data: CampaignDeleteInput!) {
    campaignDelete(data: $data, force: true) {
      success
    }
  }
`;
/**
 * __useCampaignDeleteMutation__
 *
 * To run a mutation, you first call `useCampaignDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCampaignDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [campaignDeleteMutation, { data, loading, error }] = useCampaignDeleteMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCampaignDeleteMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CampaignDeleteDocument, options);
}
export const CampaignsPageDocument = gql `
  query CampaignsPage($filter: CampaignFilter, $skip: Int, $first: Int) {
    campaignsList(filter: $filter, skip: $skip, first: $first) {
      items {
        ...CampaignsPageCampaignFragment
      }
      count
    }
  }
  ${CampaignsPageCampaignFragmentFragmentDoc}
`;
/**
 * __useCampaignsPageQuery__
 *
 * To run a query within a React component, call `useCampaignsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignsPageQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useCampaignsPageQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(CampaignsPageDocument, options);
}
export function useCampaignsPageLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(CampaignsPageDocument, options);
}
export const CampaignListDocument = gql `
  query CampaignList {
    campaignsList {
      items {
        id
        name
      }
    }
  }
`;
/**
 * __useCampaignListQuery__
 *
 * To run a query within a React component, call `useCampaignListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignListQuery({
 *   variables: {
 *   },
 * });
 */
export function useCampaignListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(CampaignListDocument, options);
}
export function useCampaignListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(CampaignListDocument, options);
}
export const CampaignWithExperienceListDocument = gql `
  query CampaignWithExperienceList($filter: CampaignFilter) {
    campaignsList(filter: $filter) {
      items {
        ...CampaignsPageCampaignFragment
      }
    }
  }
  ${CampaignsPageCampaignFragmentFragmentDoc}
`;
/**
 * __useCampaignWithExperienceListQuery__
 *
 * To run a query within a React component, call `useCampaignWithExperienceListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignWithExperienceListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignWithExperienceListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCampaignWithExperienceListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(CampaignWithExperienceListDocument, options);
}
export function useCampaignWithExperienceListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(CampaignWithExperienceListDocument, options);
}
export const CampaignListByOrganizationDocument = gql `
  query CampaignListByOrganization($orgId: ID!) {
    campaignsList(filter: { vizslaOrganization: { id: { equals: $orgId } } }) {
      items {
        id
        name
      }
    }
  }
`;
/**
 * __useCampaignListByOrganizationQuery__
 *
 * To run a query within a React component, call `useCampaignListByOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignListByOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignListByOrganizationQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useCampaignListByOrganizationQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(CampaignListByOrganizationDocument, options);
}
export function useCampaignListByOrganizationLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(CampaignListByOrganizationDocument, options);
}
export const CampaignStaffingDocument = gql `
  query CampaignStaffing($id: ID) {
    campaign(id: $id) {
      id
      campaignStaff {
        items {
          id
          user {
            id
            firstName
            lastName
            email
          }
        }
      }
    }
  }
`;
/**
 * __useCampaignStaffingQuery__
 *
 * To run a query within a React component, call `useCampaignStaffingQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignStaffingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignStaffingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCampaignStaffingQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(CampaignStaffingDocument, options);
}
export function useCampaignStaffingLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(CampaignStaffingDocument, options);
}
export const CampaignsStatisticsDocument = gql `
  query CampaignsStatistics($organizationId: ID!) {
    totalCount: campaignsList(filter: { vizslaOrganization: { id: { equals: $organizationId } } }) {
      count
    }
    totalActive: campaignsList(
      filter: {
        vizslaOrganization: { id: { equals: $organizationId } }
        campaignStatus: { equals: "" }
      }
    ) {
      count
    }
    totalCompleted: campaignsList(
      filter: {
        campaignStatus: { equals: "" }
        vizslaOrganization: { id: { equals: $organizationId } }
      }
    ) {
      count
    }
    totalAttendees: vizslaOrganization(id: $organizationId) {
      campaigns(
        groupBy: {
          query: { experiences: { attendees: { id: { as: "count", fn: { aggregate: COUNT } } } } }
        }
      ) {
        groups {
          count: Int
        }
      }
    }
    totalRaised: campaignsList(
      filter: { vizslaOrganization: { id: { equals: $organizationId } } }
      groupBy: { query: { totalRaised: { as: "totalRaised", fn: { aggregate: SUM } } } }
    ) {
      groups {
        totalRaised: Int
      }
    }
  }
`;
/**
 * __useCampaignsStatisticsQuery__
 *
 * To run a query within a React component, call `useCampaignsStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignsStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignsStatisticsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useCampaignsStatisticsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(CampaignsStatisticsDocument, options);
}
export function useCampaignsStatisticsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(CampaignsStatisticsDocument, options);
}
export const FundraisingSettingsByCampaignIdDocument = gql `
  query FundraisingSettingsByCampaignId($filter: CampaignFundraisingSettingFilter) {
    campaignFundraisingSettingsList(filter: $filter) {
      items {
        id
        generalSettings {
          id
          isEnabled
          isObligation
          isWithoutPenalty
          pageSetUp
          chargeTime
          time
          date
          chargeDate
          isFacebookEnabled
          isInstagramEnabled
        }
        donationSettings {
          id
          isEnabled
          allowAnonymousDonations
          allowSelfDonations
          allowDonorsToOptIn
          allowDonorsToDedicateDonations
          coveredFees
          percentageAmount
          feesEnabled
          feesMessage
          roundupEnabled
          roundupMessage
        }
        teamSettings {
          id
          isEnabled
          teamCapabilities
          joiningTeamEnabled
          allowTeamsMakeTheirTeamInvite
          allowAttendeeRequestAdmissionOntoTeam
          maximumTeams
          maximumTeamMembers
          teamSharingEnabled
          appealMessage
          thankYouMessage
        }
        individualSettings {
          id
          isEnabled
          appealMessage
          thankYouMessage
        }
      }
    }
  }
`;
/**
 * __useFundraisingSettingsByCampaignIdQuery__
 *
 * To run a query within a React component, call `useFundraisingSettingsByCampaignIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFundraisingSettingsByCampaignIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFundraisingSettingsByCampaignIdQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFundraisingSettingsByCampaignIdQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(FundraisingSettingsByCampaignIdDocument, options);
}
export function useFundraisingSettingsByCampaignIdLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(FundraisingSettingsByCampaignIdDocument, options);
}
export const CampaignFundraisingSettingsCreateDocument = gql `
  mutation CampaignFundraisingSettingsCreate($createData: CampaignFundraisingSettingCreateInput!) {
    campaignFundraisingSettingCreate(data: $createData) {
      id
    }
  }
`;
/**
 * __useCampaignFundraisingSettingsCreateMutation__
 *
 * To run a mutation, you first call `useCampaignFundraisingSettingsCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCampaignFundraisingSettingsCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [campaignFundraisingSettingsCreateMutation, { data, loading, error }] = useCampaignFundraisingSettingsCreateMutation({
 *   variables: {
 *      createData: // value for 'createData'
 *   },
 * });
 */
export function useCampaignFundraisingSettingsCreateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CampaignFundraisingSettingsCreateDocument, options);
}
export const CampaignFundraisingSettingsUpdateDocument = gql `
  mutation CampaignFundraisingSettingsUpdate(
    $updateData: CampaignFundraisingSettingUpdateInput!
    $settingsId: ID!
  ) {
    campaignFundraisingSettingUpdate(data: $updateData, filter: { id: $settingsId }) {
      id
    }
  }
`;
/**
 * __useCampaignFundraisingSettingsUpdateMutation__
 *
 * To run a mutation, you first call `useCampaignFundraisingSettingsUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCampaignFundraisingSettingsUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [campaignFundraisingSettingsUpdateMutation, { data, loading, error }] = useCampaignFundraisingSettingsUpdateMutation({
 *   variables: {
 *      updateData: // value for 'updateData'
 *      settingsId: // value for 'settingsId'
 *   },
 * });
 */
export function useCampaignFundraisingSettingsUpdateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CampaignFundraisingSettingsUpdateDocument, options);
}
export const FundraisingSettingsByExperienceIdDocument = gql `
  query FundraisingSettingsByExperienceId($filter: ExperienceFundraisingFilter) {
    experienceFundraisingsList(filter: $filter) {
      items {
        id
        generalSettings {
          id
          isEnabled
          isObligation
          isWithoutPenalty
          pageSetUp
          chargeTime
          time
          date
          chargeDate
          isFacebookEnabled
          isInstagramEnabled
        }
        donationSettings {
          id
          isEnabled
          allowAnonymousDonations
          allowSelfDonations
          allowDonorsToOptIn
          allowDonorsToDedicateDonations
          coveredFees
          percentageAmount
          feesEnabled
          feesMessage
          roundupEnabled
          roundupMessage
        }
        teamSettings {
          id
          isEnabled
          teamCapabilities
          joiningTeamEnabled
          allowTeamsMakeTheirTeamInvite
          allowAttendeeRequestAdmissionOntoTeam
          maximumTeams
          maximumTeamMembers
          teamSharingEnabled
          appealMessage
          thankYouMessage
        }
        individualSettings {
          id
          isEnabled
          appealMessage
          thankYouMessage
        }
      }
    }
  }
`;
/**
 * __useFundraisingSettingsByExperienceIdQuery__
 *
 * To run a query within a React component, call `useFundraisingSettingsByExperienceIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFundraisingSettingsByExperienceIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFundraisingSettingsByExperienceIdQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFundraisingSettingsByExperienceIdQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(FundraisingSettingsByExperienceIdDocument, options);
}
export function useFundraisingSettingsByExperienceIdLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(FundraisingSettingsByExperienceIdDocument, options);
}
export const ExperienceFundraisingSettingsCreateDocument = gql `
  mutation ExperienceFundraisingSettingsCreate($createData: ExperienceFundraisingCreateInput!) {
    experienceFundraisingCreate(data: $createData) {
      id
    }
  }
`;
/**
 * __useExperienceFundraisingSettingsCreateMutation__
 *
 * To run a mutation, you first call `useExperienceFundraisingSettingsCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExperienceFundraisingSettingsCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [experienceFundraisingSettingsCreateMutation, { data, loading, error }] = useExperienceFundraisingSettingsCreateMutation({
 *   variables: {
 *      createData: // value for 'createData'
 *   },
 * });
 */
export function useExperienceFundraisingSettingsCreateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ExperienceFundraisingSettingsCreateDocument, options);
}
export const ExperienceFundraisingSettingsUpdateDocument = gql `
  mutation ExperienceFundraisingSettingsUpdate(
    $updateData: ExperienceFundraisingUpdateInput!
    $settingsId: ID!
  ) {
    experienceFundraisingUpdate(data: $updateData, filter: { id: $settingsId }) {
      id
    }
  }
`;
/**
 * __useExperienceFundraisingSettingsUpdateMutation__
 *
 * To run a mutation, you first call `useExperienceFundraisingSettingsUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExperienceFundraisingSettingsUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [experienceFundraisingSettingsUpdateMutation, { data, loading, error }] = useExperienceFundraisingSettingsUpdateMutation({
 *   variables: {
 *      updateData: // value for 'updateData'
 *      settingsId: // value for 'settingsId'
 *   },
 * });
 */
export function useExperienceFundraisingSettingsUpdateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ExperienceFundraisingSettingsUpdateDocument, options);
}
export const RegistrationAndTicketingRecentActivityDocument = gql `
  query RegistrationAndTicketingRecentActivity($id: ID!, $skip: Int, $first: Int) {
    campaign(id: $id) {
      allocatedDonations(
        first: $first
        skip: $skip
        sort: { createdAt: DESC }
        filter: { parents: { every: null } }
      ) {
        count
        items {
          id
          amount
          createdAt
          donor {
            ...USER_RECENT_FRAGMENT
          }
          individualFundraising {
            id
            attendee {
              user {
                ...USER_RECENT_FRAGMENT
              }
            }
          }
          teamFundraising {
            name
          }
        }
      }
      teams(sort: { createdAt: DESC }, first: $first) {
        items {
          id
          name
          createdAt
          fundraiserGoal
          avatar {
            id
            downloadUrl
          }
          members {
            items {
              id
              role
              attendee {
                user {
                  ...USER_RECENT_FRAGMENT
                }
              }
            }
            count
          }
          donations {
            items {
              id
              amount
            }
          }
          campaign {
            fundraisingSettings {
              teamSettings {
                maximumTeamMembers
              }
            }
          }
        }
        count
      }
    }
  }
  ${User_Recent_FragmentFragmentDoc}
`;
/**
 * __useRegistrationAndTicketingRecentActivityQuery__
 *
 * To run a query within a React component, call `useRegistrationAndTicketingRecentActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegistrationAndTicketingRecentActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegistrationAndTicketingRecentActivityQuery({
 *   variables: {
 *      id: // value for 'id'
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useRegistrationAndTicketingRecentActivityQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(RegistrationAndTicketingRecentActivityDocument, options);
}
export function useRegistrationAndTicketingRecentActivityLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(RegistrationAndTicketingRecentActivityDocument, options);
}
export const PersonalMissionRecentActivityDocument = gql `
  query PersonalMissionRecentActivity($id: ID!, $first: Int) {
    campaign(id: $id) {
      donations(sort: { createdAt: DESC }, first: $first) {
        items {
          id
          amount
        }
        count
      }
    }
  }
`;
/**
 * __usePersonalMissionRecentActivityQuery__
 *
 * To run a query within a React component, call `usePersonalMissionRecentActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonalMissionRecentActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonalMissionRecentActivityQuery({
 *   variables: {
 *      id: // value for 'id'
 *      first: // value for 'first'
 *   },
 * });
 */
export function usePersonalMissionRecentActivityQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(PersonalMissionRecentActivityDocument, options);
}
export function usePersonalMissionRecentActivityLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(PersonalMissionRecentActivityDocument, options);
}
export const ImpactGivingRecentActivityDocument = gql `
  query ImpactGivingRecentActivity($id: ID!, $first: Int) {
    campaign(id: $id) {
      donations(sort: { createdAt: DESC }, first: $first) {
        items {
          id
          amount
        }
        count
      }
    }
  }
`;
/**
 * __useImpactGivingRecentActivityQuery__
 *
 * To run a query within a React component, call `useImpactGivingRecentActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useImpactGivingRecentActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImpactGivingRecentActivityQuery({
 *   variables: {
 *      id: // value for 'id'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useImpactGivingRecentActivityQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ImpactGivingRecentActivityDocument, options);
}
export function useImpactGivingRecentActivityLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ImpactGivingRecentActivityDocument, options);
}
export const CampaignDiscountsSettingsListDocument = gql `
  query CampaignDiscountsSettingsList($filter: DiscountCodeSettingFilter!) {
    discountCodeSettingsList(filter: $filter) {
      items {
        ...CampaignDiscountsSettingsFragment
      }
    }
  }
  ${CampaignDiscountsSettingsFragmentFragmentDoc}
`;
/**
 * __useCampaignDiscountsSettingsListQuery__
 *
 * To run a query within a React component, call `useCampaignDiscountsSettingsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignDiscountsSettingsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignDiscountsSettingsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCampaignDiscountsSettingsListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(CampaignDiscountsSettingsListDocument, options);
}
export function useCampaignDiscountsSettingsListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(CampaignDiscountsSettingsListDocument, options);
}
export const CampaignDiscountSettingsCreateDocument = gql `
  mutation CampaignDiscountSettingsCreate($createData: DiscountCodeSettingCreateInput!) {
    discountCodeSettingCreate(data: $createData) {
      ...CampaignDiscountsSettingsFragment
    }
  }
  ${CampaignDiscountsSettingsFragmentFragmentDoc}
`;
/**
 * __useCampaignDiscountSettingsCreateMutation__
 *
 * To run a mutation, you first call `useCampaignDiscountSettingsCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCampaignDiscountSettingsCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [campaignDiscountSettingsCreateMutation, { data, loading, error }] = useCampaignDiscountSettingsCreateMutation({
 *   variables: {
 *      createData: // value for 'createData'
 *   },
 * });
 */
export function useCampaignDiscountSettingsCreateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CampaignDiscountSettingsCreateDocument, options);
}
export const CampaignDiscountSettingsUpdateDocument = gql `
  mutation CampaignDiscountSettingsUpdate(
    $data: DiscountCodeSettingUpdateInput!
    $filter: DiscountCodeSettingKeyFilter!
  ) {
    discountCodeSettingUpdate(data: $data, filter: $filter) {
      ...CampaignDiscountsSettingsFragment
    }
  }
  ${CampaignDiscountsSettingsFragmentFragmentDoc}
`;
/**
 * __useCampaignDiscountSettingsUpdateMutation__
 *
 * To run a mutation, you first call `useCampaignDiscountSettingsUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCampaignDiscountSettingsUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [campaignDiscountSettingsUpdateMutation, { data, loading, error }] = useCampaignDiscountSettingsUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCampaignDiscountSettingsUpdateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CampaignDiscountSettingsUpdateDocument, options);
}
export const CampaignDiscountSettingsDeleteDocument = gql `
  mutation CampaignDiscountSettingsDelete($id: ID!) {
    discountCodeSettingDelete(filter: { id: $id }) {
      success
    }
  }
`;
/**
 * __useCampaignDiscountSettingsDeleteMutation__
 *
 * To run a mutation, you first call `useCampaignDiscountSettingsDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCampaignDiscountSettingsDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [campaignDiscountSettingsDeleteMutation, { data, loading, error }] = useCampaignDiscountSettingsDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCampaignDiscountSettingsDeleteMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CampaignDiscountSettingsDeleteDocument, options);
}
export const CampaignDiscountCounterByNameDocument = gql `
  query CampaignDiscountCounterByName($campaignId: ID!, $discountName: String!) {
    campaign(id: $campaignId) {
      discountCodeSettings(filter: { name: { equals: $discountName } }) {
        count
      }
    }
  }
`;
/**
 * __useCampaignDiscountCounterByNameQuery__
 *
 * To run a query within a React component, call `useCampaignDiscountCounterByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignDiscountCounterByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignDiscountCounterByNameQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *      discountName: // value for 'discountName'
 *   },
 * });
 */
export function useCampaignDiscountCounterByNameQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(CampaignDiscountCounterByNameDocument, options);
}
export function useCampaignDiscountCounterByNameLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(CampaignDiscountCounterByNameDocument, options);
}
export const CampaignDiscountSettingsDocument = gql `
  query CampaignDiscountSettings($campaignId: ID!, $filter: DiscountCodeSettingFilter!) {
    campaign(id: $campaignId) {
      discountCodeSettings(filter: $filter) {
        items {
          ...CampaignDiscountsSettingsFragment
        }
      }
    }
  }
  ${CampaignDiscountsSettingsFragmentFragmentDoc}
`;
/**
 * __useCampaignDiscountSettingsQuery__
 *
 * To run a query within a React component, call `useCampaignDiscountSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignDiscountSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignDiscountSettingsQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCampaignDiscountSettingsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(CampaignDiscountSettingsDocument, options);
}
export function useCampaignDiscountSettingsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(CampaignDiscountSettingsDocument, options);
}
export const CampaignFaQsListDocument = gql `
  query CampaignFAQsList($filter: CampaignFAQFilter) {
    campaignFAQSList(filter: $filter) {
      items {
        ...CampaignFaqFragment
      }
    }
  }
  ${CampaignFaqFragmentFragmentDoc}
`;
/**
 * __useCampaignFaQsListQuery__
 *
 * To run a query within a React component, call `useCampaignFaQsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignFaQsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignFaQsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCampaignFaQsListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(CampaignFaQsListDocument, options);
}
export function useCampaignFaQsListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(CampaignFaQsListDocument, options);
}
export const CampaignFaqCreateDocument = gql `
  mutation campaignFAQCreate($data: CampaignFAQCreateInput!) {
    campaignFAQCreate(data: $data) {
      ...CampaignFaqFragment
    }
  }
  ${CampaignFaqFragmentFragmentDoc}
`;
/**
 * __useCampaignFaqCreateMutation__
 *
 * To run a mutation, you first call `useCampaignFaqCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCampaignFaqCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [campaignFaqCreateMutation, { data, loading, error }] = useCampaignFaqCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCampaignFaqCreateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CampaignFaqCreateDocument, options);
}
export const CampaignFaqUpdateDocument = gql `
  mutation campaignFAQUpdate($data: CampaignFAQUpdateInput!, $filter: CampaignFAQKeyFilter!) {
    campaignFAQUpdate(data: $data, filter: $filter) {
      ...CampaignFaqFragment
    }
  }
  ${CampaignFaqFragmentFragmentDoc}
`;
/**
 * __useCampaignFaqUpdateMutation__
 *
 * To run a mutation, you first call `useCampaignFaqUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCampaignFaqUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [campaignFaqUpdateMutation, { data, loading, error }] = useCampaignFaqUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCampaignFaqUpdateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CampaignFaqUpdateDocument, options);
}
export const CampaignFaqDeleteDocument = gql `
  mutation CampaignFaqDelete($id: ID!) {
    campaignFAQDelete(filter: { id: $id }) {
      success
    }
  }
`;
/**
 * __useCampaignFaqDeleteMutation__
 *
 * To run a mutation, you first call `useCampaignFaqDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCampaignFaqDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [campaignFaqDeleteMutation, { data, loading, error }] = useCampaignFaqDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCampaignFaqDeleteMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CampaignFaqDeleteDocument, options);
}
export const CampaignTemplatesListDocument = gql `
  query CampaignTemplatesList($id: ID!) {
    campaignTemplatesList(filter: { campaign: { id: { equals: $id } } }) {
      items {
        id
        templates {
          items {
            id
            templateType {
              type
              title
            }
          }
        }
        campaign {
          id
        }
      }
    }
  }
`;
/**
 * __useCampaignTemplatesListQuery__
 *
 * To run a query within a React component, call `useCampaignTemplatesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignTemplatesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignTemplatesListQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCampaignTemplatesListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(CampaignTemplatesListDocument, options);
}
export function useCampaignTemplatesListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(CampaignTemplatesListDocument, options);
}
export const CampaignTemplateCreateDocument = gql `
  mutation CampaignTemplateCreate($data: CampaignTemplateCreateInput!) {
    campaignTemplateCreate(data: $data) {
      id
    }
  }
`;
/**
 * __useCampaignTemplateCreateMutation__
 *
 * To run a mutation, you first call `useCampaignTemplateCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCampaignTemplateCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [campaignTemplateCreateMutation, { data, loading, error }] = useCampaignTemplateCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCampaignTemplateCreateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CampaignTemplateCreateDocument, options);
}
export const TemplateStructureListDocument = gql `
  query TemplateStructureList {
    templateStructuresList {
      items {
        id
      }
      count
    }
  }
`;
/**
 * __useTemplateStructureListQuery__
 *
 * To run a query within a React component, call `useTemplateStructureListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplateStructureListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateStructureListQuery({
 *   variables: {
 *   },
 * });
 */
export function useTemplateStructureListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(TemplateStructureListDocument, options);
}
export function useTemplateStructureListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(TemplateStructureListDocument, options);
}
export const TemplateTypeListDocument = gql `
  query TemplateTypeList {
    templateTypesList {
      items {
        id
        type
      }
    }
  }
`;
/**
 * __useTemplateTypeListQuery__
 *
 * To run a query within a React component, call `useTemplateTypeListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplateTypeListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateTypeListQuery({
 *   variables: {
 *   },
 * });
 */
export function useTemplateTypeListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(TemplateTypeListDocument, options);
}
export function useTemplateTypeListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(TemplateTypeListDocument, options);
}
export const TemplateStructureDocument = gql `
  query TemplateStructure($campaignId: ID!, $templateType: String!) {
    templateStructuresList(
      filter: {
        campaignTemplates: { campaign: { id: { equals: $campaignId } } }
        templateType: { type: { equals: $templateType } }
      }
    ) {
      items {
        id
        structure
        templateType {
          id
          title
          type
        }
      }
    }
  }
`;
/**
 * __useTemplateStructureQuery__
 *
 * To run a query within a React component, call `useTemplateStructureQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplateStructureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplateStructureQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *      templateType: // value for 'templateType'
 *   },
 * });
 */
export function useTemplateStructureQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(TemplateStructureDocument, options);
}
export function useTemplateStructureLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(TemplateStructureDocument, options);
}
export const CampaignPageTemplateUpdateDocument = gql `
  mutation CampaignPageTemplateUpdate($data: TemplateStructureUpdateInput!) {
    templateStructureUpdate(data: $data) {
      id
      structure
      templateType {
        id
        title
        type
      }
    }
  }
`;
/**
 * __useCampaignPageTemplateUpdateMutation__
 *
 * To run a mutation, you first call `useCampaignPageTemplateUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCampaignPageTemplateUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [campaignPageTemplateUpdateMutation, { data, loading, error }] = useCampaignPageTemplateUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCampaignPageTemplateUpdateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CampaignPageTemplateUpdateDocument, options);
}
export const DefaultQuestionsByCategoryDocument = gql `
  query DefaultQuestionsByCategory {
    defaultQuestionsList {
      items {
        id
        type
        title
        category
        isMandatory
      }
    }
  }
`;
/**
 * __useDefaultQuestionsByCategoryQuery__
 *
 * To run a query within a React component, call `useDefaultQuestionsByCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useDefaultQuestionsByCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDefaultQuestionsByCategoryQuery({
 *   variables: {
 *   },
 * });
 */
export function useDefaultQuestionsByCategoryQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(DefaultQuestionsByCategoryDocument, options);
}
export function useDefaultQuestionsByCategoryLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(DefaultQuestionsByCategoryDocument, options);
}
export const CampaignCustomQuestionListDocument = gql `
  query CampaignCustomQuestionList($filter: CustomCampaignQuestionFilter) {
    customCampaignQuestionsList(filter: $filter) {
      items {
        id
        type
        options
        experience {
          items {
            ...CampaignQuestionExperienceFragment
          }
        }
      }
    }
  }
  ${CampaignQuestionExperienceFragmentFragmentDoc}
`;
/**
 * __useCampaignCustomQuestionListQuery__
 *
 * To run a query within a React component, call `useCampaignCustomQuestionListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignCustomQuestionListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignCustomQuestionListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCampaignCustomQuestionListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(CampaignCustomQuestionListDocument, options);
}
export function useCampaignCustomQuestionListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(CampaignCustomQuestionListDocument, options);
}
export const CampaignQuestionsDocument = gql `
  query CampaignQuestions(
    $id: ID!
    $defaultQuestionsFilter: DefaultCampaignQuestionFilter
    $customQuestionsFilter: CustomCampaignQuestionFilter
  ) {
    campaign(id: $id) {
      id
      defaultCampaignQuestions(filter: $defaultQuestionsFilter) {
        items {
          ...DefaultCampaignQuestionFragment
        }
      }
      customCampaignQuestions(filter: $customQuestionsFilter) {
        items {
          ...CustomCampaignQuestionFragment
        }
      }
    }
  }
  ${DefaultCampaignQuestionFragmentFragmentDoc}
  ${CustomCampaignQuestionFragmentFragmentDoc}
`;
/**
 * __useCampaignQuestionsQuery__
 *
 * To run a query within a React component, call `useCampaignQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignQuestionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      defaultQuestionsFilter: // value for 'defaultQuestionsFilter'
 *      customQuestionsFilter: // value for 'customQuestionsFilter'
 *   },
 * });
 */
export function useCampaignQuestionsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(CampaignQuestionsDocument, options);
}
export function useCampaignQuestionsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(CampaignQuestionsDocument, options);
}
export const CustomCampaignQuestionCreateDocument = gql `
  mutation customCampaignQuestionCreate($data: CustomCampaignQuestionCreateInput!) {
    customCampaignQuestionCreate(data: $data) {
      ...CustomCampaignQuestionFragment
    }
  }
  ${CustomCampaignQuestionFragmentFragmentDoc}
`;
/**
 * __useCustomCampaignQuestionCreateMutation__
 *
 * To run a mutation, you first call `useCustomCampaignQuestionCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomCampaignQuestionCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customCampaignQuestionCreateMutation, { data, loading, error }] = useCustomCampaignQuestionCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCustomCampaignQuestionCreateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CustomCampaignQuestionCreateDocument, options);
}
export const CampaignDefaultQuestionsCreateManyDocument = gql `
  mutation CampaignDefaultQuestionsCreateMany($data: [DefaultCampaignQuestionCreateManyInput]!) {
    defaultCampaignQuestionCreateMany(data: $data) {
      items {
        id
      }
      count
    }
  }
`;
/**
 * __useCampaignDefaultQuestionsCreateManyMutation__
 *
 * To run a mutation, you first call `useCampaignDefaultQuestionsCreateManyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCampaignDefaultQuestionsCreateManyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [campaignDefaultQuestionsCreateManyMutation, { data, loading, error }] = useCampaignDefaultQuestionsCreateManyMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCampaignDefaultQuestionsCreateManyMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CampaignDefaultQuestionsCreateManyDocument, options);
}
export const UpdateDefaultCampaignQuestionDocument = gql `
  mutation UpdateDefaultCampaignQuestion($id: ID!, $data: DefaultCampaignQuestionUpdateInput!) {
    defaultCampaignQuestionUpdate(data: $data, filter: { id: $id }) {
      id
      isEnabled
      isRequired
      shouldDisplay
      experience {
        items {
          ...CampaignQuestionExperienceFragment
        }
      }
    }
  }
  ${CampaignQuestionExperienceFragmentFragmentDoc}
`;
/**
 * __useUpdateDefaultCampaignQuestionMutation__
 *
 * To run a mutation, you first call `useUpdateDefaultCampaignQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDefaultCampaignQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDefaultCampaignQuestionMutation, { data, loading, error }] = useUpdateDefaultCampaignQuestionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateDefaultCampaignQuestionMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(UpdateDefaultCampaignQuestionDocument, options);
}
export const UpdateCustomCampaignQuestionDocument = gql `
  mutation UpdateCustomCampaignQuestion($id: ID!, $data: CustomCampaignQuestionUpdateInput!) {
    customCampaignQuestionUpdate(data: $data, filter: { id: $id }) {
      ...CustomCampaignQuestionFragment
      experience {
        items {
          ...CampaignQuestionExperienceFragment
        }
      }
    }
  }
  ${CustomCampaignQuestionFragmentFragmentDoc}
  ${CampaignQuestionExperienceFragmentFragmentDoc}
`;
/**
 * __useUpdateCustomCampaignQuestionMutation__
 *
 * To run a mutation, you first call `useUpdateCustomCampaignQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomCampaignQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomCampaignQuestionMutation, { data, loading, error }] = useUpdateCustomCampaignQuestionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCustomCampaignQuestionMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(UpdateCustomCampaignQuestionDocument, options);
}
export const UpdateCustomQuestionsByFilterDocument = gql `
  mutation UpdateCustomQuestionsByFilter(
    $data: CustomCampaignQuestionUpdateByFilterInput!
    $filter: CustomCampaignQuestionFilter
  ) {
    customCampaignQuestionUpdateByFilter(data: $data, filter: $filter) {
      items {
        id
        isEnabled
      }
    }
  }
`;
/**
 * __useUpdateCustomQuestionsByFilterMutation__
 *
 * To run a mutation, you first call `useUpdateCustomQuestionsByFilterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomQuestionsByFilterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomQuestionsByFilterMutation, { data, loading, error }] = useUpdateCustomQuestionsByFilterMutation({
 *   variables: {
 *      data: // value for 'data'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useUpdateCustomQuestionsByFilterMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(UpdateCustomQuestionsByFilterDocument, options);
}
export const UpdateDefaultQuestionsByFilterDocument = gql `
  mutation UpdateDefaultQuestionsByFilter(
    $data: DefaultCampaignQuestionUpdateByFilterInput!
    $filter: DefaultCampaignQuestionFilter
  ) {
    defaultCampaignQuestionUpdateByFilter(data: $data, filter: $filter) {
      items {
        id
        isEnabled
      }
    }
  }
`;
/**
 * __useUpdateDefaultQuestionsByFilterMutation__
 *
 * To run a mutation, you first call `useUpdateDefaultQuestionsByFilterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDefaultQuestionsByFilterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDefaultQuestionsByFilterMutation, { data, loading, error }] = useUpdateDefaultQuestionsByFilterMutation({
 *   variables: {
 *      data: // value for 'data'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useUpdateDefaultQuestionsByFilterMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(UpdateDefaultQuestionsByFilterDocument, options);
}
export const CustomCampaignQuestionDeleteDocument = gql `
  mutation CustomCampaignQuestionDelete($data: CustomCampaignQuestionDeleteInput!) {
    customCampaignQuestionDelete(data: $data) {
      success
    }
  }
`;
/**
 * __useCustomCampaignQuestionDeleteMutation__
 *
 * To run a mutation, you first call `useCustomCampaignQuestionDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomCampaignQuestionDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customCampaignQuestionDeleteMutation, { data, loading, error }] = useCustomCampaignQuestionDeleteMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCustomCampaignQuestionDeleteMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CustomCampaignQuestionDeleteDocument, options);
}
export const CampaignStaffCreateManyDocument = gql `
  mutation CampaignStaffCreateMany($dataToCreate: [CampaignStaffCreateManyInput]!) {
    createdStaffs: campaignStaffCreateMany(data: $dataToCreate) {
      count
      items {
        ...CampaignStaffFragment
      }
    }
  }
  ${CampaignStaffFragmentFragmentDoc}
`;
/**
 * __useCampaignStaffCreateManyMutation__
 *
 * To run a mutation, you first call `useCampaignStaffCreateManyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCampaignStaffCreateManyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [campaignStaffCreateManyMutation, { data, loading, error }] = useCampaignStaffCreateManyMutation({
 *   variables: {
 *      dataToCreate: // value for 'dataToCreate'
 *   },
 * });
 */
export function useCampaignStaffCreateManyMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CampaignStaffCreateManyDocument, options);
}
export const CampaignStaffUpdateByFilterDocument = gql `
  mutation CampaignStaffUpdateByFilter(
    $data: CampaignStaffUpdateByFilterInput!
    $filter: CampaignStaffFilter
  ) {
    updatedCampaignStaffs: campaignStaffUpdateByFilter(data: $data, filter: $filter) {
      items {
        ...CampaignStaffFragment
      }
    }
  }
  ${CampaignStaffFragmentFragmentDoc}
`;
/**
 * __useCampaignStaffUpdateByFilterMutation__
 *
 * To run a mutation, you first call `useCampaignStaffUpdateByFilterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCampaignStaffUpdateByFilterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [campaignStaffUpdateByFilterMutation, { data, loading, error }] = useCampaignStaffUpdateByFilterMutation({
 *   variables: {
 *      data: // value for 'data'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCampaignStaffUpdateByFilterMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CampaignStaffUpdateByFilterDocument, options);
}
export const CampaignStaffListDocument = gql `
  query CampaignStaffList($filter: CampaignStaffFilter) {
    campaignStaffsList(filter: $filter) {
      items {
        ...CampaignStaffFragment
      }
    }
  }
  ${CampaignStaffFragmentFragmentDoc}
`;
/**
 * __useCampaignStaffListQuery__
 *
 * To run a query within a React component, call `useCampaignStaffListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignStaffListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignStaffListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCampaignStaffListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(CampaignStaffListDocument, options);
}
export function useCampaignStaffListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(CampaignStaffListDocument, options);
}
export const CampaignStaffDeleteByFilterDocument = gql `
  mutation CampaignStaffDeleteByFilter($filter: CampaignStaffFilter!) {
    campaignStaffDeleteByFilter(filter: $filter) {
      success
    }
  }
`;
/**
 * __useCampaignStaffDeleteByFilterMutation__
 *
 * To run a mutation, you first call `useCampaignStaffDeleteByFilterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCampaignStaffDeleteByFilterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [campaignStaffDeleteByFilterMutation, { data, loading, error }] = useCampaignStaffDeleteByFilterMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCampaignStaffDeleteByFilterMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CampaignStaffDeleteByFilterDocument, options);
}
export const DonorsListDocument = gql `
  query DonorsList($filter: DonorFilter, $skip: Int, $first: Int) {
    donorsList(filter: $filter, skip: $skip, first: $first) {
      count
      items {
        ...DonorFragment
      }
    }
  }
  ${DonorFragmentFragmentDoc}
`;
/**
 * __useDonorsListQuery__
 *
 * To run a query within a React component, call `useDonorsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDonorsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDonorsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useDonorsListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(DonorsListDocument, options);
}
export function useDonorsListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(DonorsListDocument, options);
}
export const UsersDonorsListDocument = gql `
  query UsersDonorsList($organizationId: ID!, $skip: Int, $first: Int) {
    usersList(
      filter: {
        donors: { some: { campaign: { vizslaOrganization: { id: { equals: $organizationId } } } } }
      }
      skip: $skip
      first: $first
    ) {
      count
      items {
        id
        firstName
        lastName
        avatar {
          id
          downloadUrl
        }
        donors(filter: { campaign: { vizslaOrganization: { id: { equals: $organizationId } } } }) {
          count
          items {
            id
            donations {
              items {
                id
                amount
              }
            }
            campaign {
              id
              name
            }
          }
        }
      }
    }
  }
`;
/**
 * __useUsersDonorsListQuery__
 *
 * To run a query within a React component, call `useUsersDonorsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersDonorsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersDonorsListQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useUsersDonorsListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(UsersDonorsListDocument, options);
}
export function useUsersDonorsListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(UsersDonorsListDocument, options);
}
export const EarlyBirdRegistrationSettingsDocument = gql `
  query EarlyBirdRegistrationSettings($id: ID!) {
    experience(id: $id) {
      id
      earlyBirdUrlCode
      earlyBirdRegistrationAvailable
      earlyBirdRegistrationsApplyTo
      earlyBirdRegistrationsMode
      earlyBirdRegistrationOptions {
        items {
          ...EarlyBirdRegistrationOptionFragment
        }
        count
      }
    }
  }
  ${EarlyBirdRegistrationOptionFragmentFragmentDoc}
`;
/**
 * __useEarlyBirdRegistrationSettingsQuery__
 *
 * To run a query within a React component, call `useEarlyBirdRegistrationSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEarlyBirdRegistrationSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEarlyBirdRegistrationSettingsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEarlyBirdRegistrationSettingsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(EarlyBirdRegistrationSettingsDocument, options);
}
export function useEarlyBirdRegistrationSettingsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(EarlyBirdRegistrationSettingsDocument, options);
}
export const EarlyBirdRegistrationSettingsUpdateDocument = gql `
  mutation earlyBirdRegistrationSettingsUpdate(
    $data: ExperienceUpdateInput!
    $filter: ExperienceKeyFilter!
  ) {
    experienceUpdate(data: $data, filter: $filter) {
      id
      earlyBirdRegistrationAvailable
      earlyBirdRegistrationsApplyTo
      earlyBirdRegistrationsMode
      earlyBirdRegistrationOptions {
        items {
          ...EarlyBirdRegistrationOptionFragment
        }
        count
      }
    }
  }
  ${EarlyBirdRegistrationOptionFragmentFragmentDoc}
`;
/**
 * __useEarlyBirdRegistrationSettingsUpdateMutation__
 *
 * To run a mutation, you first call `useEarlyBirdRegistrationSettingsUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEarlyBirdRegistrationSettingsUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [earlyBirdRegistrationSettingsUpdateMutation, { data, loading, error }] = useEarlyBirdRegistrationSettingsUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useEarlyBirdRegistrationSettingsUpdateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(EarlyBirdRegistrationSettingsUpdateDocument, options);
}
export const EarlyBirdRegistrationInvitationsDocument = gql `
  query earlyBirdRegistrationInvitations($filter: EarlyBirdRegistrationInvitationFilter!) {
    earlyBirdRegistrationInvitationsList(filter: $filter) {
      items {
        ...EarlyBirdRegistrationInvitationFragment
      }
    }
  }
  ${EarlyBirdRegistrationInvitationFragmentFragmentDoc}
`;
/**
 * __useEarlyBirdRegistrationInvitationsQuery__
 *
 * To run a query within a React component, call `useEarlyBirdRegistrationInvitationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEarlyBirdRegistrationInvitationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEarlyBirdRegistrationInvitationsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useEarlyBirdRegistrationInvitationsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(EarlyBirdRegistrationInvitationsDocument, options);
}
export function useEarlyBirdRegistrationInvitationsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(EarlyBirdRegistrationInvitationsDocument, options);
}
export const EarlyBirdRegistrationInvitationCreateDocument = gql `
  mutation earlyBirdRegistrationInvitationCreate(
    $data: EarlyBirdRegistrationInvitationCreateInput!
  ) {
    earlyBirdRegistrationInvitationCreate(data: $data) {
      ...EarlyBirdRegistrationInvitationFragment
    }
  }
  ${EarlyBirdRegistrationInvitationFragmentFragmentDoc}
`;
/**
 * __useEarlyBirdRegistrationInvitationCreateMutation__
 *
 * To run a mutation, you first call `useEarlyBirdRegistrationInvitationCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEarlyBirdRegistrationInvitationCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [earlyBirdRegistrationInvitationCreateMutation, { data, loading, error }] = useEarlyBirdRegistrationInvitationCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEarlyBirdRegistrationInvitationCreateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(EarlyBirdRegistrationInvitationCreateDocument, options);
}
export const EarlyBirdRegistrationInvitationDeleteDocument = gql `
  mutation earlyBirdRegistrationInvitationDelete($id: ID!) {
    earlyBirdRegistrationInvitationDelete(filter: { id: $id }) {
      success
    }
  }
`;
/**
 * __useEarlyBirdRegistrationInvitationDeleteMutation__
 *
 * To run a mutation, you first call `useEarlyBirdRegistrationInvitationDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEarlyBirdRegistrationInvitationDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [earlyBirdRegistrationInvitationDeleteMutation, { data, loading, error }] = useEarlyBirdRegistrationInvitationDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEarlyBirdRegistrationInvitationDeleteMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(EarlyBirdRegistrationInvitationDeleteDocument, options);
}
export const ExperienceperCampaignDocument = gql `
  query ExperienceperCampaign($campaignId: ID!) {
    experiencesList(filter: { campaign: { id: { equals: $campaignId } } }) {
      count
      items {
        ...ExperienceFragment
      }
    }
  }
  ${ExperienceFragmentFragmentDoc}
`;
/**
 * __useExperienceperCampaignQuery__
 *
 * To run a query within a React component, call `useExperienceperCampaignQuery` and pass it any options that fit your needs.
 * When your component renders, `useExperienceperCampaignQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExperienceperCampaignQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useExperienceperCampaignQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ExperienceperCampaignDocument, options);
}
export function useExperienceperCampaignLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ExperienceperCampaignDocument, options);
}
export const TeamsPerExperienceDocument = gql `
  query TeamsPerExperience($experienceId: ID!, $teamName: String) {
    experience(id: $experienceId) {
      teams(filter: { name: { equals: $teamName } }) {
        count
        items {
          name
        }
      }
    }
  }
`;
/**
 * __useTeamsPerExperienceQuery__
 *
 * To run a query within a React component, call `useTeamsPerExperienceQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamsPerExperienceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamsPerExperienceQuery({
 *   variables: {
 *      experienceId: // value for 'experienceId'
 *      teamName: // value for 'teamName'
 *   },
 * });
 */
export function useTeamsPerExperienceQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(TeamsPerExperienceDocument, options);
}
export function useTeamsPerExperienceLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(TeamsPerExperienceDocument, options);
}
export const ExperienceListDocument = gql `
  query ExperienceList($filter: ExperienceFilter, $skip: Int, $first: Int) {
    experiencesList(filter: $filter, skip: $skip, first: $first) {
      items {
        ...ExperienceFragment
      }
    }
  }
  ${ExperienceFragmentFragmentDoc}
`;
/**
 * __useExperienceListQuery__
 *
 * To run a query within a React component, call `useExperienceListQuery` and pass it any options that fit your needs.
 * When your component renders, `useExperienceListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExperienceListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useExperienceListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ExperienceListDocument, options);
}
export function useExperienceListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ExperienceListDocument, options);
}
export const ExperienceDocument = gql `
  query Experience($id: ID!) {
    experience(id: $id) {
      ...ExperienceFragment
    }
  }
  ${ExperienceFragmentFragmentDoc}
`;
/**
 * __useExperienceQuery__
 *
 * To run a query within a React component, call `useExperienceQuery` and pass it any options that fit your needs.
 * When your component renders, `useExperienceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExperienceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExperienceQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ExperienceDocument, options);
}
export function useExperienceLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ExperienceDocument, options);
}
export const ExperienceCreateManyDocument = gql `
  mutation ExperienceCreateMany($data: [ExperienceCreateManyInput]!) {
    experienceCreateMany(data: $data) {
      count
    }
  }
`;
/**
 * __useExperienceCreateManyMutation__
 *
 * To run a mutation, you first call `useExperienceCreateManyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExperienceCreateManyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [experienceCreateManyMutation, { data, loading, error }] = useExperienceCreateManyMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useExperienceCreateManyMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ExperienceCreateManyDocument, options);
}
export const ExperienceCreateDocument = gql `
  mutation ExperienceCreate($data: ExperienceCreateInput!) {
    experienceCreate(data: $data) {
      ...ExperienceFragment
    }
  }
  ${ExperienceFragmentFragmentDoc}
`;
/**
 * __useExperienceCreateMutation__
 *
 * To run a mutation, you first call `useExperienceCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExperienceCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [experienceCreateMutation, { data, loading, error }] = useExperienceCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useExperienceCreateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ExperienceCreateDocument, options);
}
export const ExperienceUpdateDocument = gql `
  mutation ExperienceUpdate($data: ExperienceUpdateInput!, $filter: ExperienceKeyFilter!) {
    experienceUpdate(data: $data, filter: $filter) {
      ...ExperienceFragment
    }
  }
  ${ExperienceFragmentFragmentDoc}
`;
/**
 * __useExperienceUpdateMutation__
 *
 * To run a mutation, you first call `useExperienceUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExperienceUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [experienceUpdateMutation, { data, loading, error }] = useExperienceUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useExperienceUpdateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ExperienceUpdateDocument, options);
}
export const ExperienceDeleteDocument = gql `
  mutation ExperienceDelete($id: ID!) {
    experienceDelete(filter: { id: $id }) {
      success
    }
  }
`;
/**
 * __useExperienceDeleteMutation__
 *
 * To run a mutation, you first call `useExperienceDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExperienceDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [experienceDeleteMutation, { data, loading, error }] = useExperienceDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExperienceDeleteMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ExperienceDeleteDocument, options);
}
export const ExperienceGeneralSettingsListDocument = gql `
  query ExperienceGeneralSettingsList($id: ID!) {
    experience(id: $id) {
      ...ExperienceFragment
      ...ExperienceGeneralFragment
    }
  }
  ${ExperienceFragmentFragmentDoc}
  ${ExperienceGeneralFragmentFragmentDoc}
`;
/**
 * __useExperienceGeneralSettingsListQuery__
 *
 * To run a query within a React component, call `useExperienceGeneralSettingsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useExperienceGeneralSettingsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExperienceGeneralSettingsListQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExperienceGeneralSettingsListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ExperienceGeneralSettingsListDocument, options);
}
export function useExperienceGeneralSettingsListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ExperienceGeneralSettingsListDocument, options);
}
export const ExperienceGeneralUpdateMutationDocument = gql `
  mutation ExperienceGeneralUpdateMutation(
    $data: ExperienceUpdateInput!
    $filter: ExperienceKeyFilter!
  ) {
    experienceUpdate(data: $data, filter: $filter) {
      ...ExperienceFragment
      ...ExperienceGeneralFragment
    }
  }
  ${ExperienceFragmentFragmentDoc}
  ${ExperienceGeneralFragmentFragmentDoc}
`;
/**
 * __useExperienceGeneralUpdateMutationMutation__
 *
 * To run a mutation, you first call `useExperienceGeneralUpdateMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExperienceGeneralUpdateMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [experienceGeneralUpdateMutationMutation, { data, loading, error }] = useExperienceGeneralUpdateMutationMutation({
 *   variables: {
 *      data: // value for 'data'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useExperienceGeneralUpdateMutationMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ExperienceGeneralUpdateMutationDocument, options);
}
export const ExperienceVenueCreateMutationDocument = gql `
  mutation ExperienceVenueCreateMutation($data: ExperienceVenueCreateInput!) {
    experienceVenueCreate(data: $data) {
      ...ExperienceVenueFragment
    }
  }
  ${ExperienceVenueFragmentFragmentDoc}
`;
/**
 * __useExperienceVenueCreateMutationMutation__
 *
 * To run a mutation, you first call `useExperienceVenueCreateMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExperienceVenueCreateMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [experienceVenueCreateMutationMutation, { data, loading, error }] = useExperienceVenueCreateMutationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useExperienceVenueCreateMutationMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ExperienceVenueCreateMutationDocument, options);
}
export const ExperienceVenueUpdateMutationDocument = gql `
  mutation ExperienceVenueUpdateMutation(
    $data: ExperienceVenueUpdateInput!
    $filter: ExperienceVenueKeyFilter!
  ) {
    experienceVenueUpdate(data: $data, filter: $filter) {
      ...ExperienceVenueFragment
    }
  }
  ${ExperienceVenueFragmentFragmentDoc}
`;
/**
 * __useExperienceVenueUpdateMutationMutation__
 *
 * To run a mutation, you first call `useExperienceVenueUpdateMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExperienceVenueUpdateMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [experienceVenueUpdateMutationMutation, { data, loading, error }] = useExperienceVenueUpdateMutationMutation({
 *   variables: {
 *      data: // value for 'data'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useExperienceVenueUpdateMutationMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ExperienceVenueUpdateMutationDocument, options);
}
export const ExperienceVenueDeleteMutationDocument = gql `
  mutation ExperienceVenueDeleteMutation($id: ID!) {
    experienceVenueDelete(filter: { id: $id }) {
      success
    }
  }
`;
/**
 * __useExperienceVenueDeleteMutationMutation__
 *
 * To run a mutation, you first call `useExperienceVenueDeleteMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExperienceVenueDeleteMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [experienceVenueDeleteMutationMutation, { data, loading, error }] = useExperienceVenueDeleteMutationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExperienceVenueDeleteMutationMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ExperienceVenueDeleteMutationDocument, options);
}
export const ExperienceTransactionFeesDocument = gql `
  query ExperienceTransactionFees($id: ID) {
    experience(id: $id) {
      id
      transactionFees
    }
  }
`;
/**
 * __useExperienceTransactionFeesQuery__
 *
 * To run a query within a React component, call `useExperienceTransactionFeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useExperienceTransactionFeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExperienceTransactionFeesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExperienceTransactionFeesQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ExperienceTransactionFeesDocument, options);
}
export function useExperienceTransactionFeesLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ExperienceTransactionFeesDocument, options);
}
export const CampaignTeamsListDocument = gql `
  query CampaignTeamsList($filter: TeamFundraisingFilter!) {
    teamFundraisingsList(filter: $filter) {
      items {
        avatar {
          id
          downloadUrl
        }
        id
        name
      }
    }
  }
`;
/**
 * __useCampaignTeamsListQuery__
 *
 * To run a query within a React component, call `useCampaignTeamsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignTeamsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignTeamsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCampaignTeamsListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(CampaignTeamsListDocument, options);
}
export function useCampaignTeamsListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(CampaignTeamsListDocument, options);
}
export const ExperienceBibAssignmentSettingsDocument = gql `
  query ExperienceBibAssignmentSettings($id: ID!) {
    experience(id: $id) {
      ...ExperienceBibAssignmentSettingsFragment
    }
  }
  ${ExperienceBibAssignmentSettingsFragmentFragmentDoc}
`;
/**
 * __useExperienceBibAssignmentSettingsQuery__
 *
 * To run a query within a React component, call `useExperienceBibAssignmentSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExperienceBibAssignmentSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExperienceBibAssignmentSettingsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExperienceBibAssignmentSettingsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ExperienceBibAssignmentSettingsDocument, options);
}
export function useExperienceBibAssignmentSettingsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ExperienceBibAssignmentSettingsDocument, options);
}
export const ExperienceBibAssignmentSettingsUpdateDocument = gql `
  mutation ExperienceBibAssignmentSettingsUpdate(
    $data: ExperienceUpdateInput!
    $filter: ExperienceKeyFilter
  ) {
    experienceUpdate(data: $data, filter: $filter) {
      ...ExperienceBibAssignmentSettingsFragment
    }
  }
  ${ExperienceBibAssignmentSettingsFragmentFragmentDoc}
`;
/**
 * __useExperienceBibAssignmentSettingsUpdateMutation__
 *
 * To run a mutation, you first call `useExperienceBibAssignmentSettingsUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExperienceBibAssignmentSettingsUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [experienceBibAssignmentSettingsUpdateMutation, { data, loading, error }] = useExperienceBibAssignmentSettingsUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useExperienceBibAssignmentSettingsUpdateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ExperienceBibAssignmentSettingsUpdateDocument, options);
}
export const ExperienceBibPoolCreateDocument = gql `
  mutation ExperienceBibPoolCreate($validatedData: JSON!, $additionalData: JSON!) {
    bibPoolCreate(validatedData: $validatedData, additionalData: $additionalData) {
      experienceBibPoolCreate
    }
  }
`;
/**
 * __useExperienceBibPoolCreateMutation__
 *
 * To run a mutation, you first call `useExperienceBibPoolCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExperienceBibPoolCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [experienceBibPoolCreateMutation, { data, loading, error }] = useExperienceBibPoolCreateMutation({
 *   variables: {
 *      validatedData: // value for 'validatedData'
 *      additionalData: // value for 'additionalData'
 *   },
 * });
 */
export function useExperienceBibPoolCreateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ExperienceBibPoolCreateDocument, options);
}
export const ExperienceBibPoolUpdateDocument = gql `
  mutation ExperienceBibPoolUpdate($validatedData: JSON!, $additionalData: JSON!) {
    bibPoolUpdate(validatedData: $validatedData, additionalData: $additionalData) {
      experienceBibPoolUpdate
    }
  }
`;
/**
 * __useExperienceBibPoolUpdateMutation__
 *
 * To run a mutation, you first call `useExperienceBibPoolUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExperienceBibPoolUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [experienceBibPoolUpdateMutation, { data, loading, error }] = useExperienceBibPoolUpdateMutation({
 *   variables: {
 *      validatedData: // value for 'validatedData'
 *      additionalData: // value for 'additionalData'
 *   },
 * });
 */
export function useExperienceBibPoolUpdateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ExperienceBibPoolUpdateDocument, options);
}
export const ExperienceBibPoolDeleteDocument = gql `
  mutation ExperienceBibPoolDelete($filter: ExperienceBibPoolKeyFilter!) {
    experienceBibPoolDelete(filter: $filter) {
      success
    }
  }
`;
/**
 * __useExperienceBibPoolDeleteMutation__
 *
 * To run a mutation, you first call `useExperienceBibPoolDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExperienceBibPoolDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [experienceBibPoolDeleteMutation, { data, loading, error }] = useExperienceBibPoolDeleteMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useExperienceBibPoolDeleteMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ExperienceBibPoolDeleteDocument, options);
}
export const ExperienceBibAssignmentsListDocument = gql `
  query ExperienceBibAssignmentsList($filter: ExperienceBibAssignmentFilter) {
    experienceBibAssignmentsList(filter: $filter) {
      items {
        ...BibAssignmentFragment
      }
    }
  }
  ${BibAssignmentFragmentFragmentDoc}
`;
/**
 * __useExperienceBibAssignmentsListQuery__
 *
 * To run a query within a React component, call `useExperienceBibAssignmentsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useExperienceBibAssignmentsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExperienceBibAssignmentsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useExperienceBibAssignmentsListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ExperienceBibAssignmentsListDocument, options);
}
export function useExperienceBibAssignmentsListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ExperienceBibAssignmentsListDocument, options);
}
export const ExperienceBibAssignmentCreateDocument = gql `
  mutation ExperienceBibAssignmentCreate($data: ExperienceBibAssignmentCreateInput!) {
    experienceBibAssignmentCreate(data: $data) {
      ...BibAssignmentFragment
    }
  }
  ${BibAssignmentFragmentFragmentDoc}
`;
/**
 * __useExperienceBibAssignmentCreateMutation__
 *
 * To run a mutation, you first call `useExperienceBibAssignmentCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExperienceBibAssignmentCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [experienceBibAssignmentCreateMutation, { data, loading, error }] = useExperienceBibAssignmentCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useExperienceBibAssignmentCreateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ExperienceBibAssignmentCreateDocument, options);
}
export const ExperienceBibExcludeNumberDocument = gql `
  mutation ExperienceBibExcludeNumber($experienceId: ID!, $bibNumber: Int!) {
    bibExcludeNumber(experienceId: $experienceId, bibNumber: $bibNumber) {
      deleted
      bibAssignment {
        ...BibAssignmentFragment
      }
    }
  }
  ${BibAssignmentFragmentFragmentDoc}
`;
/**
 * __useExperienceBibExcludeNumberMutation__
 *
 * To run a mutation, you first call `useExperienceBibExcludeNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExperienceBibExcludeNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [experienceBibExcludeNumberMutation, { data, loading, error }] = useExperienceBibExcludeNumberMutation({
 *   variables: {
 *      experienceId: // value for 'experienceId'
 *      bibNumber: // value for 'bibNumber'
 *   },
 * });
 */
export function useExperienceBibExcludeNumberMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ExperienceBibExcludeNumberDocument, options);
}
export const ExperienceBibAssignIndividualNumberDocument = gql `
  mutation ExperienceBibAssignIndividualNumber(
    $experienceId: ID!
    $attendeeId: ID!
    $bibNumber: Int!
  ) {
    bibAssignIndividualNumber(
      experienceId: $experienceId
      attendeeId: $attendeeId
      bibNumber: $bibNumber
    ) {
      deleted
      bibAssignment {
        ...BibAssignmentFragment
      }
    }
  }
  ${BibAssignmentFragmentFragmentDoc}
`;
/**
 * __useExperienceBibAssignIndividualNumberMutation__
 *
 * To run a mutation, you first call `useExperienceBibAssignIndividualNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExperienceBibAssignIndividualNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [experienceBibAssignIndividualNumberMutation, { data, loading, error }] = useExperienceBibAssignIndividualNumberMutation({
 *   variables: {
 *      experienceId: // value for 'experienceId'
 *      attendeeId: // value for 'attendeeId'
 *      bibNumber: // value for 'bibNumber'
 *   },
 * });
 */
export function useExperienceBibAssignIndividualNumberMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ExperienceBibAssignIndividualNumberDocument, options);
}
export const ExperienceBibAssignBatchDocument = gql `
  mutation ExperienceBibAssignBatch($experienceId: ID!, $sortBy: String!, $order: String!) {
    bibAssignBatch(experienceId: $experienceId, sortBy: $sortBy, order: $order) {
      success
    }
  }
`;
/**
 * __useExperienceBibAssignBatchMutation__
 *
 * To run a mutation, you first call `useExperienceBibAssignBatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExperienceBibAssignBatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [experienceBibAssignBatchMutation, { data, loading, error }] = useExperienceBibAssignBatchMutation({
 *   variables: {
 *      experienceId: // value for 'experienceId'
 *      sortBy: // value for 'sortBy'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useExperienceBibAssignBatchMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ExperienceBibAssignBatchDocument, options);
}
export const ExperienceBibAssignmentCreateManyDocument = gql `
  mutation ExperienceBibAssignmentCreateMany($data: [ExperienceBibAssignmentCreateManyInput!]!) {
    experienceBibAssignmentCreateMany(data: $data) {
      items {
        ...BibAssignmentFragment
      }
    }
  }
  ${BibAssignmentFragmentFragmentDoc}
`;
/**
 * __useExperienceBibAssignmentCreateManyMutation__
 *
 * To run a mutation, you first call `useExperienceBibAssignmentCreateManyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExperienceBibAssignmentCreateManyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [experienceBibAssignmentCreateManyMutation, { data, loading, error }] = useExperienceBibAssignmentCreateManyMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useExperienceBibAssignmentCreateManyMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ExperienceBibAssignmentCreateManyDocument, options);
}
export const ExperienceBibAssignmentDeleteManyDocument = gql `
  mutation ExperienceBibAssignmentDeleteMany($filter: ExperienceBibAssignmentFilter!) {
    experienceBibAssignmentDeleteByFilter(filter: $filter) {
      success
    }
  }
`;
/**
 * __useExperienceBibAssignmentDeleteManyMutation__
 *
 * To run a mutation, you first call `useExperienceBibAssignmentDeleteManyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExperienceBibAssignmentDeleteManyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [experienceBibAssignmentDeleteManyMutation, { data, loading, error }] = useExperienceBibAssignmentDeleteManyMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useExperienceBibAssignmentDeleteManyMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ExperienceBibAssignmentDeleteManyDocument, options);
}
export const ExperienceBibAssignmentDeleteDocument = gql `
  mutation ExperienceBibAssignmentDelete($filter: ExperienceBibAssignmentKeyFilter!) {
    experienceBibAssignmentDelete(filter: $filter) {
      success
    }
  }
`;
/**
 * __useExperienceBibAssignmentDeleteMutation__
 *
 * To run a mutation, you first call `useExperienceBibAssignmentDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExperienceBibAssignmentDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [experienceBibAssignmentDeleteMutation, { data, loading, error }] = useExperienceBibAssignmentDeleteMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useExperienceBibAssignmentDeleteMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ExperienceBibAssignmentDeleteDocument, options);
}
export const OrdersbydonorsDocument = gql `
  query ordersbydonors($orderId: ID) {
    order(id: $orderId) {
      donations {
        items {
          amount
          donor {
            createdAt
            firstName
            lastName
            email
            gender
            companyName
          }
          donationAllocations {
            items {
              teamFundraising {
                name
              }
              individualFundraising {
                attendee {
                  user {
                    firstName
                    lastName
                    createdAt
                  }
                }
              }
            }
          }
        }
      }
      registrationOptionFulfillment {
        items {
          attendee {
            id
            createdAt
            user {
              id
              firstName
              lastName
              gender
              companyName
              email
              createdAt
            }
          }
        }
      }
      user {
        firstName
        lastName
        email
        createdAt
      }
    }
  }
`;
/**
 * __useOrdersbydonorsQuery__
 *
 * To run a query within a React component, call `useOrdersbydonorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersbydonorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersbydonorsQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useOrdersbydonorsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(OrdersbydonorsDocument, options);
}
export function useOrdersbydonorsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(OrdersbydonorsDocument, options);
}
export const OrganizationCreateDocument = gql `
  mutation OrganizationCreate($data: JSON!) {
    organizationCreate(data: $data) {
      success
    }
  }
`;
/**
 * __useOrganizationCreateMutation__
 *
 * To run a mutation, you first call `useOrganizationCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganizationCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organizationCreateMutation, { data, loading, error }] = useOrganizationCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useOrganizationCreateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(OrganizationCreateDocument, options);
}
export const VizslaOrganizationStaffDocument = gql `
  query VizslaOrganizationStaff(
    $id: ID!
    $filter: VizslaOrganizationStaffFilter
    $skip: Int
    $first: Int
  ) {
    vizslaOrganization(id: $id) {
      id
      vizslaOrganizationStaff(filter: $filter, skip: $skip, first: $first) {
        count
        items {
          id
          user {
            ...UserFragment
            avatar {
              id
              downloadUrl
            }
          }
        }
      }
    }
  }
  ${UserFragmentFragmentDoc}
`;
/**
 * __useVizslaOrganizationStaffQuery__
 *
 * To run a query within a React component, call `useVizslaOrganizationStaffQuery` and pass it any options that fit your needs.
 * When your component renders, `useVizslaOrganizationStaffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVizslaOrganizationStaffQuery({
 *   variables: {
 *      id: // value for 'id'
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useVizslaOrganizationStaffQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(VizslaOrganizationStaffDocument, options);
}
export function useVizslaOrganizationStaffLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(VizslaOrganizationStaffDocument, options);
}
export const OrganizationMembersDocument = gql `
  query organizationMembers($filter: VizslaOrganizationStaffFilter!, $skip: Int, $first: Int) {
    vizslaOrganizationStaffsList(filter: $filter, skip: $skip, first: $first) {
      count
      items {
        id
        roles
        user {
          email
          status
          avatar {
            downloadUrl
          }
          firstName
          lastName
          phone {
            number
            code
          }
          updatedAt
        }
      }
    }
  }
`;
/**
 * __useOrganizationMembersQuery__
 *
 * To run a query within a React component, call `useOrganizationMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationMembersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useOrganizationMembersQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(OrganizationMembersDocument, options);
}
export function useOrganizationMembersLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(OrganizationMembersDocument, options);
}
export const AddOrganizationMemberDocument = gql `
  mutation addOrganizationMember($data: JSON!) {
    addOrganizationMember(data: $data) {
      success
    }
  }
`;
/**
 * __useAddOrganizationMemberMutation__
 *
 * To run a mutation, you first call `useAddOrganizationMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOrganizationMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOrganizationMemberMutation, { data, loading, error }] = useAddOrganizationMemberMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddOrganizationMemberMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(AddOrganizationMemberDocument, options);
}
export const ResendTemporaryPasswordDocument = gql `
  mutation resendTemporaryPassword($data: JSON!) {
    resendTemporaryPassword(data: $data) {
      success
    }
  }
`;
/**
 * __useResendTemporaryPasswordMutation__
 *
 * To run a mutation, you first call `useResendTemporaryPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendTemporaryPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendTemporaryPasswordMutation, { data, loading, error }] = useResendTemporaryPasswordMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useResendTemporaryPasswordMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ResendTemporaryPasswordDocument, options);
}
export const VizslaOrganizationStaffUpdateDocument = gql `
  mutation vizslaOrganizationStaffUpdate(
    $data: VizslaOrganizationStaffUpdateInput!
    $filter: VizslaOrganizationStaffKeyFilter
  ) {
    vizslaOrganizationStaffUpdate(data: $data, filter: $filter) {
      id
    }
  }
`;
/**
 * __useVizslaOrganizationStaffUpdateMutation__
 *
 * To run a mutation, you first call `useVizslaOrganizationStaffUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVizslaOrganizationStaffUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [vizslaOrganizationStaffUpdateMutation, { data, loading, error }] = useVizslaOrganizationStaffUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useVizslaOrganizationStaffUpdateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(VizslaOrganizationStaffUpdateDocument, options);
}
export const PartnershipOrganizationsListDocument = gql `
  query PartnershipOrganizationsList($filter: PartnershipOrganizationFilter) {
    partnershipOrganizationsList(filter: $filter) {
      items {
        id
        name
        email
        avatar {
          id
          downloadUrl
        }
        organization {
          id
        }
      }
    }
  }
`;
/**
 * __usePartnershipOrganizationsListQuery__
 *
 * To run a query within a React component, call `usePartnershipOrganizationsListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartnershipOrganizationsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartnershipOrganizationsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function usePartnershipOrganizationsListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(PartnershipOrganizationsListDocument, options);
}
export function usePartnershipOrganizationsListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(PartnershipOrganizationsListDocument, options);
}
export const OrganizationStatisticsDocument = gql `
  query OrganizationStatistics($organizationId: ID!) {
    attendeesCount: attendeesList(
      filter: {
        experience: { campaign: { vizslaOrganization: { id: { equals: $organizationId } } } }
      }
    ) {
      count
    }
    donorsCount: donorsList(
      filter: { campaign: { vizslaOrganization: { id: { equals: $organizationId } } } }
    ) {
      count
    }
    fundraisersCount: attendeesList(
      filter: {
        experience: { campaign: { vizslaOrganization: { id: { equals: $organizationId } } } }
        fundraisingPageLink: { is_not_empty: true }
      }
    ) {
      count
    }
    teamsCount: teamFundraisingsList(
      filter: { campaign: { vizslaOrganization: { id: { equals: $organizationId } } } }
    ) {
      count
    }
  }
`;
/**
 * __useOrganizationStatisticsQuery__
 *
 * To run a query within a React component, call `useOrganizationStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationStatisticsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useOrganizationStatisticsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(OrganizationStatisticsDocument, options);
}
export function useOrganizationStatisticsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(OrganizationStatisticsDocument, options);
}
export const OrganizationFilteredUsersListDocument = gql `
  query OrganizationFilteredUsersList($filter: UserFilter, $skip: Int, $first: Int) {
    usersList(filter: $filter, skip: $skip, first: $first) {
      count
      items {
        ...BaseUserFragment
      }
    }
  }
  ${BaseUserFragmentFragmentDoc}
`;
/**
 * __useOrganizationFilteredUsersListQuery__
 *
 * To run a query within a React component, call `useOrganizationFilteredUsersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationFilteredUsersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationFilteredUsersListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useOrganizationFilteredUsersListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(OrganizationFilteredUsersListDocument, options);
}
export function useOrganizationFilteredUsersListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(OrganizationFilteredUsersListDocument, options);
}
export const OrganizationFilteredDonorsListDocument = gql `
  query OrganizationFilteredDonorsList($filter: UserFilter, $skip: Int, $first: Int) {
    usersList(filter: $filter, skip: $skip, first: $first) {
      count
      items {
        id
        firstName
        lastName
        avatar {
          id
          downloadUrl
        }
        attendees {
          items {
            id
            teamMembership {
              team {
                id
                name
              }
            }
            totalRaised
          }
        }
        donors {
          items {
            id
            campaign {
              id
            }
          }
        }
      }
    }
  }
`;
/**
 * __useOrganizationFilteredDonorsListQuery__
 *
 * To run a query within a React component, call `useOrganizationFilteredDonorsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationFilteredDonorsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationFilteredDonorsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useOrganizationFilteredDonorsListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(OrganizationFilteredDonorsListDocument, options);
}
export function useOrganizationFilteredDonorsListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(OrganizationFilteredDonorsListDocument, options);
}
export const OrganizationTeamsListDocument = gql `
  query OrganizationTeamsList($filter: TeamFundraisingFilter, $skip: Int, $first: Int) {
    teamFundraisingsList(filter: $filter, skip: $skip, first: $first) {
      count
      items {
        id
        name
        avatar {
          id
          downloadUrl
        }
        captain: members(filter: { role: { equals: "" } }, first: 1) {
          items {
            id
            attendee {
              id
              user {
                id
                firstName
                lastName
              }
            }
          }
          count
        }
        membersCount: members {
          count
        }
        totalRaised
        createdAt
        webLink
      }
    }
  }
`;
/**
 * __useOrganizationTeamsListQuery__
 *
 * To run a query within a React component, call `useOrganizationTeamsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationTeamsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationTeamsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useOrganizationTeamsListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(OrganizationTeamsListDocument, options);
}
export function useOrganizationTeamsListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(OrganizationTeamsListDocument, options);
}
export const UserContactInfoDocument = gql `
  query UserContactInfo($userId: ID!) {
    user(id: $userId) {
      id
      firstName
      lastName
      createdAt
      status
      avatar {
        id
        downloadUrl
      }
      campaignStaff {
        items {
          campaign {
            id
            campaignStatus
          }
        }
      }
      donors {
        count
      }
      attendees {
        items {
          id
          totalRaised
          experience {
            campaign {
              id
              campaignStatus
            }
          }
        }
      }
      roles {
        items {
          id
          name
        }
      }
    }
  }
`;
/**
 * __useUserContactInfoQuery__
 *
 * To run a query within a React component, call `useUserContactInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserContactInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserContactInfoQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserContactInfoQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(UserContactInfoDocument, options);
}
export function useUserContactInfoLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(UserContactInfoDocument, options);
}
export const UserContactProfileInfoDocument = gql `
  query UserContactProfileInfo($userId: ID!) {
    user(id: $userId) {
      id
      firstName
      lastName
      email
      phone {
        code
        number
      }
      birthDate
      address {
        city
        country
        state
        street1
        street2
        zip
      }
    }
  }
`;
/**
 * __useUserContactProfileInfoQuery__
 *
 * To run a query within a React component, call `useUserContactProfileInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserContactProfileInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserContactProfileInfoQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserContactProfileInfoQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(UserContactProfileInfoDocument, options);
}
export function useUserContactProfileInfoLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(UserContactProfileInfoDocument, options);
}
export const CrmContactUpdateDocument = gql `
  mutation CRMContactUpdate($data: CRMContactUpdateInput!) {
    cRMContactUpdate(data: $data) {
      id
    }
  }
`;
/**
 * __useCrmContactUpdateMutation__
 *
 * To run a mutation, you first call `useCrmContactUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCrmContactUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [crmContactUpdateMutation, { data, loading, error }] = useCrmContactUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCrmContactUpdateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CrmContactUpdateDocument, options);
}
export const CrmContactCreateDocument = gql `
  mutation CRMContactCreate($data: CRMContactCreateInput!) {
    cRMContactCreate(data: $data) {
      createdAt
    }
  }
`;
/**
 * __useCrmContactCreateMutation__
 *
 * To run a mutation, you first call `useCrmContactCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCrmContactCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [crmContactCreateMutation, { data, loading, error }] = useCrmContactCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCrmContactCreateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CrmContactCreateDocument, options);
}
export const CrmContactsListDocument = gql `
  query CRMContactsList($skip: Int, $first: Int, $filter: CRMContactFilter) {
    cRMContactsList(skip: $skip, first: $first, filter: $filter) {
      items {
        id
        firstName
        lastName
        middleName
        email
        phone1 {
          code
          number
        }
        phone2 {
          code
          number
        }
        note
        address {
          street1
          street2
          city
          state
          country
          zip
        }
      }
      count
    }
  }
`;
/**
 * __useCrmContactsListQuery__
 *
 * To run a query within a React component, call `useCrmContactsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCrmContactsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCrmContactsListQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCrmContactsListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(CrmContactsListDocument, options);
}
export function useCrmContactsListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(CrmContactsListDocument, options);
}
export const CrmContactCreateManyDocument = gql `
  mutation CRMContactCreateMany($data: [CRMContactCreateManyInput]!) {
    cRMContactCreateMany(data: $data) {
      items {
        id
      }
    }
  }
`;
/**
 * __useCrmContactCreateManyMutation__
 *
 * To run a mutation, you first call `useCrmContactCreateManyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCrmContactCreateManyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [crmContactCreateManyMutation, { data, loading, error }] = useCrmContactCreateManyMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCrmContactCreateManyMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CrmContactCreateManyDocument, options);
}
export const ImportUsersFromCsvDocument = gql `
  mutation ImportUsersFromCSV($data: [JSON!]!) {
    importUsersFromCSV(data: $data) {
      success
      data {
        row
        message
      }
    }
  }
`;
/**
 * __useImportUsersFromCsvMutation__
 *
 * To run a mutation, you first call `useImportUsersFromCsvMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportUsersFromCsvMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importUsersFromCsvMutation, { data, loading, error }] = useImportUsersFromCsvMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useImportUsersFromCsvMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ImportUsersFromCsvDocument, options);
}
export const RegistrationPricingTierCreateManyDocument = gql `
  mutation RegistrationPricingTierCreateMany($data: [RegistrationPricingTierCreateManyInput]!) {
    registrationPricingTierCreateMany(data: $data) {
      count
    }
  }
`;
/**
 * __useRegistrationPricingTierCreateManyMutation__
 *
 * To run a mutation, you first call `useRegistrationPricingTierCreateManyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegistrationPricingTierCreateManyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registrationPricingTierCreateManyMutation, { data, loading, error }] = useRegistrationPricingTierCreateManyMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRegistrationPricingTierCreateManyMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(RegistrationPricingTierCreateManyDocument, options);
}
export const RegistrationPricingTierDeleteByFilterDocument = gql `
  mutation RegistrationPricingTierDeleteByFilter($filter: RegistrationPricingTierFilter!) {
    registrationPricingTierDeleteByFilter(filter: $filter) {
      success
    }
  }
`;
/**
 * __useRegistrationPricingTierDeleteByFilterMutation__
 *
 * To run a mutation, you first call `useRegistrationPricingTierDeleteByFilterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegistrationPricingTierDeleteByFilterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registrationPricingTierDeleteByFilterMutation, { data, loading, error }] = useRegistrationPricingTierDeleteByFilterMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useRegistrationPricingTierDeleteByFilterMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(RegistrationPricingTierDeleteByFilterDocument, options);
}
export const TicketingPricingTierCreateManyDocument = gql `
  mutation TicketingPricingTierCreateMany($data: [TicketingPricingTierCreateManyInput]!) {
    ticketingPricingTierCreateMany(data: $data) {
      count
    }
  }
`;
/**
 * __useTicketingPricingTierCreateManyMutation__
 *
 * To run a mutation, you first call `useTicketingPricingTierCreateManyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTicketingPricingTierCreateManyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ticketingPricingTierCreateManyMutation, { data, loading, error }] = useTicketingPricingTierCreateManyMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useTicketingPricingTierCreateManyMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(TicketingPricingTierCreateManyDocument, options);
}
export const TicketingPricingTierDeleteByFilterDocument = gql `
  mutation TicketingPricingTierDeleteByFilter($filter: TicketingPricingTierFilter!) {
    ticketingPricingTierDeleteByFilter(filter: $filter) {
      success
    }
  }
`;
/**
 * __useTicketingPricingTierDeleteByFilterMutation__
 *
 * To run a mutation, you first call `useTicketingPricingTierDeleteByFilterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTicketingPricingTierDeleteByFilterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ticketingPricingTierDeleteByFilterMutation, { data, loading, error }] = useTicketingPricingTierDeleteByFilterMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTicketingPricingTierDeleteByFilterMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(TicketingPricingTierDeleteByFilterDocument, options);
}
export const ProgramsListDocument = gql `
  query ProgramsList($filter: ProgramFilter) {
    programsList(filter: $filter) {
      items {
        ...ProgramFragment
      }
    }
  }
  ${ProgramFragmentFragmentDoc}
`;
/**
 * __useProgramsListQuery__
 *
 * To run a query within a React component, call `useProgramsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useProgramsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgramsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useProgramsListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ProgramsListDocument, options);
}
export function useProgramsListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ProgramsListDocument, options);
}
export const ProgramsListQueryDocument = gql `
  query ProgramsListQuery($filter: ProgramFilter, $CampaignFilter: CampaignFilter) {
    programsList(filter: $filter) {
      items {
        ...ProgramFragment
        campaigns(filter: $CampaignFilter) {
          items {
            ...CampaignsPageCampaignFragment
          }
        }
      }
    }
  }
  ${ProgramFragmentFragmentDoc}
  ${CampaignsPageCampaignFragmentFragmentDoc}
`;
/**
 * __useProgramsListQueryQuery__
 *
 * To run a query within a React component, call `useProgramsListQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useProgramsListQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProgramsListQueryQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      CampaignFilter: // value for 'CampaignFilter'
 *   },
 * });
 */
export function useProgramsListQueryQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ProgramsListQueryDocument, options);
}
export function useProgramsListQueryLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ProgramsListQueryDocument, options);
}
export const ProgramCreateDocument = gql `
  mutation ProgramCreate($data: ProgramCreateInput!) {
    programCreate(data: $data) {
      ...ProgramFragment
    }
  }
  ${ProgramFragmentFragmentDoc}
`;
/**
 * __useProgramCreateMutation__
 *
 * To run a mutation, you first call `useProgramCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProgramCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [programCreateMutation, { data, loading, error }] = useProgramCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useProgramCreateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ProgramCreateDocument, options);
}
export const RegistrationOptionDocument = gql `
  query RegistrationOption($id: ID!) {
    registrationOption(id: $id) {
      ...RegistrationOptionFragment
    }
  }
  ${RegistrationOptionFragmentFragmentDoc}
`;
/**
 * __useRegistrationOptionQuery__
 *
 * To run a query within a React component, call `useRegistrationOptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegistrationOptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegistrationOptionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRegistrationOptionQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(RegistrationOptionDocument, options);
}
export function useRegistrationOptionLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(RegistrationOptionDocument, options);
}
export const RegistrationOptionUpdateDocument = gql `
  mutation RegistrationOptionUpdate($data: RegistrationOptionUpdateInput!) {
    registrationOptionUpdate(data: $data) {
      ...RegistrationOptionFragment
    }
  }
  ${RegistrationOptionFragmentFragmentDoc}
`;
/**
 * __useRegistrationOptionUpdateMutation__
 *
 * To run a mutation, you first call `useRegistrationOptionUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegistrationOptionUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registrationOptionUpdateMutation, { data, loading, error }] = useRegistrationOptionUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRegistrationOptionUpdateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(RegistrationOptionUpdateDocument, options);
}
export const RegistrationOptionsListDocument = gql `
  query RegistrationOptionsList($filter: RegistrationOptionFilter) {
    registrationOptionsList(filter: $filter) {
      count
      items {
        ...RegistrationOptionFragment
      }
    }
  }
  ${RegistrationOptionFragmentFragmentDoc}
`;
/**
 * __useRegistrationOptionsListQuery__
 *
 * To run a query within a React component, call `useRegistrationOptionsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegistrationOptionsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegistrationOptionsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useRegistrationOptionsListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(RegistrationOptionsListDocument, options);
}
export function useRegistrationOptionsListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(RegistrationOptionsListDocument, options);
}
export const RegistrationOptionDeleteDocument = gql `
  mutation RegistrationOptionDelete($id: ID!) {
    registrationOptionDelete(filter: { id: $id }, force: true) {
      success
    }
  }
`;
/**
 * __useRegistrationOptionDeleteMutation__
 *
 * To run a mutation, you first call `useRegistrationOptionDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegistrationOptionDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registrationOptionDeleteMutation, { data, loading, error }] = useRegistrationOptionDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRegistrationOptionDeleteMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(RegistrationOptionDeleteDocument, options);
}
export const RegistrationOptionCreateDocument = gql `
  mutation RegistrationOptionCreate($data: RegistrationOptionCreateInput!) {
    registrationOptionCreate(data: $data) {
      ...RegistrationOptionFragment
    }
  }
  ${RegistrationOptionFragmentFragmentDoc}
`;
/**
 * __useRegistrationOptionCreateMutation__
 *
 * To run a mutation, you first call `useRegistrationOptionCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegistrationOptionCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registrationOptionCreateMutation, { data, loading, error }] = useRegistrationOptionCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRegistrationOptionCreateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(RegistrationOptionCreateDocument, options);
}
export const RegistrationOptionSwagbagCreateDocument = gql `
  mutation registrationOptionSwagbagCreate($data: RegistrationOptionSwagbagCreateInput!) {
    registrationOptionSwagbagCreate(data: $data) {
      id
    }
  }
`;
/**
 * __useRegistrationOptionSwagbagCreateMutation__
 *
 * To run a mutation, you first call `useRegistrationOptionSwagbagCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegistrationOptionSwagbagCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registrationOptionSwagbagCreateMutation, { data, loading, error }] = useRegistrationOptionSwagbagCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRegistrationOptionSwagbagCreateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(RegistrationOptionSwagbagCreateDocument, options);
}
export const RegistrationOptionSwagbagListDocument = gql `
  query registrationOptionSwagbagList($filter: RegistrationOptionSwagbagFilter!) {
    registrationOptionSwagbagsList(filter: $filter) {
      items {
        id
        storeAsset {
          ...StoreAssetFragment
        }
      }
    }
  }
  ${StoreAssetFragmentFragmentDoc}
`;
/**
 * __useRegistrationOptionSwagbagListQuery__
 *
 * To run a query within a React component, call `useRegistrationOptionSwagbagListQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegistrationOptionSwagbagListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegistrationOptionSwagbagListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useRegistrationOptionSwagbagListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(RegistrationOptionSwagbagListDocument, options);
}
export function useRegistrationOptionSwagbagListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(RegistrationOptionSwagbagListDocument, options);
}
export const RegistrationOptionSwagbagDeleteDocument = gql `
  mutation registrationOptionSwagbagDelete($filter: RegistrationOptionSwagbagKeyFilter!) {
    registrationOptionSwagbagDelete(filter: $filter) {
      success
    }
  }
`;
/**
 * __useRegistrationOptionSwagbagDeleteMutation__
 *
 * To run a mutation, you first call `useRegistrationOptionSwagbagDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegistrationOptionSwagbagDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registrationOptionSwagbagDeleteMutation, { data, loading, error }] = useRegistrationOptionSwagbagDeleteMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useRegistrationOptionSwagbagDeleteMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(RegistrationOptionSwagbagDeleteDocument, options);
}
export const RegistrationOptionSwagbagListInventoryDocument = gql `
  query registrationOptionSwagbagListInventory($filter: GetAllAssetInventoryFilter!) {
    getAllAssetInventoriesList(filter: $filter) {
      items {
        id
        aviable
        swagbagAvailability
      }
    }
  }
`;
/**
 * __useRegistrationOptionSwagbagListInventoryQuery__
 *
 * To run a query within a React component, call `useRegistrationOptionSwagbagListInventoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegistrationOptionSwagbagListInventoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegistrationOptionSwagbagListInventoryQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useRegistrationOptionSwagbagListInventoryQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(RegistrationOptionSwagbagListInventoryDocument, options);
}
export function useRegistrationOptionSwagbagListInventoryLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(RegistrationOptionSwagbagListInventoryDocument, options);
}
export const StoreAssetDocument = gql `
  query StoreAsset($id: ID!) {
    storeAsset(id: $id) {
      ...StoreAssetFragment
    }
  }
  ${StoreAssetFragmentFragmentDoc}
`;
/**
 * __useStoreAssetQuery__
 *
 * To run a query within a React component, call `useStoreAssetQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoreAssetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoreAssetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStoreAssetQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(StoreAssetDocument, options);
}
export function useStoreAssetLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(StoreAssetDocument, options);
}
export const StoreAssetsDocument = gql `
  query StoreAssets($filter: StoreAssetFilter, $skip: Int, $first: Int) {
    storeAssetsList(filter: $filter, sort: { createdAt: DESC }, skip: $skip, first: $first) {
      count
      items {
        ...StoreAssetSimpleFragment
      }
    }
  }
  ${StoreAssetSimpleFragmentFragmentDoc}
`;
/**
 * __useStoreAssetsQuery__
 *
 * To run a query within a React component, call `useStoreAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoreAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoreAssetsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useStoreAssetsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(StoreAssetsDocument, options);
}
export function useStoreAssetsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(StoreAssetsDocument, options);
}
export const StoreAssetCreateDocument = gql `
  mutation StoreAssetCreate($data: StoreAssetCreateInput!) {
    storeAssetCreate(data: $data) {
      ...StoreAssetSimpleFragment
    }
  }
  ${StoreAssetSimpleFragmentFragmentDoc}
`;
/**
 * __useStoreAssetCreateMutation__
 *
 * To run a mutation, you first call `useStoreAssetCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStoreAssetCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [storeAssetCreateMutation, { data, loading, error }] = useStoreAssetCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useStoreAssetCreateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(StoreAssetCreateDocument, options);
}
export const StoreAssetUpdateDocument = gql `
  mutation StoreAssetUpdate($id: ID!, $data: StoreAssetUpdateInput!) {
    storeAssetUpdate(data: $data, filter: { id: $id }) {
      ...StoreAssetFragment
    }
  }
  ${StoreAssetFragmentFragmentDoc}
`;
/**
 * __useStoreAssetUpdateMutation__
 *
 * To run a mutation, you first call `useStoreAssetUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStoreAssetUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [storeAssetUpdateMutation, { data, loading, error }] = useStoreAssetUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useStoreAssetUpdateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(StoreAssetUpdateDocument, options);
}
export const StoreHistoryEventDocument = gql `
  query StoreHistoryEvent($id: ID!) {
    storeHistoryEvent(id: $id) {
      ...StoreHistoryEventFragment
    }
  }
  ${StoreHistoryEventFragmentFragmentDoc}
`;
/**
 * __useStoreHistoryEventQuery__
 *
 * To run a query within a React component, call `useStoreHistoryEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoreHistoryEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoreHistoryEventQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStoreHistoryEventQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(StoreHistoryEventDocument, options);
}
export function useStoreHistoryEventLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(StoreHistoryEventDocument, options);
}
export const StoreHistoryEventsListDocument = gql `
  query StoreHistoryEventsList($filter: StoreHistoryEventFilter, $skip: Int, $first: Int) {
    storeHistoryEventsList(filter: $filter, sort: { createdAt: DESC }, skip: $skip, first: $first) {
      count
      items {
        ...StoreHistoryEventFragment
      }
    }
  }
  ${StoreHistoryEventFragmentFragmentDoc}
`;
/**
 * __useStoreHistoryEventsListQuery__
 *
 * To run a query within a React component, call `useStoreHistoryEventsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoreHistoryEventsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoreHistoryEventsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useStoreHistoryEventsListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(StoreHistoryEventsListDocument, options);
}
export function useStoreHistoryEventsListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(StoreHistoryEventsListDocument, options);
}
export const StoreHistoryEventCreateWithRecalcDocument = gql `
  mutation StoreHistoryEventCreateWithRecalc($data: StoreHistoryEventCreateWithRecalcInput!) {
    storeHistoryEventCreateWithRecalc(input: $data) {
      storeHistoryEvent {
        id
      }
    }
  }
`;
/**
 * __useStoreHistoryEventCreateWithRecalcMutation__
 *
 * To run a mutation, you first call `useStoreHistoryEventCreateWithRecalcMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStoreHistoryEventCreateWithRecalcMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [storeHistoryEventCreateWithRecalcMutation, { data, loading, error }] = useStoreHistoryEventCreateWithRecalcMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useStoreHistoryEventCreateWithRecalcMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(StoreHistoryEventCreateWithRecalcDocument, options);
}
export const ExperienceStoreAssetsListDocument = gql `
  query ExperienceStoreAssetsList($filter: ExperienceStoreAssetFilter, $skip: Int, $first: Int) {
    experienceStoreAssetsList(
      filter: $filter
      sort: { createdAt: DESC }
      skip: $skip
      first: $first
    ) {
      count
      items {
        ...ExperienceStoreAssetFragment
      }
    }
  }
  ${ExperienceStoreAssetFragmentFragmentDoc}
`;
/**
 * __useExperienceStoreAssetsListQuery__
 *
 * To run a query within a React component, call `useExperienceStoreAssetsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useExperienceStoreAssetsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExperienceStoreAssetsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useExperienceStoreAssetsListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ExperienceStoreAssetsListDocument, options);
}
export function useExperienceStoreAssetsListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ExperienceStoreAssetsListDocument, options);
}
export const ExperienceStoreSettingsDocument = gql `
  query ExperienceStoreSettings($id: ID!) {
    experience(id: $id) {
      ...ExperienceStoreSettingsFragment
    }
  }
  ${ExperienceStoreSettingsFragmentFragmentDoc}
`;
/**
 * __useExperienceStoreSettingsQuery__
 *
 * To run a query within a React component, call `useExperienceStoreSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExperienceStoreSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExperienceStoreSettingsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExperienceStoreSettingsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ExperienceStoreSettingsDocument, options);
}
export function useExperienceStoreSettingsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ExperienceStoreSettingsDocument, options);
}
export const ExperienceStoreAssetCreateDocument = gql `
  mutation ExperienceStoreAssetCreate($data: ExperienceStoreAssetCreateInput!) {
    experienceStoreAssetCreate(data: $data) {
      ...ExperienceStoreAssetFragment
    }
  }
  ${ExperienceStoreAssetFragmentFragmentDoc}
`;
/**
 * __useExperienceStoreAssetCreateMutation__
 *
 * To run a mutation, you first call `useExperienceStoreAssetCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExperienceStoreAssetCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [experienceStoreAssetCreateMutation, { data, loading, error }] = useExperienceStoreAssetCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useExperienceStoreAssetCreateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ExperienceStoreAssetCreateDocument, options);
}
export const ExperienceStoreAssetUpdateDocument = gql `
  mutation ExperienceStoreAssetUpdate($id: ID!, $data: ExperienceStoreAssetUpdateInput!) {
    experienceStoreAssetUpdate(filter: { id: $id }, data: $data) {
      ...ExperienceStoreAssetFragment
    }
  }
  ${ExperienceStoreAssetFragmentFragmentDoc}
`;
/**
 * __useExperienceStoreAssetUpdateMutation__
 *
 * To run a mutation, you first call `useExperienceStoreAssetUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExperienceStoreAssetUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [experienceStoreAssetUpdateMutation, { data, loading, error }] = useExperienceStoreAssetUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useExperienceStoreAssetUpdateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ExperienceStoreAssetUpdateDocument, options);
}
export const ExperienceStoreAssetDeleteDocument = gql `
  mutation ExperienceStoreAssetDelete($filter: ExperienceStoreAssetKeyFilter) {
    experienceStoreAssetDelete(filter: $filter) {
      success
    }
  }
`;
/**
 * __useExperienceStoreAssetDeleteMutation__
 *
 * To run a mutation, you first call `useExperienceStoreAssetDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExperienceStoreAssetDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [experienceStoreAssetDeleteMutation, { data, loading, error }] = useExperienceStoreAssetDeleteMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useExperienceStoreAssetDeleteMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ExperienceStoreAssetDeleteDocument, options);
}
export const ExperienceStoreSettingsUpdateDocument = gql `
  mutation ExperienceStoreSettingsUpdate($id: ID!, $data: ExperienceUpdateInput!) {
    experienceUpdate(filter: { id: $id }, data: $data) {
      ...ExperienceStoreSettingsFragment
    }
  }
  ${ExperienceStoreSettingsFragmentFragmentDoc}
`;
/**
 * __useExperienceStoreSettingsUpdateMutation__
 *
 * To run a mutation, you first call `useExperienceStoreSettingsUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExperienceStoreSettingsUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [experienceStoreSettingsUpdateMutation, { data, loading, error }] = useExperienceStoreSettingsUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useExperienceStoreSettingsUpdateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ExperienceStoreSettingsUpdateDocument, options);
}
export const GetAllAssetsByOrganizationListDocument = gql `
  query GetAllAssetsByOrganizationList(
    $filter: GetAllAssetInventoryFilter
    $skip: Int
    $first: Int
  ) {
    getAllAssetInventoriesList(filter: $filter, skip: $skip, first: $first) {
      count
      items {
        id
        organizationId
        aviable
        storeAvailability
        swagbagAvailability
        name
        picture
        unitPrice
        total
        profit
        sales
        price
      }
    }
  }
`;
/**
 * __useGetAllAssetsByOrganizationListQuery__
 *
 * To run a query within a React component, call `useGetAllAssetsByOrganizationListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAssetsByOrganizationListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllAssetsByOrganizationListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useGetAllAssetsByOrganizationListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetAllAssetsByOrganizationListDocument, options);
}
export function useGetAllAssetsByOrganizationListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetAllAssetsByOrganizationListDocument, options);
}
export const GetAllFileListDocument = gql `
  query GetAllFileList($filter: FileFilter) {
    filesList(filter: $filter) {
      items {
        id
        downloadUrl
      }
    }
  }
`;
/**
 * __useGetAllFileListQuery__
 *
 * To run a query within a React component, call `useGetAllFileListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllFileListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllFileListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetAllFileListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetAllFileListDocument, options);
}
export function useGetAllFileListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetAllFileListDocument, options);
}
export const GetAllAssetInventoryDocument = gql `
  query getAllAssetInventory($id: ID!) {
    getAllAssetInventory(id: $id) {
      name
      variantsPossible
      unitPrice
      total
      swagbagAvailability
      storeAvailability
      sales
      profit
      picture
      organizationId
      id
      aviable
      description
      continueSalesWhenOutOfStock
      note
      notifyWhenStockMinimumIsAchieved
      price
      stockMinimumBoundary
    }
  }
`;
/**
 * __useGetAllAssetInventoryQuery__
 *
 * To run a query within a React component, call `useGetAllAssetInventoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAssetInventoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllAssetInventoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAllAssetInventoryQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetAllAssetInventoryDocument, options);
}
export function useGetAllAssetInventoryLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetAllAssetInventoryDocument, options);
}
export const GetAssetPictureDocument = gql `
  query getAssetPicture($id: ID!) {
    file(id: $id) {
      downloadUrl
      fileId
      filename
      id
    }
  }
`;
/**
 * __useGetAssetPictureQuery__
 *
 * To run a query within a React component, call `useGetAssetPictureQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetPictureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetPictureQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAssetPictureQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetAssetPictureDocument, options);
}
export function useGetAssetPictureLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetAssetPictureDocument, options);
}
export const GetAssetByExperiencieViewsListDocument = gql `
  query getAssetByExperiencieViewsList($filter: GetAssetByExperiencieViewFilter!) {
    getAssetByExperiencieViewsList(filter: $filter) {
      items {
        available
        continueSalesWhenOutOfStock
        id
        assetId
        name
        picture
        price
        storeAvailability
        total
        assetPrice
        experienceStoreAssetId
      }
    }
  }
`;
/**
 * __useGetAssetByExperiencieViewsListQuery__
 *
 * To run a query within a React component, call `useGetAssetByExperiencieViewsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetByExperiencieViewsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetByExperiencieViewsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetAssetByExperiencieViewsListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetAssetByExperiencieViewsListDocument, options);
}
export function useGetAssetByExperiencieViewsListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetAssetByExperiencieViewsListDocument, options);
}
export const StoreGhostItemCreateDocument = gql `
  mutation StoreGhostItemCreate($data: StoreInventoryItemCreateInput!) {
    storeInventoryItemCreate(data: $data) {
      id
    }
  }
`;
/**
 * __useStoreGhostItemCreateMutation__
 *
 * To run a mutation, you first call `useStoreGhostItemCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStoreGhostItemCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [storeGhostItemCreateMutation, { data, loading, error }] = useStoreGhostItemCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useStoreGhostItemCreateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(StoreGhostItemCreateDocument, options);
}
export const StoreGhostItemUpdateByFilterDocument = gql `
  mutation storeGhostItemUpdateByFilter(
    $data: StoreInventoryItemUpdateByFilterInput!
    $filter: StoreInventoryItemFilter
  ) {
    storeInventoryItemUpdateByFilter(data: $data, filter: $filter) {
      items {
        id
      }
    }
  }
`;
/**
 * __useStoreGhostItemUpdateByFilterMutation__
 *
 * To run a mutation, you first call `useStoreGhostItemUpdateByFilterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStoreGhostItemUpdateByFilterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [storeGhostItemUpdateByFilterMutation, { data, loading, error }] = useStoreGhostItemUpdateByFilterMutation({
 *   variables: {
 *      data: // value for 'data'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useStoreGhostItemUpdateByFilterMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(StoreGhostItemUpdateByFilterDocument, options);
}
export const StripePlansDocument = gql `
  query StripePlans {
    stripePlans {
      id
      object
      name
      active
      description
      prices {
        id
        active
        nickname
        unit_amount
        recurring
      }
    }
  }
`;
/**
 * __useStripePlansQuery__
 *
 * To run a query within a React component, call `useStripePlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useStripePlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStripePlansQuery({
 *   variables: {
 *   },
 * });
 */
export function useStripePlansQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(StripePlansDocument, options);
}
export function useStripePlansLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(StripePlansDocument, options);
}
export const CheckoutSubscriptionDocument = gql `
  mutation CheckoutSubscription($data: CheckoutSubscriptionInput!) {
    checkoutSubscription(data: $data) {
      id
      metadata
      url
    }
  }
`;
/**
 * __useCheckoutSubscriptionMutation__
 *
 * To run a mutation, you first call `useCheckoutSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckoutSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkoutSubscriptionMutation, { data, loading, error }] = useCheckoutSubscriptionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCheckoutSubscriptionMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CheckoutSubscriptionDocument, options);
}
export const UsersStripePlansDocument = gql `
  query UsersStripePlans($filter: VizslaStripePlanFilter) {
    vizslaStripePlansList(filter: $filter) {
      items {
        id
        priceId
        userId
        productId
        customerId
        subscriptionId
        status
      }
    }
  }
`;
/**
 * __useUsersStripePlansQuery__
 *
 * To run a query within a React component, call `useUsersStripePlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersStripePlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersStripePlansQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useUsersStripePlansQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(UsersStripePlansDocument, options);
}
export function useUsersStripePlansLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(UsersStripePlansDocument, options);
}
export const StripeInvoicesDocument = gql `
  query StripeInvoices($customerId: String!) {
    stripeInvoices(customerId: $customerId) {
      id
      customer
      created
      description
      status
      amount_due
      amount_paid
      payment_settings
      hosted_invoice_url
    }
  }
`;
/**
 * __useStripeInvoicesQuery__
 *
 * To run a query within a React component, call `useStripeInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStripeInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStripeInvoicesQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useStripeInvoicesQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(StripeInvoicesDocument, options);
}
export function useStripeInvoicesLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(StripeInvoicesDocument, options);
}
export const StripePaymentMethodsDocument = gql `
  query StripePaymentMethods($userId: String!) {
    stripePaymentMethods(userId: $userId) {
      items {
        __typename
        ... on CardPaymentMethod {
          id
          type
          primary
          reference
          brand
        }
        ... on BankPaymentMethod {
          id
          type
          primary
          reference
          name
        }
        ... on UnknownPaymentMethod {
          id
          type
          primary
        }
      }
    }
  }
`;
/**
 * __useStripePaymentMethodsQuery__
 *
 * To run a query within a React component, call `useStripePaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStripePaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStripePaymentMethodsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useStripePaymentMethodsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(StripePaymentMethodsDocument, options);
}
export function useStripePaymentMethodsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(StripePaymentMethodsDocument, options);
}
export const StripePaymentMethodDefaultDocument = gql `
  mutation StripePaymentMethodDefault($userId: String!, $paymentMethodId: String!) {
    stripePaymentMethodDefault(userId: $userId, paymentMethodId: $paymentMethodId) {
      __typename
      ... on CardPaymentMethod {
        id
        type
        primary
        reference
        brand
      }
      ... on BankPaymentMethod {
        id
        type
        primary
        reference
        name
      }
      ... on UnknownPaymentMethod {
        id
        type
        primary
      }
    }
  }
`;
/**
 * __useStripePaymentMethodDefaultMutation__
 *
 * To run a mutation, you first call `useStripePaymentMethodDefaultMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStripePaymentMethodDefaultMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stripePaymentMethodDefaultMutation, { data, loading, error }] = useStripePaymentMethodDefaultMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      paymentMethodId: // value for 'paymentMethodId'
 *   },
 * });
 */
export function useStripePaymentMethodDefaultMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(StripePaymentMethodDefaultDocument, options);
}
export const StripePaymentMethodInactiveDocument = gql `
  mutation StripePaymentMethodInactive($userId: String!, $paymentMethodId: String!) {
    stripePaymentMethodInactive(userId: $userId, paymentMethodId: $paymentMethodId) {
      success
    }
  }
`;
/**
 * __useStripePaymentMethodInactiveMutation__
 *
 * To run a mutation, you first call `useStripePaymentMethodInactiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStripePaymentMethodInactiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stripePaymentMethodInactiveMutation, { data, loading, error }] = useStripePaymentMethodInactiveMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      paymentMethodId: // value for 'paymentMethodId'
 *   },
 * });
 */
export function useStripePaymentMethodInactiveMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(StripePaymentMethodInactiveDocument, options);
}
export const TeamRTsListDocument = gql `
  query TeamRTsList($filter: TeamFundraisingFilter, $skip: Int, $first: Int) {
    teamFundraisingsList(filter: $filter, skip: $skip, first: $first) {
      count
      items {
        ...TeamRTFragment
      }
    }
  }
  ${TeamRtFragmentFragmentDoc}
`;
/**
 * __useTeamRTsListQuery__
 *
 * To run a query within a React component, call `useTeamRTsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamRTsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamRTsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useTeamRTsListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(TeamRTsListDocument, options);
}
export function useTeamRTsListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(TeamRTsListDocument, options);
}
export const TeamRtCreateDocument = gql `
  mutation TeamRTCreate($createData: TeamFundraisingCreateInput!) {
    teamFundraisingCreate(data: $createData) {
      id
      name
    }
  }
`;
/**
 * __useTeamRtCreateMutation__
 *
 * To run a mutation, you first call `useTeamRtCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTeamRtCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [teamRtCreateMutation, { data, loading, error }] = useTeamRtCreateMutation({
 *   variables: {
 *      createData: // value for 'createData'
 *   },
 * });
 */
export function useTeamRtCreateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(TeamRtCreateDocument, options);
}
export const TeamWebLinkCounterByNameDocument = gql `
  query TeamWebLinkCounterByName($campaignId: ID!, $webLink: String!) {
    teamFundraisingsList(
      filter: { campaign: { id: { equals: $campaignId } }, webLink: { equals: $webLink } }
    ) {
      count
    }
  }
`;
/**
 * __useTeamWebLinkCounterByNameQuery__
 *
 * To run a query within a React component, call `useTeamWebLinkCounterByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamWebLinkCounterByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamWebLinkCounterByNameQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *      webLink: // value for 'webLink'
 *   },
 * });
 */
export function useTeamWebLinkCounterByNameQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(TeamWebLinkCounterByNameDocument, options);
}
export function useTeamWebLinkCounterByNameLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(TeamWebLinkCounterByNameDocument, options);
}
export const TeamRTsNamesListDocument = gql `
  query TeamRTsNamesList($filter: TeamFundraisingFilter) {
    teamFundraisingsList(filter: $filter, first: 10) {
      items {
        id
        name
        campaign {
          id
        }
      }
    }
  }
`;
/**
 * __useTeamRTsNamesListQuery__
 *
 * To run a query within a React component, call `useTeamRTsNamesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamRTsNamesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamRTsNamesListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTeamRTsNamesListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(TeamRTsNamesListDocument, options);
}
export function useTeamRTsNamesListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(TeamRTsNamesListDocument, options);
}
export const TicketingOptionDocument = gql `
  query TicketingOption($id: ID!) {
    ticketingOption(id: $id) {
      ...TicketingOptionFragment
    }
  }
  ${TicketingOptionFragmentFragmentDoc}
`;
/**
 * __useTicketingOptionQuery__
 *
 * To run a query within a React component, call `useTicketingOptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useTicketingOptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTicketingOptionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTicketingOptionQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(TicketingOptionDocument, options);
}
export function useTicketingOptionLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(TicketingOptionDocument, options);
}
export const TicketingOptionsListDocument = gql `
  query TicketingOptionsList($filter: TicketingOptionFilter) {
    ticketingOptionsList(filter: $filter) {
      items {
        ...TicketingOptionFragment
      }
    }
  }
  ${TicketingOptionFragmentFragmentDoc}
`;
/**
 * __useTicketingOptionsListQuery__
 *
 * To run a query within a React component, call `useTicketingOptionsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTicketingOptionsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTicketingOptionsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTicketingOptionsListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(TicketingOptionsListDocument, options);
}
export function useTicketingOptionsListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(TicketingOptionsListDocument, options);
}
export const TicketingOptionDeleteDocument = gql `
  mutation TicketingOptionDelete($id: ID!) {
    ticketingOptionDelete(filter: { id: $id }, force: true) {
      success
    }
  }
`;
/**
 * __useTicketingOptionDeleteMutation__
 *
 * To run a mutation, you first call `useTicketingOptionDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTicketingOptionDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ticketingOptionDeleteMutation, { data, loading, error }] = useTicketingOptionDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTicketingOptionDeleteMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(TicketingOptionDeleteDocument, options);
}
export const TicketingOptionUpdateDocument = gql `
  mutation TicketingOptionUpdate(
    $data: TicketingOptionUpdateInput!
    $filter: TicketingOptionKeyFilter!
  ) {
    ticketingOptionUpdate(data: $data, filter: $filter) {
      ...TicketingOptionFragment
    }
  }
  ${TicketingOptionFragmentFragmentDoc}
`;
/**
 * __useTicketingOptionUpdateMutation__
 *
 * To run a mutation, you first call `useTicketingOptionUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTicketingOptionUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ticketingOptionUpdateMutation, { data, loading, error }] = useTicketingOptionUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTicketingOptionUpdateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(TicketingOptionUpdateDocument, options);
}
export const TicketingOptionCreateDocument = gql `
  mutation TicketingOptionCreate($data: TicketingOptionCreateInput!) {
    ticketingOptionCreate(data: $data) {
      ...TicketingOptionFragment
    }
  }
  ${TicketingOptionFragmentFragmentDoc}
`;
/**
 * __useTicketingOptionCreateMutation__
 *
 * To run a mutation, you first call `useTicketingOptionCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTicketingOptionCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ticketingOptionCreateMutation, { data, loading, error }] = useTicketingOptionCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useTicketingOptionCreateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(TicketingOptionCreateDocument, options);
}
export const GetOrdersDocument = gql `
  query GetOrders($filter: TransactionVizslaViewExpFilter, $skip: Int, $first: Int) {
    transactionVizslaViewExpsList(
      filter: $filter
      sort: { OrderDate: DESC }
      skip: $skip
      first: $first
    ) {
      count
      items {
        id
        OrderDate
        OrderStatus
        Ordersubtype
        OrderItemTransType
        OrderitemCommentDetail
        OrderItemsId
        UsersFirsName
        UsersLastName
        CampaignName
        ExperienceName
        ExperienceId
        OrderTotalAmount
        OrderItemsAmount
        OrderPaymentMethod
        OrderitemCommentDetail
        Userid
      }
    }
  }
`;
/**
 * __useGetOrdersQuery__
 *
 * To run a query within a React component, call `useGetOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrdersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useGetOrdersQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetOrdersDocument, options);
}
export function useGetOrdersLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetOrdersDocument, options);
}
export const User_Status_UpdateDocument = gql `
  mutation User_status_update($data: UserUpdateInput!) {
    userUpdate(data: $data) {
      status
    }
  }
`;
/**
 * __useUser_Status_UpdateMutation__
 *
 * To run a mutation, you first call `useUser_Status_UpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUser_Status_UpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userStatusUpdateMutation, { data, loading, error }] = useUser_Status_UpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUser_Status_UpdateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(User_Status_UpdateDocument, options);
}
export const InboxesListDocument = gql `
  query InboxesList($filter: InboxFilter!) {
    inboxesList(filter: $filter) {
      items {
        ...InboxFragmentWithLastMessage
      }
    }
  }
  ${InboxFragmentWithLastMessageFragmentDoc}
`;
/**
 * __useInboxesListQuery__
 *
 * To run a query within a React component, call `useInboxesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useInboxesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInboxesListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useInboxesListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(InboxesListDocument, options);
}
export function useInboxesListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(InboxesListDocument, options);
}
export const SendMessageDocument = gql `
  mutation SendMessage($data: InboxMessageCreateInput!) {
    inboxMessage: inboxMessageCreate(data: $data) {
      ...InboxMessageFragment
      inbox {
        id
      }
    }
  }
  ${InboxMessageFragmentFragmentDoc}
`;
/**
 * __useSendMessageMutation__
 *
 * To run a mutation, you first call `useSendMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMessageMutation, { data, loading, error }] = useSendMessageMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSendMessageMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(SendMessageDocument, options);
}
export const InboxMessagesDocument = gql `
  query InboxMessages($inboxId: ID!) {
    inboxMessages: inboxMessagesList(filter: { inbox: { id: { equals: $inboxId } } }) {
      items {
        ...InboxMessageFragment
      }
    }
  }
  ${InboxMessageFragmentFragmentDoc}
`;
/**
 * __useInboxMessagesQuery__
 *
 * To run a query within a React component, call `useInboxMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useInboxMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInboxMessagesQuery({
 *   variables: {
 *      inboxId: // value for 'inboxId'
 *   },
 * });
 */
export function useInboxMessagesQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(InboxMessagesDocument, options);
}
export function useInboxMessagesLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(InboxMessagesDocument, options);
}
export const DeleteInboxByUserDocument = gql `
  mutation DeleteInboxByUser($inboxId: ID!, $userId: ID!) {
    inboxUpdate(data: { id: $inboxId, deletedBy: { connect: { id: $userId } } }) {
      id
    }
  }
`;
/**
 * __useDeleteInboxByUserMutation__
 *
 * To run a mutation, you first call `useDeleteInboxByUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInboxByUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInboxByUserMutation, { data, loading, error }] = useDeleteInboxByUserMutation({
 *   variables: {
 *      inboxId: // value for 'inboxId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDeleteInboxByUserMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(DeleteInboxByUserDocument, options);
}
export const MarkInboxMessagesAsReadDocument = gql `
  mutation MarkInboxMessagesAsRead($messageId: ID!, $userId: ID!) {
    inboxMessageUpdate(data: { id: $messageId, readBy: { connect: { id: $userId } } }) {
      id
    }
  }
`;
/**
 * __useMarkInboxMessagesAsReadMutation__
 *
 * To run a mutation, you first call `useMarkInboxMessagesAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkInboxMessagesAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markInboxMessagesAsReadMutation, { data, loading, error }] = useMarkInboxMessagesAsReadMutation({
 *   variables: {
 *      messageId: // value for 'messageId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useMarkInboxMessagesAsReadMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(MarkInboxMessagesAsReadDocument, options);
}
export const NewInboxMessageDocument = gql `
  subscription NewInboxMessage {
    InboxMessage(
      filter: {
        mutation_in: [create]
        node: { createdBy: { is_self: false }, inbox: { recipients: { some: { is_self: true } } } }
      }
    ) {
      node {
        ...InboxMessageFragment
        inbox {
          ...InboxFragment
        }
      }
    }
  }
  ${InboxMessageFragmentFragmentDoc}
  ${InboxFragmentFragmentDoc}
`;
/**
 * __useNewInboxMessageSubscription__
 *
 * To run a query within a React component, call `useNewInboxMessageSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNewInboxMessageSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewInboxMessageSubscription({
 *   variables: {
 *   },
 * });
 */
export function useNewInboxMessageSubscription(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSubscription(NewInboxMessageDocument, options);
}
export const InboxCreateDocument = gql `
  mutation InboxCreate($data: InboxCreateInput!) {
    inboxCreate(data: $data) {
      ...InboxFragment
    }
  }
  ${InboxFragmentFragmentDoc}
`;
/**
 * __useInboxCreateMutation__
 *
 * To run a mutation, you first call `useInboxCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInboxCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inboxCreateMutation, { data, loading, error }] = useInboxCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useInboxCreateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(InboxCreateDocument, options);
}
export const AttendeeManagementDocument = gql `
  query AttendeeManagement($id: ID!) {
    attendee(id: $id) {
      ...ATTENDEE_FRAGMENT
      fundraising {
        ...INDIVIDUAL_FUNDRAISING_MANAGEMENT_FRAGMENT
      }
    }
  }
  ${Attendee_FragmentFragmentDoc}
  ${Individual_Fundraising_Management_FragmentFragmentDoc}
`;
/**
 * __useAttendeeManagementQuery__
 *
 * To run a query within a React component, call `useAttendeeManagementQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttendeeManagementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttendeeManagementQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAttendeeManagementQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(AttendeeManagementDocument, options);
}
export function useAttendeeManagementLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(AttendeeManagementDocument, options);
}
export const ClientCampaignDocument = gql `
  query ClientCampaign($id: ID!) {
    campaign(id: $id) {
      ...ClientCampaignFragment
    }
  }
  ${ClientCampaignFragmentFragmentDoc}
`;
/**
 * __useClientCampaignQuery__
 *
 * To run a query within a React component, call `useClientCampaignQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientCampaignQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientCampaignQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useClientCampaignQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ClientCampaignDocument, options);
}
export function useClientCampaignLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ClientCampaignDocument, options);
}
export const PublicTemplatesByCampaignIdDocument = gql `
  query PublicTemplatesByCampaignId($campaignId: ID!) {
    campaign(id: $campaignId) {
      campaignTemplates {
        id
        templates {
          items {
            id
            templateType {
              type
              title
            }
          }
        }
      }
    }
  }
`;
/**
 * __usePublicTemplatesByCampaignIdQuery__
 *
 * To run a query within a React component, call `usePublicTemplatesByCampaignIdQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicTemplatesByCampaignIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicTemplatesByCampaignIdQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function usePublicTemplatesByCampaignIdQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(PublicTemplatesByCampaignIdDocument, options);
}
export function usePublicTemplatesByCampaignIdLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(PublicTemplatesByCampaignIdDocument, options);
}
export const ClientSingleCampaignStatisticsDocument = gql `
  query ClientSingleCampaignStatistics($campaignId: ID!) {
    attendeesList(filter: { experience: { campaign: { id: { equals: $campaignId } } } }) {
      count
      items {
        id
        createdAt
      }
    }
    fundraisersList: attendeesList(
      filter: {
        experience: { campaign: { id: { equals: $campaignId } } }
        fundraisingPageLink: { is_not_empty: true }
      }
    ) {
      count
      items {
        id
        createdAt
      }
    }
  }
`;
/**
 * __useClientSingleCampaignStatisticsQuery__
 *
 * To run a query within a React component, call `useClientSingleCampaignStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientSingleCampaignStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientSingleCampaignStatisticsQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useClientSingleCampaignStatisticsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ClientSingleCampaignStatisticsDocument, options);
}
export function useClientSingleCampaignStatisticsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ClientSingleCampaignStatisticsDocument, options);
}
export const DnsRecordQueryBySlugDocument = gql `
  query DnsRecordQueryBySlug($slug: String!) {
    dnsRecord(slug: $slug) {
      ...DnsRecordFragment
    }
  }
  ${DnsRecordFragmentFragmentDoc}
`;
/**
 * __useDnsRecordQueryBySlugQuery__
 *
 * To run a query within a React component, call `useDnsRecordQueryBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useDnsRecordQueryBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDnsRecordQueryBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useDnsRecordQueryBySlugQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(DnsRecordQueryBySlugDocument, options);
}
export function useDnsRecordQueryBySlugLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(DnsRecordQueryBySlugDocument, options);
}
export const DnsRecordCreateDocument = gql `
  mutation DnsRecordCreate($slug: String!, $campaignId: ID!) {
    dnsRecordCreate(data: { slug: $slug, campaign: { connect: { id: $campaignId } } }) {
      id
      slug
      campaign {
        id
        name
      }
    }
  }
`;
/**
 * __useDnsRecordCreateMutation__
 *
 * To run a mutation, you first call `useDnsRecordCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDnsRecordCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dnsRecordCreateMutation, { data, loading, error }] = useDnsRecordCreateMutation({
 *   variables: {
 *      slug: // value for 'slug'
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useDnsRecordCreateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(DnsRecordCreateDocument, options);
}
export const PublicCampaignInfoBySlugDocument = gql `
  query PublicCampaignInfoBySlug($slug: String) {
    dnsRecord(slug: $slug) {
      id
      campaign {
        id
        name
        fundraisingGoal
        totalRaised
        campaignTemplates {
          id
          templates {
            items {
              id
              templateType {
                type
                title
              }
              structure
            }
          }
        }
        vizslaOrganization {
          id
          name
        }
        logo {
          id
          fileId
          filename
          downloadUrl
        }
        experiences {
          items {
            id
            name
            experienceType
            experienceCategory
            startDate
            endDate
            waiverAllowMultipleAttendeeConsent
            waiverTextBody
            experienceHeaderImage {
              id
              filename
              downloadUrl
              fileId
              public
            }
            experienceLogoImage {
              fileId
              filename
              id
              public
              downloadUrl
            }
            registrationOptions {
              items {
                id
                name
                pricingSettings {
                  id
                  pricingType
                  pricingTiers {
                    items {
                      id
                      price
                      startDate
                      endDate
                    }
                  }
                  pricingEnabled
                }
              }
            }
            venues {
              items {
                id
                name
                notes
                address
              }
            }
          }
        }
      }
    }
  }
`;
/**
 * __usePublicCampaignInfoBySlugQuery__
 *
 * To run a query within a React component, call `usePublicCampaignInfoBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicCampaignInfoBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicCampaignInfoBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function usePublicCampaignInfoBySlugQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(PublicCampaignInfoBySlugDocument, options);
}
export function usePublicCampaignInfoBySlugLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(PublicCampaignInfoBySlugDocument, options);
}
export const GetPublicCampaignInfoBySlugDocument = gql `
  query GetPublicCampaignInfoBySlug($slug: String!) {
    getPublicCampaignInfoBySlug(slug: $slug) {
      organization
      campaign
      experiences
      campaignTemplates
    }
  }
`;
/**
 * __useGetPublicCampaignInfoBySlugQuery__
 *
 * To run a query within a React component, call `useGetPublicCampaignInfoBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublicCampaignInfoBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublicCampaignInfoBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetPublicCampaignInfoBySlugQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetPublicCampaignInfoBySlugDocument, options);
}
export function useGetPublicCampaignInfoBySlugLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetPublicCampaignInfoBySlugDocument, options);
}
export const DonationOptionByIdDocument = gql `
  query donationOptionById($id: ID!) {
    donationOption(id: $id) {
      ...DonationOptionFragment
    }
  }
  ${DonationOptionFragmentFragmentDoc}
`;
/**
 * __useDonationOptionByIdQuery__
 *
 * To run a query within a React component, call `useDonationOptionByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useDonationOptionByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDonationOptionByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDonationOptionByIdQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(DonationOptionByIdDocument, options);
}
export function useDonationOptionByIdLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(DonationOptionByIdDocument, options);
}
export const DonationOptionsListDocument = gql `
  query DonationOptionsList($filter: DonationOptionFilter) {
    donationOptionsList(filter: $filter) {
      items {
        ...DonationOptionFragment
      }
    }
  }
  ${DonationOptionFragmentFragmentDoc}
`;
/**
 * __useDonationOptionsListQuery__
 *
 * To run a query within a React component, call `useDonationOptionsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDonationOptionsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDonationOptionsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDonationOptionsListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(DonationOptionsListDocument, options);
}
export function useDonationOptionsListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(DonationOptionsListDocument, options);
}
export const DonationOptionUpdateDocument = gql `
  mutation DonationOptionUpdate($data: DonationOptionUpdateInput!) {
    donationOptionUpdate(data: $data) {
      ...DonationOptionFragment
    }
  }
  ${DonationOptionFragmentFragmentDoc}
`;
/**
 * __useDonationOptionUpdateMutation__
 *
 * To run a mutation, you first call `useDonationOptionUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDonationOptionUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [donationOptionUpdateMutation, { data, loading, error }] = useDonationOptionUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDonationOptionUpdateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(DonationOptionUpdateDocument, options);
}
export const DonationOptionCreateDocument = gql `
  mutation DonationOptionCreate($data: DonationOptionCreateInput!) {
    donationOptionCreate(data: $data) {
      ...DonationOptionFragment
    }
  }
  ${DonationOptionFragmentFragmentDoc}
`;
/**
 * __useDonationOptionCreateMutation__
 *
 * To run a mutation, you first call `useDonationOptionCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDonationOptionCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [donationOptionCreateMutation, { data, loading, error }] = useDonationOptionCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDonationOptionCreateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(DonationOptionCreateDocument, options);
}
export const DonationOptionDeleteDocument = gql `
  mutation DonationOptionDelete($id: ID!) {
    donationOptionDelete(filter: { id: $id }) {
      success
    }
  }
`;
/**
 * __useDonationOptionDeleteMutation__
 *
 * To run a mutation, you first call `useDonationOptionDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDonationOptionDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [donationOptionDeleteMutation, { data, loading, error }] = useDonationOptionDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDonationOptionDeleteMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(DonationOptionDeleteDocument, options);
}
export const UnallocatedDonationResumeDocument = gql `
  query UnallocatedDonationResume($filter: DonationAllocationFilter!) {
    metrics: donationAllocationsList(
      filter: $filter
      groupBy: {
        query: {
          amount: { as: "total", fn: { aggregate: SUM } }
          id: { as: "count", fn: { aggregate: COUNT } }
        }
      }
    ) {
      donations: items {
        id
        campaign {
          id
        }
        experience {
          id
        }
        teamFundraising {
          id
        }
      }
      groups {
        total: Float
        count: Int
      }
    }
  }
`;
/**
 * __useUnallocatedDonationResumeQuery__
 *
 * To run a query within a React component, call `useUnallocatedDonationResumeQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnallocatedDonationResumeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnallocatedDonationResumeQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useUnallocatedDonationResumeQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(UnallocatedDonationResumeDocument, options);
}
export function useUnallocatedDonationResumeLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(UnallocatedDonationResumeDocument, options);
}
export const AllocatedDonationsMetricsDocument = gql `
  query AllocatedDonationsMetrics($filter: DonationAllocationFilter!) {
    donations: donationAllocationsList(
      filter: $filter
      groupBy: {
        query: {
          createdAt: { as: "date", fn: { datePart: DATE } }
          amount: { as: "amount", fn: { aggregate: SUM } }
          id: { as: "count", fn: { aggregate: COUNT } }
        }
      }
    ) {
      groups {
        date: Date
        amount: Float
        count: Int
      }
    }
  }
`;
/**
 * __useAllocatedDonationsMetricsQuery__
 *
 * To run a query within a React component, call `useAllocatedDonationsMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllocatedDonationsMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllocatedDonationsMetricsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAllocatedDonationsMetricsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(AllocatedDonationsMetricsDocument, options);
}
export function useAllocatedDonationsMetricsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(AllocatedDonationsMetricsDocument, options);
}
export const AllocatedDonationsDocument = gql `
  query AllocatedDonations($filter: DonationAllocationFilter!, $skip: Int, $first: Int) {
    donations: donationAllocationsList(filter: $filter, skip: $skip, first: $first) {
      count
      items {
        id
        amount
        createdAt
        individualFundraising {
          id
          attendee {
            user {
              firstName
              lastName
            }
          }
        }
        donor {
          firstName
          lastName
          avatar {
            downloadUrl
          }
          allocatedDonations(filter: $filter, first: 1, orderBy: createdAt_ASC) {
            items {
              id
            }
          }
        }
      }
    }
  }
`;
/**
 * __useAllocatedDonationsQuery__
 *
 * To run a query within a React component, call `useAllocatedDonationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllocatedDonationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllocatedDonationsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useAllocatedDonationsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(AllocatedDonationsDocument, options);
}
export function useAllocatedDonationsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(AllocatedDonationsDocument, options);
}
export const ExperienceHeaderImageDocument = gql `
  query ExperienceHeaderImage($id: ID!) {
    experience(id: $id) {
      ...ExperienceHeaderImageFragment
    }
  }
  ${ExperienceHeaderImageFragmentFragmentDoc}
`;
/**
 * __useExperienceHeaderImageQuery__
 *
 * To run a query within a React component, call `useExperienceHeaderImageQuery` and pass it any options that fit your needs.
 * When your component renders, `useExperienceHeaderImageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExperienceHeaderImageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExperienceHeaderImageQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ExperienceHeaderImageDocument, options);
}
export function useExperienceHeaderImageLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ExperienceHeaderImageDocument, options);
}
export const ExperienceLogoImageDocument = gql `
  query ExperienceLogoImage($id: ID!) {
    experience(id: $id) {
      ...ExperienceHeaderLogoFragment
    }
  }
  ${ExperienceHeaderLogoFragmentFragmentDoc}
`;
/**
 * __useExperienceLogoImageQuery__
 *
 * To run a query within a React component, call `useExperienceLogoImageQuery` and pass it any options that fit your needs.
 * When your component renders, `useExperienceLogoImageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExperienceLogoImageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExperienceLogoImageQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ExperienceLogoImageDocument, options);
}
export function useExperienceLogoImageLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ExperienceLogoImageDocument, options);
}
export const ExperienceHeaderImagesUpdateDocument = gql `
  mutation ExperienceHeaderImagesUpdate(
    $data: ExperienceUpdateInput!
    $filter: ExperienceKeyFilter
  ) {
    experience: experienceUpdate(data: $data, filter: $filter, destroyDetached: true) {
      ...ExperienceHeaderImageFragment
    }
  }
  ${ExperienceHeaderImageFragmentFragmentDoc}
`;
/**
 * __useExperienceHeaderImagesUpdateMutation__
 *
 * To run a mutation, you first call `useExperienceHeaderImagesUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExperienceHeaderImagesUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [experienceHeaderImagesUpdateMutation, { data, loading, error }] = useExperienceHeaderImagesUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useExperienceHeaderImagesUpdateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ExperienceHeaderImagesUpdateDocument, options);
}
export const ExperienceHeaderLogoUpdateDocument = gql `
  mutation ExperienceHeaderLogoUpdate($data: ExperienceUpdateInput!, $filter: ExperienceKeyFilter) {
    experience: experienceUpdate(data: $data, filter: $filter, destroyDetached: true) {
      ...ExperienceHeaderLogoFragment
    }
  }
  ${ExperienceHeaderLogoFragmentFragmentDoc}
`;
/**
 * __useExperienceHeaderLogoUpdateMutation__
 *
 * To run a mutation, you first call `useExperienceHeaderLogoUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExperienceHeaderLogoUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [experienceHeaderLogoUpdateMutation, { data, loading, error }] = useExperienceHeaderLogoUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useExperienceHeaderLogoUpdateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ExperienceHeaderLogoUpdateDocument, options);
}
export const ExperienceGalleryImagesDocument = gql `
  query ExperienceGalleryImages($id: ID!) {
    experience: experience(id: $id) {
      ...ExperienceGalleryImagesFragment
    }
  }
  ${ExperienceGalleryImagesFragmentFragmentDoc}
`;
/**
 * __useExperienceGalleryImagesQuery__
 *
 * To run a query within a React component, call `useExperienceGalleryImagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useExperienceGalleryImagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExperienceGalleryImagesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExperienceGalleryImagesQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ExperienceGalleryImagesDocument, options);
}
export function useExperienceGalleryImagesLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ExperienceGalleryImagesDocument, options);
}
export const ExperienceGalleryAttachImagesDocument = gql `
  mutation ExperienceGalleryAttachImages(
    $data: ExperienceUpdateInput!
    $filter: ExperienceKeyFilter!
  ) {
    experience: experienceUpdate(data: $data, filter: $filter) {
      ...ExperienceGalleryImagesFragment
    }
  }
  ${ExperienceGalleryImagesFragmentFragmentDoc}
`;
/**
 * __useExperienceGalleryAttachImagesMutation__
 *
 * To run a mutation, you first call `useExperienceGalleryAttachImagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExperienceGalleryAttachImagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [experienceGalleryAttachImagesMutation, { data, loading, error }] = useExperienceGalleryAttachImagesMutation({
 *   variables: {
 *      data: // value for 'data'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useExperienceGalleryAttachImagesMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ExperienceGalleryAttachImagesDocument, options);
}
export const ExperienceGalleryDetachImageDocument = gql `
  mutation ExperienceGalleryDetachImage(
    $data: ExperienceUpdateInput!
    $filter: ExperienceKeyFilter!
  ) {
    experience: experienceUpdate(data: $data, filter: $filter, destroyDetached: true) {
      ...ExperienceGalleryImagesFragment
    }
  }
  ${ExperienceGalleryImagesFragmentFragmentDoc}
`;
/**
 * __useExperienceGalleryDetachImageMutation__
 *
 * To run a mutation, you first call `useExperienceGalleryDetachImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExperienceGalleryDetachImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [experienceGalleryDetachImageMutation, { data, loading, error }] = useExperienceGalleryDetachImageMutation({
 *   variables: {
 *      data: // value for 'data'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useExperienceGalleryDetachImageMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ExperienceGalleryDetachImageDocument, options);
}
export const FundraisingSettingsByExperienceDocument = gql `
  query FundraisingSettingsByExperience($filter: ExperienceFundraisingFilter) {
    experienceFundraisingsList(filter: $filter) {
      items {
        id
        generalSettings {
          id
          isEnabled
          isObligation
          isWithoutPenalty
          pageSetUp
          chargeTime
          time
          date
          chargeDate
          isFacebookEnabled
          isInstagramEnabled
        }
        donationSettings {
          id
          isEnabled
          allowAnonymousDonations
          allowSelfDonations
          allowDonorsToOptIn
          allowDonorsToDedicateDonations
          coveredFees
          percentageAmount
          feesEnabled
          feesMessage
          roundupEnabled
          roundupMessage
        }
        teamSettings {
          id
          isEnabled
          teamCapabilities
          joiningTeamEnabled
          allowTeamsMakeTheirTeamInvite
          allowAttendeeRequestAdmissionOntoTeam
          maximumTeams
          maximumTeamMembers
          teamSharingEnabled
          appealMessage
          thankYouMessage
        }
        individualSettings {
          id
          isEnabled
          appealMessage
          thankYouMessage
        }
      }
    }
  }
`;
/**
 * __useFundraisingSettingsByExperienceQuery__
 *
 * To run a query within a React component, call `useFundraisingSettingsByExperienceQuery` and pass it any options that fit your needs.
 * When your component renders, `useFundraisingSettingsByExperienceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFundraisingSettingsByExperienceQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFundraisingSettingsByExperienceQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(FundraisingSettingsByExperienceDocument, options);
}
export function useFundraisingSettingsByExperienceLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(FundraisingSettingsByExperienceDocument, options);
}
export const IndividualFundraisingManagementDocument = gql `
  query IndividualFundraisingManagement($filter: IndividualFundraisingFilter!) {
    individualFundraisingsList(filter: $filter) {
      count
      items {
        ...INDIVIDUAL_FUNDRAISING_CONTENT_FRAGMENT
      }
    }
  }
  ${Individual_Fundraising_Content_FragmentFragmentDoc}
`;
/**
 * __useIndividualFundraisingManagementQuery__
 *
 * To run a query within a React component, call `useIndividualFundraisingManagementQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndividualFundraisingManagementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndividualFundraisingManagementQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useIndividualFundraisingManagementQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(IndividualFundraisingManagementDocument, options);
}
export function useIndividualFundraisingManagementLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(IndividualFundraisingManagementDocument, options);
}
export const IndividualFundraisingUpdateContentDocument = gql `
  mutation IndividualFundraisingUpdateContent(
    $data: IndividualFundraisingUpdateInput!
    $filter: IndividualFundraisingKeyFilter!
  ) {
    individualFundraising: individualFundraisingUpdate(data: $data, filter: $filter) {
      ...INDIVIDUAL_FUNDRAISING_CONTENT_FRAGMENT
    }
  }
  ${Individual_Fundraising_Content_FragmentFragmentDoc}
`;
/**
 * __useIndividualFundraisingUpdateContentMutation__
 *
 * To run a mutation, you first call `useIndividualFundraisingUpdateContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIndividualFundraisingUpdateContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [individualFundraisingUpdateContentMutation, { data, loading, error }] = useIndividualFundraisingUpdateContentMutation({
 *   variables: {
 *      data: // value for 'data'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useIndividualFundraisingUpdateContentMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(IndividualFundraisingUpdateContentDocument, options);
}
export const IndividualFundraisingCreateContentDocument = gql `
  mutation IndividualFundraisingCreateContent($data: IndividualFundraisingCreateInput!) {
    individualFundraising: individualFundraisingCreate(data: $data) {
      ...INDIVIDUAL_FUNDRAISING_MANAGEMENT_FRAGMENT
    }
  }
  ${Individual_Fundraising_Management_FragmentFragmentDoc}
`;
/**
 * __useIndividualFundraisingCreateContentMutation__
 *
 * To run a mutation, you first call `useIndividualFundraisingCreateContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIndividualFundraisingCreateContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [individualFundraisingCreateContentMutation, { data, loading, error }] = useIndividualFundraisingCreateContentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useIndividualFundraisingCreateContentMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(IndividualFundraisingCreateContentDocument, options);
}
export const IndividualFundraisingUpdateGalleryDocument = gql `
  mutation IndividualFundraisingUpdateGallery(
    $data: IndividualFundraisingUpdateInput!
    $filter: IndividualFundraisingKeyFilter!
  ) {
    individualFundraising: individualFundraisingUpdate(
      data: $data
      filter: $filter
      destroyDetached: true
    ) {
      ...INDIVIDUAL_FUNDRAISING_GALLERY_FRAGMENT
    }
  }
  ${Individual_Fundraising_Gallery_FragmentFragmentDoc}
`;
/**
 * __useIndividualFundraisingUpdateGalleryMutation__
 *
 * To run a mutation, you first call `useIndividualFundraisingUpdateGalleryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIndividualFundraisingUpdateGalleryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [individualFundraisingUpdateGalleryMutation, { data, loading, error }] = useIndividualFundraisingUpdateGalleryMutation({
 *   variables: {
 *      data: // value for 'data'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useIndividualFundraisingUpdateGalleryMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(IndividualFundraisingUpdateGalleryDocument, options);
}
export const IndividualFundraisingUpdateHeaderDocument = gql `
  mutation IndividualFundraisingUpdateHeader(
    $data: IndividualFundraisingUpdateInput!
    $filter: IndividualFundraisingKeyFilter!
  ) {
    individualFundraising: individualFundraisingUpdate(
      data: $data
      filter: $filter
      destroyDetached: true
    ) {
      ...INDIVIDUAL_FUNDRAISING_HEADER_FRAGMENT
    }
  }
  ${Individual_Fundraising_Header_FragmentFragmentDoc}
`;
/**
 * __useIndividualFundraisingUpdateHeaderMutation__
 *
 * To run a mutation, you first call `useIndividualFundraisingUpdateHeaderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIndividualFundraisingUpdateHeaderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [individualFundraisingUpdateHeaderMutation, { data, loading, error }] = useIndividualFundraisingUpdateHeaderMutation({
 *   variables: {
 *      data: // value for 'data'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useIndividualFundraisingUpdateHeaderMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(IndividualFundraisingUpdateHeaderDocument, options);
}
export const OrganizationLogoUpdateDocument = gql `
  mutation OrganizationLogoUpdate(
    $data: VizslaOrganizationUpdateInput!
    $filter: VizslaOrganizationKeyFilter
  ) {
    vizslaOrganizationUpdate(data: $data, filter: $filter, destroyDetached: true) {
      ...ORGANIZATION_LOGO_FRAGMENT
    }
  }
  ${Organization_Logo_FragmentFragmentDoc}
`;
/**
 * __useOrganizationLogoUpdateMutation__
 *
 * To run a mutation, you first call `useOrganizationLogoUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrganizationLogoUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [organizationLogoUpdateMutation, { data, loading, error }] = useOrganizationLogoUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useOrganizationLogoUpdateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(OrganizationLogoUpdateDocument, options);
}
export const SocialMediaCreateDocument = gql `
  mutation SocialMediaCreate($data: SocialMediaCreateInput!) {
    socialMediaCreate(data: $data) {
      id
    }
  }
`;
/**
 * __useSocialMediaCreateMutation__
 *
 * To run a mutation, you first call `useSocialMediaCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSocialMediaCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [socialMediaCreateMutation, { data, loading, error }] = useSocialMediaCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSocialMediaCreateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(SocialMediaCreateDocument, options);
}
export const SocialMediaDocument = gql `
  query SocialMedia($filter: SocialMediaFilter, $skip: Int, $first: Int) {
    socialMedias: socialMediasList(filter: $filter, skip: $skip, first: $first) {
      items {
        id
        organization {
          id
          name
        }
        socialMedia
        uRL
      }
    }
  }
`;
/**
 * __useSocialMediaQuery__
 *
 * To run a query within a React component, call `useSocialMediaQuery` and pass it any options that fit your needs.
 * When your component renders, `useSocialMediaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSocialMediaQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      skip: // value for 'skip'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useSocialMediaQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(SocialMediaDocument, options);
}
export function useSocialMediaLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(SocialMediaDocument, options);
}
export const SocialMediaDeleteDocument = gql `
  mutation SocialMediaDelete($id: ID!) {
    socialMediaDelete(filter: { id: $id }, force: true) {
      success
    }
  }
`;
/**
 * __useSocialMediaDeleteMutation__
 *
 * To run a mutation, you first call `useSocialMediaDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSocialMediaDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [socialMediaDeleteMutation, { data, loading, error }] = useSocialMediaDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSocialMediaDeleteMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(SocialMediaDeleteDocument, options);
}
export const OrganizationImpactByIdDocument = gql `
  query OrganizationImpactByID($organizationID: ID!, $userID: ID!) {
    organization: vizslaOrganization(id: $organizationID) {
      id
      name
      ein
      contactEmail
      logo {
        ...FileURLFragment
      }
    }
    registrations: attendeesList(
      sort: { createdAt: ASC }
      filter: {
        AND: [
          { experience: { campaign: { vizslaOrganization: { id: { equals: $organizationID } } } } }
        ]
      }
    ) {
      items {
        id
        createdAt
      }
    }
    totalRaised: donationAllocationsList(
      filter: { AND: [{ campaign: { vizslaOrganization: { id: { equals: $organizationID } } } }] }
      groupBy: { query: { amount: { as: "totalRaised", fn: { aggregate: SUM } } } }
    ) {
      groups {
        totalRaised: Int
      }
    }
    averageDonation: donationAllocationsList(
      filter: { AND: [{ campaign: { vizslaOrganization: { id: { equals: $organizationID } } } }] }
      groupBy: { query: { amount: { as: "averageDonation", fn: { aggregate: AVG } } } }
    ) {
      groups {
        averageDonation: Int
      }
    }
    totalDonations: donationAllocationsList(
      filter: {
        AND: [
          { campaign: { vizslaOrganization: { id: { equals: $organizationID } } } }
          { donationFulfillment: { donor: { id: { equals: $userID } } } }
        ]
      }
      groupBy: { query: { amount: { as: "totalDonations", fn: { aggregate: SUM } } } }
    ) {
      groups {
        totalDonations: Int
      }
    }
  }
  ${FileUrlFragmentFragmentDoc}
`;
/**
 * __useOrganizationImpactByIdQuery__
 *
 * To run a query within a React component, call `useOrganizationImpactByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationImpactByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationImpactByIdQuery({
 *   variables: {
 *      organizationID: // value for 'organizationID'
 *      userID: // value for 'userID'
 *   },
 * });
 */
export function useOrganizationImpactByIdQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(OrganizationImpactByIdDocument, options);
}
export function useOrganizationImpactByIdLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(OrganizationImpactByIdDocument, options);
}
export const StripeCreatePaymentIntentForItemDocument = gql `
  mutation StripeCreatePaymentIntentForItem(
    $amount: Float!
    $meta: JSON!
    $connectedStripeAccountId: String!
    $email: String
  ) {
    stripeCreatePaymentIntentForItem(
      amount: $amount
      meta: $meta
      connectedStripeAccountId: $connectedStripeAccountId
      email: $email
    ) {
      paymentIntent
    }
  }
`;
/**
 * __useStripeCreatePaymentIntentForItemMutation__
 *
 * To run a mutation, you first call `useStripeCreatePaymentIntentForItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStripeCreatePaymentIntentForItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stripeCreatePaymentIntentForItemMutation, { data, loading, error }] = useStripeCreatePaymentIntentForItemMutation({
 *   variables: {
 *      amount: // value for 'amount'
 *      meta: // value for 'meta'
 *      connectedStripeAccountId: // value for 'connectedStripeAccountId'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useStripeCreatePaymentIntentForItemMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(StripeCreatePaymentIntentForItemDocument, options);
}
export const StripeCreateConnectAccountLinkDocument = gql `
  mutation StripeCreateConnectAccountLink(
    $refreshUrl: String!
    $returnUrl: String!
    $organizationId: ID!
  ) {
    stripeCreateConnectAccountLink(
      refreshUrl: $refreshUrl
      returnUrl: $returnUrl
      organizationId: $organizationId
    ) {
      accountLink
    }
  }
`;
/**
 * __useStripeCreateConnectAccountLinkMutation__
 *
 * To run a mutation, you first call `useStripeCreateConnectAccountLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStripeCreateConnectAccountLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stripeCreateConnectAccountLinkMutation, { data, loading, error }] = useStripeCreateConnectAccountLinkMutation({
 *   variables: {
 *      refreshUrl: // value for 'refreshUrl'
 *      returnUrl: // value for 'returnUrl'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useStripeCreateConnectAccountLinkMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(StripeCreateConnectAccountLinkDocument, options);
}
export const StripeRetrieveAccountDocument = gql `
  query StripeRetrieveAccount($organizationId: ID!) {
    stripeRetrieveAccount(organizationId: $organizationId) {
      stripeAccount
    }
  }
`;
/**
 * __useStripeRetrieveAccountQuery__
 *
 * To run a query within a React component, call `useStripeRetrieveAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useStripeRetrieveAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStripeRetrieveAccountQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useStripeRetrieveAccountQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(StripeRetrieveAccountDocument, options);
}
export function useStripeRetrieveAccountLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(StripeRetrieveAccountDocument, options);
}
export const JoinTeamFundraisingDocument = gql `
  mutation JoinTeamFundraising($membership: CampaignTeamMemberCreateInput!) {
    membership: campaignTeamMemberCreate(data: $membership) {
      ...TEAM_MEMBERSHIP_FRAGMENT
    }
  }
  ${Team_Membership_FragmentFragmentDoc}
`;
/**
 * __useJoinTeamFundraisingMutation__
 *
 * To run a mutation, you first call `useJoinTeamFundraisingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinTeamFundraisingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinTeamFundraisingMutation, { data, loading, error }] = useJoinTeamFundraisingMutation({
 *   variables: {
 *      membership: // value for 'membership'
 *   },
 * });
 */
export function useJoinTeamFundraisingMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(JoinTeamFundraisingDocument, options);
}
export const TeamFundraisingByExperienceIdDocument = gql `
  query TeamFundraisingByExperienceID($experienceID: ID!) {
    teams: teamFundraisingsList(filter: { experience: { id: { equals: $experienceID } } }) {
      items {
        ...TEAM_FUNDRAISING_FRAGMENT
      }
    }
  }
  ${Team_Fundraising_FragmentFragmentDoc}
`;
/**
 * __useTeamFundraisingByExperienceIdQuery__
 *
 * To run a query within a React component, call `useTeamFundraisingByExperienceIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamFundraisingByExperienceIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamFundraisingByExperienceIdQuery({
 *   variables: {
 *      experienceID: // value for 'experienceID'
 *   },
 * });
 */
export function useTeamFundraisingByExperienceIdQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(TeamFundraisingByExperienceIdDocument, options);
}
export function useTeamFundraisingByExperienceIdLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(TeamFundraisingByExperienceIdDocument, options);
}
export const LeaveTeamFundraisingDocument = gql `
  mutation LeaveTeamFundraising($attendeeId: ID!, $teamId: ID!) {
    removal: campaignTeamMemberDeleteByFilter(
      filter: { team: { id: { equals: $teamId } }, attendee: { id: { equals: $attendeeId } } }
    ) {
      success
    }
  }
`;
/**
 * __useLeaveTeamFundraisingMutation__
 *
 * To run a mutation, you first call `useLeaveTeamFundraisingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveTeamFundraisingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveTeamFundraisingMutation, { data, loading, error }] = useLeaveTeamFundraisingMutation({
 *   variables: {
 *      attendeeId: // value for 'attendeeId'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useLeaveTeamFundraisingMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(LeaveTeamFundraisingDocument, options);
}
export const TeamFundraisingManagementDocument = gql `
  query TeamFundraisingManagement($id: ID!) {
    teamFundraising(id: $id) {
      ...TeamFundraisingManagementFragment
    }
  }
  ${TeamFundraisingManagementFragmentFragmentDoc}
`;
/**
 * __useTeamFundraisingManagementQuery__
 *
 * To run a query within a React component, call `useTeamFundraisingManagementQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamFundraisingManagementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamFundraisingManagementQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTeamFundraisingManagementQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(TeamFundraisingManagementDocument, options);
}
export function useTeamFundraisingManagementLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(TeamFundraisingManagementDocument, options);
}
export const CurrentUserTeamFundraisingPageDocument = gql `
  query CurrentUserTeamFundraisingPage($teamID: ID!) {
    user {
      firstName
      lastName
      attendees(filter: { teamMembership: { team: { id: { equals: $teamID } } } }) {
        items {
          id
          fundraising {
            id
            goal
            raised
            hero {
              downloadUrl
            }
            logo {
              downloadUrl
            }
          }
        }
      }
    }
  }
`;
/**
 * __useCurrentUserTeamFundraisingPageQuery__
 *
 * To run a query within a React component, call `useCurrentUserTeamFundraisingPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserTeamFundraisingPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserTeamFundraisingPageQuery({
 *   variables: {
 *      teamID: // value for 'teamID'
 *   },
 * });
 */
export function useCurrentUserTeamFundraisingPageQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(CurrentUserTeamFundraisingPageDocument, options);
}
export function useCurrentUserTeamFundraisingPageLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(CurrentUserTeamFundraisingPageDocument, options);
}
export const TeamFundraisingTopDocument = gql `
  query teamFundraisingTop($teamId: ID!) {
    teamFundraising: teamFundraisingsList(filter: { id: { equals: $teamId } }) {
      items {
        members {
          items {
            attendee {
              user {
                firstName
              }
              fundraising {
                allocatedDonations {
                  items {
                    amount
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
/**
 * __useTeamFundraisingTopQuery__
 *
 * To run a query within a React component, call `useTeamFundraisingTopQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamFundraisingTopQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamFundraisingTopQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useTeamFundraisingTopQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(TeamFundraisingTopDocument, options);
}
export function useTeamFundraisingTopLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(TeamFundraisingTopDocument, options);
}
export const TopDonationTeamFundraisersDocument = gql `
  query TopDonationTeamFundraisers($teamId: ID!) {
    fundraisers: individualFundraisingsList(
      filter: { attendee: { teamMembership: { team: { id: { equals: $teamId } } } } }
    ) {
      count
      items {
        id
        attendee {
          user {
            ...UserFragment
          }
        }
        allocatedDonations(
          groupBy: { query: { amount: { as: "total", fn: { aggregate: SUM } } } }
        ) {
          groups {
            total: Float
          }
        }
      }
    }
  }
  ${UserFragmentFragmentDoc}
`;
/**
 * __useTopDonationTeamFundraisersQuery__
 *
 * To run a query within a React component, call `useTopDonationTeamFundraisersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopDonationTeamFundraisersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopDonationTeamFundraisersQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useTopDonationTeamFundraisersQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(TopDonationTeamFundraisersDocument, options);
}
export function useTopDonationTeamFundraisersLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(TopDonationTeamFundraisersDocument, options);
}
export const Top_Average_Donation_Team_FundraisersDocument = gql `
  query TOP_AVERAGE_DONATION_TEAM_FUNDRAISERS($teamId: ID!) {
    fundraisers: individualFundraisingsList(
      filter: { attendee: { teamMembership: { team: { id: { equals: $teamId } } } } }
    ) {
      count
      items {
        id
        attendee {
          user {
            ...UserFragment
          }
        }
        allocatedDonations(
          groupBy: { query: { amount: { as: "average", fn: { aggregate: AVG } } } }
        ) {
          groups {
            average: Float
          }
        }
      }
    }
  }
  ${UserFragmentFragmentDoc}
`;
/**
 * __useTop_Average_Donation_Team_FundraisersQuery__
 *
 * To run a query within a React component, call `useTop_Average_Donation_Team_FundraisersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTop_Average_Donation_Team_FundraisersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTop_Average_Donation_Team_FundraisersQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useTop_Average_Donation_Team_FundraisersQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(Top_Average_Donation_Team_FundraisersDocument, options);
}
export function useTop_Average_Donation_Team_FundraisersLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(Top_Average_Donation_Team_FundraisersDocument, options);
}
export const TopDonorsTeamFundraisersDocument = gql `
  query TopDonorsTeamFundraisers($teamId: ID!) {
    fundraisers: individualFundraisingsList(
      filter: { attendee: { teamMembership: { team: { id: { equals: $teamId } } } } }
    ) {
      count
      items {
        id
        attendee {
          user {
            ...UserFragment
          }
        }
        allocatedDonations(
          groupBy: { query: { donor: { id: { as: "donors", fn: { aggregate: COUNT } } } } }
        ) {
          groups {
            donors: Int
          }
        }
      }
    }
  }
  ${UserFragmentFragmentDoc}
`;
/**
 * __useTopDonorsTeamFundraisersQuery__
 *
 * To run a query within a React component, call `useTopDonorsTeamFundraisersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopDonorsTeamFundraisersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopDonorsTeamFundraisersQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useTopDonorsTeamFundraisersQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(TopDonorsTeamFundraisersDocument, options);
}
export function useTopDonorsTeamFundraisersLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(TopDonorsTeamFundraisersDocument, options);
}
export const TopDonorByTeamDocument = gql `
  query topDonorByTeam($teamId: ID!) {
    donors: usersList(
      filter: { allocatedDonations: { some: { teamFundraising: { id: { equals: $teamId } } } } }
    ) {
      items {
        id
        firstName
        lastName
        avatar {
          downloadUrl
        }
        allocatedDonations(
          groupBy: {
            sort: { alias: "total", direction: ASC }
            query: { amount: { as: "total", fn: { aggregate: COUNT } } }
          }
        ) {
          groups {
            total: Float
          }
        }
      }
    }
  }
`;
/**
 * __useTopDonorByTeamQuery__
 *
 * To run a query within a React component, call `useTopDonorByTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopDonorByTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopDonorByTeamQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useTopDonorByTeamQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(TopDonorByTeamDocument, options);
}
export function useTopDonorByTeamLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(TopDonorByTeamDocument, options);
}
export const TeamFundraisingManagementByFilterDocument = gql `
  query TeamFundraisingManagementByFilter($filter: TeamFundraisingFilter!) {
    teamFundraisingsList(filter: $filter) {
      count
      items {
        ...TeamFundraisingManagementFragment
      }
    }
  }
  ${TeamFundraisingManagementFragmentFragmentDoc}
`;
/**
 * __useTeamFundraisingManagementByFilterQuery__
 *
 * To run a query within a React component, call `useTeamFundraisingManagementByFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamFundraisingManagementByFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamFundraisingManagementByFilterQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTeamFundraisingManagementByFilterQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(TeamFundraisingManagementByFilterDocument, options);
}
export function useTeamFundraisingManagementByFilterLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(TeamFundraisingManagementByFilterDocument, options);
}
export const TeamFundraisingUpdateGalleryDocument = gql `
  mutation TeamFundraisingUpdateGallery(
    $data: TeamFundraisingUpdateInput!
    $filter: TeamFundraisingKeyFilter!
  ) {
    teamFundraising: teamFundraisingUpdate(data: $data, filter: $filter, destroyDetached: true) {
      ...TeamFundraisingGalleryFragment
    }
  }
  ${TeamFundraisingGalleryFragmentFragmentDoc}
`;
/**
 * __useTeamFundraisingUpdateGalleryMutation__
 *
 * To run a mutation, you first call `useTeamFundraisingUpdateGalleryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTeamFundraisingUpdateGalleryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [teamFundraisingUpdateGalleryMutation, { data, loading, error }] = useTeamFundraisingUpdateGalleryMutation({
 *   variables: {
 *      data: // value for 'data'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTeamFundraisingUpdateGalleryMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(TeamFundraisingUpdateGalleryDocument, options);
}
export const TeamFundraisingContentAttachImageDocument = gql `
  mutation TeamFundraisingContentAttachImage(
    $data: TeamFundraisingUpdateInput!
    $filter: TeamFundraisingKeyFilter!
  ) {
    teamFundraising: teamFundraisingUpdate(data: $data, filter: $filter) {
      ...TeamFundraisingContentFragment
    }
  }
  ${TeamFundraisingContentFragmentFragmentDoc}
`;
/**
 * __useTeamFundraisingContentAttachImageMutation__
 *
 * To run a mutation, you first call `useTeamFundraisingContentAttachImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTeamFundraisingContentAttachImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [teamFundraisingContentAttachImageMutation, { data, loading, error }] = useTeamFundraisingContentAttachImageMutation({
 *   variables: {
 *      data: // value for 'data'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTeamFundraisingContentAttachImageMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(TeamFundraisingContentAttachImageDocument, options);
}
export const TeamFundraisingUpdateHeaderDocument = gql `
  mutation TeamFundraisingUpdateHeader(
    $data: TeamFundraisingUpdateInput!
    $filter: TeamFundraisingKeyFilter!
  ) {
    teamFundraising: teamFundraisingUpdate(data: $data, filter: $filter, destroyDetached: true) {
      ...TeamFundraisingHeaderFragment
    }
  }
  ${TeamFundraisingHeaderFragmentFragmentDoc}
`;
/**
 * __useTeamFundraisingUpdateHeaderMutation__
 *
 * To run a mutation, you first call `useTeamFundraisingUpdateHeaderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTeamFundraisingUpdateHeaderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [teamFundraisingUpdateHeaderMutation, { data, loading, error }] = useTeamFundraisingUpdateHeaderMutation({
 *   variables: {
 *      data: // value for 'data'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTeamFundraisingUpdateHeaderMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(TeamFundraisingUpdateHeaderDocument, options);
}
export const TeamsNamePerExperienceDocument = gql `
  query TeamsNamePerExperience($experienceId: ID!, $teamName: String) {
    experience(id: $experienceId) {
      teams(filter: { name: { equals: $teamName } }) {
        count
        items {
          name
        }
      }
    }
  }
`;
/**
 * __useTeamsNamePerExperienceQuery__
 *
 * To run a query within a React component, call `useTeamsNamePerExperienceQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamsNamePerExperienceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamsNamePerExperienceQuery({
 *   variables: {
 *      experienceId: // value for 'experienceId'
 *      teamName: // value for 'teamName'
 *   },
 * });
 */
export function useTeamsNamePerExperienceQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(TeamsNamePerExperienceDocument, options);
}
export function useTeamsNamePerExperienceLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(TeamsNamePerExperienceDocument, options);
}
export const UsersListDocument = gql `
  query UsersList($filter: UserFilter) {
    usersList(filter: $filter) {
      items {
        ...UserFragment
        avatar {
          id
          downloadUrl
        }
      }
    }
  }
  ${UserFragmentFragmentDoc}
`;
/**
 * __useUsersListQuery__
 *
 * To run a query within a React component, call `useUsersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useUsersListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(UsersListDocument, options);
}
export function useUsersListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(UsersListDocument, options);
}
export const UserAttendeeExperienceDocument = gql `
  query userAttendeeExperience($userId: ID!, $experienceId: ID!) {
    attendeesList(
      filter: { user: { id: { equals: $userId } }, experience: { id: { equals: $experienceId } } }
    ) {
      items {
        id
        user {
          firstName
          lastName
        }
        experience {
          id
          name
        }
        teamMembership {
          id
          team {
            name
          }
        }
      }
    }
  }
`;
/**
 * __useUserAttendeeExperienceQuery__
 *
 * To run a query within a React component, call `useUserAttendeeExperienceQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserAttendeeExperienceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserAttendeeExperienceQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      experienceId: // value for 'experienceId'
 *   },
 * });
 */
export function useUserAttendeeExperienceQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(UserAttendeeExperienceDocument, options);
}
export function useUserAttendeeExperienceLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(UserAttendeeExperienceDocument, options);
}
export const IndividualFundraisingByAttendeeDocument = gql `
  query individualFundraisingByAttendee($filter: AttendeeFilter!) {
    attendeesList(filter: $filter) {
      items {
        fundraising {
          id
        }
        experience {
          id
          name
        }
      }
    }
  }
`;
/**
 * __useIndividualFundraisingByAttendeeQuery__
 *
 * To run a query within a React component, call `useIndividualFundraisingByAttendeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndividualFundraisingByAttendeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndividualFundraisingByAttendeeQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useIndividualFundraisingByAttendeeQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(IndividualFundraisingByAttendeeDocument, options);
}
export function useIndividualFundraisingByAttendeeLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(IndividualFundraisingByAttendeeDocument, options);
}
export const UserDocument = gql `
  query User {
    user {
      id
      email
      firstName
      lastName
      currentApplication
      status
      attendees {
        count
        items {
          teamMembership {
            team {
              name
            }
          }
        }
      }
      avatar {
        id
        downloadUrl
      }
      roles {
        items {
          id
          name
        }
      }
      vizslaOrganizationStaff {
        items {
          id
          roles
          vizslaOrganization {
            id
          }
        }
      }
    }
  }
`;
/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(UserDocument, options);
}
export function useUserLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(UserDocument, options);
}
export const ChangeUserCurrentAppDocument = gql `
  mutation ChangeUserCurrentApp($userId: ID!, $applicationId: String!) {
    userUpdate(filter: { id: $userId }, data: { currentApplication: $applicationId }) {
      id
      currentApplication
    }
  }
`;
/**
 * __useChangeUserCurrentAppMutation__
 *
 * To run a mutation, you first call `useChangeUserCurrentAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeUserCurrentAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeUserCurrentAppMutation, { data, loading, error }] = useChangeUserCurrentAppMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      applicationId: // value for 'applicationId'
 *   },
 * });
 */
export function useChangeUserCurrentAppMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ChangeUserCurrentAppDocument, options);
}
export const CurrentAttendeeDocument = gql `
  query currentAttendee($attendeeid: ID) {
    attendee(id: $attendeeid) {
      user {
        id
        email
        firstName
        lastName
        gender
        createdAt
        phone {
          code
          number
        }
        birthDate
        address {
          city
          country
          state
          street1
          street2
          zip
        }
      }
    }
  }
`;
/**
 * __useCurrentAttendeeQuery__
 *
 * To run a query within a React component, call `useCurrentAttendeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentAttendeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentAttendeeQuery({
 *   variables: {
 *      attendeeid: // value for 'attendeeid'
 *   },
 * });
 */
export function useCurrentAttendeeQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(CurrentAttendeeDocument, options);
}
export function useCurrentAttendeeLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(CurrentAttendeeDocument, options);
}
export const VizslaOrganizationDocument = gql `
  query VizslaOrganization($id: ID!) {
    vizslaOrganization(id: $id) {
      id
      name
      ein
      contactPhone {
        code
        number
      }
      contactAddress {
        country
        city
        state
        zip
        street1
        street2
      }
      contactEmail
      stripeIntegration {
        createdAt
        id
        status
        stripeAccountID
      }
      logo {
        id
        downloadUrl
      }
      negativeLogo {
        id
        downloadUrl
      }
      currency
      language
      preferredDateFormat
      timeZone
    }
  }
`;
/**
 * __useVizslaOrganizationQuery__
 *
 * To run a query within a React component, call `useVizslaOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useVizslaOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVizslaOrganizationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVizslaOrganizationQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(VizslaOrganizationDocument, options);
}
export function useVizslaOrganizationLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(VizslaOrganizationDocument, options);
}
export const AttendeeRegistrationQueryDocument = gql `
  query AttendeeRegistrationQuery($emails: [String!], $experienceId: ID!) {
    attendeesList(
      filter: { user: { email: { in: $emails } }, experience: { id: { equals: $experienceId } } }
    ) {
      items {
        id
        user {
          id
          firstName
          email
        }
        registrationOption {
          id
          name
        }
      }
    }
  }
`;
/**
 * __useAttendeeRegistrationQueryQuery__
 *
 * To run a query within a React component, call `useAttendeeRegistrationQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttendeeRegistrationQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttendeeRegistrationQueryQuery({
 *   variables: {
 *      emails: // value for 'emails'
 *      experienceId: // value for 'experienceId'
 *   },
 * });
 */
export function useAttendeeRegistrationQueryQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(AttendeeRegistrationQueryDocument, options);
}
export function useAttendeeRegistrationQueryLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(AttendeeRegistrationQueryDocument, options);
}
export const AttendeeFundraiserLandingDocument = gql `
  query AttendeeFundraiserLanding($filter: AttendeeFilter!) {
    attendeesList(filter: $filter) {
      items {
        id
        fundraiserGoal
        totalRaised
        user {
          lastName
          firstName
          id
          email
          avatar {
            id
            downloadUrl
          }
        }
        fundraisingPageLink
        teamMembership {
          id
          role
          team {
            id
            name
            avatar {
              id
              downloadUrl
            }
            fundraiserGoal
            totalRaised
          }
        }
        fundraising {
          goal
          id
          description
          raised
          title
          webLink
          logo {
            downloadUrl
          }
          hero {
            downloadUrl
          }
          gallery {
            items {
              downloadUrl
            }
          }
        }
        experience {
          id
          name
          campaign {
            id
            name
            logo {
              id
              downloadUrl
            }
            image {
              id
              downloadUrl
            }
            fundraisingGoal
            totalRaised
            vizslaOrganization {
              id
            }
          }
        }
      }
    }
  }
`;
/**
 * __useAttendeeFundraiserLandingQuery__
 *
 * To run a query within a React component, call `useAttendeeFundraiserLandingQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttendeeFundraiserLandingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttendeeFundraiserLandingQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAttendeeFundraiserLandingQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(AttendeeFundraiserLandingDocument, options);
}
export function useAttendeeFundraiserLandingLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(AttendeeFundraiserLandingDocument, options);
}
export const RegisterAttendeeDocument = gql `
  mutation RegisterAttendee($attendees: [RegisterAttendeeInfo]!, $transactionId: ID) {
    registerAttendee(attendees: $attendees, transactionId: $transactionId) {
      attendees {
        id
      }
    }
  }
`;
/**
 * __useRegisterAttendeeMutation__
 *
 * To run a mutation, you first call `useRegisterAttendeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterAttendeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerAttendeeMutation, { data, loading, error }] = useRegisterAttendeeMutation({
 *   variables: {
 *      attendees: // value for 'attendees'
 *      transactionId: // value for 'transactionId'
 *   },
 * });
 */
export function useRegisterAttendeeMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(RegisterAttendeeDocument, options);
}
export const ResolvePublicAttendeeRegistrationFormDocument = gql `
  mutation ResolvePublicAttendeeRegistrationForm(
    $attendees: [RegisterAttendeeInfo]!
    $donation: AddDonationInput
    $purchasedStoreItems: [StorePurchaseEventCreatePurchasedStoreItems]
  ) {
    resolvePublicAttendeeRegistrationForm(
      attendees: $attendees
      donation: $donation
      purchasedStoreItems: $purchasedStoreItems
    ) {
      success
    }
  }
`;
/**
 * __useResolvePublicAttendeeRegistrationFormMutation__
 *
 * To run a mutation, you first call `useResolvePublicAttendeeRegistrationFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResolvePublicAttendeeRegistrationFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resolvePublicAttendeeRegistrationFormMutation, { data, loading, error }] = useResolvePublicAttendeeRegistrationFormMutation({
 *   variables: {
 *      attendees: // value for 'attendees'
 *      donation: // value for 'donation'
 *      purchasedStoreItems: // value for 'purchasedStoreItems'
 *   },
 * });
 */
export function useResolvePublicAttendeeRegistrationFormMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(ResolvePublicAttendeeRegistrationFormDocument, options);
}
export const AttendeOrderDataListDocument = gql `
  query attendeOrderDataList(
    $registrationFilter: RegistrationOptionFulfillmentFilter
    $assetFilter: AssetFullfillmentFilter
    $attendeeId: ID
    $swagbagFilter: AssetSwagbagFulfillmentFilter
  ) {
    attendee(id: $attendeeId) {
      id
      fundraisingPageLink
      fundraiserGoal
      totalRaised
      waiverSignedAt
      waiverSignature
      assetSwagbag(filter: $swagbagFilter) {
        items {
          id
          status
          amount
          storeInventoryItem {
            id
            variantsValues
            countOfTotal
            itemToSellWhenOutOfStock
            storeAsset {
              name
              image {
                downloadUrl
              }
            }
          }
        }
        count
      }
      registrationOption {
        id
        name
        pricingSettings {
          pricingType
          pricingTiers {
            items {
              price
              taxDeductibleCurrencyAmount
              taxDeductiblePercentsAmount
              taxDeductibleType
            }
          }
        }
      }
      bibAssignment {
        bibNumber
      }
      experience {
        waiverEnabled
        waiverTextBody
      }
      checkIn
      assetFulfillment(filter: $assetFilter) {
        items {
          id
          amount
          storeInventoryItem {
            id
            name
            itemToSellWhenOutOfStock
            variantsValues
            storeAsset {
              name
              image {
                downloadUrl
              }
            }
          }
        }
      }
      fundraising {
        id
        raised
        goal
        webLink
      }
      user {
        id
        email
        avatar {
          id
          downloadUrl
          previewUrl
        }
        firstName
        lastName
        phone {
          number
        }
        gender
        birthDate
        address {
          city
          state
        }
        status
        createdAt
      }
      registrationOptionFulfillment(filter: $registrationFilter) {
        items {
          registrationOption {
            id
            name
            experience {
              id
            }
          }
          order {
            id
            number
            payment {
              status
              totalAmount
            }
            createdAt
            registrationOptionFulfillment {
              items {
                amount
                registrationOption {
                  id
                  name
                  experience {
                    name
                    id
                  }
                }
                attendee {
                  id
                  checkIn
                  user {
                    firstName
                    lastName
                    avatar {
                      id
                      downloadUrl
                      previewUrl
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
/**
 * __useAttendeOrderDataListQuery__
 *
 * To run a query within a React component, call `useAttendeOrderDataListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAttendeOrderDataListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAttendeOrderDataListQuery({
 *   variables: {
 *      registrationFilter: // value for 'registrationFilter'
 *      assetFilter: // value for 'assetFilter'
 *      attendeeId: // value for 'attendeeId'
 *      swagbagFilter: // value for 'swagbagFilter'
 *   },
 * });
 */
export function useAttendeOrderDataListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(AttendeOrderDataListDocument, options);
}
export function useAttendeOrderDataListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(AttendeOrderDataListDocument, options);
}
export const PublicClientDnsRecordBySlugDocument = gql `
  query PublicClientDnsRecordBySlug($slug: String!) {
    dnsRecord(slug: $slug) {
      ...PublicClientDnsRecordFragment
    }
  }
  ${PublicClientDnsRecordFragmentFragmentDoc}
`;
/**
 * __usePublicClientDnsRecordBySlugQuery__
 *
 * To run a query within a React component, call `usePublicClientDnsRecordBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicClientDnsRecordBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicClientDnsRecordBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function usePublicClientDnsRecordBySlugQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(PublicClientDnsRecordBySlugDocument, options);
}
export function usePublicClientDnsRecordBySlugLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(PublicClientDnsRecordBySlugDocument, options);
}
export const AddDonationDocument = gql `
  mutation AddDonation($input: AddDonationInput, $paymentInfo: AddDonationPaymentInfo!) {
    addDonation(input: $input, paymentInfo: $paymentInfo) {
      id
    }
  }
`;
/**
 * __useAddDonationMutation__
 *
 * To run a mutation, you first call `useAddDonationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDonationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDonationMutation, { data, loading, error }] = useAddDonationMutation({
 *   variables: {
 *      input: // value for 'input'
 *      paymentInfo: // value for 'paymentInfo'
 *   },
 * });
 */
export function useAddDonationMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(AddDonationDocument, options);
}
export const ExperienceLandingDocument = gql `
  query ExperienceLanding($id: ID!) {
    experience(id: $id) {
      ...ExperienceLandingFragment
    }
  }
  ${ExperienceLandingFragmentFragmentDoc}
`;
/**
 * __useExperienceLandingQuery__
 *
 * To run a query within a React component, call `useExperienceLandingQuery` and pass it any options that fit your needs.
 * When your component renders, `useExperienceLandingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExperienceLandingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExperienceLandingQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ExperienceLandingDocument, options);
}
export function useExperienceLandingLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ExperienceLandingDocument, options);
}
export const ExperienceRegistrationQueryDocument = gql `
  query ExperienceRegistrationQuery($id: ID!) {
    experience(id: $id) {
      id
      name
      waiverEnabled
      waiverAllowMultipleAttendeeConsent
      waiverTextBody
      isStoreEnabled
    }
  }
`;
/**
 * __useExperienceRegistrationQueryQuery__
 *
 * To run a query within a React component, call `useExperienceRegistrationQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useExperienceRegistrationQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExperienceRegistrationQueryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExperienceRegistrationQueryQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ExperienceRegistrationQueryDocument, options);
}
export function useExperienceRegistrationQueryLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ExperienceRegistrationQueryDocument, options);
}
export const ExperienceAttendeesDocument = gql `
  query ExperienceAttendees($id: ID!) {
    attendees: attendeesList(filter: { experience: { id: { equals: $id } } }) {
      count
      items {
        id
        user {
          id
          email
        }
      }
    }
  }
`;
/**
 * __useExperienceAttendeesQuery__
 *
 * To run a query within a React component, call `useExperienceAttendeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useExperienceAttendeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExperienceAttendeesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExperienceAttendeesQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ExperienceAttendeesDocument, options);
}
export function useExperienceAttendeesLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ExperienceAttendeesDocument, options);
}
export const NewOrderShoppingCartDocument = gql `
  mutation NewOrderShoppingCart($data: OrderCreateInput!) {
    orderCreate(data: $data) {
      ...OrderFragment
    }
  }
  ${OrderFragmentFragmentDoc}
`;
/**
 * __useNewOrderShoppingCartMutation__
 *
 * To run a mutation, you first call `useNewOrderShoppingCartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewOrderShoppingCartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newOrderShoppingCartMutation, { data, loading, error }] = useNewOrderShoppingCartMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useNewOrderShoppingCartMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(NewOrderShoppingCartDocument, options);
}
export const NewOrderItemDocument = gql `
  mutation NewOrderItem($data: OrderItemCreateInput!) {
    orderItemCreate(data: $data) {
      id
      description
      category
      transactionType
      amount
      order {
        id
      }
    }
  }
`;
/**
 * __useNewOrderItemMutation__
 *
 * To run a mutation, you first call `useNewOrderItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewOrderItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newOrderItemMutation, { data, loading, error }] = useNewOrderItemMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useNewOrderItemMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(NewOrderItemDocument, options);
}
export const NewDiscountUsageDocument = gql `
  mutation NewDiscountUsage(
    $discount: DiscountCodeSettingKeyFilter!
    $order: OrderKeyFilter!
    $amount: Float!
  ) {
    shoppingCartRegisterDiscount(discount: $discount, order: $order, amount: $amount) {
      id
      description
      category
      transactionType
      amount
      order {
        id
      }
    }
  }
`;
/**
 * __useNewDiscountUsageMutation__
 *
 * To run a mutation, you first call `useNewDiscountUsageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewDiscountUsageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newDiscountUsageMutation, { data, loading, error }] = useNewDiscountUsageMutation({
 *   variables: {
 *      discount: // value for 'discount'
 *      order: // value for 'order'
 *      amount: // value for 'amount'
 *   },
 * });
 */
export function useNewDiscountUsageMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(NewDiscountUsageDocument, options);
}
export const NewStatusOrderDocument = gql `
  mutation NewStatusOrder($data: OrderHistoryCreateInput!) {
    orderHistoryCreate(data: $data) {
      id
      status
      reason
      order {
        id
      }
    }
  }
`;
/**
 * __useNewStatusOrderMutation__
 *
 * To run a mutation, you first call `useNewStatusOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewStatusOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newStatusOrderMutation, { data, loading, error }] = useNewStatusOrderMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useNewStatusOrderMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(NewStatusOrderDocument, options);
}
export const NewRegistratioOptionFullfimentDocument = gql `
  mutation NewRegistratioOptionFullfiment($data: RegistrationOptionFulfillmentCreateInput!) {
    registrationOptionFulfillmentCreate(data: $data) {
      id
      status
      amount
      attendee {
        id
      }
      order {
        id
      }
    }
  }
`;
/**
 * __useNewRegistratioOptionFullfimentMutation__
 *
 * To run a mutation, you first call `useNewRegistratioOptionFullfimentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewRegistratioOptionFullfimentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newRegistratioOptionFullfimentMutation, { data, loading, error }] = useNewRegistratioOptionFullfimentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useNewRegistratioOptionFullfimentMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(NewRegistratioOptionFullfimentDocument, options);
}
export const NewAssetFullfimentDocument = gql `
  mutation NewAssetFullfiment($data: AssetFullfillmentCreateInput!) {
    assetFullfillmentCreate(data: $data) {
      id
      status
      amount
      attendee {
        id
      }
      order {
        id
      }
    }
  }
`;
/**
 * __useNewAssetFullfimentMutation__
 *
 * To run a mutation, you first call `useNewAssetFullfimentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewAssetFullfimentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newAssetFullfimentMutation, { data, loading, error }] = useNewAssetFullfimentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useNewAssetFullfimentMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(NewAssetFullfimentDocument, options);
}
export const NewSwagbagFullfimentDocument = gql `
  mutation NewSwagbagFullfiment($data: AssetSwagbagFulfillmentCreateInput!) {
    assetSwagbagFulfillmentCreate(data: $data) {
      id
      status
      amount
      attendee {
        id
      }
      order {
        id
      }
    }
  }
`;
/**
 * __useNewSwagbagFullfimentMutation__
 *
 * To run a mutation, you first call `useNewSwagbagFullfimentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewSwagbagFullfimentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newSwagbagFullfimentMutation, { data, loading, error }] = useNewSwagbagFullfimentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useNewSwagbagFullfimentMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(NewSwagbagFullfimentDocument, options);
}
export const NewPaymentOrderDocument = gql `
  mutation NewPaymentOrder($data: PaymentCreateInput!) {
    paymentCreate(data: $data) {
      id
      status
      gateway
      method
      category
      reasonStatus
      totalAmount
      order {
        id
      }
    }
  }
`;
/**
 * __useNewPaymentOrderMutation__
 *
 * To run a mutation, you first call `useNewPaymentOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewPaymentOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newPaymentOrderMutation, { data, loading, error }] = useNewPaymentOrderMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useNewPaymentOrderMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(NewPaymentOrderDocument, options);
}
export const NewDonationOrderDocument = gql `
  mutation NewDonationOrder($data: ExperienceDonationCreateInput!) {
    experienceDonationCreate(data: $data) {
      id
      amount
    }
  }
`;
/**
 * __useNewDonationOrderMutation__
 *
 * To run a mutation, you first call `useNewDonationOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewDonationOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newDonationOrderMutation, { data, loading, error }] = useNewDonationOrderMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useNewDonationOrderMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(NewDonationOrderDocument, options);
}
export const NewDonationOrderIndividualDocument = gql `
  mutation NewDonationOrderIndividual($data: IndividualFundraisingDonationCreateInput!) {
    individualFundraisingDonationCreate(data: $data) {
      id
      amount
    }
  }
`;
/**
 * __useNewDonationOrderIndividualMutation__
 *
 * To run a mutation, you first call `useNewDonationOrderIndividualMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewDonationOrderIndividualMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newDonationOrderIndividualMutation, { data, loading, error }] = useNewDonationOrderIndividualMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useNewDonationOrderIndividualMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(NewDonationOrderIndividualDocument, options);
}
export const RegistrationOptionsDocument = gql `
  query RegistrationOptions($ids: [ID!]) {
    registrationOptionsList(filter: { id: { in: $ids } }) {
      items {
        id
        name
        ageRequirement
      }
    }
  }
`;
/**
 * __useRegistrationOptionsQuery__
 *
 * To run a query within a React component, call `useRegistrationOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegistrationOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegistrationOptionsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useRegistrationOptionsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(RegistrationOptionsDocument, options);
}
export function useRegistrationOptionsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(RegistrationOptionsDocument, options);
}
export const PublicClientStoreAssetsByExperienceIdDocument = gql `
  query PublicClientStoreAssetsByExperienceId($experienceId: ID!) {
    experienceStoreAssetsList(filter: { experience: { id: { equals: $experienceId } } }) {
      items {
        ...PublicClientExperienceStoreAssetFragment
      }
    }
  }
  ${PublicClientExperienceStoreAssetFragmentFragmentDoc}
`;
/**
 * __usePublicClientStoreAssetsByExperienceIdQuery__
 *
 * To run a query within a React component, call `usePublicClientStoreAssetsByExperienceIdQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicClientStoreAssetsByExperienceIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicClientStoreAssetsByExperienceIdQuery({
 *   variables: {
 *      experienceId: // value for 'experienceId'
 *   },
 * });
 */
export function usePublicClientStoreAssetsByExperienceIdQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(PublicClientStoreAssetsByExperienceIdDocument, options);
}
export function usePublicClientStoreAssetsByExperienceIdLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(PublicClientStoreAssetsByExperienceIdDocument, options);
}
export const TmpPublicClientStorePurchaseEventCreateDocument = gql `
  mutation TmpPublicClientStorePurchaseEventCreate($data: StorePurchaseEventCreateInput!) {
    storePurchaseEventCreate(input: $data) {
      success
    }
  }
`;
/**
 * __useTmpPublicClientStorePurchaseEventCreateMutation__
 *
 * To run a mutation, you first call `useTmpPublicClientStorePurchaseEventCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTmpPublicClientStorePurchaseEventCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tmpPublicClientStorePurchaseEventCreateMutation, { data, loading, error }] = useTmpPublicClientStorePurchaseEventCreateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useTmpPublicClientStorePurchaseEventCreateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(TmpPublicClientStorePurchaseEventCreateDocument, options);
}
export const GetAssetByExperiencesListDocument = gql `
  query getAssetByExperiencesList($filter: GetAssetByExperienceFilter!) {
    getAssetByExperiencesList(filter: $filter) {
      items {
        available
        continueSalesWhenOutOfStock
        id
        assetId
        inventoryId
        name
        picture
        price
        storeAvailability
        total
        itemToSellWhenOutOfStock
        variantsValues
      }
    }
  }
`;
/**
 * __useGetAssetByExperiencesListQuery__
 *
 * To run a query within a React component, call `useGetAssetByExperiencesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetByExperiencesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetByExperiencesListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetAssetByExperiencesListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetAssetByExperiencesListDocument, options);
}
export function useGetAssetByExperiencesListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetAssetByExperiencesListDocument, options);
}
export const TeamFundraisingLandingDocument = gql `
  query TeamFundraisingLanding($filter: TeamFundraisingFilter) {
    teamFundraisingsList(filter: $filter) {
      items {
        id
        name
        createdAt
        avatar {
          id
          downloadUrl
        }
        gallery {
          items {
            downloadUrl
          }
        }
        teamMissionTitle
        teamMissionDescription
        teamPhoto {
          downloadUrl
        }
        teamCaptain: members(filter: { role: { equals: "Team Captain" } }) {
          items {
            id
            role
            attendee {
              id
              fundraiserGoal
              totalRaised
              user {
                id
                firstName
                lastName
                avatar {
                  id
                  downloadUrl
                }
              }
            }
          }
        }
        members {
          count
          items {
            id
            role
            attendee {
              id
              user {
                id
                firstName
                lastName
                avatar {
                  id
                  downloadUrl
                }
              }
              registrationOption {
                id
                name
              }
            }
          }
        }
        fundraiserGoal
        totalRaised
        experience {
          id
        }
        donations {
          items {
            id
          }
        }
        campaign {
          id
          name
          logo {
            id
            downloadUrl
          }
          image {
            id
            downloadUrl
          }
          fundraisingGoal
          totalRaised
        }
      }
    }
  }
`;
/**
 * __useTeamFundraisingLandingQuery__
 *
 * To run a query within a React component, call `useTeamFundraisingLandingQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamFundraisingLandingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamFundraisingLandingQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTeamFundraisingLandingQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(TeamFundraisingLandingDocument, options);
}
export function useTeamFundraisingLandingLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(TeamFundraisingLandingDocument, options);
}
export const TeamFundraisingDocument = gql `
  query TeamFundraising($filter: TeamFundraisingFilter!) {
    team: teamFundraisingsList(filter: $filter) {
      items {
        id
        name
        avatar {
          downloadUrl
        }
        experience {
          id
        }
      }
    }
  }
`;
/**
 * __useTeamFundraisingQuery__
 *
 * To run a query within a React component, call `useTeamFundraisingQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamFundraisingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamFundraisingQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTeamFundraisingQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(TeamFundraisingDocument, options);
}
export function useTeamFundraisingLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(TeamFundraisingDocument, options);
}
export const IndividualFundraisingDocument = gql `
  query individualFundraising($filter: IndividualFundraisingFilter!) {
    fundraisers: individualFundraisingsList(filter: $filter) {
      items {
        id
        title
        logo {
          downloadUrl
        }
        attendee {
          experience {
            id
          }
        }
      }
    }
  }
`;
/**
 * __useIndividualFundraisingQuery__
 *
 * To run a query within a React component, call `useIndividualFundraisingQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndividualFundraisingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndividualFundraisingQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useIndividualFundraisingQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(IndividualFundraisingDocument, options);
}
export function useIndividualFundraisingLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(IndividualFundraisingDocument, options);
}
export const PublicClientUserDocument = gql `
  query PublicClientUser {
    user {
      id
      email
      firstName
      lastName
      avatar {
        id
        downloadUrl
      }
      roles {
        items {
          id
          name
        }
      }
      phone {
        code
        number
      }
      vizslaOrganizationStaff {
        items {
          id
          roles
          vizslaOrganization {
            id
          }
        }
      }
    }
  }
`;
/**
 * __usePublicClientUserQuery__
 *
 * To run a query within a React component, call `usePublicClientUserQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicClientUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicClientUserQuery({
 *   variables: {
 *   },
 * });
 */
export function usePublicClientUserQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(PublicClientUserDocument, options);
}
export function usePublicClientUserLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(PublicClientUserDocument, options);
}
