export const PREDICATE_WRAPPERS = ['AND', 'OR'];
export function connect(connectData) {
    return {
        to(entityKey, entityId) {
            return Object.assign({ [entityKey]: {
                    connect: {
                        id: entityId,
                    },
                } }, connectData);
        },
    };
}
function buildFilterPredicate(field, predicate, value) {
    return {
        [field]: {
            [predicate]: value,
        },
    };
}
function wrapTo(to, data) {
    return {
        [to]: data,
    };
}
export const gql = {
    PREDICATE_WRAPPERS,
    wrapTo,
    connect,
    buildFilterPredicate,
    wrapToOR(predicates) {
        return wrapTo('OR', predicates);
    },
    wrapToAND(predicates) {
        return wrapTo('AND', predicates);
    },
    gte(field, value) {
        return buildFilterPredicate(field, 'gte', value);
    },
    lte(field, value) {
        return buildFilterPredicate(field, 'lte', value);
    },
    in(field, values) {
        return buildFilterPredicate(field, 'in', values);
    },
    contains(field, value) {
        return buildFilterPredicate(field, 'contains', value);
    },
    equals(field, value) {
        return buildFilterPredicate(field, 'contains', value);
    },
};
