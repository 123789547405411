import { Locale } from '@vizsla/constants';
export var FormatStyle;
(function (FormatStyle) {
    FormatStyle["Currency"] = "currency";
    FormatStyle["Percent"] = "percent";
})(FormatStyle || (FormatStyle = {}));
export function formatMoney(value = 0, minimumFractionDigits = 0) {
    const currency = 'USD';
    return Number(value).toLocaleString(Locale.US, {
        style: FormatStyle.Currency,
        currency,
        minimumFractionDigits,
    });
}
export function formatPercentage(value = 0, minimumFractionDigits = 0) {
    if (!Number.isFinite(value)) {
        return null;
    }
    const decimal = value / 100;
    return decimal.toLocaleString(Locale.US, {
        style: FormatStyle.Percent,
        minimumFractionDigits,
    });
}
export function calcPercentage(x = 0, y = 0) {
    if (!Number.isFinite(x) || !Number.isFinite(y)) {
        return 0;
    }
    if (y <= 0) {
        return 0;
    }
    return Math.round((x / y) * 100);
}
export function formatNumber(value = 0) {
    return value.toLocaleString(Locale.US);
}
