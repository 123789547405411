import React from 'react';
function usePopover() {
    const [el, setEl] = React.useState(null);
    const [state, setState] = React.useState(null);
    const openPopover = React.useCallback((event, stateEvent) => {
        setEl(event.currentTarget);
        if (stateEvent !== undefined) {
            setState(stateEvent);
        }
    }, [setEl]);
    const closePopover = React.useCallback(() => {
        setEl(null);
        setState(null);
    }, [setEl]);
    return { isOpen: Boolean(el), el, state, openPopover, closePopover };
}
export { usePopover };
