import React from 'react';
export var DateFilterType;
(function (DateFilterType) {
    DateFilterType["options"] = "OPTIONS";
    DateFilterType["custom"] = "CUSTOM";
})(DateFilterType || (DateFilterType = {}));
export var DateFilterRange;
(function (DateFilterRange) {
    DateFilterRange["thisWeek"] = "THIS_WEEK";
    DateFilterRange["thisYear"] = "THIS_YEAR";
    DateFilterRange["lastYear"] = "LAST_YEAR";
    DateFilterRange["last7Days"] = "LAST_7_DAYS";
    DateFilterRange["last30Days"] = "LAST_30_DAYS";
    DateFilterRange["last90Days"] = "LAST_90_DAYS";
    DateFilterRange["last180Days"] = "LAST_180_DAYS";
})(DateFilterRange || (DateFilterRange = {}));
export const DATE_FILTER_OPTIONS = [
    {
        label: 'This Week',
        value: DateFilterRange.thisWeek,
    },
    {
        label: 'Last 7 days',
        value: DateFilterRange.last7Days,
    },
    {
        label: 'Last 30 days',
        value: DateFilterRange.last30Days,
    },
    {
        label: 'Last 90 days',
        value: DateFilterRange.last90Days,
    },
    {
        label: 'Last 180 days',
        value: DateFilterRange.last180Days,
    },
    {
        label: 'This year',
        value: DateFilterRange.thisYear,
    },
    {
        label: 'Last year',
        value: DateFilterRange.lastYear,
    },
];
export const CustomFiltersContext = React.createContext({
    filters: [],
    filterValues: {},
    hasFilterValues: false,
    onSetFilterValue: () => { },
    onResetFilterValue: () => { },
    onResetFilterValues: () => { },
    getFilterKeyFromSettings: () => '',
});
