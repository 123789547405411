import React from 'react';
import { toast } from 'material-react-toastify';
const useNotification = () => {
    const success = React.useCallback((message, options) => toast.success(message, options), []);
    const error = React.useCallback((message, options) => toast.error(message, options), []);
    const warning = React.useCallback((message, options) => toast.warning(message, options), []);
    const info = React.useCallback((message, options) => toast.info(message, options), []);
    return {
        success,
        error,
        warning,
        info,
    };
};
export { useNotification };
