import { gql } from '@apollo/client';
export const USER_FRAGMENT = gql `
  fragment UserFragment on User {
    id
    email
    firstName
    lastName
    fullName
    status

    avatar {
      downloadUrl
    }
  }
`;
export const USERS_LIST_QUERY = gql `
  query UsersList($filter: UserFilter) {
    usersList(filter: $filter) {
      items {
        ...UserFragment
        avatar {
          id
          downloadUrl
        }
      }
    }
  }
  ${USER_FRAGMENT}
`;
export const USER_ATTENDEE_EXPERIENCE = gql `
  query userAttendeeExperience($userId: ID!, $experienceId: ID!) {
    attendeesList(
      filter: { user: { id: { equals: $userId } }, experience: { id: { equals: $experienceId } } }
    ) {
      items {
        id
        user {
          firstName
          lastName
        }
        experience {
          id
          name
        }
        teamMembership {
          id
          team {
            name
          }
        }
      }
    }
  }
`;
export const INDIVIDUAL_FUNDRAISING_BY_ATTENDEE = gql `
  query individualFundraisingByAttendee($filter: AttendeeFilter!) {
    attendeesList(filter: $filter) {
      items {
        fundraising {
          id
        }
        experience {
          id
          name
        }
      }
    }
  }
`;
export const CURRENT_USER_QUERY = gql `
  query User {
    user {
      id
      email
      firstName
      lastName
      currentApplication
      status
      attendees {
        count
        items {
          teamMembership {
            team {
              name
            }
          }
        }
      }
      avatar {
        id
        downloadUrl
      }
      roles {
        items {
          id
          name
        }
      }
      vizslaOrganizationStaff {
        items {
          id
          roles
          vizslaOrganization {
            id
          }
        }
      }
    }
  }
`;
export const USER_SIGNUP_MUTATION = gql `
  mutation UserSignup($user: UserCreateInput!) {
    userSignUpWithToken(user: $user) {
      id
      email
      firstName
      lastName
      avatar {
        id
        downloadUrl
      }
    }
  }
`;
export const CHANGE_USER_CURRENT_APPLICATION_MUTATION = gql `
  mutation ChangeUserCurrentApp($userId: ID!, $applicationId: String!) {
    userUpdate(filter: { id: $userId }, data: { currentApplication: $applicationId }) {
      id
      currentApplication
    }
  }
`;
export const CURRENT_ATTENDEE_QUERY = gql `
  query currentAttendee($attendeeid: ID) {
    attendee(id: $attendeeid) {
      user {
        id
        email
        firstName
        lastName
        gender
        createdAt
        phone {
          code
          number
        }
        birthDate
        address {
          city
          country
          state
          street1
          street2
          zip
        }
      }
    }
  }
`;
