import _ from 'lodash';
import { gql, subtractDate, getStartOfWeek, getStartOfYear, getEndOfWeek, getEndOfYear, getNowDateTime, getDaysIn, toDateTimeFromISO, isFunction, } from '@vizsla/utils';
import { DateFilterType, DateFilterRange, } from '@vizsla/providers';
const handleGqlWrapper = (key, queryVariables) => {
    const [wrapper] = Object.keys(queryVariables);
    if (gql.PREDICATE_WRAPPERS.includes(wrapper)) {
        return {
            key: wrapper,
            queryVariables: queryVariables[wrapper],
        };
    }
    return {
        key,
        queryVariables,
    };
};
const createGqlDateRange = (startDateField, endDateField) => {
    const currentDate = getNowDateTime();
    const convertToStartDay = (dateISO) => {
        return toDateTimeFromISO(dateISO).startOf('day');
    };
    const convertToEndDay = (dateISO) => {
        return toDateTimeFromISO(dateISO).endOf('day');
    };
    const createGqlDateRangeByThisWeek = () => {
        const startDateCurrentWeek = convertToStartDay(getStartOfWeek(currentDate));
        const endDateCurrentWeek = convertToEndDay(getEndOfWeek(currentDate));
        return gql.wrapToAND([
            gql.gte(startDateField, startDateCurrentWeek),
            gql.lte(endDateField, endDateCurrentWeek),
        ]);
    };
    const createGqlDateRangeByThisYear = () => {
        const startOfCurrentYearDate = convertToStartDay(getStartOfYear(currentDate));
        const endOfCurrentYearDate = convertToEndDay(getEndOfYear(currentDate));
        return gql.wrapToAND([
            gql.gte(startDateField, startOfCurrentYearDate),
            gql.lte(endDateField, endOfCurrentYearDate),
        ]);
    };
    const createGqlDateRangeByLastYear = () => {
        const daysCountInThisYear = getDaysIn(currentDate, 'year');
        const beforeDaysByYearDate = convertToStartDay(subtractDate(currentDate, 'days', daysCountInThisYear));
        const endDate = convertToEndDay(currentDate);
        return gql.wrapToAND([
            gql.gte(startDateField, beforeDaysByYearDate),
            gql.lte(endDateField, endDate),
        ]);
    };
    const createGqlDateRangeByDays = (daysCount) => {
        const beforeDaysByCountDate = convertToStartDay(subtractDate(currentDate, 'days', daysCount));
        const endDate = convertToEndDay(currentDate);
        return gql.wrapToAND([
            gql.gte(startDateField, beforeDaysByCountDate),
            gql.lte(endDateField, endDate),
        ]);
    };
    return {
        [DateFilterRange.lastYear]: createGqlDateRangeByLastYear(),
        [DateFilterRange.thisWeek]: createGqlDateRangeByThisWeek(),
        [DateFilterRange.thisYear]: createGqlDateRangeByThisYear(),
        [DateFilterRange.last7Days]: createGqlDateRangeByDays(7),
        [DateFilterRange.last30Days]: createGqlDateRangeByDays(30),
        [DateFilterRange.last90Days]: createGqlDateRangeByDays(90),
        [DateFilterRange.last180Days]: createGqlDateRangeByDays(180),
    };
};
export function createGqlRange(fromField, toField, fromValue, toValue) {
    const hasFromValue = !_.isNil(fromValue) && !_.isNil(fromField);
    const hasToValue = !_.isNil(toValue) && !_.isNil(toField);
    if (hasFromValue && hasToValue) {
        return gql.wrapToAND([
            gql.gte(fromField, fromValue),
            gql.lte(toField, toValue),
        ]);
    }
    if (hasFromValue && !hasToValue) {
        return {
            gte: fromValue,
        };
    }
    if (!hasFromValue && hasToValue) {
        return {
            lte: toValue,
        };
    }
    return {};
}
export function createGqlCustomDateRange(startDateField, endDateField, startDateValue, endDateValue) {
    var _a, _b;
    const startDateTime = (_a = toDateTimeFromISO(startDateValue)) === null || _a === void 0 ? void 0 : _a.startOf('day');
    const endDateTime = (_b = toDateTimeFromISO(endDateValue)) === null || _b === void 0 ? void 0 : _b.endOf('day');
    return createGqlRange(startDateField, endDateField, startDateTime, endDateTime);
}
export const buildCheckboxFilterQuery = (checkboxFilterValue, checkboxFilterKey, checkboxFilterSettings) => {
    const values = Object.keys(checkboxFilterValue).filter(valueKey => {
        return Boolean(checkboxFilterValue[valueKey]);
    });
    if (values.length === 0) {
        return {};
    }
    return {
        key: checkboxFilterKey,
        queryVariables: { in: values },
    };
};
export const buildDateFilterQuery = (dateFilterValue, dateFilterKey, dateFilterSettings) => {
    const [dateType] = _.keys(dateFilterValue);
    if (!dateType) {
        return {};
    }
    // When selected default date range options
    if (dateType === DateFilterType.options && (dateFilterSettings === null || dateFilterSettings === void 0 ? void 0 : dateFilterSettings.fromToFields.length)) {
        const [startDateField, endDateField] = dateFilterSettings.fromToFields;
        const dateFilterRange = dateFilterValue[dateType];
        const gqlDateRangeQuery = createGqlDateRange(startDateField, endDateField)[dateFilterRange];
        return handleGqlWrapper(dateFilterKey, gqlDateRangeQuery);
    }
    // When selected custom date range
    if (dateType === DateFilterType.custom && (dateFilterSettings === null || dateFilterSettings === void 0 ? void 0 : dateFilterSettings.fromToFields.length)) {
        const [startDateField, endDateField] = dateFilterSettings.fromToFields;
        const [from, to] = dateFilterValue[dateType];
        const startDateValue = from === null || from === void 0 ? void 0 : from[startDateField];
        const endDateValue = to === null || to === void 0 ? void 0 : to[endDateField];
        const gqlDateRangeQuery = createGqlCustomDateRange(startDateField, endDateField, startDateValue, endDateValue);
        return handleGqlWrapper(from ? startDateField : endDateField, gqlDateRangeQuery);
    }
    return {};
};
export const buildRangeCountFilterQuery = (filterValue, filterValueName, filterSettings) => {
    const { from: fromValueObject, to: toValueObject } = filterValue;
    const fromValue = fromValueObject === null || fromValueObject === void 0 ? void 0 : fromValueObject[filterValueName];
    const toValue = toValueObject === null || toValueObject === void 0 ? void 0 : toValueObject[filterValueName];
    if (isFunction(filterSettings === null || filterSettings === void 0 ? void 0 : filterSettings.customQuery)) {
        const { key, queryVariables } = filterSettings.customQuery(fromValue, toValue);
        return handleGqlWrapper(key, queryVariables);
    }
    const gqlRange = createGqlRange(filterValueName, filterValueName, fromValue, toValue);
    return handleGqlWrapper(filterValueName, gqlRange);
};
