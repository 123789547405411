import React from 'react';
import { Grid, Link, Typography } from '@mui/material';

import { useAppAuth } from '@vizsla/hooks';

export const EmptyOrganizationScreen = () => {
  const { logout } = useAppAuth();

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      direction="column"
      height="100%"
      spacing={2}
    >
      <Grid item>
        <Typography>No organization connected</Typography>
      </Grid>
      <Grid item>
        <Link onClick={logout} sx={{ cursor: 'pointer', textDecoration: 'none' }}>
          <Typography color="secondary">Log Out</Typography>
        </Link>
      </Grid>
    </Grid>
  );
};
