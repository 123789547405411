export function mutableList(list) {
    const getIndexById = (id) => {
        return list.findIndex(item => item.id === id);
    };
    const add = (item) => {
        list.push(item);
    };
    const addToStart = (item) => {
        list.unshift(item);
    };
    const removeById = (id) => {
        const index = getIndexById(id);
        if (index !== -1) {
            list.splice(index, 1);
        }
    };
    const removeByIndex = (index) => {
        if (index !== -1) {
            list.splice(index, 1);
        }
    };
    const removeLast = () => {
        list.pop();
    };
    const removeFirst = () => {
        list.shift();
    };
    const updateById = (id, updateItem) => {
        const index = getIndexById(id);
        if (index !== -1) {
            list[index] = updateItem;
        }
    };
    const map = predicate => {
        list = list.map(predicate);
    };
    const mapByIds = (ids, predicate) => {
        list = list.map((item, index) => {
            if (ids.includes(item.id)) {
                return predicate(item, index, list);
            }
            return item;
        });
    };
    const updateByIndex = (index, updateItem) => {
        list[index] = updateItem;
    };
    return {
        add,
        addToStart,
        map,
        mapByIds,
        removeById,
        removeLast,
        removeFirst,
        removeByIndex,
        updateById,
        updateByIndex,
    };
}
