var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback } from 'react';
import { useMarkInboxMessagesAsReadMutation } from '@vizsla/graphql';
import { useCurrentUserQuery } from '.';
export function useMarkInboxMessageAsReadMutation({ onCompleted, }) {
    const { userId, loading: currentUserIsLoading } = useCurrentUserQuery();
    const [markAsRead, { data, loading, error }] = useMarkInboxMessagesAsReadMutation();
    const mutation = useCallback((messagesIds) => __awaiter(this, void 0, void 0, function* () {
        if (!userId || messagesIds.length === 0)
            return;
        yield Promise.all(messagesIds.map(messageId => markAsRead({ variables: { messageId, userId } })));
        yield (onCompleted === null || onCompleted === void 0 ? void 0 : onCompleted());
    }), [markAsRead, onCompleted, userId]);
    return { mutation, data, loading: loading || currentUserIsLoading, error };
}
