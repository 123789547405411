import { TEMPLATE_PARSER_BRACKETS } from '@vizsla/constants';
export function templateParser(template, variables) {
    if (variables === undefined) {
        return template;
    }
    return template.replace(TEMPLATE_PARSER_BRACKETS, (_, key) => {
        const value = variables[key];
        return value !== undefined ? value : '';
    });
}
export function createTemplate(notificationMessages) {
    return (messageKey, options) => {
        const message = notificationMessages[messageKey];
        return templateParser(message, options);
    };
}
