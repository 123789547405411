export var ExperienceType;
(function (ExperienceType) {
    ExperienceType["registration"] = "Registration";
    ExperienceType["ticketing"] = "Ticketing";
})(ExperienceType || (ExperienceType = {}));
export var ExperiencePrivacy;
(function (ExperiencePrivacy) {
    ExperiencePrivacy["public"] = "Public";
    ExperiencePrivacy["whiteList"] = "White-List";
    ExperiencePrivacy["fundraiserQualifier"] = "Fundraiser Qualifier";
})(ExperiencePrivacy || (ExperiencePrivacy = {}));
export var TransactionFeesType;
(function (TransactionFeesType) {
    TransactionFeesType["organization"] = "Organization Pay Transaction Fee";
    TransactionFeesType["supporter"] = "Supporter Pays Transaction Fee";
    TransactionFeesType["organizationSupporter"] = "Organization & Supporter Split Transaction Fee";
})(TransactionFeesType || (TransactionFeesType = {}));
