export var Application;
(function (Application) {
    Application["Unknown"] = "UNKNOWN";
    Application["VizslaAdminClient"] = "VIZSLA_ADMIN_CLIENT";
    Application["OrganizationAdminClient"] = "ORG_ADMIN_CLIENT";
    Application["CheckInClient"] = "CHECKIN_CLIENT";
    Application["ConsumerClient"] = "CONSUMER_CLIENT";
})(Application || (Application = {}));
export var AppClient;
(function (AppClient) {
    AppClient["AdminClient"] = "AdminClient";
    AppClient["CheckInClient"] = "CheckInClient";
    AppClient["ConsumerClient"] = "ConsumerClient";
    AppClient["PublicClient"] = "PublicClient";
})(AppClient || (AppClient = {}));
