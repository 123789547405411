import { gql } from '@apollo/client';
import { FILE_URL_FRAGMENT } from '../file';
export const ORGANIZATION_LOGO_FRAGMENT = gql `
  fragment ORGANIZATION_LOGO_FRAGMENT on VizslaOrganization {
    logo {
      ...FileURLFragment
    }

    negativeLogo {
      ...FileURLFragment
    }
  }
`;
export const ORGANIZATION_LOGO_UPDATE = gql `
  ${FILE_URL_FRAGMENT}
  ${ORGANIZATION_LOGO_FRAGMENT}

  mutation OrganizationLogoUpdate(
    $data: VizslaOrganizationUpdateInput!
    $filter: VizslaOrganizationKeyFilter
  ) {
    vizslaOrganizationUpdate(data: $data, filter: $filter, destroyDetached: true) {
      ...ORGANIZATION_LOGO_FRAGMENT
    }
  }
`;
export const SOCIAL_MEDIA_CREATE = gql `
  mutation SocialMediaCreate($data: SocialMediaCreateInput!) {
    socialMediaCreate(data: $data) {
      id
    }
  }
`;
export const SOCIAL_MEDIA_QUERY = gql `
  query SocialMedia($filter: SocialMediaFilter, $skip: Int, $first: Int) {
    socialMedias: socialMediasList(filter: $filter, skip: $skip, first: $first) {
      items {
        id
        organization {
          id
          name
        }
        socialMedia
        uRL
      }
    }
  }
`;
export const SOCIAL_MEDIA_DELETE_MUTATION = gql `
  mutation SocialMediaDelete($id: ID!) {
    socialMediaDelete(filter: { id: $id }, force: true) {
      success
    }
  }
`;
