var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useMutation } from '@apollo/client';
import { EXPERIENCE_GALLERY_DETACH_IMAGES } from '@vizsla/graphql';
import { useExperienceId } from '../experiences';
export function useExperienceGalleryDetachImages() {
    const id = useExperienceId();
    const [execute, { loading, error }] = useMutation(EXPERIENCE_GALLERY_DETACH_IMAGES);
    function detach(files) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const payload = files.map(f => ({
                fileId: f.fileId,
            }));
            const result = yield execute({
                variables: {
                    filter: { id },
                    data: {
                        experienceGalleryImages: {
                            disconnect: payload,
                        },
                    },
                },
            });
            return (_a = result.data) === null || _a === void 0 ? void 0 : _a.experience;
        });
    }
    return {
        detach,
        loading,
        error,
    };
}
