var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { useApolloClient } from '@apollo/client';
import _ from 'lodash';
import { EXPERIENCE_LOGO_IMAGE_QUERY, useExperienceHeaderLogoUpdateMutation, useExperienceLogoImageQuery, EXPERIENCE_GALLERY_IMAGES_QUERY, } from '@vizsla/graphql';
const EXP_IMAGES_QUERIES = {
    experienceLogoImageQuery: EXPERIENCE_LOGO_IMAGE_QUERY,
};
export const useExperienceImages = (experienceId) => {
    var _a;
    const apolloClient = useApolloClient();
    // const { data: experienceHeaderImageData, loading: loadingHeaderImage } = useQuery(
    //   EXPERIENCE_HEADER_IMAGE,
    //   {
    //     skip: !experienceId,
    //     variables: {
    //       id: experienceId,
    //     },
    //   },
    // );
    const { data: experienceLogoImageData, loading: loadingLogoImage } = useExperienceLogoImageQuery({
        skip: !experienceId,
        variables: {
            id: experienceId,
        },
    });
    /** @deprecated */
    const experienceHeaderImages = [];
    const experienceLogoImage = ((_a = experienceLogoImageData === null || experienceLogoImageData === void 0 ? void 0 : experienceLogoImageData.experience) === null || _a === void 0 ? void 0 : _a.experienceLogoImage) || null;
    const hasExperienceLogoImage = !_.isNil(experienceLogoImage);
    const setExperienceImagesInCache = React.useCallback((newSettings, queryType) => {
        apolloClient.writeQuery({
            query: EXP_IMAGES_QUERIES[queryType],
            variables: {
                id: experienceId,
            },
            data: {
                experience: Object.assign(Object.assign({}, newSettings), { __typename: 'Experience' }),
            },
        });
    }, [apolloClient, experienceId]);
    const [experienceHeaderLogoUpdateMutation, { loading: updatingExperienceLogo }] = useExperienceHeaderLogoUpdateMutation();
    // Header Images Update
    /** @deprecated Changed by `VSL-1129`, use `useExperienceHeaderImage` instead. */
    const createExperienceHeaderImages = (experienceHeaderImages) => __awaiter(void 0, void 0, void 0, function* () { });
    /** @deprecated Changed by `VSL-1129`, use `useExperienceHeaderImage` instead. */
    const updateExperienceHeaderImage = (experienceHeaderImage, headerImageId) => __awaiter(void 0, void 0, void 0, function* () { });
    /** @deprecated Changed by `VSL-1129`, use `useExperienceHeaderImage` instead. */
    const deleteExperienceHeaderImage = (headerImageId) => __awaiter(void 0, void 0, void 0, function* () { });
    // Header Logo Update
    const createExperienceLogoImage = (experienceHeaderLogoImage) => __awaiter(void 0, void 0, void 0, function* () {
        var _b;
        const res = yield experienceHeaderLogoUpdateMutation({
            variables: {
                data: {
                    experienceLogoImage: {
                        create: experienceHeaderLogoImage,
                    },
                },
                filter: {
                    id: experienceId,
                },
            },
            refetchQueries: [{ query: EXPERIENCE_GALLERY_IMAGES_QUERY }],
            awaitRefetchQueries: true,
        });
        const newExperienceLogoImage = ((_b = res === null || res === void 0 ? void 0 : res.data) === null || _b === void 0 ? void 0 : _b.experience.experienceLogoImage) || {};
        setExperienceImagesInCache({
            experienceLogoImage: Object.assign(Object.assign({}, newExperienceLogoImage), { __typename: 'File' }),
        }, 'experienceLogoImageQuery');
    });
    const updateExperienceLogoImage = (experienceLogoImage) => __awaiter(void 0, void 0, void 0, function* () {
        var _c;
        const res = yield experienceHeaderLogoUpdateMutation({
            variables: {
                data: {
                    experienceLogoImage: {
                        update: experienceLogoImage,
                    },
                },
                filter: {
                    id: experienceId,
                },
            },
            refetchQueries: [{ query: EXPERIENCE_GALLERY_IMAGES_QUERY }],
            awaitRefetchQueries: true,
        });
        const newExperienceLogoImage = ((_c = res === null || res === void 0 ? void 0 : res.data) === null || _c === void 0 ? void 0 : _c.experience.experienceLogoImage) || {};
        setExperienceImagesInCache({
            experienceLogoImage: Object.assign(Object.assign({}, newExperienceLogoImage), { __typename: 'File' }),
        }, 'experienceLogoImageQuery');
    });
    const deleteExperienceLogoImage = (logoImageId) => __awaiter(void 0, void 0, void 0, function* () {
        yield experienceHeaderLogoUpdateMutation({
            variables: {
                data: {
                    experienceLogoImage: {
                        disconnect: {
                            id: logoImageId,
                        },
                    },
                },
                filter: {
                    id: experienceId,
                },
            },
        });
        setExperienceImagesInCache({
            experienceLogoImage: null,
        }, 'experienceLogoImageQuery');
    });
    return {
        experienceHeaderImages,
        experienceLogoImage,
        createExperienceHeaderImages,
        updateExperienceHeaderImage,
        deleteExperienceHeaderImage,
        createExperienceLogoImage,
        updateExperienceLogoImage,
        deleteExperienceLogoImage,
        loadingHeaderImages: false,
        loadingLogoImage,
        updatingHeaderImages: false,
        updatingExperienceLogo,
        hasExperienceLogoImage,
    };
};
