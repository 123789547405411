/** @deprecated Use {@link DonationFrecuency} instead. */
export var DonationType;
(function (DonationType) {
    DonationType["oneTime"] = "One-Time";
    DonationType["monthly"] = "Monthly";
    DonationType["quarterly"] = "Quarterly";
    DonationType["semiAnnually"] = "Semi-Annually";
    DonationType["annually"] = "Annually";
})(DonationType || (DonationType = {}));
export var DonationFrecuency;
(function (DonationFrecuency) {
    DonationFrecuency["ONE_TIME"] = "one-time";
    DonationFrecuency["MONTHLY"] = "monthly";
    DonationFrecuency["QUARTERLY"] = "quarterly";
    DonationFrecuency["SEMI_ANNUALLY"] = "semi-annually";
    DonationFrecuency["ANNUALLY"] = "annually";
})(DonationFrecuency || (DonationFrecuency = {}));
export var DonorType;
(function (DonorType) {
    DonorType["individual"] = "individual";
    DonorType["organization"] = "organization";
})(DonorType || (DonorType = {}));
