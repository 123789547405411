import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { ModalsContext } from './ModalsContext';
const ModalsProvider = ({ children }) => {
    const [state, setState] = React.useState({});
    const openModal = React.useCallback((id, args) => setState(prevState => (Object.assign(Object.assign({}, prevState), { [id]: {
            isOpen: true,
            args,
        } }))), []);
    const closeModal = React.useCallback((id) => setState(prevState => {
        const modalStateById = prevState[id];
        return Object.assign(Object.assign({}, prevState), { [id]: Object.assign(Object.assign({}, modalStateById), { isOpen: false }) });
    }), []);
    return (_jsx(ModalsContext.Provider, Object.assign({ value: { state, openModal, closeModal } }, { children: children })));
};
export { ModalsProvider };
