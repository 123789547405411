import { gql } from '@apollo/client';
export const FILE_URL_FRAGMENT = gql `
  fragment FileURLFragment on File {
    id
    fileId
    filename
    downloadUrl
    createdAt

    createdBy {
      id
      firstName
      lastName
    }
  }
`;
