export var DateFormatPatterns;
(function (DateFormatPatterns) {
    DateFormatPatterns["shortMonthDayYear"] = "MMM dd, yyyy";
    DateFormatPatterns["fullMonthDayYear"] = "MMMM dd, yyyy";
    DateFormatPatterns["fullMonthDay"] = "MMMM dd";
    DateFormatPatterns["smallDateWithLocalTime"] = "LLL dd \u2013 t";
    DateFormatPatterns["shortDateWithSlash"] = "MM/dd/yyyy";
    DateFormatPatterns["shortDateWithDash"] = "yyyy-MM-dd";
    DateFormatPatterns["fullDateStartsFromMonth"] = "LLL. dd, yyyy";
    DateFormatPatterns["fullDateWithTime"] = "MM/dd/yyyy hh:mm a";
    DateFormatPatterns["meridiemTimePadded"] = "hh:mm a";
})(DateFormatPatterns || (DateFormatPatterns = {}));
