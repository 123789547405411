import { red, pink, purple, deepPurple, indigo, blue, lightBlue, teal, green, lightGreen, lime, yellow, amber, orange, deepOrange, } from '@mui/material/colors';
export const USER_AVATAR_COLORS = [
    red.A700,
    pink.A700,
    purple.A700,
    deepPurple.A700,
    indigo.A700,
    blue.A700,
    lightBlue.A700,
    teal.A700,
    green.A700,
    lightGreen.A700,
    lime.A700,
    yellow.A700,
    orange.A700,
    deepOrange.A700,
    amber.A700,
];
