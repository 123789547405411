import { useMemo } from 'react';
import { useExperienceListQuery } from '@vizsla/graphql';
export function useExperiencesByOrganizationId(organizationId) {
    const { data: response, loading, error, } = useExperienceListQuery({
        skip: !organizationId,
        variables: {
            filter: {
                campaign: {
                    vizslaOrganization: { id: { equals: organizationId } },
                },
            },
        },
    });
    const data = useMemo(() => { var _a, _b; return ((_b = (_a = response === null || response === void 0 ? void 0 : response.experiencesList) === null || _a === void 0 ? void 0 : _a.items) !== null && _b !== void 0 ? _b : []); }, [response]);
    return { data, loading, error };
}
