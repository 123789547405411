var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useNewInboxMessageSubscription } from '@vizsla/graphql';
export function useInboxMessageAddedSubscription({ onNewMessage, }) {
    return useNewInboxMessageSubscription({
        onSubscriptionData: ({ subscriptionData: { data } }) => {
            var _a, _b;
            const _c = (_b = (_a = data === null || data === void 0 ? void 0 : data.InboxMessage) === null || _a === void 0 ? void 0 : _a.node) !== null && _b !== void 0 ? _b : {}, { inbox } = _c, message = __rest(_c, ["inbox"]);
            if (!inbox || !message)
                return;
            onNewMessage(inbox, message);
        },
    });
}
