import { gql } from '@apollo/client';
export const VIZSLA_ORGANIZATION_QUERY = gql `
  query VizslaOrganization($id: ID!) {
    vizslaOrganization(id: $id) {
      id
      name
      ein
      contactPhone {
        code
        number
      }
      contactAddress {
        country
        city
        state
        zip
        street1
        street2
      }
      contactEmail
      stripeIntegration {
        createdAt
        id
        status
        stripeAccountID
      }
      logo {
        id
        downloadUrl
      }
      negativeLogo {
        id
        downloadUrl
      }
      currency
      language
      preferredDateFormat
      timeZone
    }
  }
`;
