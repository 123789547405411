import { useMemo } from 'react';
import { useInboxMessagesQuery } from '@vizsla/graphql';
import { useInboxContext } from '../context';
export function useInboxMessages() {
    const { selectedInbox, selectedInboxIsDraft } = useInboxContext();
    const { inboxId, skipQuery } = useMemo(() => ({
        inboxId: selectedInboxIsDraft ? '' : selectedInbox === null || selectedInbox === void 0 ? void 0 : selectedInbox.id,
        skipQuery: selectedInboxIsDraft || !(selectedInbox === null || selectedInbox === void 0 ? void 0 : selectedInbox.id),
    }), [selectedInbox, selectedInboxIsDraft]);
    const { data: response, error, loading, refetch, } = useInboxMessagesQuery({
        skip: skipQuery,
        variables: { inboxId },
    });
    const data = useMemo(() => { var _a; return ((_a = response === null || response === void 0 ? void 0 : response.inboxMessages.items) !== null && _a !== void 0 ? _a : []); }, [response]);
    return { data, error, loading, refetch };
}
