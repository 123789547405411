export const STORE_ALL_VALUE = 'all';
export const ALL_STORE_SIZES = ['XS', 'S', 'M', 'L', 'XL', 'XXL'];
export const STORE_SIZES_SELECT_OPTIONS = ALL_STORE_SIZES.map(size => ({
    value: size,
    label: size,
}));
export const ALL_STORE_SIZE_OPTION = {
    value: STORE_ALL_VALUE,
    label: 'All Sizes',
};
export const ALL_STORE_SIZES_SELECT_OPTIONS = [
    ALL_STORE_SIZE_OPTION,
    ...STORE_SIZES_SELECT_OPTIONS,
];
export const ALL_STORE_STYLES = ['Male', 'Female', 'Unisex'];
export const STORE_STYLES_SELECT_OPTIONS = ALL_STORE_STYLES.map(style => ({
    value: style,
    label: style,
}));
export const ALL_STORE_STYLE_OPTION = {
    value: STORE_ALL_VALUE,
    label: 'All Styles',
};
export const ALL_STORE_STYLES_SELECT_OPTIONS = [
    ALL_STORE_STYLE_OPTION,
    ...STORE_STYLES_SELECT_OPTIONS,
];
