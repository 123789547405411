import React from 'react';
import _ from 'lodash';
import { removeEmptyPropertiesFromObject, gql } from '@vizsla/utils';
import { useCustomFilters } from '../useCustomFilters';
import { buildDateFilterQuery, buildCheckboxFilterQuery, buildRangeCountFilterQuery, } from './utils';
export function useCustomFiltersQueryVariables() {
    const { filters, filterValues, getFilterKeyFromSettings } = useCustomFilters();
    const queryBuilderByFilterType = (filterSettings) => {
        const { type: filterType } = filterSettings;
        const filterKey = getFilterKeyFromSettings(filterSettings);
        switch (filterType) {
            case 'checkbox': {
                const filterValue = filterValues[filterKey];
                if (!filterValue) {
                    return {};
                }
                return buildCheckboxFilterQuery(filterValue, filterKey, filterSettings);
            }
            case 'date': {
                const filterValue = filterValues[filterKey];
                if (!filterValue) {
                    return {};
                }
                return buildDateFilterQuery(filterValue, filterKey, filterSettings);
            }
            case 'range-count': {
                const filterValue = filterValues[filterKey];
                if (!filterValue) {
                    return {};
                }
                return buildRangeCountFilterQuery(filterValue, filterKey, filterSettings);
            }
        }
        return {};
    };
    const createQueryVariables = () => {
        const gqlQueryVariables = filters.reduce((queryVariablesCollector, filterSettings) => {
            const query = queryBuilderByFilterType(filterSettings);
            if (!_.isEmpty(query)) {
                const { key, queryVariables } = query;
                const HAS_GQL_WRAPPER_IN_QUERY_VARIABLES = gql.PREDICATE_WRAPPERS.includes(key) && queryVariablesCollector[key];
                if (HAS_GQL_WRAPPER_IN_QUERY_VARIABLES) {
                    return Object.assign(Object.assign({}, queryVariablesCollector), { 
                        // push to AND/OR query wrapper (queryVariablesCollector[key]) new array (queryVariables)
                        [key]: [...queryVariablesCollector[key], ...queryVariables] });
                }
                return Object.assign(Object.assign({}, queryVariablesCollector), { [key]: queryVariables });
            }
            return Object.assign({}, queryVariablesCollector);
        }, {});
        return removeEmptyPropertiesFromObject(gqlQueryVariables);
    };
    const queryVariables = React.useMemo(() => {
        if (_.isEmpty(filterValues)) {
            return {};
        }
        return createQueryVariables();
    }, [filters, filterValues]);
    return queryVariables;
}
