import React from 'react';
function useInterval(callback, delay) {
    const savedCallback = React.useRef();
    // Because useRef don't support lazy initial value
    React.useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);
    // Set up the interval.
    React.useEffect(() => {
        function tick() {
            if (savedCallback === null || savedCallback === void 0 ? void 0 : savedCallback.current) {
                savedCallback === null || savedCallback === void 0 ? void 0 : savedCallback.current();
            }
        }
        if (delay !== null) {
            const id = setInterval(tick, delay);
            return () => {
                clearInterval(id);
            };
        }
        return undefined;
    }, [delay]);
}
export { useInterval };
