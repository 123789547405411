import * as yup from 'yup';
import * as Yup from 'yup';
export const SignupSchema = yup.object({
    firstName: yup.string().nullable().required('First Name is required'),
    lastName: yup.string().nullable().required('Last Name is required'),
    email: yup.string().email('Invalid Email').required('Email is required'),
    password: yup.string().required('Password is required'),
    confirmPassword: yup
        .string()
        .oneOf([yup.ref('password'), null], 'Passwords must match')
        .required('Confirmation is required'),
});
export const LoginSchema = yup.object({
    email: yup.string().email('Invalid Email').required('Email is required'),
    password: yup.string().required('Password is required'),
});
export const ConfirmEmailSchema = yup.object({
    email: yup.string().email('Invalid Email').required('Email is required'),
    code: yup.string().required('Code is required'),
});
export const CompleteRegistrationSchema = yup.object({
    email: yup.string().email('Invalid Email').required('Email is required'),
    tempPassword: yup.string().required('Confirmation Code is required'),
    newPassword: yup.string().required('Password is required'),
    confirmNewPassword: yup
        .string()
        .oneOf([yup.ref('newPassword'), null], 'Passwords must match')
        .required('Confirmation is required'),
});
const VERIFICATION_CODE_LENGTH = 6;
export const ForgotPasswordValidationSchema = Yup.object({
    email: Yup.string().email('Invalid email').required('Required'),
});
export const ForgotPasswordCodeVerificationValidationSchema = Yup.object({
    email: Yup.string().email('Invalid email').required('Required'),
    code: Yup.string()
        .required('Required')
        .test('code', 'Invalid code', value => (value === null || value === void 0 ? void 0 : value.length) === VERIFICATION_CODE_LENGTH),
    password: Yup.string().required('Required'),
});
