import React from 'react';
import { json2csv, saveFile } from '@vizsla/utils';
import { useInbox } from './useInbox';
import { useCurrentUser } from '../user';
const mapRecipients = (inboxes, user) => {
    const recipients = inboxes.reduce((acc, inbox) => {
        var _a, _b;
        const recipients = (_b = (_a = inbox.recipients) === null || _a === void 0 ? void 0 : _a.items) !== null && _b !== void 0 ? _b : [];
        const recipientsWithoutUser = recipients.filter(recipient => recipient.id !== user.id);
        recipientsWithoutUser.forEach(recipient => {
            var _a;
            var _b;
            if (!recipient.id)
                return;
            (_a = acc[_b = recipient.id]) !== null && _a !== void 0 ? _a : (acc[_b] = {
                Email: recipient.email,
                Firstname: recipient.firstName,
                Lastname: recipient.lastName,
            });
        });
        return acc;
    }, {});
    return Object.values(recipients);
};
export function useDownloadRecipients() {
    const { inboxes } = useInbox();
    const { user } = useCurrentUser();
    return React.useCallback(() => {
        if (!(user === null || user === void 0 ? void 0 : user.id))
            return;
        const csv = json2csv(mapRecipients(inboxes, user));
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const filename = `recipients-${new Date().toISOString()}.csv`;
        saveFile(blob, filename);
    }, [inboxes, user]);
}
