var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { EXPERIENCE_GALLERY_IMAGES_QUERY } from '@vizsla/graphql';
import { useExperienceId } from '../experiences';
export function useExperienceGalleryList() {
    const id = useExperienceId();
    const { loading, error, data, refetch } = useQuery(EXPERIENCE_GALLERY_IMAGES_QUERY, {
        fetchPolicy: 'network-only',
        variables: {
            id,
        },
    });
    const images = useMemo(() => {
        var _a, _b;
        const result = (_b = (_a = data === null || data === void 0 ? void 0 : data.experience) === null || _a === void 0 ? void 0 : _a.experienceGalleryImages) === null || _b === void 0 ? void 0 : _b.items;
        return Array.isArray(result) ? result : [];
    }, [data]);
    function refresh() {
        return __awaiter(this, void 0, void 0, function* () {
            yield refetch({ id });
        });
    }
    return {
        data: images,
        loading,
        error,
        refresh,
    };
}
