var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useMemo } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useShoppingCart } from './useShoppingCart';
const REGISTER_ATTENDEES = gql `
  mutation ShoppingCartRegisterAttendees($data: [ShoppingCartRegisterAttendeesManyInput!]!) {
    attendees: shoppingCartRegisterAttendees(data: $data) {
      items {
        id
        user {
          id
        }
      }
    }
  }
`;
export function useShoppingCartRegistrations() {
    const { items, editMany } = useShoppingCart();
    const registrations = useMemo(() => {
        return items.filter(i => i.type === 'registration');
    }, [items]);
    const [executeRegisterAttendees] = useMutation(REGISTER_ATTENDEES);
    /**
     * Register all the entries into `Attendee` and finally updates the state if `cart` is set as param.
     * @param cart A filtered version of the shopping cart items, if not was passed the function will use the actual shopping cart state.
     */
    function registerAttendees(cart) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!Array.isArray(cart)) {
                cart = registrations;
            }
            if (cart.length === 0) {
                throw new TypeError(`The registration option array must have at least one item`);
            }
            const payload = [];
            for (const item of cart) {
                if (item.attendee) {
                    payload.push({
                        registrationOptionId: item.option.id,
                        waiverSignature: item.attendee.waiverSignature,
                        user: {
                            firstName: item.attendee.firstName,
                            lastName: item.attendee.lastName,
                            email: item.attendee.email,
                            birthDate: item.attendee.birthday,
                            address: {
                                street1: item.attendee.address,
                                city: item.attendee.city,
                                state: item.attendee.state,
                            },
                        },
                    });
                }
            }
            const response = yield executeRegisterAttendees({
                variables: {
                    data: payload,
                },
            });
            if (!response.data) {
                throw new Error('Failed Attendee registration');
            }
            editMany(cart);
            return response.data.attendees;
        });
    }
    return {
        items,
        registerAttendees,
    };
}
