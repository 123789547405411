var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect } from 'react';
import { useUsersListLazyQuery } from '@vizsla/graphql';
import { useInbox } from './useInbox';
export function useLoadInboxFromUrl(recipientsIds, onInboxLoaded) {
    const { createDraftInbox, inboxesLoading } = useInbox();
    const [fetchUsers] = useUsersListLazyQuery();
    useEffect(() => {
        const loadInbox = () => __awaiter(this, void 0, void 0, function* () {
            const users = yield fetchUsers({
                variables: { filter: { id: { in: recipientsIds } } },
            }).then(res => { var _a, _b; return (_b = (_a = res.data) === null || _a === void 0 ? void 0 : _a.usersList.items) !== null && _b !== void 0 ? _b : []; });
            createDraftInbox(users);
            onInboxLoaded === null || onInboxLoaded === void 0 ? void 0 : onInboxLoaded();
        });
        if (!inboxesLoading && recipientsIds && recipientsIds.length > 0) {
            loadInbox().catch(console.error);
        }
    }, [recipientsIds, fetchUsers, createDraftInbox, inboxesLoading, onInboxLoaded]);
}
