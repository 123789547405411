var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { LEAVE_TEAM_FUNDRAISING_MUTATION } from '@vizsla/graphql';
export function useLeaveTeamFundraising() {
    const [loading, setLoading] = useState(false);
    const [execute] = useMutation(LEAVE_TEAM_FUNDRAISING_MUTATION);
    function leaveTeam(options) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            if (!options.teamId) {
                throw new TypeError(`Team ID must be a string. Got ${typeof options.teamId}`);
            }
            if (!options.attendeeId) {
                throw new TypeError(`Attendee ID must be a string. Got ${typeof options.attendeeId}`);
            }
            setLoading(true);
            try {
                const response = yield execute({
                    variables: {
                        attendeeId: options.attendeeId,
                        teamId: options.teamId,
                    },
                });
                setLoading(false);
                return Boolean((_a = response.data) === null || _a === void 0 ? void 0 : _a.removal);
            }
            catch (err) {
                setLoading(false);
                throw err;
            }
        });
    }
    return {
        loading,
        leaveTeam,
    };
}
