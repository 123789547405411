import { useCurrentUser } from '..';
import { useOrganizationById } from '.';
export const useCurrentOrganization = () => {
    var _a, _b, _c;
    const { user, loading: userIsLoading } = useCurrentUser();
    // todo: implement different Organizations and different Roles support
    // right now the first organizationStaff record defines which organization user can look
    const organizationId = ((_c = (_b = (_a = user === null || user === void 0 ? void 0 : user.vizslaOrganizationStaff) === null || _a === void 0 ? void 0 : _a.items[0]) === null || _b === void 0 ? void 0 : _b.vizslaOrganization) === null || _c === void 0 ? void 0 : _c.id) || undefined;
    const { organization, organizationIsLoading } = useOrganizationById(organizationId);
    const isLoading = userIsLoading || organizationIsLoading;
    if (isLoading || !organizationId) {
        return {
            organization: null,
            organizationId: null,
            organizationIsLoading: isLoading,
        };
    }
    return {
        organizationId,
        organization,
        organizationIsLoading: isLoading,
    };
};
