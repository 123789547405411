export var CampaignQuestionUserGroup;
(function (CampaignQuestionUserGroup) {
    CampaignQuestionUserGroup["attendee"] = "Attendee";
    CampaignQuestionUserGroup["donor"] = "Donor";
    CampaignQuestionUserGroup["team"] = "Team";
})(CampaignQuestionUserGroup || (CampaignQuestionUserGroup = {}));
export var CampaignQuestionInputType;
(function (CampaignQuestionInputType) {
    CampaignQuestionInputType["text"] = "Text";
    CampaignQuestionInputType["date"] = "Date";
    CampaignQuestionInputType["checkbox"] = "Checkbox";
    CampaignQuestionInputType["radio"] = "Radio";
    CampaignQuestionInputType["select"] = "Select";
    CampaignQuestionInputType["switch"] = "Switch";
})(CampaignQuestionInputType || (CampaignQuestionInputType = {}));
export var CampaignQuestionFormat;
(function (CampaignQuestionFormat) {
    CampaignQuestionFormat["singleLine"] = "singleLine";
    CampaignQuestionFormat["multiLine"] = "multiLine";
    CampaignQuestionFormat["number"] = "number";
    CampaignQuestionFormat["money"] = "money";
    CampaignQuestionFormat["email"] = "email";
    CampaignQuestionFormat["phone"] = "phone";
    CampaignQuestionFormat["singleChoice"] = "singleChoice";
    CampaignQuestionFormat["multipleChoice"] = "multipleChoice";
    CampaignQuestionFormat["date"] = "date";
    CampaignQuestionFormat["dateTime"] = "dateTime";
})(CampaignQuestionFormat || (CampaignQuestionFormat = {}));
export var CampaignQuestionCategories;
(function (CampaignQuestionCategories) {
    CampaignQuestionCategories["Mandatory"] = "Mandatory";
    CampaignQuestionCategories["Personal"] = "Personal";
    CampaignQuestionCategories["Location"] = "Location";
    CampaignQuestionCategories["Company"] = "Company";
    CampaignQuestionCategories["Emergency"] = "Emergency";
    CampaignQuestionCategories["Other"] = "Other";
})(CampaignQuestionCategories || (CampaignQuestionCategories = {}));
export var CampaignQuestionType;
(function (CampaignQuestionType) {
    CampaignQuestionType["Default"] = "Default";
    CampaignQuestionType["Custom"] = "Custom";
})(CampaignQuestionType || (CampaignQuestionType = {}));
