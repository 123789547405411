import { useExperienceLandingQuery } from '@vizsla/graphql';
const useExperienceById = (id) => {
    const { data, loading } = useExperienceLandingQuery({
        variables: {
            id,
        },
        skip: !id,
    });
    return { experience: data === null || data === void 0 ? void 0 : data.experience, loading };
};
export { useExperienceById };
