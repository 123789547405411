import React from 'react';
import { Grid, Typography } from '@mui/material';
import _ from 'lodash';

import { useModal, useNotification } from '@vizsla/hooks';
import { Modal } from '@vizsla/components';

import { MODALS } from 'src/constants/modals';
import { t } from 'src/utils/template';

export const ConfirmationDeleteDialog: React.FC = () => {
  const { isOpen, closeModal, args } = useModal(MODALS.CONFIRMATION_DELETE_MODAL);
  const notification = useNotification();
  const [isLoading, setIsLoading] = React.useState(false);

  const onCancel = React.useCallback(() => {
    closeModal();
  }, [closeModal]);

  const onDelete = React.useCallback(async () => {
    if (_.isNil(args.deleteFunction)) {
      closeModal();
    }
    try {
      setIsLoading(true);
      await args.deleteFunction();
      notification.success(
        t('entity_delete_success', { objectName: args?.objectName ?? 'Object' }),
      );
      closeModal();
    } catch (error) {
      console.error(error);
      notification.error(t('entity_delete_error', { errorMessage: error?.message ?? '' }));
    } finally {
      setIsLoading(false);
    }
  }, [closeModal, args, notification]);

  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <Modal.Content>
        <Grid
          sx={{ padding: theme => theme.spacing(4, 0, 0, 0) }}
          container
          justifyContent="center"
        >
          <Typography variant="body1">
            <span>{`Are you sure you want to delete ${
              args?.objectType ? `the ${args?.objectType}` : ''
            }`}</span>
            <b>{` "${args?.objectName ?? 'this object'}" `}</b>?
          </Typography>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Modal.Action type="secondary" content="Cancel" onAction={onCancel} />
        <Modal.Action type="primary" content="Delete" onAction={onDelete} loading={isLoading} />
      </Modal.Actions>
    </Modal>
  );
};
