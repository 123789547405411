import styled from '@emotion/styled';
import { Typography } from '@mui/material';

import { Button as ButtonBase } from '@vizsla/components';

const SPACING_UNIT = 3;

/// Container

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${p => p.theme.spacing(SPACING_UNIT)};
`;

/// Header

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

/// HeaderTitle

export const HeaderTitle = styled(Typography)``;

HeaderTitle.defaultProps = {
  variant: 'h6',
};

/// Buttons

export const Buttons = styled.div`
  display: flex;
  justify-content: center;
  gap: ${p => p.theme.spacing(2)};
`;

/// Button

export const Button = styled(ButtonBase)`
  margin: 0;
`;
