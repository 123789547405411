export var DiscountCategoryTypes;
(function (DiscountCategoryTypes) {
    DiscountCategoryTypes["partner"] = "Partner";
    DiscountCategoryTypes["team"] = "Team";
    DiscountCategoryTypes["vip"] = "VIP";
    DiscountCategoryTypes["other"] = "Other";
})(DiscountCategoryTypes || (DiscountCategoryTypes = {}));
export var DiscountAmountTypes;
(function (DiscountAmountTypes) {
    DiscountAmountTypes["dollar"] = "dollar";
    DiscountAmountTypes["percentage"] = "percentage";
})(DiscountAmountTypes || (DiscountAmountTypes = {}));
export var DiscountDatePickerTypes;
(function (DiscountDatePickerTypes) {
    DiscountDatePickerTypes["singleDate"] = "singleDate";
    DiscountDatePickerTypes["dateRange"] = "dateRange";
})(DiscountDatePickerTypes || (DiscountDatePickerTypes = {}));
export var DiscountAllocationTypes;
(function (DiscountAllocationTypes) {
    DiscountAllocationTypes["byExperience"] = "byExperience";
    DiscountAllocationTypes["allExperiences"] = "allExperiences";
})(DiscountAllocationTypes || (DiscountAllocationTypes = {}));
export var DiscountStatusTypes;
(function (DiscountStatusTypes) {
    DiscountStatusTypes["live"] = "Live";
    DiscountStatusTypes["scheduled"] = "Scheduled";
    DiscountStatusTypes["completed"] = "Completed";
    DiscountStatusTypes["expired"] = "Expired";
})(DiscountStatusTypes || (DiscountStatusTypes = {}));
