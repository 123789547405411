import React from 'react';

import { useCheckinAttendeesQuery, AttendeeFilter } from '@vizsla/graphql';
import { useExperienceId, usePagination, usePaginationQueryVariables } from '@vizsla/hooks';

import { AttendeesTableDataContext } from './AttendeesTableDataContext';

const AttendeesTableDataProvider: React.FC = ({ children }) => {
  const experienceId = useExperienceId();

  const [searchValue, setSearchValue] = React.useState<string>();

  const pagination = usePagination();
  const paginationQueryVariables = usePaginationQueryVariables();

  const filters = React.useMemo((): AttendeeFilter | undefined => {
    if (!searchValue) {
      return undefined;
    }

    const numberSearchValue = Number(searchValue);

    if (isNaN(numberSearchValue)) {
      return {
        OR: [
          {
            user: {
              OR: [
                { firstName: { contains: searchValue } },
                { lastName: { contains: searchValue } },
              ],
            },
          },
          {
            teamMembership: {
              team: {
                name: { contains: searchValue },
              },
            },
          },
        ],
      };
    }

    return {
      bibAssignment: {
        bibNumber: { equals: numberSearchValue },
      },
    };
  }, [searchValue]);

  const { data, loading: isAttendeesLoading } = useCheckinAttendeesQuery({
    variables: {
      filter: {
        experience: {
          id: {
            equals: experienceId,
          },
        },
        ...filters,
      },
      ...paginationQueryVariables,
    },
  });

  const attendees = React.useMemo(() => {
    return data?.attendeesList.items || [];
  }, [data?.attendeesList.items]);

  React.useEffect(() => {
    pagination.onCountChange(data?.attendeesList.count || 0);
  }, [data?.attendeesList.count, pagination]);

  return (
    <AttendeesTableDataContext.Provider
      value={{ attendees, isAttendeesLoading, setSearchValue, pagination }}
    >
      {children}
    </AttendeesTableDataContext.Provider>
  );
};

export { AttendeesTableDataProvider };
