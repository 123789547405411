import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import _ from 'lodash';
import qs from 'qs';
const useQueryParams = (options = {}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = React.useMemo(() => (Object.assign(Object.assign({}, options.initialValues), qs.parse(location.search, { ignoreQueryPrefix: true }))), [location.search, options.initialValues]);
    React.useEffect(() => {
        if (_.isEmpty(params) && !_.isEmpty(options.initialValues) && !!options.initialValues) {
            navigate({
                search: qs.stringify(options.initialValues),
            });
        }
    }, [params, navigate, options.initialValues]);
    const setQueryParams = React.useCallback((value, { shouldReplace = false } = {}) => {
        navigate({
            search: qs.stringify(shouldReplace
                ? value
                : Object.assign(Object.assign({}, params), value), { arrayFormat: 'brackets' }),
        });
    }, [navigate, params]);
    return [params, { setQueryParams }];
};
export { useQueryParams };
