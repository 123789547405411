var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback } from 'react';
import { InboxMessagesDocument, useSendMessageMutation, } from '@vizsla/graphql';
import { useCurrentUserQuery } from '.';
import { InboxEvents, useEventBus } from '../inbox.events';
const wait = (ms) => new Promise(res => setTimeout(res, ms));
const delayRefetchedQuery = (observableQuery) => __awaiter(void 0, void 0, void 0, function* () {
    yield wait(500); // 3s to make it super obvious if working or not
    observableQuery.refetch();
});
export function useCreateMessageMutation() {
    const { publish } = useEventBus();
    const { userId, loading: currentUserIsLoading } = useCurrentUserQuery();
    const [createMessage, { loading, error }] = useSendMessageMutation();
    const mutation = useCallback((inbox, body) => __awaiter(this, void 0, void 0, function* () {
        var _a;
        const response = yield createMessage({
            variables: {
                data: {
                    body,
                    inbox: { connect: { id: inbox === null || inbox === void 0 ? void 0 : inbox.id } },
                    readBy: { connect: [{ id: userId }] },
                },
            },
            refetchQueries: [InboxMessagesDocument],
            onQueryUpdated: delayRefetchedQuery,
        });
        const message = (_a = response.data) === null || _a === void 0 ? void 0 : _a.inboxMessage;
        publish(InboxEvents.MessageSent, { message, inbox });
        return message;
    }), [createMessage, publish, userId]);
    return { mutation, loading: loading || currentUserIsLoading, error };
}
