import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { PaginationContext, DEFAULT_COUNT, DEFAULT_ROWS_PER_PAGE_OPTIONS, DEFAULT_PAGE, DEFAULT_ROWS_PER_PAGE, } from './PaginationContext';
const PaginationProvider = ({ children, paginationConfig }) => {
    const initialCount = (paginationConfig === null || paginationConfig === void 0 ? void 0 : paginationConfig.count) || DEFAULT_COUNT;
    const initialPage = (paginationConfig === null || paginationConfig === void 0 ? void 0 : paginationConfig.page) || DEFAULT_PAGE;
    const initialRowsPerPage = (paginationConfig === null || paginationConfig === void 0 ? void 0 : paginationConfig.rowsPerPage) || DEFAULT_ROWS_PER_PAGE;
    const rowsPerPageOptions = (paginationConfig === null || paginationConfig === void 0 ? void 0 : paginationConfig.rowsPerPageOptions) || DEFAULT_ROWS_PER_PAGE_OPTIONS;
    const [count, setCount] = React.useState(initialCount);
    const [page, setPage] = React.useState(initialPage);
    const [rowsPerPage, seRowsPerPage] = React.useState(initialRowsPerPage);
    const onPageChange = React.useCallback((event, page) => {
        setPage(page);
    }, [setPage]);
    const onRowsPerPageChange = React.useCallback((event) => {
        seRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }, [setPage, seRowsPerPage]);
    return (_jsx(PaginationContext.Provider, Object.assign({ value: {
            count,
            page,
            rowsPerPage,
            onPageChange,
            onRowsPerPageChange,
            rowsPerPageOptions,
            onCountChange: setCount,
        } }, { children: children })));
};
PaginationProvider.displayName = 'PaginationProvider';
function withPagination(componentProps) {
    return (Component, paginationConfig) => (_jsx(PaginationProvider, Object.assign({ paginationConfig: paginationConfig }, { children: _jsx(Component, Object.assign({}, componentProps)) })));
}
export { PaginationProvider, withPagination };
