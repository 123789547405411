import { gql } from '@apollo/client';
import { PUBLIC_CLIENT_CAMPAIGN_FRAGMENT } from './campaign';
export const DNS_RECORD_FRAGMENT = gql `
  fragment DnsRecordFragment on DnsRecord {
    id
    slug
    campaign {
      ...PublicClientCampaignFragment
    }
  }
  ${PUBLIC_CLIENT_CAMPAIGN_FRAGMENT}
`;
export const DNS_RECORD_BY_SLUG_QUERY = gql `
  query DnsRecordQueryBySlug($slug: String!) {
    dnsRecord(slug: $slug) {
      ...DnsRecordFragment
    }
  }
  ${DNS_RECORD_FRAGMENT}
`;
export const DNS_RECORD_CREATE_MUTATION = gql `
  mutation DnsRecordCreate($slug: String!, $campaignId: ID!) {
    dnsRecordCreate(data: { slug: $slug, campaign: { connect: { id: $campaignId } } }) {
      id
      slug
      campaign {
        id
        name
      }
    }
  }
`;
export const PUBLIC_CAMPAIGN_INFO_BY_SLUG_QUERY = gql `
  query PublicCampaignInfoBySlug($slug: String) {
    dnsRecord(slug: $slug) {
      id
      campaign {
        id
        name
        fundraisingGoal
        totalRaised
        campaignTemplates {
          id
          templates {
            items {
              id
              templateType {
                type
                title
              }
              structure
            }
          }
        }
        vizslaOrganization {
          id
          name
        }
        logo {
          id
          fileId
          filename
          downloadUrl
        }
        experiences {
          items {
            id
            name
            experienceType
            experienceCategory
            startDate
            endDate
            waiverAllowMultipleAttendeeConsent
            waiverTextBody
            experienceHeaderImage {
              id
              filename
              downloadUrl
              fileId
              public
            }
            experienceLogoImage {
              fileId
              filename
              id
              public
              downloadUrl
            }
            registrationOptions {
              items {
                id
                name
                pricingSettings {
                  id
                  pricingType
                  pricingTiers {
                    items {
                      id
                      price
                      startDate
                      endDate
                    }
                  }
                  pricingEnabled
                }
              }
            }
            venues {
              items {
                id
                name
                notes
                address
              }
            }
          }
        }
      }
    }
  }
`;
export const GET_PUBLIC_INFO_BY_SLUG_QUERY = gql `
  query GetPublicCampaignInfoBySlug($slug: String!) {
    getPublicCampaignInfoBySlug(slug: $slug) {
      organization
      campaign
      experiences
      campaignTemplates
    }
  }
`;
