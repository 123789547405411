var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback } from 'react';
import { useUnallocatedDonationResumeLazyQuery, } from '@vizsla/graphql';
/** Fetchs all the allocated donations metrics to someone. */
export function useUnallocatedDonationsResumeQueries() {
    const [executeQuery] = useUnallocatedDonationResumeLazyQuery();
    /** Fetchs a resume of unallocated donations for a fundraising team. */
    const byTeamFundraising = useCallback((teamId, options) => __awaiter(this, void 0, void 0, function* () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        const filters = (_a = options === null || options === void 0 ? void 0 : options.filters) !== null && _a !== void 0 ? _a : [];
        const response = yield executeQuery({
            variables: {
                filter: {
                    AND: [
                        ...filters,
                        {
                            teamFundraising: {
                                id: {
                                    equals: teamId,
                                },
                            },
                        },
                        {
                            individualFundraising: null,
                        },
                        {
                            childs: {
                                none: null,
                            },
                        },
                    ],
                },
            },
        });
        const metrics = (_d = (_c = (_b = response.data) === null || _b === void 0 ? void 0 : _b.metrics) === null || _c === void 0 ? void 0 : _c.groups) === null || _d === void 0 ? void 0 : _d[0];
        const donations = (_g = (_f = (_e = response.data) === null || _e === void 0 ? void 0 : _e.metrics) === null || _f === void 0 ? void 0 : _f.donations) !== null && _g !== void 0 ? _g : [];
        if (!metrics || !donations)
            return undefined;
        const resume = {
            count: (_h = metrics.count) !== null && _h !== void 0 ? _h : 0,
            total: (_j = metrics.total) !== null && _j !== void 0 ? _j : 0,
            donations,
        };
        return resume;
    }), []);
    return {
        byTeamFundraising,
    };
}
