import { gql } from '@apollo/client';
const EXPERIENCE_HEADER_IMAGE_FRAGMENT = gql `
  fragment ExperienceHeaderImageFragment on Experience {
    experienceHeaderImage {
      id
      filename
      downloadUrl
      fileId
      public
    }
  }
`;
const EXPERIENCE_HEADER_LOGO_FRAGMENT = gql `
  fragment ExperienceHeaderLogoFragment on Experience {
    experienceLogoImage {
      id
      filename
      downloadUrl
      fileId
      public
    }
  }
`;
export const EXPERIENCE_HEADER_IMAGE = gql `
  ${EXPERIENCE_HEADER_IMAGE_FRAGMENT}

  query ExperienceHeaderImage($id: ID!) {
    experience(id: $id) {
      ...ExperienceHeaderImageFragment
    }
  }
`;
export const EXPERIENCE_LOGO_IMAGE_QUERY = gql `
  ${EXPERIENCE_HEADER_LOGO_FRAGMENT}
  query ExperienceLogoImage($id: ID!) {
    experience(id: $id) {
      ...ExperienceHeaderLogoFragment
    }
  }
`;
export const EXPERIENCE_HEADER_IMAGE_UPDATE = gql `
  ${EXPERIENCE_HEADER_IMAGE_FRAGMENT}

  mutation ExperienceHeaderImagesUpdate(
    $data: ExperienceUpdateInput!
    $filter: ExperienceKeyFilter
  ) {
    experience: experienceUpdate(data: $data, filter: $filter, destroyDetached: true) {
      ...ExperienceHeaderImageFragment
    }
  }
`;
export const EXPERIENCE_HEADER_LOGO_UPDATE_MUTATION = gql `
  ${EXPERIENCE_HEADER_LOGO_FRAGMENT}
  mutation ExperienceHeaderLogoUpdate($data: ExperienceUpdateInput!, $filter: ExperienceKeyFilter) {
    experience: experienceUpdate(data: $data, filter: $filter, destroyDetached: true) {
      ...ExperienceHeaderLogoFragment
    }
  }
`;
/// Gallery
export const EXPERIENCE_GALLERY_IMAGES_FRAGMENT = gql `
  fragment ExperienceGalleryImagesFragment on Experience {
    experienceGalleryImages {
      items {
        id
        filename
        downloadUrl
        fileId
        public
      }
    }
  }
`;
export const EXPERIENCE_GALLERY_IMAGES_QUERY = gql `
  ${EXPERIENCE_GALLERY_IMAGES_FRAGMENT}

  query ExperienceGalleryImages($id: ID!) {
    experience: experience(id: $id) {
      ...ExperienceGalleryImagesFragment
    }
  }
`;
export const EXPERIENCE_GALLERY_ATTACH_IMAGES = gql `
  ${EXPERIENCE_GALLERY_IMAGES_FRAGMENT}

  mutation ExperienceGalleryAttachImages(
    $data: ExperienceUpdateInput!
    $filter: ExperienceKeyFilter!
  ) {
    experience: experienceUpdate(data: $data, filter: $filter) {
      ...ExperienceGalleryImagesFragment
    }
  }
`;
export const EXPERIENCE_GALLERY_DETACH_IMAGES = gql `
  ${EXPERIENCE_GALLERY_IMAGES_FRAGMENT}

  mutation ExperienceGalleryDetachImage(
    $data: ExperienceUpdateInput!
    $filter: ExperienceKeyFilter!
  ) {
    experience: experienceUpdate(data: $data, filter: $filter, destroyDetached: true) {
      ...ExperienceGalleryImagesFragment
    }
  }
`;
export const EXPERIENCE_FUNDRAISING_SETTINGS = gql `
  query FundraisingSettingsByExperience($filter: ExperienceFundraisingFilter) {
    experienceFundraisingsList(filter: $filter) {
      items {
        id
        generalSettings {
          id
          isEnabled
          isObligation
          isWithoutPenalty
          pageSetUp
          chargeTime
          time
          date
          chargeDate
          isFacebookEnabled
          isInstagramEnabled
        }
        donationSettings {
          id
          isEnabled
          allowAnonymousDonations
          allowSelfDonations
          allowDonorsToOptIn
          allowDonorsToDedicateDonations
          coveredFees
          percentageAmount
          feesEnabled
          feesMessage
          roundupEnabled
          roundupMessage
        }
        teamSettings {
          id
          isEnabled
          teamCapabilities
          joiningTeamEnabled
          allowTeamsMakeTheirTeamInvite
          allowAttendeeRequestAdmissionOntoTeam
          maximumTeams
          maximumTeamMembers
          teamSharingEnabled
          appealMessage
          thankYouMessage
        }
        individualSettings {
          id
          isEnabled
          appealMessage
          thankYouMessage
        }
      }
    }
  }
`;
