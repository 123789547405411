import { parseDomain, ParseResultType } from 'parse-domain';
function useSubdomain(subDomainLevel = 0) {
    const { hostname } = window.location;
    const parseResult = parseDomain(hostname);
    switch (parseResult.type) {
        // localhost
        case ParseResultType.Reserved: {
            const { labels } = parseResult;
            return labels[subDomainLevel];
        }
        // public-app.vizsla.com
        case ParseResultType.Listed: {
            const { subDomains } = parseResult;
            return subDomains[subDomainLevel];
        }
    }
    return undefined;
}
export { useSubdomain };
