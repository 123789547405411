import { gql, useQuery } from '@apollo/client';
import React from 'react';
const EXPERIENCE_LIST_QUERY = gql `
  query ExperienceList($organizationIDs: [ID!]!) {
    experiences: experiencesList(
      filter: { campaign: { vizslaOrganization: { id: { in: $organizationIDs } } } }
    ) {
      items {
        id
        name
      }
    }
  }
`;
export function useExperiencesListByOrganizationsIds(organizationIDs) {
    const { data: response, loading } = useQuery(EXPERIENCE_LIST_QUERY, {
        skip: organizationIDs.length === 0,
        variables: { organizationIDs },
    });
    const data = React.useMemo(() => { var _a, _b; return (_b = (_a = response === null || response === void 0 ? void 0 : response.experiences) === null || _a === void 0 ? void 0 : _a.items) !== null && _b !== void 0 ? _b : []; }, [response]);
    return { data, loading };
}
