import { useMemo } from 'react';
import { isBoolean, isFunction } from 'lodash';
import { gql } from '@vizsla/utils';
import { useSearch } from './useSearch';
function isMultiFieldSearchQuery(searchFields) {
    return Array.isArray(searchFields);
}
function isSearchCallback(customFilter) {
    return isFunction(customFilter);
}
export function useSearchQueryVariables(searchParam) {
    const { searchValue: text } = useSearch();
    return useMemo(() => {
        if (!text)
            return {};
        if (isBoolean(searchParam)) {
            return { _fullText: text };
        }
        if (isMultiFieldSearchQuery(searchParam)) {
            const fields = searchParam.map(f => gql.contains(f, text));
            return gql.wrapToOR(fields);
        }
        if (isSearchCallback(searchParam)) {
            return searchParam(text);
        }
        return gql.contains(searchParam, text);
    }, [text]);
}
