import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { INBOX_FILTER_OPTIONS } from '@vizsla/constants';
export const InboxFiltersContext = React.createContext(null);
export const InboxFiltersContextProvider = ({ userFilter, allowedFilterOptions = INBOX_FILTER_OPTIONS, children, }) => {
    const [search, setSearch] = React.useState('');
    const [filters, setFilters] = React.useState([]);
    return (_jsx(InboxFiltersContext.Provider, Object.assign({ value: {
            search,
            setSearch,
            filters,
            setFilters,
            userFilter,
            allowedFilterOptions,
        } }, { children: children })));
};
export const useInboxFilters = () => {
    const context = React.useContext(InboxFiltersContext);
    if (context === null) {
        throw new Error('useInboxFilters must be used within InboxFiltersProvider');
    }
    return context;
};
