import { PaletteColor, Shade } from './constants';
export const customPalette = {
    paper: {
        background: {
            default: PaletteColor.White,
            lightGray: Shade.Gray[25],
        },
        border: {
            dashed: Shade.Gray[400],
        },
    },
    typography: {
        color: {
            default: PaletteColor.DefaultText,
            link: PaletteColor.TealText,
        },
    },
    tableHead: {
        background: Shade.Gray[25],
        color: Shade.Gray[500],
    },
    tableRow: {
        background: {
            main: PaletteColor.White,
            hover: Shade.Teal[25],
            selected: Shade.Blue[25],
        },
        color: PaletteColor.DefaultText,
    },
    button: {
        primary: {
            background: {
                default: Shade.Blue[500],
                light: Shade.Blue[25],
                disabled: PaletteColor.PrimaryFaded,
            },
            color: {
                default: PaletteColor.ContrastText,
                light: Shade.Blue[500],
            },
        },
        secondary: {
            background: {
                default: Shade.Teal[600],
                light: Shade.Teal[50],
                disabled: PaletteColor.SecondaryFaded,
            },
            color: {
                default: PaletteColor.ContrastText,
                light: Shade.Teal[700],
            },
        },
        info: {
            background: {
                default: Shade.Gray[500],
                light: Shade.Gray[50],
                hover: PaletteColor.DefaultDark,
                hoverLight: Shade.Gray[25],
                disabled: Shade.Gray[200],
            },
            color: {
                default: PaletteColor.ContrastText,
                light: Shade.Gray[600],
            },
        },
        error: {
            background: {
                default: Shade.Red[800],
                light: Shade.Red[25],
                hover: Shade.Red[900],
                disabled: PaletteColor.ErrorFaded,
            },
            color: {
                default: PaletteColor.ContrastText,
                light: Shade.Red[800],
            },
        },
        disabled: {
            background: Shade.Gray[200],
            backgroundLight: Shade.Gray[25],
            color: PaletteColor.ContrastText,
            colorLight: Shade.Gray[300],
        },
    },
    tab: {
        background: {
            default: PaletteColor.White,
        },
        color: {
            default: PaletteColor.PrimaryTeal,
            selected: PaletteColor.BlackText,
            hover: Shade.Gray[700],
        },
        border: {
            default: Shade.Gray[200],
            selected: Shade.Gray[900],
        },
    },
    input: {
        background: {
            default: PaletteColor.White,
        },
        color: {
            default: PaletteColor.DefaultText,
            adornment: PaletteColor.PrimaryGray,
        },
        border: {
            default: Shade.Gray[200],
            focused: PaletteColor.PrimaryTeal,
        },
    },
    inputLabel: {
        background: {
            default: PaletteColor.White,
        },
        color: {
            default: PaletteColor.GrayText,
            focused: PaletteColor.PrimaryTeal,
            error: PaletteColor.ErrorMain,
        },
    },
    formHelperText: {
        color: {
            default: PaletteColor.GrayText,
            error: PaletteColor.ErrorMain,
        },
    },
    datePicker: {
        color: {
            icon: Shade.Gray[700],
        },
    },
    radio: {
        color: {
            default: Shade.Blue[500],
            secondary: Shade.Gray[400],
            disabled: Shade.Gray[200],
        },
    },
    checkbox: {
        color: {
            default: Shade.Blue[500],
            secondary: Shade.Gray[400],
            disabled: Shade.Gray[200],
            indeterminate: Shade.Gray[400],
        },
    },
    switch: {
        color: {
            on: {
                primary: PaletteColor.PrimaryTeal,
                secondary: Shade.Teal[100],
            },
            off: {
                primary: Shade.Gray[200],
                secondary: Shade.Gray[100],
            },
        },
        border: PaletteColor.White,
        shadow: {
            on: Shade.Teal[200],
            off: Shade.Gray[200],
        },
    },
    toggleGroup: {
        background: {
            default: Shade.Gray[50],
            selected: PaletteColor.White,
        },
        color: {
            default: Shade.Gray[400],
            selected: PaletteColor.BlackText,
        },
    },
    chip: {
        background: {
            light: Shade.Blue[25],
            dark: Shade.Blue[500],
            outlined: PaletteColor.White,
        },
        color: {
            light: Shade.Blue[500],
            dark: PaletteColor.White,
            outlined: PaletteColor.DefaultText,
        },
        border: Shade.Gray[100],
    },
    overlay: {
        background: 'rgba(93, 105, 117, 0.5)',
    },
    nav: {
        item: {
            background: {
                primary: Shade.Gray[700],
                active: Shade.Teal[600],
            },
            color: {
                default: Shade.Gray[200],
                active: PaletteColor.ContrastText,
            },
        },
        icon: {
            fill: {
                default: Shade.Teal[700],
                admin: Shade.Blue[200],
                active: PaletteColor.White,
                alert: PaletteColor.ErrorMain,
            },
        },
    },
    menuItem: {
        background: {
            default: PaletteColor.White,
            hover: PaletteColor.PrimaryTeal,
            selected: Shade.Teal[100],
        },
        color: {
            default: PaletteColor.DefaultText,
            hover: PaletteColor.ContrastText,
            disabled: Shade.Gray[200],
        },
    },
    tooltip: {
        background: {
            default: PaletteColor.PrimaryGray,
        },
    },
};
