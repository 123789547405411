import { CampaignStatus, CampaignType } from '@vizsla/types';
import { PaletteColor, Shade } from '@vizsla/theme';
export const CAMPAIGN_STATUS_LABELS = {
    [CampaignStatus.paused]: 'Pause Campaign',
    [CampaignStatus.live]: 'Live Campaign',
    [CampaignStatus.completed]: 'Complete Campaign',
    [CampaignStatus.draft]: 'Draft Campaign',
};
export const CAMPAIGN_STATUS_PALETTE = {
    [CampaignStatus.draft]: PaletteColor.InfoMain,
    [CampaignStatus.paused]: '#EF923A',
    [CampaignStatus.live]: PaletteColor.SuccessMain,
    [CampaignStatus.completed]: Shade.Gray[300],
};
export const CAMPAIGN_STATUS_OPTIONS = [
    {
        label: CAMPAIGN_STATUS_LABELS[CampaignStatus.paused],
        value: CampaignStatus.paused,
    },
    {
        label: CAMPAIGN_STATUS_LABELS[CampaignStatus.live],
        value: CampaignStatus.live,
    },
    {
        label: CAMPAIGN_STATUS_LABELS[CampaignStatus.completed],
        value: CampaignStatus.completed,
    },
    {
        label: CAMPAIGN_STATUS_LABELS[CampaignStatus.draft],
        value: CampaignStatus.draft,
    },
];
export const CAMPAIGN_TYPE_OPTIONS = [
    {
        label: 'Impact Giving',
        value: CampaignType.impactGiving,
    },
    {
        label: 'Personal Mission',
        value: CampaignType.personalMission,
    },
    {
        label: 'Registration And Ticketing',
        value: CampaignType.registrationAndTicketing,
    },
];
