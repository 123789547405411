export const COGNITO_ERROR_CODES = {
    UsernameExistsException: {
        field: 'email',
    },
    InvalidPasswordException: {
        field: 'password',
    },
    UnknownError: {
        field: 'email',
    },
};
