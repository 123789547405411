var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { useDnsRecordQueryBySlugLazyQuery, useDnsRecordCreateMutation } from '@vizsla/graphql';
import { generateSlug } from '@vizsla/utils';
const useDnsRecord = () => {
    const [dnsRecordQueryBySlugQuery, { loading }] = useDnsRecordQueryBySlugLazyQuery();
    const [dnsRecordCreateMutation, { loading: creating }] = useDnsRecordCreateMutation();
    const getDnsRecordBySlug = React.useCallback((slug) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const { data } = yield dnsRecordQueryBySlugQuery({ variables: { slug } });
            return (data === null || data === void 0 ? void 0 : data.dnsRecord) || null;
        }
        catch (error) {
            console.error({ error });
            throw error;
        }
    }), [dnsRecordQueryBySlugQuery]);
    const createDnsRecord = React.useCallback((campaignName, campaignId) => __awaiter(void 0, void 0, void 0, function* () {
        const slug = generateSlug(campaignName);
        try {
            yield dnsRecordCreateMutation({
                variables: {
                    slug,
                    campaignId,
                },
            });
        }
        catch (error) {
            console.error({ error });
            throw error;
        }
    }), [dnsRecordCreateMutation]);
    return {
        loading,
        creating,
        getDnsRecordBySlug,
        createDnsRecord,
    };
};
export { useDnsRecord };
