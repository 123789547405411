import { ActionButtonBackgroundType, HeroMediaType, PageBackgroundType, RegistrationsSectionDisplayStyleType, HeaderLogoAlignType, } from '@vizsla/constants';
import templateSchema from './templateSchema.json';
const INITIAL_COLOR_LIBRARY = [
    '#202B38',
    '#fff',
    '#E7E9EB',
    '#A0A7AF',
    '#EC3350',
    '#F64D73',
    '#BC42AA',
    '#6739B6',
    '#2A9AFF',
    '#6EC6EE',
    '#28C2D5',
    '#00D687',
    '#FFC109',
    '#F97309',
];
export const INITIAL_THEME = {
    headerBackground: '#fff',
    textLinksColor: '#fff',
    progressBarBegin: '#fff',
    progressBarEnd: '#00d687',
    footerBackground: '#fff',
    footerTextColor: '#fff',
    accentColor: '#202B38',
    headerLogoAlign: HeaderLogoAlignType.left,
    pageBackgroundFirstColor: '#fff',
    pageBackgroundSecondColor: '#fff',
    pageBackgroundType: PageBackgroundType.white,
    heroMediaType: HeroMediaType.single,
    optionCardTextColor: '#fff',
    optionCardBackgroundColor: '#fff',
    colorLibrary: INITIAL_COLOR_LIBRARY,
    isProgressBarRaisedAmountVisible: true,
    isProgressBarGoalAmountVisible: true,
    isOurStoryTitleVisible: true,
    isOurStoryDescriptionVisible: true,
    isLeaderboardVisible: true,
    isActivityVisible: true,
    registrationButtonLabel: 'Register Now',
    registrationButtonBackgrondType: ActionButtonBackgroundType.filled,
    registrationButtonIsVisible: true,
    donationButtonLabel: 'Make a Donation',
    donationButtonBackgrondType: ActionButtonBackgroundType.filled,
    donationButtonIsVisible: true,
    shareButtonLabel: 'Share',
    shareButtonBackgrondType: ActionButtonBackgroundType.filled,
    shareButtonIsVisible: true,
    registrationsSectionTitle: 'Make a difference',
    registrationsSectionDescription: 'You can help fundraise for the cause once you register, or just make a donation.',
    registrationsSectionDisplayStyle: RegistrationsSectionDisplayStyleType.cards,
    ourStorySectionTitle: 'Our Story',
    ourStoryDescription: 'Tell us your Story',
    gallerySectionTitle: 'Make a difference',
    gallerySectionDescription: '',
    gallerySectionImagesPerRows: 4,
    gallerySectionRows: 1,
    gallerySectionImages: [],
};
export const INITIAL_STATE = {
    theme: INITIAL_THEME,
    nodes: templateSchema.appSchema.nodes,
    templateTitle: 'Template Title',
    templateStructureId: '',
};
export var GeneralLandingActions;
(function (GeneralLandingActions) {
    GeneralLandingActions["set"] = "set";
})(GeneralLandingActions || (GeneralLandingActions = {}));
export var ExperienceLandingActions;
(function (ExperienceLandingActions) {
    ExperienceLandingActions["updateAccentColor"] = "updateAccentColor";
    ExperienceLandingActions["updateColorLibrary"] = "updateColorLibrary";
    ExperienceLandingActions["updateProgressBarColor"] = "updateProgressBarColor";
    ExperienceLandingActions["updatePageBackgroundType"] = "updatePageBackgroundType";
    ExperienceLandingActions["updatePageBackgroundFirstColor"] = "updatePageBackgroundFirstColor";
    ExperienceLandingActions["updatePageBackgroundSecondColor"] = "updatePageBackgroundSecondColor";
    ExperienceLandingActions["updateRegistrationButtonLabel"] = "updateRegistrationButtonLabel";
    ExperienceLandingActions["updateDonationButtonLabel"] = "updateDonationButtonLabel";
    ExperienceLandingActions["updateShareButtonLabel"] = "updateShareButtonLabel";
    ExperienceLandingActions["updateRegistrationButtonBackgrondType"] = "updateRegistrationButtonBackgrondType";
    ExperienceLandingActions["updateDonationButtonBackgrondType"] = "updateDonationButtonBackgrondType";
    ExperienceLandingActions["updateShareButtonBackgrondType"] = "updateShareButtonBackgrondType";
    ExperienceLandingActions["updateIsRegistrationButtonVisible"] = "updateIsRegistrationButtonVisible";
    ExperienceLandingActions["updateIsDonationButtonVisible"] = "updateIsDonationButtonVisible";
    ExperienceLandingActions["updateIsShareButtonVisible"] = "updateIsShareButtonVisible";
    ExperienceLandingActions["updateIsProgressBarRaisedAmountVisible"] = "updateIsProgressBarRaisedAmountVisible";
    ExperienceLandingActions["updateIsProgressBarGoalAmountVisible"] = "updateIsProgressBarGoalAmountVisible";
    ExperienceLandingActions["updateisOurStoryTitleVisible"] = "updateisOurStoryTitleVisible";
    ExperienceLandingActions["updateIsLeaderboardVisible"] = "updateisLeaderboardVisible";
    ExperienceLandingActions["updateIsActivityVisible"] = "updateIsActivityVisible";
    ExperienceLandingActions["updateisOurStoryDescriptionVisible"] = "updateisOurStoryDescriptionVisible";
    ExperienceLandingActions["updateHeroMediaType"] = "updateHeroMediaType";
    ExperienceLandingActions["updateHeaderLogoAlign"] = "updateHeaderLogoAlign";
    ExperienceLandingActions["updateRegistrationsSectionTitle"] = "updateRegistrationsSectionTitle";
    ExperienceLandingActions["updateRegistrationsSectionDescription"] = "updateRegistrationsSectionDescription";
    ExperienceLandingActions["updateRegistrationsSectionDisplayStyle"] = "updateRegistrationsSectionDisplayStyle";
    ExperienceLandingActions["resetThemeColors"] = "resetThemeColors";
    ExperienceLandingActions["updateOurStorySectionTitle"] = "updateOurStorySectionTitle";
    ExperienceLandingActions["updateOurStorySectionDescription"] = "updateOurStorySectionDescription";
    // Gallery
    /** @deprecated The [VSL-212](https://8base-dev.atlassian.net/browse/VSL-212) was redesign by [comment](https://8base-dev.atlassian.net/browse/VSL-212?focusedCommentId=26224). */
    ExperienceLandingActions["UPDATE_GALLERY_TITLE"] = "UPDATE_LANDING_GALLERY_TITLE";
    /** @deprecated The [VSL-212](https://8base-dev.atlassian.net/browse/VSL-212) was redesign by [comment](https://8base-dev.atlassian.net/browse/VSL-212?focusedCommentId=26224). */
    ExperienceLandingActions["UPDATE_GALLERY_DESCRIPTION"] = "UPDATE_LANDING_GALLERY_DESCRIPTION";
    /** @deprecated The [VSL-212](https://8base-dev.atlassian.net/browse/VSL-212) was redesign by [comment](https://8base-dev.atlassian.net/browse/VSL-212?focusedCommentId=26224). */
    ExperienceLandingActions["UPDATE_GALLERY_ROWS"] = "UPDATE_LANDING_GALLERY_ROWS";
    /** @deprecated The [VSL-212](https://8base-dev.atlassian.net/browse/VSL-212) was redesign by [comment](https://8base-dev.atlassian.net/browse/VSL-212?focusedCommentId=26224). */
    ExperienceLandingActions["UPDATE_GALLERY_IMAGES_PER_ROWS"] = "UPDATE_LANDING_GALLERY_IMAGES_PER_ROWS";
    /** @deprecated The [VSL-212](https://8base-dev.atlassian.net/browse/VSL-212) was redesign by [comment](https://8base-dev.atlassian.net/browse/VSL-212?focusedCommentId=26224). */
    ExperienceLandingActions["UPDATE_GALLERY_IMAGES"] = "UPDATE_LANDING_GALLERY_IMAGES";
})(ExperienceLandingActions || (ExperienceLandingActions = {}));
