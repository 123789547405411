var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import _ from 'lodash';
import { Settings, DateTime } from 'luxon';
import { DateFormatPatterns, Locale } from '@vizsla/constants';
Settings.defaultLocale = Locale.US;
export function getStringifiedDateRange(start, end) {
    if (_.isNil(start)) {
        return '-';
    }
    const date1 = DateTime.fromISO(start);
    if (_.isNil(end)) {
        return date1.toFormat(DateFormatPatterns.fullMonthDayYear);
    }
    const date2 = DateTime.fromISO(end);
    if (date1.year === date2.year) {
        const formattedStartDate = date1.toFormat(DateFormatPatterns.fullMonthDay);
        const formattedEndDate = date2.toFormat(DateFormatPatterns.fullMonthDayYear);
        return `${formattedStartDate} - ${formattedEndDate}`;
    }
    const formattedStartDate = date1.toFormat(DateFormatPatterns.fullMonthDayYear);
    const formattedEndDate = date2.toFormat(DateFormatPatterns.fullMonthDayYear);
    return `${formattedStartDate} - ${formattedEndDate}`;
}
export function concatDateWithTime(date, time) {
    if (_.isNil(date)) {
        return null;
    }
    if (_.isNil(time)) {
        return date;
    }
    const startDateObject = DateTime.fromISO(date).toObject();
    const startTimeObject = DateTime.fromISO(time).toObject();
    return DateTime.fromObject(Object.assign(Object.assign({}, startDateObject), { hour: startTimeObject.hour, minute: startTimeObject.minute })).toISO();
}
export function concatDateWithTimeInObj(obj) {
    const { startDate, startTime, endTime, endDate } = obj, rest = __rest(obj, ["startDate", "startTime", "endTime", "endDate"]);
    return Object.assign(Object.assign({}, rest), { startDate: concatDateWithTime(startDate, startTime), endDate: concatDateWithTime(endDate, endTime) });
}
export function formatDate(date, toFormatPattern, fromFormatPattern) {
    if (_.isNil(date)) {
        return null;
    }
    if (_.isNil(fromFormatPattern)) {
        return DateTime.fromISO(date).toFormat(toFormatPattern);
    }
    return DateTime.fromFormat(date, fromFormatPattern).toFormat(toFormatPattern);
}
export function formatDateIntl(date, options) {
    if (!date)
        return null;
    return DateTime.fromISO(date).toLocaleString(options);
}
export function getNowDate() {
    return DateTime.local().toISODate();
}
export function getNowDateTime() {
    return DateTime.local().toISO();
}
export function toDateTimeFromISO(dateISO) {
    if (_.isNil(dateISO)) {
        return null;
    }
    return DateTime.fromISO(dateISO);
}
export function getEpochFromDate(dateISO) {
    if (_.isNil(dateISO)) {
        return null;
    }
    return DateTime.fromISO(dateISO).toMillis();
}
export function isValidDate(date) {
    if (_.isNil(date)) {
        return false;
    }
    return DateTime.fromISO(date).isValid;
}
export function trimTime(date) {
    if (_.isNil(date)) {
        return null;
    }
    return DateTime.fromISO(date).toISODate();
}
export function trimDate(date) {
    if (_.isNil(date)) {
        return null;
    }
    return DateTime.fromISO(date).toISOTime();
}
export function isDateEquals(d1, d2, unit = 'day') {
    if (_.isNil(d1) || _.isNil(d2)) {
        return false;
    }
    const dateA = DateTime.fromISO(d1).startOf(unit);
    const dateB = DateTime.fromISO(d2).startOf(unit);
    return dateA.equals(dateB);
}
export function isDateGreaterThan(d1, d2, unit = 'day') {
    if (_.isNil(d1) || _.isNil(d2)) {
        return false;
    }
    const dateA = DateTime.fromISO(d1).startOf(unit);
    const dateB = DateTime.fromISO(d2).startOf(unit);
    return dateA > dateB;
}
export function isDateGreaterOrEquals(dateA, dateB, unit = 'day') {
    if (_.isNil(dateA) || _.isNil(dateB)) {
        return false;
    }
    return DateTime.fromISO(dateA).startOf(unit) >= DateTime.fromISO(dateB).startOf(unit);
}
export function isDateLessOrEquals(dateA, dateB, unit = 'day') {
    if (_.isNil(dateA) || _.isNil(dateB)) {
        return false;
    }
    return DateTime.fromISO(dateA).startOf(unit) <= DateTime.fromISO(dateB).startOf(unit);
}
export function getStartOf(date, unit = 'month') {
    return DateTime.fromISO(date).startOf(unit).toISODate();
}
export function getEndOf(date, unit = 'month') {
    return DateTime.fromISO(date).endOf(unit).toISODate();
}
export function getStartOfMonth(date) {
    return getStartOf(date, 'month');
}
export function getStartOfWeek(date) {
    return getStartOf(date, 'week');
}
export function getStartOfYear(date) {
    return getStartOf(date, 'year');
}
export function getEndOfWeek(date) {
    return getEndOf(date, 'week');
}
export function getEndOfMonth(date) {
    return getEndOf(date, 'month');
}
export function getEndOfYear(date) {
    return getEndOf(date, 'year');
}
export function getDaysIn(dateISO, unit) {
    if (unit === 'month') {
        return DateTime.fromISO(dateISO).daysInMonth;
    }
    return DateTime.fromISO(dateISO).daysInYear;
}
export function getMaxDateTime(dateA, dateB) {
    if (_.isNil(dateA) && _.isNil(dateB)) {
        return null;
    }
    if (_.isNil(dateA)) {
        return dateB;
    }
    if (_.isNil(dateB)) {
        return dateA;
    }
    if (isDateGreaterOrEquals(dateA, dateB, 'minute')) {
        return dateA;
    }
    return dateB;
}
export function getMinDateTime(dateA, dateB) {
    if (_.isNil(dateA) && _.isNil(dateB)) {
        return null;
    }
    if (_.isNil(dateA)) {
        return dateB;
    }
    if (_.isNil(dateB)) {
        return dateA;
    }
    if (isDateLessOrEquals(dateA, dateB, 'minute')) {
        return dateA;
    }
    return dateB;
}
export function getPastDaysFromNow(isoDate) {
    if (_.isNil(isoDate)) {
        return 0;
    }
    const rawDays = DateTime.fromISO(isoDate).diffNow('days').negate().days;
    return Math.floor(rawDays);
}
export function addDate(date, unit = 'month', count = 1) {
    if (_.isNil(date)) {
        return null;
    }
    return DateTime.fromISO(date)
        .plus({ [unit]: count })
        .toISODate();
}
export function subtractDate(date, unit = 'month', count = 1) {
    if (_.isNil(date)) {
        return null;
    }
    return DateTime.fromISO(date)
        .minus({ [unit]: count })
        .toISODate();
}
export function formatRelativeTime(date) {
    if (!date)
        return null;
    const now = DateTime.local();
    const then = DateTime.fromISO(date);
    if (now.hasSame(then, 'day')) {
        const time = then.toLocaleString(DateTime.TIME_SIMPLE);
        return `Today, ${time}`;
    }
    // otherwise, format it as a date with time
    return then.toLocaleString(DateTime.DATETIME_MED);
}
