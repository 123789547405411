import React from 'react';
import { useDonationOptionsListQuery } from '@vizsla/graphql';
export function useDonationOptionsByExperienceIdQuery(experienceId) {
    const { data: response, loading, error, } = useDonationOptionsListQuery({
        skip: !experienceId,
        variables: {
            filter: { experience: { id: { equals: experienceId } } },
        },
    });
    const data = React.useMemo(() => { var _a, _b; return (_b = (_a = response === null || response === void 0 ? void 0 : response.donationOptionsList) === null || _a === void 0 ? void 0 : _a.items) !== null && _b !== void 0 ? _b : []; }, [response]);
    return {
        data,
        loading,
        error,
    };
}
