import React from 'react';
import { useApolloClient } from '@apollo/client';
import { isFunction, isString, get, isNull } from 'lodash';
import produce from 'immer';
export function useApolloCacheQueryManager({ query, typeName, variables, id, getResultData, defaultResultData, optimistic, }) {
    const apolloClient = useApolloClient();
    const queryResultData = apolloClient.readQuery({
        query,
        variables,
        id,
    }, optimistic);
    const getPreparedData = () => {
        if (isNull(queryResultData)) {
            return queryResultData;
        }
        if (!getResultData) {
            return queryResultData;
        }
        if (isFunction(getResultData)) {
            return getResultData(queryResultData);
        }
        if (isString(getResultData)) {
            return get(queryResultData, getResultData, defaultResultData);
        }
        return queryResultData;
    };
    const preparedQueryData = getPreparedData();
    const updateQuery = React.useCallback((updaterQueryFn, updateQuerySetting) => {
        var _a, _b;
        if (isNull(queryResultData)) {
            throw new Error(`Read error from cache by ${typeName}`);
        }
        const updatedData = produce(queryResultData, updaterQueryFn);
        const queryVariables = (_a = updateQuerySetting === null || updateQuerySetting === void 0 ? void 0 : updateQuerySetting.variables) !== null && _a !== void 0 ? _a : variables;
        const queryOptions = (_b = updateQuerySetting === null || updateQuerySetting === void 0 ? void 0 : updateQuerySetting.options) !== null && _b !== void 0 ? _b : {};
        apolloClient.writeQuery(Object.assign({ query, variables: queryVariables, data: Object.assign(Object.assign({}, updatedData), { __typename: typeName }) }, queryOptions));
    }, [queryResultData, variables, apolloClient, query, typeName]);
    return {
        cache: preparedQueryData,
        updateQuery,
    };
}
