import _ from 'lodash';
import { PricingType } from '@vizsla/types';
import { getNowDateTime, isDateGreaterOrEquals } from './date';
export const resolveCurrentPrice = (settings) => {
    var _a, _b, _c, _d;
    if (!(settings === null || settings === void 0 ? void 0 : settings.pricingEnabled)) {
        return 0;
    }
    const tiers = _.sortBy(_.get(settings, ['pricingTiers', 'items'], []), ['endDate']);
    switch (settings === null || settings === void 0 ? void 0 : settings.pricingType) {
        case PricingType.Fixed: {
            return (_b = (_a = tiers[0]) === null || _a === void 0 ? void 0 : _a.price) !== null && _b !== void 0 ? _b : 0;
        }
        case PricingType.Scaled: {
            return (_d = (_c = tiers.find(tier => isDateGreaterOrEquals(tier === null || tier === void 0 ? void 0 : tier.endDate, getNowDateTime()))) === null || _c === void 0 ? void 0 : _c.price) !== null && _d !== void 0 ? _d : 0;
        }
        default: {
            return 0;
        }
    }
};
