var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useDonationOptionsListQuery, useDonationOptionCreateMutation, useDonationOptionDeleteMutation, useDonationOptionUpdateMutation, DONATION_OPTIONS_LIST_QUERY, } from '@vizsla/graphql';
import { mutableList } from '@vizsla/utils';
import { useApolloCacheQueryManager } from '../apollo';
// TODO make field mandatory when experience donation page will transfer to public page
function useDonationOptions(experienceId) {
    var _a;
    const donationOptionsListQueryVariables = experienceId
        ? {
            variables: {
                filter: {
                    experience: {
                        id: {
                            equals: experienceId,
                        },
                    },
                },
            },
        }
        : {};
    const { data: donationOptionsListQuery, loading } = useDonationOptionsListQuery(Object.assign(Object.assign({}, donationOptionsListQueryVariables), { skip: !experienceId }));
    const { updateQuery: updateDonationOptionsListQuery } = useApolloCacheQueryManager(Object.assign({ query: DONATION_OPTIONS_LIST_QUERY, typeName: 'DonationOptionListResponse' }, donationOptionsListQueryVariables));
    const [donationOptionCreateMutation, { loading: creating }] = useDonationOptionCreateMutation();
    const [donationOptionUpdateMutation, { loading: updating }] = useDonationOptionUpdateMutation();
    const [donationOptionDeleteMutation, { loading: deleting }] = useDonationOptionDeleteMutation();
    const createDonationOption = (donationOption) => __awaiter(this, void 0, void 0, function* () {
        try {
            const { data } = yield donationOptionCreateMutation({
                variables: {
                    data: Object.assign(Object.assign({}, donationOption), { experience: {
                            connect: {
                                id: experienceId,
                            },
                        } }),
                },
            });
            const createdDonationOption = data === null || data === void 0 ? void 0 : data.donationOptionCreate;
            if (createdDonationOption) {
                updateDonationOptionsListQuery(query => {
                    mutableList(query.donationOptionsList.items).add(createdDonationOption);
                });
            }
        }
        catch (error) {
            console.error({ error });
            throw error;
        }
    });
    const updateDonationOption = (donationOption) => __awaiter(this, void 0, void 0, function* () {
        try {
            yield donationOptionUpdateMutation({
                variables: {
                    data: donationOption,
                },
            });
        }
        catch (error) {
            console.error({ error });
            throw error;
        }
    });
    const deleteDonationOption = (id) => __awaiter(this, void 0, void 0, function* () {
        try {
            yield donationOptionDeleteMutation({
                variables: {
                    id,
                },
            });
            updateDonationOptionsListQuery(query => {
                mutableList(query.donationOptionsList.items).removeById(id);
            });
        }
        catch (error) {
            console.error({ error });
            throw error;
        }
    });
    const donationOptions = ((_a = donationOptionsListQuery === null || donationOptionsListQuery === void 0 ? void 0 : donationOptionsListQuery.donationOptionsList) === null || _a === void 0 ? void 0 : _a.items) || [];
    return {
        donationOptions,
        createDonationOption,
        updateDonationOption,
        deleteDonationOption,
        loading,
        updating,
        creating,
        deleting,
    };
}
export { useDonationOptions };
