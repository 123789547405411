import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
export const ProviderComposer = ({ children, providers }) => {
    if (providers.length === 0) {
        return _jsx(React.Fragment, { children: children });
    }
    return (_jsx(React.Fragment, { children: providers.reduceRight((composedProviders, injectProvider) => {
            const { provider: Provider, providerProps = {}, conditionInject = true } = injectProvider;
            if (!conditionInject) {
                return composedProviders;
            }
            return _jsx(Provider, Object.assign({}, providerProps, { children: composedProviders }));
        }, children) }));
};
