import { isNil } from 'lodash';
import { DateTime } from 'luxon';
import { USER_AVATAR_COLORS } from '@vizsla/constants';
export const getUserFullName = (user) => {
    if (isNil(user) || !user.firstName)
        return 'Anonymous';
    if (!user.lastName)
        return user.firstName;
    return `${user.firstName} ${user.lastName}`;
};
export const getInitials = (user) => {
    if (!user) {
        return null;
    }
    const firstName = (user === null || user === void 0 ? void 0 : user.firstName) || '';
    const lastName = (user === null || user === void 0 ? void 0 : user.lastName) || '';
    const nameLetter = firstName[0] || '';
    const lastnameLetter = lastName[0] || '';
    return (nameLetter + lastnameLetter).toUpperCase();
};
export const getRandomUserAvatarColor = () => {
    const randomIndex = Math.floor(Math.random() * USER_AVATAR_COLORS.length);
    return USER_AVATAR_COLORS[randomIndex];
};
export const getUserAge = (user, dateUnit = 'year', valueIfNull = '-') => {
    const birhDate = user === null || user === void 0 ? void 0 : user.birthDate;
    if (isNil(birhDate)) {
        return valueIfNull;
    }
    return Math.round(DateTime.fromISO(birhDate).diffNow(dateUnit).years) * -1;
};
