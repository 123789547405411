import React from 'react';
import { isFunction } from '@vizsla/utils';
function useFormStepper(steps) {
    const [step, setStep] = React.useState(0);
    const isFirst = step === 0;
    const isLast = step === steps.length - 1;
    const prev = React.useCallback(() => {
        if (!isFirst) {
            setStep(currentStep => currentStep - 1);
        }
    }, [isFirst, setStep]);
    const next = React.useCallback(() => {
        if (!isLast) {
            setStep(currentStep => currentStep + 1);
        }
    }, [isLast, setStep]);
    const validate = React.useCallback((values, validationOptions) => {
        var _a, _b, _c;
        const canValidate = isFunction((_a = steps[step]) === null || _a === void 0 ? void 0 : _a.validate);
        if (canValidate) {
            return (_c = (_b = steps[step]) === null || _b === void 0 ? void 0 : _b.validate) === null || _c === void 0 ? void 0 : _c.call(_b, values, step, validationOptions);
        }
        return {};
    }, [steps, step]);
    const render = React.useCallback((payload) => {
        var _a, _b, _c;
        const canRender = isFunction((_a = steps[step]) === null || _a === void 0 ? void 0 : _a.render);
        if (canRender) {
            return (_c = (_b = steps[step]) === null || _b === void 0 ? void 0 : _b.render) === null || _c === void 0 ? void 0 : _c.call(_b, payload, step);
        }
        return null;
    }, [steps, step]);
    return { step, setStep, isFirst, isLast, prev, next, validate, render };
}
export { useFormStepper };
