export const STYLE_IMPORTANT = '!important';
export const PaletteColor = {
    White: '#FFFFFF',
    Black: '#000000',
    DefaultText: '#202B38',
    BlueText: '#1B3C84',
    TealText: '#009FB0',
    GrayText: '#5D6975',
    DarkGreyText: '#222B37',
    BlackText: '#000000',
    ContrastText: '#FFFFFF',
    DefaultMain: '#5D6975',
    DefaultLight: '#838F9B',
    DefaultDark: '#37434F',
    PrimaryMain: '#183779',
    PrimaryLight: '#3E5D9F',
    PrimaryDark: '#001153',
    PrimaryTeal: '#00BFD4',
    PrimaryGray: '#2A394A',
    PrimaryFaded: '#BDC6DB',
    SecondaryMain: '#009FB0',
    SecondaryLight: '#26C5D6',
    SecondaryDark: '#00798A',
    SecondaryFaded: '#BDD8DB',
    ErrorMain: '#E93E3E',
    ErrorLight: '#FF6464',
    ErrorDark: '#C31818',
    ErrorFaded: '#E6B3B3',
    WarningMain: '#E25137',
    WarningLight: '#FF775D',
    WarningDark: '#BC2B11',
    SuccessMain: '#07C27D',
    SuccessLight: '#2DE8A3',
    SuccessDark: '#009C57',
    SuccessFaded: '#08BE94',
    InfoMain: '#21A4FF',
    InfoLight: '#47CAFF',
    InfoDark: '#007ED9',
    ExtraProgress: '#B500B4',
    WarningProgress: '#EF923A',
};
export const Gradient = {
    LinearBlueTeal: 'linear-gradient(143deg, #1B3C84 0%, #028D98 92%)',
    LinearDarkGray: 'linear-gradient(180deg, #50677F 0%, #202B38 100%)',
};
export const Shade = {
    Blue: {
        25: '#EDF5FD',
        50: '#CBD9F1',
        100: '#8BA3D0',
        200: '#5A75AF',
        300: '#49649E',
        400: '#325092',
        500: '#1B3C84',
        600: '#183779',
        700: '#16316B',
        800: '#132B5E',
        900: '#112755',
    },
    Teal: {
        25: '#F0FBFD',
        50: '#D9F5F9',
        100: '#ADEBF1',
        200: '#82E0EA',
        300: '#57D5E3',
        400: '#2BCADB',
        500: '#00BFD4',
        600: '#009FB0',
        700: '#007E8C',
        800: '#005E68',
        900: '#003D44',
    },
    Gray: {
        25: '#F5F6F7',
        50: '#E7E9EB',
        100: '#D9DCDE',
        200: '#C0C4C9',
        300: '#A0A7AF',
        400: '#848C96',
        500: '#5D6975',
        600: '#445160',
        700: '#2A394A',
        800: '#253241',
        900: '#202B38',
    },
    Red: {
        25: '#FBE9E7',
        50: '#FFEBED',
        100: '#FECDD1',
        200: '#EE9998',
        300: '#E37270',
        400: '#ED524C',
        500: '#F14232',
        600: '#E23731',
        700: '#D02E2B',
        800: '#C32724',
        900: '#9D0100',
    },
};
export const FontWeight = {
    Regular: 400,
    Bold: 700,
    Black: 900,
};
export const FontSize = {
    S: '12px',
    Default: '14px',
    M: '16px',
    L: '18px',
    XL: '20px',
    XXL: '24px',
    XXXL: '28px',
    XXXXL: '40px',
};
export const Shadow = {
    None: 'none',
    Elevation1: '0 5px 15px 0 rgba(0,0,0,0.08)',
    Elevation2: '0 5px 15px 0 rgba(171,171,171,0.40)',
    Elevation3: '0 5px 15px 0 rgba(0,0,0,0.30)',
    Elevation4: '0 5px 15px 0 rgba(171,171,171,0.40)',
    Outline: '0 0 0 2px rgba(0,0,0,0.04), 0 5px 5px 0 rgba(0,0,0,0.04)',
    ToggleOn(color) {
        return `0 0 0 3px ${PaletteColor.White}, 0 0 5px 2px ${color}`;
    },
    ToggleOff: `0 0 0 3px ${PaletteColor.White}`,
};
export const TextAlign = {
    Left: 'left',
    Center: 'center',
    Right: 'right',
};
export const Display = {
    Block: 'block',
    Inline: 'inline',
    InlineBlock: 'inline-block',
    Flex: 'flex',
    FlexItem: 'flex-item',
    Grid: 'grid',
};
export const JustifyContent = {
    FlexStart: 'flex-start',
    Center: 'center',
    FlexEnd: 'flex-end',
    SpaceAround: 'space-around',
    SpaceBetween: 'space-between',
    SpaceEvenly: 'space-evenly',
    Stretch: 'stretch',
};
export const AlignItems = {
    FlexStart: 'flex-start',
    Center: 'center',
    FlexEnd: 'flex-end',
    Baseline: 'baseline',
    Stretch: 'stretch',
};
export const TextTransform = {
    None: 'none',
    UpperCase: 'uppercase',
    LowerCase: 'lowercase',
    Capitalize: 'capitalize',
};
export const LetterSpacing = {
    Default: '0px',
    Tight: '-0.2px',
};
export const Border = {
    None: 'none',
    Solid: 'solid',
    Dashed: 'dashed',
    Dotted: 'dotted',
};
export const BorderRadius = {
    S: '4px',
    M: '8px',
    L: '12px',
    XL: '16px',
};
export const Transition = {
    ButtonBoxShadow: 'box-shadow 0.2s ease-out',
};
