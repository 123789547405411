export var SystemRoles;
(function (SystemRoles) {
    SystemRoles["Administrator"] = "Administrator";
    SystemRoles["Guest"] = "Guest";
    SystemRoles["VizslaAdministrator"] = "VIZSLA Administrator";
    SystemRoles["OrganizationAdministrator"] = "Organization Administrator";
    SystemRoles["CampaignAdministrator"] = "Campaign Administrator";
    SystemRoles["Checkiner"] = "Checkiner";
    SystemRoles["Attendee"] = "Attendee";
})(SystemRoles || (SystemRoles = {}));
export var OrganizationRoles;
(function (OrganizationRoles) {
    OrganizationRoles["OrganizationAdministrator"] = "Organization Administrator";
    OrganizationRoles["FinancialAnalyst"] = "Financial Analyst";
    OrganizationRoles["CampaignAdministrator"] = "Campaign Administrator";
    OrganizationRoles["CampaignStaff"] = "Campaign Staff";
    OrganizationRoles["Checkiner"] = "Checkiner";
})(OrganizationRoles || (OrganizationRoles = {}));
