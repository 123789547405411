export var ThemeColorLabels;
(function (ThemeColorLabels) {
    ThemeColorLabels["accent"] = "Accent";
    ThemeColorLabels["progressBar"] = "Progress Bar";
})(ThemeColorLabels || (ThemeColorLabels = {}));
export var PageBackgroundType;
(function (PageBackgroundType) {
    PageBackgroundType["white"] = "white";
    PageBackgroundType["colorEffect"] = "colorEffect";
})(PageBackgroundType || (PageBackgroundType = {}));
export var ActionButtonBackgroundType;
(function (ActionButtonBackgroundType) {
    ActionButtonBackgroundType["filled"] = "filled";
    ActionButtonBackgroundType["outlined"] = "outlined";
})(ActionButtonBackgroundType || (ActionButtonBackgroundType = {}));
export var HeroMediaType;
(function (HeroMediaType) {
    HeroMediaType["single"] = "single";
    HeroMediaType["multiple"] = "multiple";
})(HeroMediaType || (HeroMediaType = {}));
export var RegistrationsSectionDisplayStyleType;
(function (RegistrationsSectionDisplayStyleType) {
    RegistrationsSectionDisplayStyleType["cards"] = "cards";
    RegistrationsSectionDisplayStyleType["list"] = "list";
})(RegistrationsSectionDisplayStyleType || (RegistrationsSectionDisplayStyleType = {}));
export var EditorTextInputFieldSizeType;
(function (EditorTextInputFieldSizeType) {
    EditorTextInputFieldSizeType["small"] = "small";
    EditorTextInputFieldSizeType["medium"] = "medium";
})(EditorTextInputFieldSizeType || (EditorTextInputFieldSizeType = {}));
export var HeaderLogoAlignType;
(function (HeaderLogoAlignType) {
    HeaderLogoAlignType["left"] = "left";
    HeaderLogoAlignType["center"] = "center";
})(HeaderLogoAlignType || (HeaderLogoAlignType = {}));
export const ACTION_BUTTON_BACKGROUND_TYPE_OPTIONS = [
    {
        value: 'filled',
        label: 'Filled',
    },
    {
        value: 'outlined',
        label: 'Outlined',
    },
];
export const EDITOR_TEST_TOTAL_RAISED = 50;
export const EDITOR_TEST_FUNDRAISING_GOAL = 100;
