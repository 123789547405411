import * as yup from 'yup';

export const DonationOptionsSchema = yup.object({
  donation: yup.object({
    donationOption: yup
      .object({
        id: yup.string().nullable().required(),
      })
      .nullable()
      .required('Donation Option Is required'),
  }),
});

export const DONATION_FORM_SCHEMA = yup.object({
  frecuency: yup.string().required(),
  amount: yup.number().min(1).required(),
  message: yup.string(),
  makeAnonymous: yup.boolean(),
  companyName: yup.string(),
});

export const EXPERIENCE_DONATION_INSIDE_SCHEMA = yup.object({
  donation: DONATION_FORM_SCHEMA,
});
