export function decodeHtml(html) {
    const container = document.createElement('div');
    container.innerHTML = html;
    return container.textContent || '';
}
export function encodedHtmlIsEmpty(html) {
    if (!html)
        return true;
    return decodeHtml(html).trim() === '';
}
