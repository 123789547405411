import create from 'zustand';
import { nanoid } from 'nanoid';
/**
 * Generates a instance of tools to create a reactive cart ready-to-use.
 * @example
 * ```tsx
 * // useCart.ts
 *
 * export const useCart = createCartStore()
 *
 * // CartResume.tsx
 *
 * function CartResume() {
 *  const { items, subtotal } = useCart()
 *
 *  const total = useMemo(() => subtotal(), [items])
 *
 *  return (
 *    <div>
 *      {items.map(item => (
 *        <div>
 *          <span>{item.name}</span>
 *          <span>${item.price}</span>
 *        </div>
 *      ))}
 *
 *      <span>Total: {total}</span>
 *    </div>
 *  )
 * }
 * ```
 */
export function createCartStore() {
    const INITIAL_STATE = {
        items: [],
    };
    return create((setState, getState) => (Object.assign(Object.assign({}, INITIAL_STATE), { add(item) {
            const id = nanoid();
            const data = Object.assign(Object.assign({}, item), { id });
            setState(prev => {
                return { items: [...prev.items, data] };
            });
            return data;
        },
        edit(item) {
            const state = getState();
            const actual = state.items.find(i => i.id === item.id);
            if (actual) {
                const data = Object.assign(Object.assign({}, actual), item);
                const items = state.items.map(prev => {
                    if (prev.id !== data.id)
                        return prev;
                    return data;
                });
                setState({ items });
                return data;
            }
            return undefined;
        },
        editMany(items) {
            const state = getState();
            const cart = [...state.items];
            /* eslint guard-for-in: "off" */
            for (const index in cart) {
                const current = cart[index];
                const newest = items.find(i => i.id === current.id);
                if (newest) {
                    cart[index] = Object.assign(Object.assign({}, current), newest);
                }
            }
            setState({ items: cart });
            return cart;
        },
        remove(item) {
            const state = getState();
            const actual = state.items.find(i => i.id === item.id);
            const items = state.items.filter(i => i.id !== item.id);
            setState({ items });
            return actual;
        },
        total() {
            const state = getState();
            return state.items.reduce((acc, item) => {
                acc += item.price;
                return acc;
            }, 0);
        },
        clean() {
            setState(Object.assign({}, INITIAL_STATE));
        } })));
}
