import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'material-react-toastify';

import { SwitchUserRoleModal } from '@vizsla/components';

import { ApplicationProvider } from 'src/providers';
import { ConfirmationDeleteDialog } from 'src/components/shared';

import { App } from './App';

import 'material-react-toastify/dist/ReactToastify.css';
import './index.css';

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <BrowserRouter>
    <ApplicationProvider>
      <App />
      <ToastContainer />
      <ConfirmationDeleteDialog />
      <SwitchUserRoleModal />
    </ApplicationProvider>
  </BrowserRouter>,
);
