import { CampaignQuestionCategories, CampaignQuestionInputType, CampaignQuestionUserGroup, CampaignQuestionFormat, } from '@vizsla/types';
export const CustomQuestionFormatLabels = {
    singleLine: 'Single-Line',
    multiLine: 'Multi-Line',
    number: 'Number',
    money: 'Money',
    email: 'Email',
    phone: 'Phone',
    singleChoice: 'Single Choice',
    multipleChoice: 'Multiple Choice',
    date: 'Date',
    dateTime: 'DateTime',
};
export const CAMPAIGN_QUESTION_INPUT_TYPE_OPTIONS = [
    CampaignQuestionInputType.text,
    CampaignQuestionInputType.date,
    CampaignQuestionInputType.checkbox,
    CampaignQuestionInputType.radio,
    CampaignQuestionInputType.select,
    CampaignQuestionInputType.switch,
].map((inputType) => ({ label: inputType, value: inputType }));
export const USER_GROUP_OPTIONS = [
    CampaignQuestionUserGroup.attendee,
    CampaignQuestionUserGroup.donor,
    CampaignQuestionUserGroup.team,
].map((userGroup) => ({ label: userGroup, value: userGroup }));
export const QUESTION_CATEGORIES_ORDER = [
    CampaignQuestionCategories.Personal,
    CampaignQuestionCategories.Location,
    CampaignQuestionCategories.Company,
    CampaignQuestionCategories.Emergency,
    CampaignQuestionCategories.Other,
];
export const QUESTION_TYPE_TO_DEFAULT_FORMAT = {
    [CampaignQuestionInputType.text]: CampaignQuestionFormat.singleLine,
    [CampaignQuestionInputType.date]: CampaignQuestionFormat.date,
    [CampaignQuestionInputType.switch]: CampaignQuestionFormat.singleChoice,
    [CampaignQuestionInputType.select]: CampaignQuestionFormat.singleChoice,
    [CampaignQuestionInputType.radio]: CampaignQuestionFormat.singleChoice,
    [CampaignQuestionInputType.checkbox]: CampaignQuestionFormat.singleChoice,
};
