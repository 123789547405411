import React from 'react';
import { isFunction, isString, JSONParseSafe } from '@vizsla/utils';
function useLocalStorage(key, initialValue = null) {
    const [state, setState] = React.useState(() => {
        const value = localStorage.getItem(key);
        return JSONParseSafe(value) || initialValue;
    });
    const setValue = React.useCallback((value) => {
        try {
            const resultValue = isFunction(value) ? value(state) : value;
            setState(resultValue);
            const strValue = isString(resultValue) ? resultValue : JSON.stringify(resultValue);
            window.localStorage.setItem(key, strValue);
        }
        catch (error) {
            console.error(error);
        }
    }, [key, state]);
    return [state, setValue];
}
export { useLocalStorage };
