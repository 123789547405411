var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useMemo, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { EXPERIENCE_HEADER_IMAGE, EXPERIENCE_HEADER_IMAGE_UPDATE, } from '@vizsla/graphql';
import { useExperienceId } from '../experiences';
export function useExperienceHeaderImage() {
    const id = useExperienceId();
    const [data, setData] = useState();
    const [fetching, setFetching] = useState(true);
    const [updating, setUpdating] = useState(false);
    const [find] = useLazyQuery(EXPERIENCE_HEADER_IMAGE);
    const [execute] = useMutation(EXPERIENCE_HEADER_IMAGE_UPDATE);
    useEffect(() => {
        if (id) {
            fetchByExperience(id);
        }
    }, [id]);
    const hasData = useMemo(() => { var _a; return Boolean((_a = data === null || data === void 0 ? void 0 : data.downloadUrl) === null || _a === void 0 ? void 0 : _a.length); }, [data]);
    function fetchByExperience(id) {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function* () {
            setFetching(true);
            const response = yield find({
                variables: { id },
            });
            const result = (_c = (_b = (_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.experience) === null || _b === void 0 ? void 0 : _b.experienceHeaderImage) !== null && _c !== void 0 ? _c : undefined;
            setFetching(false);
            setData(result);
        });
    }
    function detach() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!data)
                return;
            setUpdating(true);
            try {
                const payload = {
                    fileId: data.fileId,
                };
                yield execute({
                    variables: {
                        filter: { id },
                        data: {
                            experienceHeaderImage: {
                                disconnect: payload,
                            },
                        },
                    },
                });
                setUpdating(false);
                setData(undefined);
            }
            catch (err) {
                setUpdating(false);
                throw err;
            }
        });
    }
    function attach(file) {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function* () {
            setUpdating(true);
            try {
                const payload = {
                    fileId: file.fileId,
                    filename: file.filename,
                    meta: file.meta,
                    mods: file.mods,
                };
                const result = yield execute({
                    variables: {
                        filter: { id },
                        data: {
                            experienceHeaderImage: {
                                create: payload,
                            },
                        },
                    },
                });
                const response = (_c = (_b = (_a = result === null || result === void 0 ? void 0 : result.data) === null || _a === void 0 ? void 0 : _a.experience) === null || _b === void 0 ? void 0 : _b.experienceHeaderImage) !== null && _c !== void 0 ? _c : {};
                if ('downloadUrl' in response) {
                    setUpdating(false);
                    setData(response);
                }
            }
            catch (err) {
                setUpdating(false);
                throw err;
            }
        });
    }
    function replace(file) {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function* () {
            if (!data)
                return;
            setUpdating(true);
            try {
                const payload = {
                    fileId: file.fileId,
                };
                const actual = {
                    fileId: data.fileId,
                };
                const result = yield execute({
                    variables: {
                        filter: { id },
                        data: {
                            experienceHeaderImage: {
                                disconnect: actual,
                                create: payload,
                            },
                        },
                    },
                });
                const response = (_c = (_b = (_a = result === null || result === void 0 ? void 0 : result.data) === null || _a === void 0 ? void 0 : _a.experience) === null || _b === void 0 ? void 0 : _b.experienceHeaderImage) !== null && _c !== void 0 ? _c : {};
                if ('downloadUrl' in response) {
                    setUpdating(false);
                    setData(response);
                }
            }
            catch (err) {
                setUpdating(false);
                throw err;
            }
        });
    }
    return {
        data,
        hasData,
        fetching,
        updating,
        detach,
        attach,
        replace,
    };
}
