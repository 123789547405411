export var InboxMessageFilterOption;
(function (InboxMessageFilterOption) {
    InboxMessageFilterOption["All"] = "all";
    InboxMessageFilterOption["Unread"] = "unread";
    InboxMessageFilterOption["Read"] = "read";
    InboxMessageFilterOption["Sent"] = "sent";
})(InboxMessageFilterOption || (InboxMessageFilterOption = {}));
export var InboxTypeFilterOption;
(function (InboxTypeFilterOption) {
    InboxTypeFilterOption["All"] = "all";
    InboxTypeFilterOption["DirectMessage"] = "Direct message";
    InboxTypeFilterOption["NewDonor"] = "New donor";
    InboxTypeFilterOption["ReturningDonor"] = "Returning donor";
    InboxTypeFilterOption["FromOrganizations"] = "From organizations";
})(InboxTypeFilterOption || (InboxTypeFilterOption = {}));
