export const ROOT_DOMAIN = 'vizsla.com';
export const PRIVATE_SUBDOMAINS = {
    production: `${ROOT_DOMAIN}`,
    staging: `staging.${ROOT_DOMAIN}`,
    qa1: `qa1.${ROOT_DOMAIN}`,
    qa2: `qa2.${ROOT_DOMAIN}`,
    qa3: `qa3.${ROOT_DOMAIN}`,
    qa4: `qa4.${ROOT_DOMAIN}`,
    localhost: 'localhost:3001',
};
export const PUBLIC_SUBDOMAINS = {
    [PRIVATE_SUBDOMAINS.production]: `public-app.${ROOT_DOMAIN}`,
    [PRIVATE_SUBDOMAINS.staging]: `public-staging.${ROOT_DOMAIN}`,
    [PRIVATE_SUBDOMAINS.qa1]: `public-qa1.${ROOT_DOMAIN}`,
    [PRIVATE_SUBDOMAINS.qa2]: `public-qa2.${ROOT_DOMAIN}`,
    [PRIVATE_SUBDOMAINS.qa3]: `public-qa3.${ROOT_DOMAIN}`,
    [PRIVATE_SUBDOMAINS.qa4]: `public-qa4.${ROOT_DOMAIN}`,
    [PRIVATE_SUBDOMAINS.localhost]: 'localhost:3003',
};
