var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { useApolloClient } from '@apollo/client';
import { useChangeUserCurrentAppMutation, CURRENT_USER_QUERY } from '@vizsla/graphql';
import { useCurrentUser } from '..';
export const useChangeUserCurrentApp = () => {
    const apolloClient = useApolloClient();
    const { user, loading: userLoading } = useCurrentUser();
    const [changeUserCurrentAppMutation, { loading: updating }] = useChangeUserCurrentAppMutation();
    const changeUserCurrentApp = React.useCallback((applicationId) => __awaiter(void 0, void 0, void 0, function* () {
        if (!user)
            return;
        yield changeUserCurrentAppMutation({
            variables: {
                userId: user.id || '',
                applicationId,
            },
        });
        apolloClient.writeQuery({
            query: CURRENT_USER_QUERY,
            data: {
                user: Object.assign(Object.assign({}, user), { currentApplication: applicationId }),
            },
        });
    }), [apolloClient, user, changeUserCurrentAppMutation]);
    return {
        userLoading,
        updating,
        changeUserCurrentApp,
    };
};
