import { gql } from '@apollo/client';
const DONATION_OPTION_FRAGMENT = gql `
  fragment DonationOptionFragment on DonationOption {
    id
    price
    description
    image {
      id
      downloadUrl
    }
  }
`;
export const DONATION_OPTION_QUERY = gql `
  query donationOptionById($id: ID!) {
    donationOption(id: $id) {
      ...DonationOptionFragment
    }
  }
  ${DONATION_OPTION_FRAGMENT}
`;
export const DONATION_OPTIONS_LIST_QUERY = gql `
  query DonationOptionsList($filter: DonationOptionFilter) {
    donationOptionsList(filter: $filter) {
      items {
        ...DonationOptionFragment
      }
    }
  }
  ${DONATION_OPTION_FRAGMENT}
`;
export const DONATION_OPTION_UPDATE_MUTATION = gql `
  mutation DonationOptionUpdate($data: DonationOptionUpdateInput!) {
    donationOptionUpdate(data: $data) {
      ...DonationOptionFragment
    }
  }
  ${DONATION_OPTION_FRAGMENT}
`;
export const DONATION_OPTION_CREATE_MUTATION = gql `
  mutation DonationOptionCreate($data: DonationOptionCreateInput!) {
    donationOptionCreate(data: $data) {
      ...DonationOptionFragment
    }
  }
  ${DONATION_OPTION_FRAGMENT}
`;
export const DONATION_OPTION_DELETE_MUTATION = gql `
  mutation DonationOptionDelete($id: ID!) {
    donationOptionDelete(filter: { id: $id }) {
      success
    }
  }
`;
