import { useCallback, useContext, useMemo } from 'react';
import { ModalsContext } from '@vizsla/providers';
export function useModal(name) {
    var _a;
    const { openModal, closeModal, state: context } = useContext(ModalsContext);
    const state = useMemo(() => context[name !== null && name !== void 0 ? name : ''], [context, name]);
    const open = useCallback((id, params) => {
        if (id)
            return openModal(id, params);
        if (name)
            return openModal(name, params);
        return undefined;
    }, [name, openModal]);
    const close = useCallback((id) => {
        if (typeof id === 'string')
            return closeModal(id);
        if (typeof name === 'string')
            return closeModal(name);
        return undefined;
    }, [name, closeModal]);
    return {
        isOpen: (_a = state === null || state === void 0 ? void 0 : state.isOpen) !== null && _a !== void 0 ? _a : false,
        args: state === null || state === void 0 ? void 0 : state.args,
        openModal: open,
        closeModal: close,
    };
}
