var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback } from 'react';
import { useAllocatedDonationsMetricsLazyQuery } from '@vizsla/graphql';
/** Fetchs all the allocated donations metrics to someone. */
export function useAllocatedDonationsMetrics() {
    const [executeQuery] = useAllocatedDonationsMetricsLazyQuery();
    /** Fetchs all the metrics of allocated donations into a fundraising team. */
    const byTeamFundraising = useCallback((teamId, options) => __awaiter(this, void 0, void 0, function* () {
        var _a, _b, _c, _d;
        const filters = (_a = options === null || options === void 0 ? void 0 : options.filters) !== null && _a !== void 0 ? _a : [];
        const filter = {
            AND: [
                {
                    teamFundraising: { id: { equals: teamId } },
                },
                {
                    parents: { none: null },
                },
            ],
        };
        const response = yield executeQuery({
            variables: { filter: { AND: [...filters, filter] } },
        });
        return (_d = (_c = (_b = response.data) === null || _b === void 0 ? void 0 : _b.donations) === null || _c === void 0 ? void 0 : _c.groups) !== null && _d !== void 0 ? _d : [];
    }), [executeQuery]);
    /** Fetchs all the metrics of allocated donations into a fundraiser. */
    const byIndividualFundraising = useCallback((individualFundraisingId, options) => __awaiter(this, void 0, void 0, function* () {
        var _e, _f, _g, _h;
        const filters = (_e = options === null || options === void 0 ? void 0 : options.filters) !== null && _e !== void 0 ? _e : [];
        const filter = {
            individualFundraising: {
                id: {
                    equals: individualFundraisingId,
                },
            },
        };
        const response = yield executeQuery({
            variables: { filter: { AND: [...filters, filter] } },
        });
        return (_h = (_g = (_f = response.data) === null || _f === void 0 ? void 0 : _f.donations) === null || _g === void 0 ? void 0 : _g.groups) !== null && _h !== void 0 ? _h : [];
    }), [executeQuery]);
    return {
        byTeamFundraising,
        byIndividualFundraising,
    };
}
