/**
 * Function to save a file to the user's computer from web browser
 */
export function saveFile(file, fileName) {
    const a = document.createElement('a');
    const url = URL.createObjectURL(file);
    a.setAttribute('href', url);
    a.setAttribute('download', fileName);
    a.style.visibility = 'hidden';
    a.click();
    URL.revokeObjectURL(url);
}
