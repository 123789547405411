import React from 'react';
import { Parser } from 'json2csv';
import { saveFile } from '@vizsla/utils';
const dataToCsv = (data, fieldsOpts) => {
    const CsvParser = new Parser(fieldsOpts);
    return CsvParser.parse(data);
};
export function useDownloadLists(columns, data, activeTab) {
    const fields = ['email'];
    const header = [{ label: 'Email', value: 'email' }];
    const dataFormat = [];
    for (let index = 0; index < columns.length; index += 1) {
        const element = columns[index];
        fields.push(element.key);
        header.push({ label: element.title.props.children, value: element.key });
    }
    for (let idx = 0; idx < data.length; idx += 1) {
        const item = data[idx];
        const dataParse = {};
        for (let index = 0; index < fields.length; index += 1) {
            const element = fields[index];
            if (element === 'experience' || element === 'organization') {
                dataParse[element] = item[element].name;
            }
            else if (element === 'name') {
                dataParse[element] = `${item.user.firstName} ${item.user.lastName}`;
            }
            else if (element === 'email') {
                dataParse[element] = item.user.email;
            }
            else {
                dataParse[element] = item[element];
            }
        }
        dataFormat.push(dataParse);
    }
    const optFields = {
        fields: header,
        defaultValue: '-',
        header: true,
    };
    return React.useCallback(() => {
        const csv = dataToCsv(dataFormat, optFields);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const filename = `${activeTab}-${new Date().toISOString()}.csv`;
        saveFile(blob, filename);
    }, [dataFormat, optFields]);
}
