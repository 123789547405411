import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { GeneralLandingActions, INITIAL_STATE, INITIAL_THEME, } from './LandingState.types';
import { LandingStateContext } from './LandingStateContext';
import { ExperienceLandingContext } from '../ExperienceLandingProvider';
function convertTemplateStructureToLandingState(templateStructure) {
    var _a, _b, _c;
    if (!templateStructure) {
        return INITIAL_STATE;
    }
    return {
        theme: ((_a = templateStructure === null || templateStructure === void 0 ? void 0 : templateStructure.structure) === null || _a === void 0 ? void 0 : _a.appSchema.theme) || INITIAL_THEME,
        nodes: (_b = templateStructure === null || templateStructure === void 0 ? void 0 : templateStructure.structure) === null || _b === void 0 ? void 0 : _b.appSchema.nodes,
        templateTitle: (_c = templateStructure === null || templateStructure === void 0 ? void 0 : templateStructure.templateType) === null || _c === void 0 ? void 0 : _c.title,
        templateStructureId: templateStructure === null || templateStructure === void 0 ? void 0 : templateStructure.id,
    };
}
const LandingStateProvider = ({ children, landingReducer, }) => {
    const { templateStructure } = React.useContext(ExperienceLandingContext);
    const [state, dispatch] = React.useReducer(landingReducer, INITIAL_STATE);
    React.useEffect(() => {
        dispatch({
            type: GeneralLandingActions.set,
            payload: convertTemplateStructureToLandingState(templateStructure),
        });
    }, [templateStructure]);
    const reset = () => {
        dispatch({
            type: GeneralLandingActions.set,
            payload: convertTemplateStructureToLandingState(templateStructure),
        });
    };
    const value = { state, dispatch, reset };
    return _jsx(LandingStateContext.Provider, Object.assign({ value: value }, { children: children }));
};
export { LandingStateProvider };
