import { gql } from '@apollo/client';
import { FILE_URL_FRAGMENT } from '../file';
import { USER_FRAGMENT } from '../users';
import { TEAM_FUNDRAISING_FRAGMENT, TEAM_MEMBERSHIP_FRAGMENT } from './teamFundraising';
export const JOIN_TEAM_FUNDRAISING_MUTATION = gql `
  ${USER_FRAGMENT}
  ${FILE_URL_FRAGMENT}
  ${TEAM_FUNDRAISING_FRAGMENT}
  ${TEAM_MEMBERSHIP_FRAGMENT}

  mutation JoinTeamFundraising($membership: CampaignTeamMemberCreateInput!) {
    membership: campaignTeamMemberCreate(data: $membership) {
      ...TEAM_MEMBERSHIP_FRAGMENT
    }
  }
`;
