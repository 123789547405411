export const mapInboxMessageData = (message) => {
    var _a, _b;
    return ({
        id: message.id,
        createdAt: message.createdAt,
        body: message.body,
        createdBy: message.createdBy,
        readBy: (_b = (_a = message.readBy) === null || _a === void 0 ? void 0 : _a.items) !== null && _b !== void 0 ? _b : [],
    });
};
export const mapInboxData = (inbox) => {
    var _a, _b, _c, _d;
    const inboxMessages = (_b = (_a = inbox.inboxMessages) === null || _a === void 0 ? void 0 : _a.items) !== null && _b !== void 0 ? _b : [];
    return {
        id: inbox.id,
        type: inbox.type,
        recipients: ((_d = (_c = inbox.recipients) === null || _c === void 0 ? void 0 : _c.items) !== null && _d !== void 0 ? _d : []),
        inboxMessages: inboxMessages.map(mapInboxMessageData),
        isNew: !(inbox === null || inbox === void 0 ? void 0 : inbox.id),
    };
};
