export var CampaignType;
(function (CampaignType) {
    CampaignType["registrationAndTicketing"] = "Registration And Ticketing";
    CampaignType["personalMission"] = "Personal Mission";
    CampaignType["impactGiving"] = "Impact Giving";
})(CampaignType || (CampaignType = {}));
export var CampaignStatus;
(function (CampaignStatus) {
    CampaignStatus["draft"] = "Draft";
    CampaignStatus["paused"] = "Paused";
    CampaignStatus["live"] = "Live";
    CampaignStatus["completed"] = "Completed";
})(CampaignStatus || (CampaignStatus = {}));
export var CampaignGoal;
(function (CampaignGoal) {
    CampaignGoal["fundraising"] = "fundraisingGoal";
    CampaignGoal["fundraiser"] = "fundraiserGoal";
    CampaignGoal["attendanceFundraisers"] = "attendanceFundraisersCountGoal";
    CampaignGoal["team"] = "teamGoal";
    CampaignGoal["donor"] = "donorGoal";
})(CampaignGoal || (CampaignGoal = {}));
export var CampaignFundraisingSettingsTypes;
(function (CampaignFundraisingSettingsTypes) {
    CampaignFundraisingSettingsTypes["donationSettings"] = "donationSettings";
    CampaignFundraisingSettingsTypes["generalSettings"] = "generalSettings";
    CampaignFundraisingSettingsTypes["teamSettings"] = "teamSettings";
    CampaignFundraisingSettingsTypes["individualSettings"] = "individualSettings";
})(CampaignFundraisingSettingsTypes || (CampaignFundraisingSettingsTypes = {}));
export var RegistrationAndTicketingRecentActivityTab;
(function (RegistrationAndTicketingRecentActivityTab) {
    RegistrationAndTicketingRecentActivityTab["Attendees"] = "Attendees";
    RegistrationAndTicketingRecentActivityTab["Fundraisers"] = "Fundraisers";
    RegistrationAndTicketingRecentActivityTab["Teams"] = "Teams";
    RegistrationAndTicketingRecentActivityTab["Donations"] = "Donations";
})(RegistrationAndTicketingRecentActivityTab || (RegistrationAndTicketingRecentActivityTab = {}));
export var PersonalMissionRecentActivityTabKey;
(function (PersonalMissionRecentActivityTabKey) {
    PersonalMissionRecentActivityTabKey["Fundraisers"] = "Fundraisers";
    PersonalMissionRecentActivityTabKey["Teams"] = "Teams";
    PersonalMissionRecentActivityTabKey["Donations"] = "Donations";
})(PersonalMissionRecentActivityTabKey || (PersonalMissionRecentActivityTabKey = {}));
export var ImpactGivingRecentActivityTab;
(function (ImpactGivingRecentActivityTab) {
    ImpactGivingRecentActivityTab["Donations"] = "Donations";
})(ImpactGivingRecentActivityTab || (ImpactGivingRecentActivityTab = {}));
export var CampaignFaqAllocation;
(function (CampaignFaqAllocation) {
    CampaignFaqAllocation["Attendee"] = "Attendee";
    CampaignFaqAllocation["Donor"] = "Donor";
})(CampaignFaqAllocation || (CampaignFaqAllocation = {}));
export var PeopleTablesTab;
(function (PeopleTablesTab) {
    PeopleTablesTab["Attendees"] = "Attendees";
    PeopleTablesTab["Fundraisers"] = "Fundraisers";
    PeopleTablesTab["Teams"] = "Teams";
    PeopleTablesTab["Donors"] = "Donors";
})(PeopleTablesTab || (PeopleTablesTab = {}));
export var CampaignActionName;
(function (CampaignActionName) {
    CampaignActionName["deleteCampaign"] = "Delete Campaign";
})(CampaignActionName || (CampaignActionName = {}));
