import React from 'react';
export var InboxEvents;
(function (InboxEvents) {
    InboxEvents["MessageReceived"] = "MessageReceived";
    InboxEvents["MessageSent"] = "MessageSent";
    InboxEvents["MessageDeleted"] = "MessageDeleted";
    InboxEvents["InboxDeleted"] = "InboxDeleted";
})(InboxEvents || (InboxEvents = {}));
// create an event bus to handle Inbox events
const BUS = {
    [InboxEvents.MessageReceived]: [],
    [InboxEvents.MessageSent]: [],
    [InboxEvents.MessageDeleted]: [],
    [InboxEvents.InboxDeleted]: [],
};
export function useEventBus() {
    const unsubscribe = React.useCallback((key, handler) => {
        if (Object.hasOwn(BUS, key)) {
            BUS[key] = BUS[key].filter(h => h !== handler);
        }
    }, []);
    const subscribe = React.useCallback((key, handler) => {
        BUS[key].push(handler);
        return () => unsubscribe(key, handler);
    }, [unsubscribe]);
    const publish = React.useCallback((key, ...payload) => {
        BUS[key].forEach(handler => handler(...payload));
    }, []);
    return { subscribe, unsubscribe, publish };
}
