import { InboxMessageFilterOption, InboxTypeFilterOption } from '@vizsla/types';
import { INBOX_MESSAGE_FILTER_OPTIONS, INBOX_TYPES_FILTER_OPTIONS } from '@vizsla/constants';
const isNotEmpty = (obj) => Object.keys(obj).length > 0;
export function buildInboxFilter({ userFilter, filters, search, }) {
    const whereConditions = [
        { deletedBy: { none: { is_self: true } } },
        { recipients: { some: { is_self: true } } },
        addSearch(search),
        addFilters(filters),
        addUserFilter(userFilter),
    ].filter(isNotEmpty);
    if (whereConditions.length === 0)
        return {};
    return { AND: whereConditions };
}
const addSearch = (search) => {
    if (!search)
        return {};
    return {
        recipients: { some: { fullName: { contains: search }, is_self: false } },
    };
};
const addFilters = (filters) => {
    if (filters.length === 0)
        return {};
    const conditions = [
        addInboxFilters(filters, INBOX_MESSAGE_FILTER_OPTIONS, mapInboxMessageFilter),
        addInboxFilters(filters, INBOX_TYPES_FILTER_OPTIONS, mapInboxTypeFilter),
    ].filter(isNotEmpty);
    if (conditions.length === 0)
        return {};
    return {
        AND: conditions,
    };
};
const addInboxFilters = (filter, allowed, fn) => {
    const inboxFilters = filter.filter(filter => allowed.includes(filter));
    if (inboxFilters.length === 0)
        return {};
    return {
        OR: inboxFilters.map(fn),
    };
};
const mapInboxMessageFilter = (filter) => {
    if (filter === InboxMessageFilterOption.All)
        return {};
    if (filter === InboxMessageFilterOption.Unread) {
        return {
            inboxMessages: { some: { readBy: { none: { is_self: true } } } },
        };
    }
    if (filter === InboxMessageFilterOption.Read) {
        return {
            inboxMessages: { some: { readBy: { some: { is_self: true } } } },
        };
    }
    if (filter === InboxMessageFilterOption.Sent) {
        return {
            inboxMessages: { some: { createdBy: { is_self: true } } },
        };
    }
    return {};
};
const mapInboxTypeFilter = (filter) => {
    if (filter === InboxTypeFilterOption.All)
        return {};
    return { type: { equals: filter } };
};
const addUserFilter = (userFilter) => {
    if (!userFilter)
        return {};
    return { recipients: { some: userFilter } };
};
