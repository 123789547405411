import { DiscountAmountTypes, } from '@vizsla/types';
function totalize(cart) {
    return cart.reduce((acc, prev) => {
        acc += prev.price;
        return acc;
    }, 0);
}
export function calculateShoppingCartSubTotal(cart) {
    const items = cart.filter(i => i.type !== 'discount');
    return totalize(items);
}
/**
 * Apply all the rules of [VSL-794](https://8base-dev.atlassian.net/browse/VSL-794) to totalize the discount amount.
 */
export function calculateShoppingCartDiscountTotal(cart) {
    var _a;
    const registrations = cart.filter(i => i.type === 'registration');
    const discount = cart.find(i => i.type === 'discount');
    const subtotal = totalize(registrations);
    if (!(discount === null || discount === void 0 ? void 0 : discount.settings))
        return 0;
    switch ((_a = discount === null || discount === void 0 ? void 0 : discount.settings) === null || _a === void 0 ? void 0 : _a.amountType) {
        case DiscountAmountTypes.dollar: {
            const amount = Math.max(discount.settings.amount, 0);
            return amount;
        }
        case DiscountAmountTypes.percentage: {
            const percentage = Math.max(discount.settings.amount, 0) / 100;
            return subtotal * percentage;
        }
        default: {
            return 0;
        }
    }
}
export function calculateShoppingCartTotal(cart) {
    const subtotal = calculateShoppingCartSubTotal(cart);
    const discount = calculateShoppingCartDiscountTotal(cart);
    return subtotal <= 0 ? subtotal : subtotal - discount;
}
