import { gql } from '@apollo/client';
export const INDIVIDUAL_FUNDRAISING_CONTENT_FRAGMENT = gql `
  fragment INDIVIDUAL_FUNDRAISING_CONTENT_FRAGMENT on IndividualFundraising {
    id
    title
    description
    goal
    webLink
  }
`;
export const INDIVIDUAL_FUNDRAISING_HEADER_FRAGMENT = gql `
  fragment INDIVIDUAL_FUNDRAISING_HEADER_FRAGMENT on IndividualFundraising {
    logo {
      ...FileURLFragment
    }

    hero {
      ...FileURLFragment
    }
  }
`;
export const INDIVIDUAL_FUNDRAISING_GALLERY_FRAGMENT = gql `
  fragment INDIVIDUAL_FUNDRAISING_GALLERY_FRAGMENT on IndividualFundraising {
    gallery {
      items {
        ...FileURLFragment
      }
    }
  }
`;
