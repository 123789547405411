export var PaymentMethod;
(function (PaymentMethod) {
    PaymentMethod["cash"] = "cash";
    PaymentMethod["check"] = "check";
    PaymentMethod["card"] = "card";
})(PaymentMethod || (PaymentMethod = {}));
export var Description;
(function (Description) {
    Description["registrationOption"] = "Registration Option";
    Description["donation"] = "Donation";
    Description["swagbag"] = "Swagbag";
    Description["asset"] = "Asset";
    Description["refund"] = "Refund";
    Description["note"] = "Note";
    Description["discount"] = "Discount";
})(Description || (Description = {}));
export var OrderStatus;
(function (OrderStatus) {
    OrderStatus["pending"] = "Pending";
    OrderStatus["canceled"] = "Canceled";
    OrderStatus["refused"] = "Refused";
    OrderStatus["delivery"] = "Delivery";
    OrderStatus["completed"] = "Completed";
    OrderStatus["refunded"] = "Refunded";
    OrderStatus["paid"] = "Paid";
})(OrderStatus || (OrderStatus = {}));
export var OrderItemCategory;
(function (OrderItemCategory) {
    OrderItemCategory["DEBIT"] = "Debit";
    OrderItemCategory["CREDIT"] = "Credit";
})(OrderItemCategory || (OrderItemCategory = {}));
export var OrderItemDescription;
(function (OrderItemDescription) {
    OrderItemDescription["REGISTRATION_OPTION"] = "Registration Option";
    OrderItemDescription["DONATION"] = "Donation";
    OrderItemDescription["SWAGBAG"] = "Swagbag";
    OrderItemDescription["ASSET"] = "Asset";
    OrderItemDescription["REFUND"] = "Refund";
    OrderItemDescription["NOTE"] = "Note";
    OrderItemDescription["DISCOUNT"] = "Discount";
})(OrderItemDescription || (OrderItemDescription = {}));
