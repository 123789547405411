import { gql } from '@apollo/client';
export const PUBLIC_CLIENT_CAMPAIGN_FRAGMENT = gql `
  fragment PublicClientCampaignFragment on Campaign {
    id
    name
    code
    contactEmail
    contactPhone {
      code
      number
    }
    logo {
      id
      downloadUrl
    }
    image {
      id
      downloadUrl
    }
    city
    state
    campaignType
    fundraisingGoal
    fundraiserGoal
    attendanceFundraisersCountGoal
    teamGoal
    donorGoal
    totalRaised
    startDate
    endDate
    campaignStatus
    createdAt
    updatedAt
    createdBy {
      id
      firstName
      lastName
    }
  }
`;
const CLIENT_CAMPAIGN_FRAGMENT = gql `
  fragment ClientCampaignFragment on Campaign {
    id
    name
    code
    contactEmail
    contactPhone {
      code
      number
    }
    logo {
      id
      downloadUrl
    }
    image {
      id
      downloadUrl
    }
    city
    state
    campaignType
    fundraisingGoal
    fundraiserGoal
    attendanceFundraisersCountGoal
    teamGoal
    teams {
      count
      items {
        id
        createdAt
      }
    }
    donorGoal
    donors {
      count
    }
    totalRaised
    startDate
    endDate
    campaignStatus
    webLink
    program {
      id
      name
    }
    createdAt
    updatedAt
    createdBy {
      id
      firstName
      lastName
    }
    experiences {
      items {
        id
      }
    }
    dnsRecord {
      id
      slug
    }
    campaignTemplates {
      id
      templates {
        items {
          id
          templateType {
            type
            title
          }
        }
      }
    }
  }
`;
export const CLIENT_CAMPAIGN_QUERY = gql `
  query ClientCampaign($id: ID!) {
    campaign(id: $id) {
      ...ClientCampaignFragment
    }
  }
  ${CLIENT_CAMPAIGN_FRAGMENT}
`;
export const PUBLIC_TEMPLATES_BY_CAMPAIGN_ID_QUERY = gql `
  query PublicTemplatesByCampaignId($campaignId: ID!) {
    campaign(id: $campaignId) {
      campaignTemplates {
        id
        templates {
          items {
            id
            templateType {
              type
              title
            }
          }
        }
      }
    }
  }
`;
export const CLIENT_SINGLE_CAMPAIGN_STATISTICS_QUERY = gql `
  query ClientSingleCampaignStatistics($campaignId: ID!) {
    attendeesList(filter: { experience: { campaign: { id: { equals: $campaignId } } } }) {
      count
      items {
        id
        createdAt
      }
    }
    fundraisersList: attendeesList(
      filter: {
        experience: { campaign: { id: { equals: $campaignId } } }
        fundraisingPageLink: { is_not_empty: true }
      }
    ) {
      count
      items {
        id
        createdAt
      }
    }
  }
`;
