import { useMemo } from 'react';
import { useInboxesListQuery } from '@vizsla/graphql';
import { useInboxFilters } from '../context/InboxFiltersContext';
import { buildInboxFilter } from '../utils';
export function useInboxesQuery() {
    const { search, filters, userFilter } = useInboxFilters();
    const { data: response, loading, error, refetch, } = useInboxesListQuery({
        variables: {
            filter: buildInboxFilter({ filters, userFilter, search }),
        },
    });
    const data = useMemo(() => { var _a, _b; return ((_b = (_a = response === null || response === void 0 ? void 0 : response.inboxesList) === null || _a === void 0 ? void 0 : _a.items) !== null && _b !== void 0 ? _b : []); }, [response]);
    return { data, loading, error, refetch };
}
