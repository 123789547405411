import _ from 'lodash';
export const { isFunction, isUndefined, isString, isNumber, isObject, isBoolean } = _;
export function isNotNilAndEmptyAll(array) {
    return array.every(value => {
        if (isNumber(value)) {
            return true;
        }
        return !_.isNil(value) && !_.isEmpty(value);
    });
}
export function delay(ms) {
    return new Promise(resolve => {
        return setTimeout(resolve, ms);
    });
}
export function removeNullablePropertiesFromObject(obj) {
    const newObj = {};
    for (const key in obj) {
        if (!_.isNull(obj[key])) {
            newObj[key] = obj[key];
        }
    }
    return newObj;
}
export function removeEmptyPropertiesFromObject(obj) {
    const newObj = {};
    for (const key in obj) {
        if (!_.isEmpty(obj[key])) {
            newObj[key] = obj[key];
        }
    }
    return newObj;
}
export const JSONParseSafe = (text) => {
    if (text) {
        try {
            return JSON.parse(text);
        }
        catch (e) {
            // eslint-disable-next-line no-console
            console.error(`unable to parse JSON ${text}`);
        }
    }
    return undefined;
};
export function replaceIfNil(value, replacement = '-') {
    if (_.isNil(value)) {
        return replacement;
    }
    return value;
}
export function getLocationPaths(pathname, fixedPathNumber = 0) {
    return pathname
        .replace(/\/$/, '')
        .split('/')
        .filter(Boolean)
        .reduce((result, path, index) => [
        ...result,
        index < 2 - fixedPathNumber ? `/${path}` : `${result[result.length - 1]}/${path}`,
    ], []);
}
export function parseHtmlText(htmlText) {
    const parser = new DOMParser();
    return parser.parseFromString(htmlText, 'text/html').documentElement.textContent;
}
