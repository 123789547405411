import React from 'react';
export const DEFAULT_COUNT = 0;
export const DEFAULT_PAGE = 0;
export const DEFAULT_ROWS_PER_PAGE = 10;
export const DEFAULT_ROWS_PER_PAGE_OPTIONS = [5, 10, 25, 50];
export const PaginationContext = React.createContext({
    count: DEFAULT_COUNT,
    page: DEFAULT_PAGE,
    rowsPerPage: DEFAULT_ROWS_PER_PAGE,
    rowsPerPageOptions: DEFAULT_ROWS_PER_PAGE_OPTIONS,
    onCountChange: () => { },
    onPageChange: () => { },
    onRowsPerPageChange: () => { },
});
