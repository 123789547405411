import { generatePath } from 'react-router-dom';
import qs from 'qs';
import { SPECIAL_SYMBOLS_REG_EXP } from '@vizsla/constants';
/**
 * Build url with query and path params.
 *
 * @example
 * buildUrl('/products/:id', { id: 123 }, { filter: 'someFilter' }) // "/products/123?filter=someFilter"
 */
export function buildUrl(path, pathParams, queryParams) {
    let query = '';
    if (queryParams) {
        const queryParamsString = qs.stringify(queryParams);
        query = queryParamsString.length > 0 ? `?${queryParamsString}` : '';
    }
    const generatedPath = generatePath(path, pathParams);
    return `${generatedPath}${query}`;
}
/**
 * Generate DNS slug
 *
 * @example
 *  const slug = generateSlug("My Awesome Campaign/[2021");
 * @returns {string} "my-awesome-campaign2021"
 */
export const generateSlug = (string) => {
    return string.trim().replace(SPECIAL_SYMBOLS_REG_EXP, '').replace(/ /g, '-').toLowerCase();
};
/**
 * @example
 *  const origin = extractOriginFromURL("https://staging.vizsla.com/home");
 * @returns {string} "https://staging.vizsla.com"
 */
export const extractOriginFromURL = (urlString) => {
    try {
        const url = new URL(urlString);
        return url.origin;
    }
    catch (error) {
        console.error({ error });
        return '';
    }
};
/**
 * @example
 *  const origin = getOriginFromUrl("https://staging.vizsla.com/home");
 * @returns {string} "https://staging.vizsla.com"
 */
export const getOriginFromUrl = extractOriginFromURL;
/**
 * @example
 *  const newURL = addSlugToURL("https://vizsla.com", "staging");
 * @returns "https://staging.vizsla.com"
 */
export function addSlugToURL(url, ...slugs) {
    const { host, protocol } = new URL(url);
    const slug = slugs.join('.');
    return `${protocol}//${slug}.${host}`;
}
