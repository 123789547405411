import { useDonationOptionByIdQuery } from '@vizsla/graphql';
function useDonationOptionById(id) {
    var _a;
    const { data, loading } = useDonationOptionByIdQuery({
        variables: {
            id,
        },
    });
    const donationOption = (_a = data === null || data === void 0 ? void 0 : data.donationOption) !== null && _a !== void 0 ? _a : null;
    return { donationOption, loading };
}
export { useDonationOptionById };
