var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { gql, useMutation } from '@apollo/client';
import { calculateShoppingCartDiscountTotal, calculateShoppingCartSubTotal, calculateShoppingCartTotal, } from '@vizsla/utils';
import { createCartStore } from '../cart';
const useStore = createCartStore();
const CHECKOUT_MUTATION = gql `
  mutation Checkout($experience: ExperienceKeyFilter!, $parentOrder: OrderKeyFilter, $cart: JSON!) {
    checkout: checkoutShoppingCart(
      data: { experience: $experience, parentOrder: $parentOrder, shoppingCart: $cart }
    ) {
      needPayment

      paymentIntent {
        provider
        metadata
      }
    }
  }
`;
export function useShoppingCart() {
    const { items: cart, add, remove, edit, editMany, clean } = useStore();
    const [executeCheckout] = useMutation(CHECKOUT_MUTATION);
    const subtotal = () => {
        return calculateShoppingCartSubTotal(cart);
    };
    const discounted = () => {
        return calculateShoppingCartDiscountTotal(cart);
    };
    const total = () => {
        return calculateShoppingCartTotal(cart);
    };
    /** Send the `checkoutShoppingCart` with the actual shopping cart. */
    const checkout = (options) => __awaiter(this, void 0, void 0, function* () {
        var _a;
        const response = yield executeCheckout({
            variables: {
                experience: options.experience,
                parentOrder: options.parentOrder,
                cart,
            },
        });
        return (_a = response.data) === null || _a === void 0 ? void 0 : _a.checkout;
    });
    return {
        items: cart,
        subtotal,
        discounted,
        total,
        checkout,
        add,
        remove,
        edit,
        editMany,
        clean,
    };
}
