export function hasFieldError(meta) {
    const hasNormalError = Boolean(meta.error);
    const hasSubmitError = Boolean(meta.submitError);
    const hasBeenTouched = Boolean(meta.touched);
    const hasBeenModified = Boolean(meta.modified);
    return (hasNormalError || hasSubmitError) && (hasBeenTouched || hasBeenModified);
}
export function getFieldError(meta) {
    let messages = [];
    if (hasFieldError(meta)) {
        const errors = meta.error || meta.submitError;
        if (typeof errors === 'object')
            messages = Object.values(errors);
        if (typeof errors === 'string')
            messages.push(errors);
        if (Array.isArray(errors))
            messages = errors;
        return messages.join(' ');
    }
    return null;
}
