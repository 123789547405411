import { gql } from '@apollo/client';
export const STRIPE_CREATE_PAYMENT_INTENT_FOR_ITEM_MUTATION = gql `
  mutation StripeCreatePaymentIntentForItem(
    $amount: Float!
    $meta: JSON!
    $connectedStripeAccountId: String!
    $email: String
  ) {
    stripeCreatePaymentIntentForItem(
      amount: $amount
      meta: $meta
      connectedStripeAccountId: $connectedStripeAccountId
      email: $email
    ) {
      paymentIntent
    }
  }
`;
export const STRIPE_CREATE_CONNECT_ACCOUNT = gql `
  mutation StripeCreateConnectAccountLink(
    $refreshUrl: String!
    $returnUrl: String!
    $organizationId: ID!
  ) {
    stripeCreateConnectAccountLink(
      refreshUrl: $refreshUrl
      returnUrl: $returnUrl
      organizationId: $organizationId
    ) {
      accountLink
    }
  }
`;
export const STRIPE_RETRIEVE_ACCOUNT_QUERY = gql `
  query StripeRetrieveAccount($organizationId: ID!) {
    stripeRetrieveAccount(organizationId: $organizationId) {
      stripeAccount
    }
  }
`;
